import Box from '@components/Box';
import Card from '@components/Card';
import { CardSize } from '@components/Card/Card';
import Flex from '@components/Flex';
import IconButton from '@components/IconButton';
import Select from '@components/Select';
import { SelectOption } from '@components/Select/types';
import Typography from '@components/Typography';
import useBreakpoint, { BreakpointObject } from '@hooks/ui/useBreakpoint';
import { ArrowRightSolid16 } from '@icons/index';
import * as CSS from 'csstype';
import { useRef, useState } from 'react';
import Tooltip from 'src/common/components/Tooltip';
import { TypographyVariant } from 'src/common/components/Typography/types';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import { useReportEvent } from 'src/services/analytics';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

type MetricCardProps = {
	flavors: SelectOption[];
	metricName: string;
	metricDescription?: string;
	metricDisplayName?: string;
	onClick?: (selectedFlavor: string | undefined) => void;
	testId?: string;
	isMetricCalculated?: boolean;
	dataIntercomSequence?: number;
};

type CardStyle = {
	typographyVariant: TypographyVariant;
	selectPadding: CSS.Property.Padding;
};

const maxDescriptionLines = 2;

export function MetricCard({
	metricDisplayName,
	flavors = [],
	metricName,
	metricDescription,
	onClick,
	testId,
	isMetricCalculated,
	dataIntercomSequence = 1,
}: MetricCardProps) {
	const { reportEvent } = useReportEvent();
	const [selectedFlavor, setSelectedFlavor] = useState(flavors[0]);
	const cardSizeBreakpoints: BreakpointObject = { base: 'sm', sm: 'sm', md: 'sm', lg: 'int', xl: 'lg' };
	const breakpoint = useBreakpoint();
	const cardSize: CardSize = cardSizeBreakpoints[breakpoint];
	const descriptionRef = useRef(null);
	const isOverflowingDescription = useOnOverflow(descriptionRef, [metricDescription], undefined, false);

	const cardStyleBySize: {
		[key in CardSize]: CardStyle;
	} = {
		sm: {
			typographyVariant: 'Paragraph14SB',
			selectPadding: '20px',
		},
		md: {
			typographyVariant: 'Paragraph14SB',
			selectPadding: '20px',
		},
		int: {
			typographyVariant: 'Paragraph16SB',
			selectPadding: '24px',
		},
		lg: {
			typographyVariant: 'Paragraph16SB',
			selectPadding: '24px',
		},
	};

	function handleFlavorChange(selectedFlavor: SelectOption) {
		reportEvent({
			event: 'metric-catalog-flavor-selected',
			metaData: { selectedFlavorValue: selectedFlavor.value, metricName, metricDisplayName },
		});
		setSelectedFlavor(selectedFlavor);
	}

	const cardStyle = cardStyleBySize[cardSize];

	const shouldDisplaySelector = flavors.length > 0;
	const FlavorSelector = () => (
		<Box justifySelf="left">
			<Select
				width="160px"
				menuStyles={{
					margin: '8px 0px',
					width: 'min-content',
					minWidth: '160px',
					maxWidth: '236px',
				}}
				menuPlacement="auto"
				onChange={handleFlavorChange}
				selectedOption={selectedFlavor}
				size="small"
				options={flavors}
			></Select>
		</Box>
	);

	function handleClick() {
		onClick?.(selectedFlavor?.value);
	}
	return (
		<Flex
			width={'100%'}
			{...buildIntercomAttributes({
				area: 'catalog',
				type: 'metric',
				target: metricDisplayName ?? metricName,
				sequence: dataIntercomSequence,
			})}
		>
			<Card testId={testId} size={cardSize} onClick={handleClick} isDisabled={!isMetricCalculated}>
				<Flex minH="100%" flexDirection="column" justifyContent="space-between">
					<Box pb="8px">
						<Typography variant={cardStyle.typographyVariant}>{metricDisplayName ?? metricName}</Typography>
					</Box>
					<Flex flexDirection="column" justifyContent={'space-between'}>
						<Tooltip size="md" variant="fluid" label={isOverflowingDescription && metricDescription}>
							<Typography minLines={2} noOfLines={maxDescriptionLines} variant={'Paragraph12R'} color="gray.600">
								<Box ref={descriptionRef}> {metricDescription}</Box>
							</Typography>
						</Tooltip>
						<Flex
							mt={cardStyle.selectPadding}
							display="flex"
							justifyContent={shouldDisplaySelector ? 'space-between' : 'flex-end'}
							alignItems="center"
							//TODO: Fix with select
							minH={'38px'}
						>
							{/* TODO: Select height should include border (is 38px, should be 36px) */}
							{shouldDisplaySelector && <FlavorSelector />}
							<IconButton
								variant="regular"
								ariaLabel="Go"
								colorScheme="blue"
								size="md"
								testId={testId && `${testId}-arrow`}
								icon={<ArrowRightSolid16 />}
							></IconButton>
						</Flex>
					</Flex>
				</Flex>
			</Card>
		</Flex>
	);
}
