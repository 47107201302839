import Flex from '@components/Flex';
import Box from '@components/Box';
import colors from 'src/style/colors';
import Typography from '@components/Typography';
import { AiSparks16, PenBold16 } from '@icons/index';
import Button from 'src/common/components/Button';
import { useAskAIChatMessages } from 'src/common/components/AskAI/hooks/useAskAIChatMessages';
import { useCallback, useState } from 'react';
import Input from 'src/common/components/Input';
import classes from './AIChatMessageLayout.module.scss';
import useToast from 'src/common/hooks/ui/useToast';
import { useReportAIEvent } from 'src/common/components/AskAI/hooks/useReportAIEvent';
export function AIChatMessageLayout({
	children,
	userPrompt,
	promptContext,
	footer,
	isLast,
	onEditUserPrompt,
}: {
	children: JSX.Element[] | JSX.Element;
	userPrompt: string;
	promptContext: Record<string, any> | undefined;
	footer?: JSX.Element;
	isLast?: boolean;
	onEditUserPrompt: () => Promise<void>;
}) {
	const toast = useToast();
	const { addChatMessage } = useAskAIChatMessages();
	const { reportAskAIEvent } = useReportAIEvent();
	const [isEditing, setIsEditing] = useState(false);
	const [editedPrompt, setEditedPrompt] = useState(userPrompt);

	const onClickEditPrompt = useCallback(() => {
		if (isLast) {
			setEditedPrompt(userPrompt);
			setIsEditing(true);
		}
	}, [userPrompt, isLast]);

	const onFinishEditing = useCallback(() => {
		setIsEditing(false);
	}, []);

	const onSubmitEditedPrompt = useCallback(
		async (prompt: string) => {
			reportAskAIEvent({
				event: 'ask-ai-edit-prompt',
				metaData: {
					userPrompt: prompt,
				},
			});
			try {
				onFinishEditing();
				await onEditUserPrompt();
				addChatMessage({ userPrompt: prompt, type: 'omni', promptContext });
			} catch {
				toast({ variant: 'error', message: 'Failed to edit prompt' });
			}
		},
		[onEditUserPrompt, addChatMessage, promptContext, onFinishEditing, toast, reportAskAIEvent]
	);

	const onCancelEditing = useCallback(() => {
		reportAskAIEvent({
			event: 'ask-ai-edit-prompt-cancel',
			metaData: {
				editedPrompt,
				userPrompt,
			},
		});
		onFinishEditing();
	}, [onFinishEditing, userPrompt, reportAskAIEvent, editedPrompt]);

	return (
		<Box>
			<Flex paddingTop={'32px'} borderRadius={'8px'} gap={'32px'} direction={'column'}>
				<Flex justifyContent={'flex-end'}>
					<Flex
						direction={'row-reverse'}
						width={isEditing ? '100%' : 'fit-content'}
						justifyContent={'center'}
						alignItems={'center'}
						gap={'8px'}
						className={classes['user-prompt-container']}
					>
						{isEditing ? (
							<Flex
								direction={'column'}
								gap={'12px'}
								width={'95%'}
								backgroundColor={colors.gray[200]}
								padding={'12px'}
								borderRadius={'8px'}
							>
								<Input
									value={editedPrompt}
									onChange={setEditedPrompt}
									width={'100%'}
									backgroundColor={colors.gray[200]}
									border={'none'}
									padding={'8px'}
									height="auto"
									onEnter={onSubmitEditedPrompt}
									autoFocus={true}
								/>
								<Flex direction={'row-reverse'} gap={'8px'}>
									<Button
										size={'small'}
										variant={'solid'}
										colorScheme="darkGray"
										onClick={() => onSubmitEditedPrompt(editedPrompt)}
										_hover={{ backgroundColor: colors.gray[1100], color: colors.white[1000] }}
									>
										Save
									</Button>
									<Button
										size={'small'}
										variant={'solid'}
										colorScheme="white"
										onClick={onCancelEditing}
										color={colors.gray[1000]}
										border={'1px solid ' + colors.gray[300]}
										_hover={{ borderColor: colors.gray[500] }}
									>
										Cancel
									</Button>
								</Flex>
							</Flex>
						) : (
							<>
								<Flex
									padding={'8px 16px'}
									flexDirection={'column'}
									alignItems={'flex-start'}
									justifyContent={'flex-start'}
									gap={'12px'}
									borderRadius={'56px'}
									backgroundColor={colors.gray[200]}
									width={'fit-content'}
									_hover={{ cursor: isLast ? 'pointer' : 'default' }}
									onClick={onClickEditPrompt}
								>
									<Typography variant={'Paragraph14R'} color={colors.gray[1000]}>
										{userPrompt}
									</Typography>
								</Flex>
								{isLast && (
									<Button
										size={'xs'}
										isIconOnly
										variant={'outline'}
										onClick={onClickEditPrompt}
										color={colors.gray[800]}
										colorScheme="lightGray"
										className={classes['edit-prompt-button']}
									>
										<PenBold16 />
									</Button>
								)}
							</>
						)}
					</Flex>
				</Flex>
				<Flex direction={'row'} gap={'12px'}>
					<Flex
						border={'1px solid'}
						width={'28px'}
						height={'28px'}
						justifyContent={'center'}
						alignItems={'center'}
						borderRadius={'99999'}
						borderColor={colors.gray[300]}
						color={colors.gray[1000]}
					>
						<AiSparks16 width={'12px'} height={'12px'} />
					</Flex>
					{children}
				</Flex>
			</Flex>
			{footer && (
				<Box paddingLeft={'40px'} paddingBottom={isLast ? '24px' : undefined}>
					{footer}
				</Box>
			)}
		</Box>
	);
}
