import { ModalHeader } from '@chakra-ui/react';
import Typography from '@components/Typography';
import Button from '@components/Button';
import Flex from '@components/Flex';
import { CloseLarge16 } from '@components/Icons';
import Modal from '@components/Modal';
import colors from 'src/style/colors';
import { AffectedMetricsModalProps } from './types';
import { getAffectedMetricDataItems } from '@components/AffectedMetricsButton/utils';
import { AffectedMetric } from '@components/AffectedMetricsButton/AffectedMetric';
import { useSemanticDefinitions } from '@hooks/stores/useSemanticDefinitions';
import Scrollable from '@components/Scrollable';
import Box from '@components/Box';

export const AffectedMetricsModal = ({ isOpen, onClose, metricNames }: AffectedMetricsModalProps) => {
	const { semanticDefinitions } = useSemanticDefinitions();

	const affectedMetricsItems = getAffectedMetricDataItems(metricNames, semanticDefinitions).map(
		({ name, displayName, entity, source }, index) => (
			<Box key={`name-${index}`} marginBottom={index === metricNames.length - 1 ? '8px' : '0'}>
				<AffectedMetric name={name} displayName={displayName} entity={entity} source={source} />
			</Box>
		)
	);

	return (
		<Modal maxWidth="408px" isOpen={isOpen} onClose={onClose} isCentered>
			<ModalHeader boxShadow={'borderBottom'} p="16px">
				<Button isIconOnly variant="outline" onClick={onClose} size="inline" colorScheme="black" position={'absolute'}>
					<CloseLarge16 color={colors.gray[900]} />
				</Button>
				<Typography width={'100%'} color={'gray.1000'} textAlign={'center'} variant="DesktopH7Medium">
					Affected metrics ({metricNames.length})
				</Typography>
			</ModalHeader>
			<Flex
				width={'100%'}
				height={'480px'}
				flexDirection={'column'}
				boxShadow={'borderBottom'}
				padding={'8px 8px 0 8px'}
			>
				<Scrollable>{affectedMetricsItems}</Scrollable>
			</Flex>
			<Flex py="12px" px="24px">
				<Button size={'medium'} colorScheme="blue" variant="solid" onClick={onClose} width={'100%'}>
					Close
				</Button>
			</Flex>
		</Modal>
	);
};
