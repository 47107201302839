import { Box } from '@chakra-ui/react';
import Button from '@components/Button';
import Flex from '@components/Flex';
import { ArrowCCW16, ChevronRight16, CloseMedium16, Copy16, Dislike16, Like16 } from '@components/Icons';
import Tooltip from '@components/Tooltip';
import { AIChatMessageLayout } from '@pages/AskAI/components/AIChatMessageLayout';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, useCallback } from 'react';
import { useModal } from 'src/common/hooks/ui/useModal';
import useToast from 'src/common/hooks/ui/useToast';
import colors from 'src/style/colors';
import Divider from '../../Divider';
import { useAIAgents } from '../hooks/useAIAgents';
import { useAskAIChatMessages } from '../hooks/useAskAIChatMessages';
import { useReportAIEvent } from '../hooks/useReportAIEvent';
import {
	AskAIChatCardProps,
	isAskAIMetricDiscoveryChatMessage,
	isAskAIOmniChatMessage,
	isAskAIOntologyBuilderDimensionChatMessage,
	isAskAIOntologyBuilderEntityChatMessage,
	isAskAIOntologyBuilderRelationshipChatMessage,
} from '../types';
import { AskAIChatCardLayout } from './AskAIChatCardLayout';
import { AskAIChatEvents } from './AskAIChatEvents';
import { AskAIErrorCard } from './AskAIErrorCard';
import { AskAILoadingResults } from './AskAILoadingResults';
import { AskAIMetricDiscoveryCardContent } from './MetricDiscovery/AskAIMetricDiscoveryCardContent';
import { AskAIOmniCardContent } from './Omni/AskAIOmniCardContent';
import { AskAIOntologyBuilderDimensionCardContent } from './OntologyBuilder/AskAIOntologyBuilderDimensionCardContent';
import { AskAIOntologyBuilderEntityCardContent } from './OntologyBuilder/AskAIOntologyBuilderEntityCardContent';
import { AskAIOntologyBuilderRelationshipCardContent } from './OntologyBuilder/AskAIOntologyBuilderRelationshipCardContent';

type CardFooterProps = {
	onLike: () => void;
	onDislike: () => void;
	onRegenerate: () => void;
	onCopyToClipboard: () => void;
	isLast: boolean;
	isFullChat: boolean;
};

function CardFooter({
	chatMessageAtom,
	onLike,
	onDislike,
	onRegenerate,
	onCopyToClipboard,
	isLast,
	isFullChat,
}: Pick<AskAIChatCardProps, 'chatMessageAtom'> & CardFooterProps) {
	const { reportAskAIEvent } = useReportAIEvent();
	const chatMessage = useAtomValue(chatMessageAtom);
	const { completionTime, vote } = chatMessage;
	const { isOpen: areAgentStepsOpen, onToggle: toggleAgentSteps } = useModal();

	const onLikeWithReport = useCallback(() => {
		reportAskAIEvent({
			event: 'ask-ai-feedback',
			metaData: {
				...chatMessage,
				kind: 'positive',
			},
		});
		onLike();
	}, [chatMessage, onLike, reportAskAIEvent]);

	const onDislikeWithReport = useCallback(() => {
		reportAskAIEvent({
			event: 'ask-ai-feedback',
			metaData: {
				...chatMessage,
				kind: 'negative',
			},
		});
		onDislike();
	}, [chatMessage, onDislike, reportAskAIEvent]);

	const onRegenerateWithReport = useCallback(() => {
		reportAskAIEvent({
			event: 'ask-ai-regenerate',
			metaData: {
				...chatMessage,
			},
		});
		onRegenerate();
	}, [chatMessage, onRegenerate, reportAskAIEvent]);

	const onClickAgentSteps = useCallback(() => {
		reportAskAIEvent({
			event: 'ask-ai-agent-steps-button-click',
			metaData: {
				userPrompt: chatMessage.userPrompt,
			},
		});
		toggleAgentSteps();
	}, [chatMessage, reportAskAIEvent, toggleAgentSteps]);

	return (
		<Flex gap={'12px'} direction={'column'} alignItems={'flex-start'}>
			{completionTime ? (
				<Flex gap={'8px'} alignItems={'center'}>
					<Flex direction={'row'} alignItems={'center'}>
						{isAskAIOmniChatMessage(chatMessage) && (
							<Tooltip label="Copy" size="md" variant="fluid" background="black" hasArrow={true} marginTop="8px">
								<Button variant={'outline'} colorScheme={'gray'} size={'xs'} p={'6px'} onClick={onCopyToClipboard}>
									<Copy16 />
								</Button>
							</Tooltip>
						)}
						{isLast && (
							<Tooltip label="Regenerate" size="md" variant="fluid" background="black" hasArrow={true} marginTop="8px">
								<Button variant={'outline'} colorScheme={'gray'} size={'xs'} p={'6px'} onClick={onRegenerateWithReport}>
									<ArrowCCW16 />
								</Button>
							</Tooltip>
						)}
						<Tooltip label="Bad response" size="md" variant="fluid" background="black" hasArrow={true} marginTop="8px">
							<Button
								variant={'outline'}
								colorScheme={'gray'}
								size={'xs'}
								p={'6px'}
								onClick={onDislikeWithReport}
								isDisabled={vote === 'down'}
							>
								<Flex color={vote === 'down' ? 'red.700' : undefined}>
									<Dislike16 />
								</Flex>
							</Button>
						</Tooltip>
						<Tooltip label="Good response" size="md" variant="fluid" background="black" hasArrow={true} marginTop="8px">
							<Button
								variant={'outline'}
								colorScheme={'gray'}
								size={'xs'}
								p={'6px'}
								onClick={onLikeWithReport}
								isDisabled={vote === 'up'}
							>
								<Flex color={vote === 'up' ? 'green.700' : undefined}>
									<Like16 />
								</Flex>
							</Button>
						</Tooltip>
					</Flex>
					<Box height={'16px'}>
						<Divider direction="vertical" width={'2px'} color={colors.gray[300]} />
					</Box>
					<Button
						variant={'outline'}
						colorScheme={'gray'}
						size={'xs'}
						p={'10px'}
						onClick={onClickAgentSteps}
						rightIcon={areAgentStepsOpen ? <CloseMedium16 /> : <ChevronRight16 />}
					>
						Agent Steps
					</Button>
				</Flex>
			) : null}
			{areAgentStepsOpen && (
				<Box width={'100%'}>
					<AskAIChatEvents chatEvents={chatMessage.events} isRunning={false} isFullChat={isFullChat} />
				</Box>
			)}
		</Flex>
	);
}

function CardContent({ chatMessageAtom, chatIndex, isLast, isFullChat }: AskAIChatCardProps) {
	const chatMessage = useAtomValue(chatMessageAtom);
	if (isAskAIOmniChatMessage(chatMessage)) {
		return <AskAIOmniCardContent chatMessageAtom={chatMessageAtom} chatIndex={chatIndex} isFullChat={isFullChat} />;
	}

	if (isAskAIMetricDiscoveryChatMessage(chatMessage)) {
		return <AskAIMetricDiscoveryCardContent chatMessageAtom={chatMessageAtom} chatIndex={chatIndex} />;
	}

	if (isAskAIOntologyBuilderEntityChatMessage(chatMessage)) {
		return (
			<AskAIOntologyBuilderEntityCardContent chatMessageAtom={chatMessageAtom} chatIndex={chatIndex} isLast={isLast} />
		);
	}

	if (isAskAIOntologyBuilderDimensionChatMessage(chatMessage)) {
		return (
			<AskAIOntologyBuilderDimensionCardContent
				chatMessageAtom={chatMessageAtom}
				chatIndex={chatIndex}
				isLast={isLast}
			/>
		);
	}

	if (isAskAIOntologyBuilderRelationshipChatMessage(chatMessage)) {
		return (
			<AskAIOntologyBuilderRelationshipCardContent
				chatMessageAtom={chatMessageAtom}
				chatIndex={chatIndex}
				isLast={isLast}
			/>
		);
	}
}

export function AskAIChatCard({
	chatMessageAtom,
	chatIndex,
	cardMargin,
	isLast = false,
	isFullChat = false,
}: AskAIChatCardProps) {
	const toast = useToast();
	const { reportAskAIEvent } = useReportAIEvent();
	const { threadId, deleteAIAgentPrompt } = useAIAgents();
	const { addChatMessage, removeChatMessage } = useAskAIChatMessages();
	const [chatMessage, setChatMessage] = useAtom(chatMessageAtom);
	const { type, userPrompt, promptContext } = chatMessage;

	const onLike = useCallback(() => {
		setChatMessage(() => ({ ...chatMessage, vote: 'up' }));
	}, [chatMessage, setChatMessage]);

	const onDislike = useCallback(() => {
		setChatMessage(() => ({ ...chatMessage, vote: 'down' }));
	}, [chatMessage, setChatMessage]);

	const onDelete = useCallback(async () => {
		if (threadId && chatMessage.promptId) {
			await deleteAIAgentPrompt({ threadId, promptId: chatMessage.promptId }).catch((error) => {
				console.error('Failed to delete AI agent prompt', error);
			});
		}
		removeChatMessage(chatMessageAtom);
	}, [chatMessage.promptId, chatMessageAtom, deleteAIAgentPrompt, removeChatMessage, threadId]);

	const onRegenerate = useCallback(async () => {
		try {
			await onDelete();
			addChatMessage({ userPrompt, type, promptContext });
		} catch {
			toast({ variant: 'error', message: 'Failed to regenerate' });
		}
	}, [onDelete, addChatMessage, promptContext, type, userPrompt, toast]);

	const onCopyToClipboard = useCallback(() => {
		reportAskAIEvent({
			event: 'ask-ai-copy',
			metaData: {
				userPrompt: chatMessage.userPrompt,
			},
		});

		if (isAskAIOmniChatMessage(chatMessage)) {
			navigator.clipboard
				.writeText(chatMessage.result?.text ?? '')
				.then(() => toast({ variant: 'ok', message: 'Copied to clipboard' }))
				.catch((err) => console.error(err));
		}
	}, [chatMessage, toast, reportAskAIEvent]);

	if (chatMessage.error) {
		return <AskAIErrorCard chatMessageAtom={chatMessageAtom} chatIndex={chatIndex} onDelete={onDelete} />;
	}

	if (isFullChat) {
		return (
			<AIChatMessageLayout
				userPrompt={userPrompt ?? ''}
				onEditUserPrompt={onDelete}
				promptContext={promptContext}
				isLast={isLast}
				footer={
					<CardFooter
						chatMessageAtom={chatMessageAtom}
						onLike={onLike}
						onDislike={onDislike}
						onRegenerate={onRegenerate}
						onCopyToClipboard={onCopyToClipboard}
						isLast={isLast}
						isFullChat={isFullChat}
					/>
				}
			>
				<Suspense fallback={<AskAILoadingResults />}>
					<CardContent
						chatMessageAtom={chatMessageAtom}
						chatIndex={chatIndex}
						isLast={isLast}
						isFullChat={isFullChat}
					/>
				</Suspense>
			</AIChatMessageLayout>
		);
	}

	return (
		<AskAIChatCardLayout
			type={type}
			cardMargin={cardMargin}
			borderColor={'gray.300'}
			userPrompt={userPrompt ?? ''}
			onDelete={onDelete}
			footer={
				<CardFooter
					chatMessageAtom={chatMessageAtom}
					onLike={onLike}
					onDislike={onDislike}
					onRegenerate={onRegenerate}
					onCopyToClipboard={onCopyToClipboard}
					isLast={isLast}
					isFullChat={isFullChat}
				/>
			}
		>
			<Suspense fallback={<AskAILoadingResults />}>
				<CardContent chatMessageAtom={chatMessageAtom} chatIndex={chatIndex} isLast={isLast} isFullChat={isFullChat} />
			</Suspense>
		</AskAIChatCardLayout>
	);
}
