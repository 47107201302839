import { useCallback, useEffect, useMemo } from 'react';
import {
	GridReadyEvent,
	RowClickedEvent,
	ValueFormatterParams,
	GetContextMenuItemsParams,
	MenuItemDef,
	GridSizeChangedEvent,
	GetMainMenuItemsParams,
	IHeaderParams,
} from 'ag-grid-community';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './ag-theme-override.scss';

import useToast from '@hooks/ui/useToast';
import Flex from '@components/Flex';
import Box from '@components/Box';
import { useGridTableApi } from './GridTableHooks';
import useTenantConfig from '@hooks/stores/useTenantConfig';
import { formatValue } from '../../utils/valueFormatting';
import { GridTableColumnHeaderRenderer, GridTableColumnHeaderRendererProps } from './GridTableColumnHeaderRenderer';
import { Copy16, RemoveCircleThin16 } from '../Icons';
import { renderToString } from 'react-dom/server';
import { isObject } from 'lodash';

export type GridTableBodyProps = {
	data: Record<string, any>[];
	columnDefs?: ColDef[];
	groupable?: boolean;
	clickable?: boolean;
	maxRowsToShow?: number;
	rowHeight?: number;
	headerHeight?: number;
	onTableReady?: (event: GridReadyEvent) => void;
	onRowClicked?: (event: RowClickedEvent) => void;
	contextMenuItems?: (params: GetContextMenuItemsParams) => MenuItemDef[];
};

export function GridTableBody({
	data,
	columnDefs,
	groupable = true,
	clickable = true,
	maxRowsToShow,
	rowHeight = 48,
	headerHeight = 40,
	onTableReady,
	onRowClicked,
	contextMenuItems,
}: GridTableBodyProps) {
	const toast = useToast();
	const tenantConfig = useTenantConfig();
	const { setGridTableApi } = useGridTableApi();

	const tableHeight = useMemo(() => {
		if (!data.length) return '200px';
		if (!maxRowsToShow) return '100%';
		const maxRows = Math.min(maxRowsToShow, data.length);
		return `${maxRows * rowHeight + headerHeight}px`;
	}, [data.length, headerHeight, maxRowsToShow, rowHeight]);

	const defaultColDef: ColDef = useMemo(
		() => ({
			editable: false,
			sortable: true,
			filter: true,
			resizable: true,
			enableValue: true,
			enableRowGroup: groupable,
			enablePivot: true,
			cellClass: 'ag-grid-custom-cell',
			cellClassRules: {
				'ag-grid-custom-cell-clickable': () => clickable,
			},
			menuTabs: ['generalMenuTab', 'columnsMenuTab'],
			valueFormatter: (params: ValueFormatterParams) => {
				return formatValue(params.colDef.field ?? '', params.value, tenantConfig);
			},
			headerComponent: (params: IHeaderParams) => {
				return GridTableColumnHeaderRenderer({ ...params });
			},
		}),
		[clickable, groupable, tenantConfig]
	);

	const getMainMenuItems = useCallback((params: GetMainMenuItemsParams) => {
		const providedColDef = params.column?.getUserProvidedColDef();
		const colId = params.column?.getColId() ?? '';
		const headerParams: GridTableColumnHeaderRendererProps = providedColDef?.headerComponentParams;
		const { removable = false, onRemoveColumn } = headerParams ?? {};
		const items: (MenuItemDef | string)[] = params.defaultItems;
		if (removable) {
			items.unshift('separator');
			items.unshift({
				name: 'Remove column',
				icon: renderToString(<RemoveCircleThin16 />),
				action: () => onRemoveColumn?.(colId),
			});
		}
		return items;
	}, []);

	const getContextMenuItems = useCallback(
		(params: GetContextMenuItemsParams) => {
			const providedColDef = params.column?.getUserProvidedColDef();
			const initValue = params.value ?? params.node?.data?.[providedColDef?.field ?? ''] ?? '';
			const value = isObject(initValue)
				? Object.values(initValue)
						.map((val) => val?.toString())
						.join(', ')
				: initValue?.toString();

			const result = contextMenuItems?.(params) ?? [
				{
					name: 'Copy',
					icon: renderToString(<Copy16 />),
					action: () =>
						navigator.clipboard
							.writeText(value || 0)
							.then(() => toast({ variant: 'ok', message: 'Copied to clipboard' })),
				},
			];
			return result;
		},
		[contextMenuItems, toast]
	);

	const onGridReady = useCallback(
		(event: GridReadyEvent) => {
			setGridTableApi({ api: event.api, columnApi: event.columnApi });
			onTableReady?.(event);
		},
		[onTableReady, setGridTableApi]
	);

	useEffect(() => {
		return () => {
			setGridTableApi({});
		};
	}, [setGridTableApi]);

	const onGridSizeChange = useCallback((gridEvent: GridSizeChangedEvent) => {
		const width = gridEvent.clientWidth;
		const columns = gridEvent.columnApi.getColumns() ?? [];
		const columnsWidth = columns.reduce((width, col) => col.getActualWidth() + width, 0);
		if (columnsWidth < width) {
			gridEvent.api.sizeColumnsToFit({ columnLimits: [{ key: '$actions', maxWidth: 130, minWidth: 130 }] });
		}
	}, []);

	const popupParent = useMemo<HTMLElement | null>(() => {
		return document.querySelector(`body`);
	}, []);

	return (
		<Flex
			alignSelf={'stretch'}
			flexGrow={1}
			height={'100%'}
			width={'100%'}
			flexDirection={'column'}
			className={'ag-theme-alpine ag-theme-override'}
		>
			<Box width={'100%'} height={tableHeight}>
				<AgGridReact
					popupParent={popupParent}
					debounceVerticalScrollbar
					rowData={data}
					defaultColDef={defaultColDef}
					columnDefs={columnDefs}
					autoGroupColumnDef={columnDefs?.find((cd) => cd.rowGroup)}
					getContextMenuItems={getContextMenuItems}
					getMainMenuItems={getMainMenuItems}
					onGridReady={onGridReady}
					onGridSizeChanged={onGridSizeChange}
					onRowClicked={onRowClicked}
					rowSelection={'single'}
					rowBuffer={50}
					suppressPaginationPanel={true}
					maintainColumnOrder={true}
					rowHeight={rowHeight}
					headerHeight={headerHeight}
					domLayout={maxRowsToShow && data.length <= maxRowsToShow ? 'autoHeight' : 'normal'}
					suppressFieldDotNotation={true}
					enableCellTextSelection={true}
					ensureDomOrder={true}
					tooltipShowDelay={300}
					tooltipHideDelay={0}
					scrollbarWidth={8}
					onFirstDataRendered={(params) => {
						params.columnApi.autoSizeAllColumns();
					}}
				/>
			</Box>
		</Flex>
	);
}
