import { useCallback } from 'react';

export function useAIPowerMode() {
	const togglePowerMode = useCallback(() => {
		if (localStorage.getItem('askAIPowerMode') === 'on') {
			localStorage.removeItem('askAIPowerMode');
		} else {
			localStorage.setItem('askAIPowerMode', 'on');
		}
	}, []);

	const isPowerMode = useCallback(() => {
		return localStorage.getItem('askAIPowerMode') === 'on';
	}, []);

	return { togglePowerMode, isPowerMode };
}
