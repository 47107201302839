import { DropdownStringEditor } from '../../Editors/DropdownStringEditor';
import { ValueEditorProps } from './ValueEditorByFilter';

const MAX_TOOLTIP_STRING_LENGTH = 150;

export function FilterStringDropdownEditor({
	options,
	displayValue,
	values,
	onApply,
	prefixComponent,
	isEditorAutoOpened = false,
	isEditing,
	onOpenEditor,
	onCloseEditor,
	isLoadingDimensionValues,
	eventMetaData,
}: ValueEditorProps) {
	return (
		<DropdownStringEditor
			eventMetaData={eventMetaData}
			options={options}
			displayValue={displayValue}
			values={values.map((v) => v?.toString() ?? null)}
			onApply={onApply}
			applyButtonLabel={'Add'}
			prefixComponent={prefixComponent}
			defaultIsEditing={isEditorAutoOpened}
			tooltip={
				displayValue.length > MAX_TOOLTIP_STRING_LENGTH
					? displayValue.substring(0, MAX_TOOLTIP_STRING_LENGTH) + '...'
					: displayValue
			}
			isLoadingOptions={isLoadingDimensionValues}
			placeholder={'Search for values'}
			isSelectable
			isEditing={isEditing}
			onOpenEditor={onOpenEditor}
			onCloseEditor={onCloseEditor}
			isIntercomDetectable={true}
			searchValuesEventMetaData={{ event: 'filters-search-values', metaData: eventMetaData }}
			selectAllEventMetaData={{ event: 'filters-select-all-values-toggle', metaData: eventMetaData }}
			selectAllMatchingEventMetaData={{
				event: 'filters-select-matching-values-toggle',
				metaData: eventMetaData,
			}}
		/>
	);
}
