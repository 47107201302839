import {
	formatDimensionLabelV2,
	getIcon,
	getText,
} from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/utils';
import { FilterV2, FilterV2Value } from '@pages/MetricPage/utils/state.types';
import { useCallback, useEffect, useMemo } from 'react';
import { DrilldownCard } from 'src/common/components/LeftExpandCollapsePanel/Drilldown';
import { ValueEditorByFilter } from 'src/common/components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/Editors/ValueEditorByFilter';
import { OperatorSelect } from 'src/common/components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/Operator';
import {
	getOperatorDisplayName,
	supportedOperatorsByFilterType,
} from 'src/common/components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/constants';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useReportEvent } from 'src/services/analytics';
import { shouldResetFilterValues, useFilter } from './useFilter';
import { HoverTooltip } from './HoverTooltip';

export const FilterDrillDownCard = ({
	filter,
	isLoading = false,
	entityName,
	filterIndex,
	eventMetaData,
	isEditorAutoOpened,
	onRemove,
	editFilter,
	onModalOpen,
	onFilterCompletenessChange,
}: {
	filter: FilterV2;
	isLoading?: boolean;
	entityName: string;
	filterIndex: number;
	eventMetaData: object;
	isEditorAutoOpened?: boolean;
	onRemove?: VoidFunction;
	editFilter: (newFilter: FilterV2) => void;
	onModalOpen: VoidFunction;
	onFilterCompletenessChange?: (isComplete: boolean) => void;
}) => {
	const { reportEvent } = useReportEvent();
	const {
		isOpen: isEditing,
		onClose: onCloseEditor,
		onOpen: onOpenEditor,
	} = useModal({
		defaultIsOpen: isEditorAutoOpened,
	});

	useEffect(() => {
		if (isEditorAutoOpened) setTimeout(onOpenEditor, 200);
	}, [isEditorAutoOpened, onOpenEditor]);

	const { isOpen: isOperatorSelectOpen, onClose: onCloseOperatorSelect, onOpen: onOpenOperatorSelect } = useModal();

	const { isLoadingDimensionValues, options, updateFilter, dimensionsEntity, dimensionName, filterState } = useFilter({
		filter,
		entityName,
		editFilter,
		eventMetaData,
		filterIndex,
	});

	const { values, label, operator, type, key } = filterState;

	const formattedLabel = formatDimensionLabelV2(label);

	const extendedEventMetadata = {
		...eventMetaData,
		dimension: key,
		dimensionType: type,
		operator: getOperatorDisplayName(operator, filter.type),
		'dimension type': filter.type,
	};

	const { title, displayValue } = useMemo(() => getText(values, formattedLabel), [values, formattedLabel]);

	const icon = useMemo(() => getIcon(type), [type]);

	const onApply = useCallback(
		(values: FilterV2Value[]) => onFilterCompletenessChange?.(updateFilter(values, operator)),
		[onFilterCompletenessChange, operator, updateFilter]
	);

	return (
		<DrilldownCard
			icon={icon}
			onRemove={onRemove}
			onModalOpen={onModalOpen}
			removeTooltip={'Remove filter'}
			title={title}
			eventMetaData={extendedEventMetadata}
			valueEditor={
				<ValueEditorByFilter
					filter={filterState}
					options={options}
					displayValue={displayValue}
					values={values}
					onApply={onApply}
					prefixComponent={
						<OperatorSelect
							eventMetadata={extendedEventMetadata}
							operator={operator}
							availableOperators={supportedOperatorsByFilterType[type]}
							type={type}
							onSelectOperator={(operator) => {
								const values = shouldResetFilterValues(filter.operator, operator) ? [] : filter.values;
								onFilterCompletenessChange?.(updateFilter(values, operator));
								onOpenEditor();
							}}
							isOpen={isOperatorSelectOpen}
							onClose={onCloseOperatorSelect}
							onOpen={onOpenOperatorSelect}
						/>
					}
					isLoadingDimensionValues={isLoadingDimensionValues || isLoading}
					eventMetaData={extendedEventMetadata}
					isEditing={isEditing}
					onOpenEditor={() => {
						reportEvent({
							event: 'filters-edit-values',
							metaData: {
								...extendedEventMetadata,
							},
						});
						onCloseOperatorSelect();
						onOpenEditor();
					}}
					onCloseEditor={onCloseEditor}
					isEditorAutoOpened
				/>
			}
			isEditing={isEditing}
			titleHoverTooltipBuilder={() => {
				if (!dimensionsEntity) return;
				return (
					<HoverTooltip
						dimensionName={dimensionName}
						dimensionsEntity={dimensionsEntity}
						entityName={entityName}
						label={label}
					/>
				);
			}}
		/>
	);
};
