import { useEffect, useCallback } from 'react';
import { AIAgentName, AskAIChatCardProps, ChatEvent, ChatEventTypes, isChatEventResultOmni } from '../types';
import { useAIAgents } from './useAIAgents';
import { useAtom } from 'jotai';

type OmniAgentState = {
	running: boolean;
};

const omniAgentState: OmniAgentState = {
	running: false,
};

function areChatEventsIdentical(event1?: ChatEvent, event2?: ChatEvent) {
	return JSON.stringify(event1) === JSON.stringify(event2);
}

function doesChatEventAlreadyExist(events?: ChatEvent[], event?: ChatEvent) {
	return events?.find((e) => areChatEventsIdentical(e, event)) !== undefined;
}

export function useOmniAgent({ chatMessageAtom }: Pick<AskAIChatCardProps, 'chatMessageAtom'>) {
	const [chatMessage, setChatMessage] = useAtom(chatMessageAtom);

	const { getOrCreateAIAgentThread, callStreamingAIAgent } = useAIAgents();

	const addChatEvent = useCallback(
		(event: ChatEvent) => {
			setChatMessage((prev) => {
				if (doesChatEventAlreadyExist(prev.events, event)) {
					return prev;
				}
				return { ...prev, events: [...(prev.events ?? []), event] };
			});
		},
		[setChatMessage]
	);

	const callOmniAgent = useCallback(
		async (userPrompt: string) => {
			if (omniAgentState.running) return;
			omniAgentState.running = true;
			const agentName: AIAgentName = `omni`;
			try {
				const body = {
					prompt: userPrompt,
					customAgentOptions: { enabledStreamEvents: ChatEventTypes },
				};
				const customAgentOptionsStr = localStorage.getItem('askAICustomAgentOptions');
				if (customAgentOptionsStr) {
					body.customAgentOptions = { ...body.customAgentOptions, ...JSON.parse(customAgentOptionsStr) };
				}
				const threadId = await getOrCreateAIAgentThread({ agentName });
				callStreamingAIAgent({
					agentName,
					threadId,
					body,
					events: {
						onError(error) {
							setChatMessage((prev) => ({
								...prev,
								type: 'omni',
								error: true,
								errorText: error,
								completionTime: Date.now(),
							}));
						},
						onEvent(event) {
							addChatEvent(event);
						},
						onClose() {
							omniAgentState.running = false;
						},
						onStart(event) {
							setChatMessage((prev) => ({
								...prev,
								promptId: event.promptId,
							}));
						},
						onDelta(event) {
							setChatMessage((prev) => ({
								...prev,
								type: 'omni',
								deltaText: (prev.deltaText ?? '') + event.text,
							}));
						},
						onResult(event) {
							if (isChatEventResultOmni(event)) {
								setChatMessage((prev) => ({
									...prev,
									type: 'omni',
									error: event.result.status === 'error',
									errorText: event.result.error,
									result: { text: event.result.text },
									completionTime: Date.now(),
								}));
							}
						},
					},
				});
			} catch (error) {
				omniAgentState.running = false;
				throw error;
			}
		},
		[getOrCreateAIAgentThread, callStreamingAIAgent, addChatEvent, setChatMessage]
	);

	useEffect(() => {
		if (chatMessage.initiated) return;
		callOmniAgent(chatMessage.userPrompt).catch((error) =>
			setChatMessage((prev) => ({
				...prev,
				error: !!error,
				errorText: 'Something went wrong.',
				completionTime: Date.now(),
			}))
		);
		setChatMessage((prev) => ({
			...prev,
			initiated: true,
		}));
	}, [callOmniAgent, setChatMessage, chatMessage.initiated, chatMessage.userPrompt]);

	return { chatMessage, setChatMessage, addChatEvent };
}
