import { useCallback } from 'react';
import { useMetricSearchParamsBuilder } from 'src/pages/MetricPage/hooks/useMetricSearchParamsBuilder';
import { MetricPagePath } from 'src/pages/MetricPage/pageRoutesPaths';
import { ChatEventStepSuccessfulCalcMetricPayload, DiscoverMetricsSuggested } from '../types';
import { useReportAIEvent } from './useReportAIEvent';
import useNavigation from 'src/services/useNavigation';
import { useMetricSearchParamsBuilderV2 } from '../../../../pages/MetricPage/hooks/useMetricSearchParamsBuilderV2';

export function useOpenInNewTab({ metric, userPrompt }: { userPrompt: string; metric: DiscoverMetricsSuggested }) {
	const { reportAskAIEvent: reportDiscoveryEvent } = useReportAIEvent();

	const [, { metricName, searchParams }] = useMetricSearchParamsBuilder(metric);
	const { navigate } = useNavigation();

	const onOpenInNewTab = useCallback(() => {
		reportDiscoveryEvent({
			event: 'ask-ai-discovery-result-click',
			metaData: {
				...metric,
				queryText: userPrompt,
				searchParams: searchParams.toString(),
			},
		});
		navigate({ path: `/${MetricPagePath}/${metricName}`, additionalSearchParams: searchParams, isNewTab: true });
	}, [userPrompt, metric, metricName, navigate, reportDiscoveryEvent, searchParams]);

	return { onOpenInNewTab, searchParams };
}

export function useOpenMetricInNewTab({
	calcPayload,
	userPrompt,
}: {
	userPrompt: string;
	calcPayload: ChatEventStepSuccessfulCalcMetricPayload;
}) {
	const metric = calcPayload.calcParamVariables;
	const { reportAskAIEvent: reportDiscoveryEvent } = useReportAIEvent();
	const [, { metricName, searchParams }] = useMetricSearchParamsBuilderV2(calcPayload);
	const { navigate } = useNavigation();

	const onOpenInNewTab = useCallback(() => {
		reportDiscoveryEvent({
			event: 'ask-ai-open-in-new-tab-click',
			metaData: {
				...metric,
				queryText: userPrompt,
				searchParams: searchParams.toString(),
			},
		});
		navigate({ path: `/${MetricPagePath}/${metricName}`, additionalSearchParams: searchParams, isNewTab: true });
	}, [userPrompt, metric, metricName, navigate, reportDiscoveryEvent, searchParams]);

	return { onOpenInNewTab, searchParams };
}
