import { Box, Flex } from '@chakra-ui/layout';
import { FlexProps } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { useModal } from 'src/common/hooks/ui/useModal';
import Divider from '../../Divider';
import { ChevronDown16, ChevronUp16, OkSmall16 } from '../../Icons';
import ListItem from '../../ListItem';
import Popover from '../../Popover';
import Typography from '../../Typography';
import { TypographyProps } from '../../Typography/types';
import { canEditRole, Roles, rolesToString, RoleType } from '../constants';

const rolesToSubtitle = {
	viewer: 'Can view and share with others.',
	editor: 'Can edit and share with others.',
	owner: 'Has full access.',
};

export function RoleSelect({
	onSelect,
	selectedRole,
	myRole,
	isBorderedToggle = false,
	isIncludingRemove = true,
}: {
	onSelect: (role: RoleType | null) => void;
	selectedRole: RoleType;
	myRole: RoleType;
	isBorderedToggle?: boolean;
	isIncludingRemove?: boolean;
}) {
	const { isOpen, onToggle, onClose } = useModal();

	const triggerElement = useMemo(() => {
		if (isBorderedToggle) {
			return (
				<RoleSelectTriggerFlex
					isOpen={isOpen}
					onToggle={onToggle}
					selectedRole={selectedRole}
					padding="1px 6px"
					gap={'6px'}
					borderRadius={'4px'}
					border={'1px solid'}
					borderColor={'gray.300'}
					textVariant="Paragraph6M"
				/>
			);
		} else {
			return (
				<RoleSelectTriggerFlex
					isOpen={isOpen}
					onToggle={onToggle}
					selectedRole={selectedRole}
					padding="6px 10px"
					gap={'4px'}
					borderRadius={'6px'}
					textVariant="DesktopH8Regular"
				/>
			);
		}
	}, [isBorderedToggle, isOpen, onToggle, selectedRole]);

	return (
		<>
			<Popover
				closeOnBlur={true}
				isOpen={isOpen}
				variant={'outline'}
				onClose={onClose}
				placement="bottom-end"
				popoverContentProps={{
					borderRadius: '8px',
					boxShadow: 'var(--chakra-shadows-lg)',
				}}
				triggerElement={triggerElement}
			>
				<Flex direction={'column'} paddingY="8px">
					{Roles.filter((role) => canEditRole(role, myRole)).map((role) => (
						<RoleSelectListItem
							key={role}
							title={capitalize(rolesToString[role])}
							subtitle={rolesToSubtitle[role]}
							isSelected={role === selectedRole}
							onClick={() => {
								onClose();
								onSelect(role);
							}}
						/>
					))}
					{isIncludingRemove && (
						<>
							<Box paddingX={'16px'}>
								<Divider direction={'horizontal'} marginTop={'8px'} marginBottom={'8px'} />
							</Box>
							<ListItem
								label={'Remove'}
								color="gray.1000"
								onClick={() => {
									onClose();
									onSelect(null);
								}}
								size={'sm'}
							/>
						</>
					)}
				</Flex>
			</Popover>
		</>
	);
}

function RoleSelectListItem({
	title,
	subtitle,
	isSelected,
	onClick,
}: {
	title: string;
	subtitle: string;
	isSelected: boolean;
	onClick?: () => void;
}) {
	return (
		<Flex
			direction={'row'}
			padding={'10px 16px'}
			gap={'12px'}
			alignItems={'center'}
			_hover={{ backgroundColor: 'blue.100' }}
			cursor={'pointer'}
			onClick={onClick}
			justifyContent={'space-between'}
		>
			<Flex gap={'4px'} direction={'column'}>
				<Typography variant="DesktopH8Regular" color={'gray.1000'}>
					{title}
				</Typography>
				<Typography variant="DesktopH10Regular" color={'gray.700'}>
					{subtitle}
				</Typography>
			</Flex>

			<Box color={'gray.1000'} width={'16px'}>
				{isSelected && <OkSmall16 />}
			</Box>
		</Flex>
	);
}

function RoleSelectTriggerFlex({
	selectedRole,
	onToggle,
	isOpen,
	textVariant,
	...flexProps
}: {
	selectedRole: RoleType;
	onToggle: () => void;
	isOpen: boolean;
	textVariant: TypographyProps['variant'];
} & FlexProps) {
	return (
		<Flex
			direction={'row'}
			alignItems={'center'}
			_hover={{ backgroundColor: 'blue.100' }}
			cursor={'pointer'}
			onClick={onToggle}
			{...flexProps}
		>
			<Typography variant={textVariant} color={'gray.1000'}>
				{rolesToString[selectedRole]}
			</Typography>
			<Box color={'gray.1000'}>{isOpen ? <ChevronUp16 /> : <ChevronDown16 />}</Box>
		</Flex>
	);
}
