import { useEffect, useCallback } from 'react';
import { AIAgentName, AskAIChatCardProps, ChatEventWithText, isChatEventResultPerformDiscovery } from '../types';
import { useAIAgents } from './useAIAgents';
import { useAtom } from 'jotai';

type DiscoveryAgentState = {
	running: boolean;
};

const discoveryAgentState: DiscoveryAgentState = {
	running: false,
};

function areChatEventsIdentical(event1?: ChatEventWithText, event2?: ChatEventWithText) {
	return JSON.stringify(event1) === JSON.stringify(event2);
}

export function useCachedPerformDiscovery({ chatMessageAtom }: Pick<AskAIChatCardProps, 'chatMessageAtom'>) {
	const [chatMessage, setChatMessage] = useAtom(chatMessageAtom);

	const { getOrCreateAIAgentThread, callStreamingAIAgent } = useAIAgents();

	const addChatEvent = useCallback(
		(event: ChatEventWithText) => {
			setChatMessage((prev) => {
				// do not append the exact same event twice
				const lastEvent = prev.events?.[prev.events.length - 1];
				if (areChatEventsIdentical(lastEvent, event)) {
					return prev;
				}
				return { ...prev, events: [...(prev.events ?? []), event] };
			});
		},
		[setChatMessage]
	);

	const callMetricDiscoveryAIAgent = useCallback(
		async (userPrompt: string) => {
			if (discoveryAgentState.running) return;
			discoveryAgentState.running = true;
			const agentName: AIAgentName = `metric-discovery-v2`;
			try {
				const threadId = await getOrCreateAIAgentThread({ agentName });
				callStreamingAIAgent({
					agentName,
					threadId,
					body: { prompt: userPrompt },
					events: {
						onError(error) {
							setChatMessage((prev) => ({
								...prev,
								type: 'metric-discovery',
								error: true,
								errorText: error,
								completionTime: Date.now(),
							}));
						},
						onEvent(event) {
							addChatEvent(event);
						},
						onClose() {
							discoveryAgentState.running = false;
						},
						onResult(event) {
							if (isChatEventResultPerformDiscovery(event)) {
								setChatMessage((prev) => ({
									...prev,
									type: 'metric-discovery',
									error: event.result.result.status === 'error',
									errorText: event.result.result.error,
									result: event.result.result.suggestion,
									completionTime: Date.now(),
								}));
							}
						},
					},
				});
			} catch (error) {
				discoveryAgentState.running = false;
				throw error;
			}
		},
		[getOrCreateAIAgentThread, callStreamingAIAgent, addChatEvent, setChatMessage]
	);

	useEffect(() => {
		if (chatMessage.initiated) return;
		callMetricDiscoveryAIAgent(chatMessage.userPrompt).catch((error) =>
			setChatMessage((prev) => ({
				...prev,
				error: !!error,
				errorText: 'Something went wrong.',
				completionTime: Date.now(),
			}))
		);
		setChatMessage((prev) => ({
			...prev,
			initiated: true,
		}));
	}, [callMetricDiscoveryAIAgent, setChatMessage, chatMessage.initiated, chatMessage.userPrompt]);

	return { chatMessage, setChatMessage, addChatEvent };
}
