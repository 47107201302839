import { PopoverContent, useDisclosure } from '@chakra-ui/react';
import Box from '@components/Box';
import Flex from '@components/Flex';
import Button from '@components/Button';
import Tooltip from '@components/Tooltip';
import ListItem from '@components/ListItem';
import Popover from '@components/Popover';
import Input from '@components/Input';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import useDebouncedCallback from 'src/common/hooks/useDebouncedCallback';
import { CloseMedium16, DashboardNew18, FolderNew18, Plus16, SearchSmall12 } from '@icons/index';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { TestIDs } from 'src/common/types/test-ids';
import { UpsertFolderModal } from '../LeftSidePanelV2/DashboardFolder';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useReportEvent } from 'src/services/analytics';
import { isDashboardsPage, isWorkspacePage } from 'src/pages/WorkspacePage/utils';
import { CollectionUpsertModal } from 'src/layout/Menu/NavigationDrawer/CollectionUpsertModal';
import { collectionsNavigationDrawer } from 'src/stores/utils';
import Typography from '../Typography';
import pluralize from 'pluralize';
import { capitalize } from 'lodash';
import { useKeyPress } from 'src/common/hooks/interaction/useKeyPress';

export const HeaderActions = ({
	searchValue,
	setSearchValue,
}: {
	searchValue: string;
	setSearchValue: (searchValue: string) => void;
}) => {
	const { reportEvent } = useReportEvent({ feature: 'Sidebar' });
	const collectionType = isWorkspacePage() ? 'workspace' : 'dashboard';
	const isDashboardFoldersEnable = useFeatureFlag('pulse.sightfull2.dashboard.folders.enable');

	const isWithDropdown = isDashboardFoldersEnable && !isWorkspacePage();
	const currentDrawerView = isDashboardsPage()
		? collectionsNavigationDrawer.dashboard
		: collectionsNavigationDrawer.workspace;

	const {
		isOpen: isCreateFolderModalOpen,
		onOpen: onCreateFolderModalOpen,
		onClose: onCreateFolderModalClose,
	} = useModal();

	const {
		isOpen: isCreateEditModalOpen,
		onOpen: onCreateDashboardModalOpen,
		onClose: onCreateEditModalClose,
	} = useModal();

	const { isOpen, onClose, onOpen } = useDisclosure();
	const { isOpen: isSearchOpen, onClose: onSearchClose, onToggle: onToggleSearch } = useDisclosure();

	const onSelectorOpen = () => {
		reportEvent({ event: 'sidebar-create-new-item-clicked' });
		onOpen();
	};

	const onSearchClearAndClose = () => {
		onSearchClose();
		setSearchValue('');
	};

	useKeyPress(['Escape'], onSearchClearAndClose);

	const onCreateNewDashboard = () => {
		if (!isDashboardFoldersEnable) reportEvent({ event: 'left-panel-add-button-clicked' });
		onCreateDashboardModalOpen();
	};

	const debouncedSearch = useDebouncedCallback((searchTerm: string) => {
		reportEvent({ event: 'sidebar-search', metaData: { searchTerm } });
		setSearchValue(searchTerm);
	}, 500);

	const onAddNewClick = () => (isWithDropdown ? onSelectorOpen() : onCreateNewDashboard());

	const PlusButton = (
		<Tooltip size="md" variant="fluid" label={'Create new'} placement={'right'}>
			<Box data-intercom-area="dashboards" data-intercom-type="button" data-intercom-target="create-new-button">
				<Button
					size={'xs'}
					borderRadius="6px"
					variant={'outline'}
					colorScheme={'gray'}
					color={'natural.600'}
					onClick={isSearchOpen ? onSearchClearAndClose : onAddNewClick}
					isIconOnly
					backgroundColor={isOpen ? colors.buttonPanelItemColor : 'transparent'}
					_hover={{ bgColor: isSearchOpen ? colors.collectionActiveColor : colors.buttonPanelItemColor }}
					mixBlendMode={'multiply'}
					data-testid={TestIDs.SIDEBAR_FOOTER_ADD_NEW_BUTTON(collectionType)}
				>
					{isSearchOpen ? <CloseMedium16 /> : <Plus16 />}
				</Button>
			</Box>
		</Tooltip>
	);

	const SearchButton = isSearchOpen ? (
		<Box marginRight={'4px'} width={'100%'}>
			<Input
				width={'100%'}
				height="28px"
				borderRadius="6px"
				autoFocus
				leftComponentPadding="32px"
				leftComponent={<SearchSmall12 color={colors.natural[600]} />}
				leftComponentBorder={false}
				size="xxs"
				placeholder={'Search'}
				placeholderStyle={{ color: 'natural.600' }}
				color="natural.1000"
				onBlur={() => {
					if (searchValue) return;
					onSearchClose();
				}}
				borderColor="transparent"
				backgroundColor={colors.collectionActiveColor}
				onChange={debouncedSearch}
			/>
		</Box>
	) : (
		<Tooltip size="md" variant="fluid" label={'Search'} placement={'right'}>
			<Box data-intercom-area="dashboards" data-intercom-type="button" data-intercom-target="search-button">
				<Button
					borderRadius="6px"
					size={'xs'}
					variant={'outline'}
					colorScheme={'gray'}
					color={'natural.600'}
					onClick={onToggleSearch}
					isIconOnly
					_hover={{ bgColor: colors.buttonPanelItemColor }}
					mixBlendMode={'multiply'}
				>
					<Box paddingX={'2px'}>
						<SearchSmall12 />
					</Box>
				</Button>
			</Box>
		</Tooltip>
	);

	return (
		<Flex alignItems={'center'} justifyContent={'space-between'} paddingRight={'16px'}>
			{!isSearchOpen && <Typography variant="DesktopH10Medium">{capitalize(pluralize(collectionType, 2))}</Typography>}
			<Flex width={'100%'} justifyContent={'flex-end'} alignItems={'center'}>
				{isDashboardsPage() && SearchButton}
				{isWithDropdown ? (
					<Popover
						triggerElement={PlusButton}
						isOpen={isOpen}
						placement={'bottom-start'}
						onClose={onClose}
						popoverContentProps={{
							boxShadow: shadows['panelShadow'],
							borderRadius: '8px',
							border: `1px solid ${colors.gray[300]}`,
						}}
					>
						<PopoverContent>
							<Flex alignSelf="center" direction={'column'} py="8px" width="224px">
								<ListItem
									color="gray.1000"
									hoverColor={'gray.200'}
									label="Dashboard"
									size="sm"
									testId={TestIDs.ADD_NEW_DASHBOARD_DROPDOWN_ITEM}
									onClick={() => {
										reportEvent({ event: 'sidebar-create-new-item-select', metaData: { itemType: 'dashboard' } });
										onCreateNewDashboard();
									}}
									prefixIcon={<DashboardNew18 />}
								/>
								<ListItem
									color="gray.1000"
									hoverColor={'gray.200'}
									label="Folder"
									size="sm"
									onClick={() => {
										reportEvent({ event: 'sidebar-create-new-item-select', metaData: { itemType: 'folder' } });
										onCreateFolderModalOpen();
									}}
									prefixIcon={<FolderNew18 />}
								/>
							</Flex>
						</PopoverContent>
					</Popover>
				) : (
					PlusButton
				)}
			</Flex>
			<UpsertFolderModal isOpen={isCreateFolderModalOpen} onClose={onCreateFolderModalClose} />
			<CollectionUpsertModal
				isOpen={isCreateEditModalOpen}
				onClose={onCreateEditModalClose}
				collectionType={currentDrawerView}
			/>
		</Flex>
	);
};
