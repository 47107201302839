import Flex from '@components/Flex';
import colors from 'src/style/colors';
import { ResourceRolesUser } from '../ResourceAccessModal/useResourceRoles';
import Avatar from '../Avatar/Avatar';
import Typography from '../Typography';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import { useRef } from 'react';
import Tooltip from '../Tooltip';

type UsersCredentialsProps = {
	user: ResourceRolesUser;
	metricName: string;
};

export function UsersCredentials({ user, metricName }: UsersCredentialsProps) {
	const nameRef = useRef(null);
	const emailRef = useRef(null);
	const isOverflowingName = useOnOverflow(nameRef, [user.userName], undefined, false);
	const isOverflowingEmail = useOnOverflow(emailRef, [user.userName], undefined, false);
	const mailToLink = `mailto:${user.email}?subject=Access%20to%20${metricName}&body=Hi%2C%0A%0AI%E2%80%99d%20like%20access%20to%20the%20"${metricName}"%20metric.%20Could%20you%20please%20grant%20me%20permission%3F%0A%0AThanks.`;
	const onListItemClick = () => (window.location.href = mailToLink);

	return (
		<Flex
			transition={'0.1s'}
			_hover={{ backgroundColor: colors.blue[100] }}
			cursor={'pointer'}
			gap={'12px'}
			width={'100%'}
			padding={'8px 12px'}
			onClick={onListItemClick}
		>
			<Avatar imageUrl={user.avatarURL || ''} name={user.userName} shape="round" width={'32px'} height={'32px'} />
			<Flex flex={1} gap={'4px'} flexDirection={'column'}>
				<Tooltip size="md" variant="fluid" label={isOverflowingName && user.userName} placement={'top'}>
					<Typography noOfLines={1} color={colors.gray[1000]} variant={'DesktopH8Regular'}>
						{user.userName}
					</Typography>
				</Tooltip>
				<Tooltip size="md" variant="fluid" label={isOverflowingEmail && user.email} placement={'top'}>
					<Typography noOfLines={1} color={colors.gray[700]} variant={'Paragraph12R'}>
						{user.email}
					</Typography>
				</Tooltip>
			</Flex>
		</Flex>
	);
}
