import shadows from 'src/style/shadows';
import { StyleProps } from 'src/style/types';

export type PopoverVariants = 'light' | 'strong' | 'solid' | 'medium' | 'outline';
type PopoverStyleProps = {
	variant: PopoverVariants;
} & StyleProps;

export default {
	baseStyle: (props: PopoverStyleProps) => {
		const shadow = shadowByVariant[props.variant];

		const base = {
			popper: { zIndex: 'var(--chakra-zIndices-popover)' },
			content: {
				bg: 'white',
				width: 'auto',
				borderRadius: '4px',
				zIndex: 'var(--chakra-zIndices-popover)',
			},
		};

		if (props.variant === 'outline') {
			return {
				...base,
				content: {
					...base.content,
					border: '1px solid',
					borderColor: 'gray.300',
				},
			};
		}

		return {
			...base,
			content: {
				...base.content,
				border: '0',
				boxShadow: shadow,
				_focusVisible: {
					boxShadow: shadow,
				},
			},
		};
	},
};

const shadowByVariant: { [key in PopoverVariants]: string } = {
	light: shadows['g-03'],
	medium: shadows['z-03'],
	strong: shadows['z-05'],
	solid: shadows['a-22'],
	outline: '',
};
