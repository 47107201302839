import Box from '@components/Box';
import { AskAIInput } from '../../AskAIInput';
import { AskAIModalItem } from '../../AskAIModalItem';
import { AskAIChatCardsList } from '../AskAIChatCardList';
import { useCallback } from 'react';
import { useAskAIChatMessages } from '../../hooks/useAskAIChatMessages';
import { DrawerFooter, DrawerHeader } from '../../../Drawer';
import { AskAIOmniChatHeader } from './AskAIOmniChatHeader';

type AskAIOmniChatPageProps = {
	onBack: () => void;
	onNewChat: () => void;
};

export function AskAIOmniChatPage({ onBack, onNewChat }: AskAIOmniChatPageProps) {
	const { addChatMessage } = useAskAIChatMessages();

	const onSubmit = useCallback(
		(userPrompt: string) => {
			addChatMessage({ userPrompt, type: 'omni' });
		},
		[addChatMessage]
	);

	return (
		<>
			<DrawerHeader padding={0}>
				<AskAIModalItem borderBottom={true}>
					<AskAIOmniChatHeader onBack={onBack} onNewChat={onNewChat} />
				</AskAIModalItem>
			</DrawerHeader>

			<AskAIChatCardsList />

			<DrawerFooter padding={0}>
				<AskAIModalItem backgroundColor={'gray.100'} borderTop={true}>
					<Box padding={'8px'} flex={1}>
						<AskAIInput
							placeholder={'Ask any business question'}
							hasBorder={true}
							onSubmit={onSubmit}
							borderRadius={'32px'}
							submitButtonBorderRadius={'26px'}
							clearInputOnSubmit={true}
							size={'sm'}
							reportEvent={'ask-ai-chat-query'}
							reportFeature={'Ask AI'}
						/>
					</Box>
				</AskAIModalItem>
			</DrawerFooter>
		</>
	);
}
