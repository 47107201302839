export const breakpointNames = ['xl', 'lg', 'md', 'sm', 'tiny'];
export type BreakpointName = typeof breakpointNames[number];

export const XLARGE_BREAKPOINT = 1760;
export const LARGE_BREAKPOINT = 1400;
export const MEDIUM_BREAKPOINT = 1160;
export const SMALL_BREAKPOINT = 900;
export const XS_BREAKPOINT = 872;

export const BREAKPOINT_GRACE_PX = 16;

export const BREAKPOINTS: Record<BreakpointName, number> = {
	xl: XLARGE_BREAKPOINT,
	lg: LARGE_BREAKPOINT,
	md: MEDIUM_BREAKPOINT,
	sm: SMALL_BREAKPOINT,
	tiny: 0,
};

export const GRID_ITEM_MARGIN: [number, number] = [16, 16];
export const GRID_ITEM_MARGIN_LG: [number, number] = [32, 32];
export const GRID_ITEM_MARGIN_BREAKPOINTS: Record<BreakpointName, [number, number]> = {
	xl: GRID_ITEM_MARGIN_LG,
	lg: GRID_ITEM_MARGIN_LG,
	md: GRID_ITEM_MARGIN,
	sm: GRID_ITEM_MARGIN,
	tiny: GRID_ITEM_MARGIN,
};
export const GRID_CONTAINER_PADDING: Record<BreakpointName, [number, number]> = {
	xl: [176, 32],
	lg: GRID_ITEM_MARGIN_LG,
	md: GRID_ITEM_MARGIN_LG,
	sm: GRID_ITEM_MARGIN_LG,
	tiny: GRID_ITEM_MARGIN_LG,
};

export const WIDTH_TO_COLUMN_FACTOR = 3 / 240;
export const HEIGHT_MIGRATION_FACTOR = 3;
export const ROW_HEIGHT_PX = 16;
export const COLUMN_COUNT = 12;
export const LEGACY_DEFAULT_WIDGET_HEIGHT = 3;
export const DEFAULT_WIDGET_WIDTH = 240;

export const WIDGET_HEADER_HEIGHT = 74;
export const WIDGET_EDIT_BUTTON_WIDTH = 28;
export const WIDGET_RIGHT_GAP = 10;

export const LEGEND_BAR_HEIGHT = 20;
export const LEGEND_BAR_PADDING_X = 16;
export const LEGEND_BAR_PADDING_BOTTOM = 12;
export const LEGEND_NAME_SQUARE_WIDTH = 10;
export const LEGEND_NAME_SQUARE_MARGIN = 6;
export const LEGEND_NAME_MIN_WIDTH = 30;
export const LEGEND_NAME_MAX_WIDTH = 100;
export const LEGEND_NAME_GAP = 12;
export const ENTIRE_LEGEND_NAME_MAX_WIDTH =
	LEGEND_NAME_SQUARE_WIDTH + LEGEND_NAME_SQUARE_MARGIN + LEGEND_NAME_MAX_WIDTH + LEGEND_NAME_GAP;
export const ENTIRE_LEGEND_NAME_MIN_WIDTH =
	LEGEND_NAME_SQUARE_WIDTH + LEGEND_NAME_SQUARE_MARGIN + LEGEND_NAME_MIN_WIDTH + LEGEND_NAME_GAP;
export const LEGEND_BAR_PLUS_MORE_WIDTH = 50;

export const SIGNAL_WIDGET_MIN_H = 8;
export const SIGNAL_WIDGET_MIN_W = 2;
export const TEXT_WIDGET_MIN_H = 2;
export const TEXT_WIDGET_MIN_W = 2;
export const DIVIDER_WIDGET_H = 2;

export const WARNING_MARKDOWN = '![](https://static.sightfull.com/assets/icons/warning.png)';
export const SUCCESS_MARKDOWN = '![](https://static.sightfull.com/assets/icons/ok.png)';
export const INFO_MARKDOWN = '![](https://static.sightfull.com/assets/icons/info.png)';
