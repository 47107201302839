import { useCallback } from 'react';
import { useMetricEditorState } from '@pages/MetricPage/hooks/useMetricEditorState';
import { removeEscaping, singleMetricToFullYaml } from 'src/models/YamlUtils/yamlUtils';
import { hashMetricYamlEditorValueForPreview } from '@pages/MetricPage/utils/stateHelpers';
import { useUpdatedPreviewHashState } from '@pages/MetricPage/hooks/useUpdatedPreviewHashAtom';
import useMutation from '@hooks/fetching/useMutation';
import { UpsertMetricMutation, UpsertMetricMutationVariables } from 'src/generated/graphql';
import { UpsertMetric } from 'src/queries/core-manager';
import { useInvalidateCache } from '@services/apollo';
import { useEntitlementsRefresh } from '@services/entitlements';
import { MetricType } from 'src/common/types/common';

export const UNTITLED_METRIC_NAME = 'untitled';
export const UNTITLED_METRIC_DISPLAY = 'Untitled';
const BASE_METRIC_EMPTY_YAML = [`name: ${UNTITLED_METRIC_NAME}`, 'entity: ""'] as const;
const BASE_METRIC_META = ['meta:', `  display_name: ${UNTITLED_METRIC_DISPLAY}`] as const;
export const AGGREGATE_METRIC_EMPTY_YAML = [
	...BASE_METRIC_EMPTY_YAML,
	'operation: count',
	...BASE_METRIC_META,
] as const;
export const FORMULA_METRIC_EMPTY_YAML = [...BASE_METRIC_EMPTY_YAML, 'formula: ""', ...BASE_METRIC_META] as const;

export function useMetricEdit({ metricName }: { metricName?: string }) {
	const { metricEditorState, setMetricEditorState } = useMetricEditorState();
	const { setMetricYamlEditorHashState } = useUpdatedPreviewHashState();
	const [upsertMetric, { loading: isUpsertMetricLoading }] = useMutation<
		UpsertMetricMutation,
		UpsertMetricMutationVariables
	>(UpsertMetric);
	const { invalidateCache } = useInvalidateCache();
	const refreshEntitlements = useEntitlementsRefresh();

	const innerPreview = useCallback(
		(valueToPreview: string, metricKind: MetricType) => {
			const fullMetricYaml = singleMetricToFullYaml(valueToPreview, metricKind);

			setMetricYamlEditorHashState((s) => ({
				...s,
				requestedPreviewHash: hashMetricYamlEditorValueForPreview(fullMetricYaml),
			}));

			setMetricEditorState((s) => ({ ...s, previewValue: fullMetricYaml }));
		},
		[setMetricEditorState, setMetricYamlEditorHashState]
	);

	const setUserDefinedValue = useCallback(
		(newYamlValue: string) => {
			setMetricEditorState((s) => ({ ...s, userDefinedValue: newYamlValue }));
		},
		[setMetricEditorState]
	);

	const onChange = useCallback(
		(newYamlValue: string, shouldPreviewAfterChange: boolean) => {
			if (metricEditorState.isLoading) return;
			setUserDefinedValue(newYamlValue);
			if (shouldPreviewAfterChange) {
				innerPreview(newYamlValue, metricEditorState.kind);
			}
		},
		[innerPreview, metricEditorState, setUserDefinedValue]
	);

	const onPreview = useCallback(() => {
		if (metricEditorState.isLoading || metricEditorState.userDefinedValue == null) return;
		innerPreview(metricEditorState.userDefinedValue, metricEditorState.kind);
	}, [innerPreview, metricEditorState]);

	const onSave = useCallback(async () => {
		if (metricEditorState.isLoading) return;

		const lines = metricEditorState.previewValue.split('\n');
		const correctLines = removeEscaping(lines);
		return await upsertMetric({
			variables: {
				// TODO: If we want to split upsert into create and update, this line should change - its currently mitigating
				// not creating a metric with an existing name
				currentMetricName: metricName ?? '',
				metricDefinition: correctLines,
				metricType: metricEditorState.kind,
			},
		}).then((result) => {
			invalidateCache();
			refreshEntitlements();
			setMetricEditorState((s) => {
				if (s.isLoading || !s.userDefinedValue) return s;
				return {
					...s,
					savedValue: s.userDefinedValue,
					previewValue: '',
				};
			});
			return result;
		});
	}, [invalidateCache, metricEditorState, metricName, refreshEntitlements, setMetricEditorState, upsertMetric]);

	return {
		onChange,
		onPreview,
		onSave,
		isUpsertMetricLoading,
		isLoading: metricEditorState.isLoading,
		setUserDefinedValue,
	};
}
