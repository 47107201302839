import * as yaml from 'js-yaml';
import classes from '../AskAI.module.scss';
import Flex from '@components/Flex';
import Box from '@components/Box';
import { ChatEventWithText } from '../types';
import Typography from '../../Typography';
import { useModal } from '../../../hooks/ui/useModal';
import { Code, Icon, Popover, PopoverAnchor, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import Scrollable from '../../Scrollable';
import { InfoRounded16 } from '../../Icons';
import { useCallback, useMemo, useState } from 'react';

export type AskAIChatEventsProps = {
	isRunning: boolean;
	chatEvents?: ChatEventWithText[];
	isFullChat?: boolean;
};

function getEventText(event: ChatEventWithText) {
	const text = event.data.text.trim();
	if (text[text.length - 1] === '.') {
		return text.slice(0, -1);
	}
	return text;
}

function getEventDataAsText(details: object) {
	try {
		return yaml.dump(details);
	} catch (e) {
		console.error('Error dumping event data', e);
		return JSON.stringify(details);
	}
}

function AskAIChatEvent({ event }: { event?: ChatEventWithText }) {
	if (!event) return null;
	const text = event.data.text;
	const details = { ...event.data, text: undefined };
	return (
		<Flex direction={'column'} gap={'4px'} padding={'8px 12px'} backgroundColor={'gray.100'}>
			<Typography variant={'Paragraph14R'} wordBreak={'break-all'}>
				{text}
			</Typography>
			<Code whiteSpace={'pre-wrap'} fontSize={'12px'}>
				{getEventDataAsText(details)}
			</Code>
		</Flex>
	);
}

export function AskAIChatEvents({ isRunning, chatEvents, isFullChat = false }: AskAIChatEventsProps) {
	const { isOpen, onClose, onOpen } = useModal();
	const lastEvent = useMemo(() => chatEvents?.slice(-1)[0], [chatEvents]);
	const lastEventIndex = (chatEvents?.length ?? 0) - 1;
	const [selectedEventIndex, setSelectedEventIndex] = useState<number>(-1);

	const onClickEvent = useCallback(
		(index: number) => {
			onOpen();
			setSelectedEventIndex(index);
		},
		[onOpen]
	);

	if (!lastEvent) return null;

	return (
		<Box padding={isFullChat ? '0px 0px 16px 0px' : '0 16px 16px 16px'}>
			<Popover placement={'left-start'} isOpen={isOpen} onClose={onClose}>
				<PopoverAnchor>
					<Box></Box>
				</PopoverAnchor>
				<PopoverTrigger>
					<Flex
						direction={'column'}
						className={!isRunning ? classes['full-chat-events'] : ''}
						gap={'6px'}
						alignItems={'flex-start'}
						justifyContent={'flex-start'}
					>
						{chatEvents?.map((event, index) => (
							<Typography key={index} variant={'Paragraph12R'}>
								<Flex
									gap={'8px'}
									alignItems={'center'}
									justifyContent={'flex-start'}
									onClick={isRunning && index === lastEventIndex ? undefined : () => onClickEvent(index)}
									_hover={{ cursor: 'pointer', color: 'gray.1000' }}
									color={'gray.800'}
									className={isRunning && index === lastEventIndex ? classes['ask-ai-animated-text'] : undefined}
								>
									<Icon as={InfoRounded16} color={isRunning && index === lastEventIndex ? '#9b7bf9' : undefined} />
									{getEventText(event)}
								</Flex>
							</Typography>
						))}
					</Flex>
				</PopoverTrigger>
				<PopoverContent borderRadius={'8px'}>
					<Box
						width={'360px'}
						maxHeight={'400px'}
						overflowY={'auto'}
						border={'1px solid'}
						borderColor={'gray.300'}
						borderRadius={'8px'}
					>
						<Scrollable>
							<AskAIChatEvent event={chatEvents?.[selectedEventIndex]} />
						</Scrollable>
					</Box>
				</PopoverContent>
			</Popover>
		</Box>
	);
}
