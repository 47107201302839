import { ApolloError } from '@apollo/client';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { DEFAULT_EMOJIPICKER_VALUE } from 'src/common/components/EmojiPicker/EmojiPicker';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import {
	DashboardGetSingleQuery,
	useGetAllFoldersLazyQuery,
	useGetCollectionsWithoutFoldersLazyQuery,
	useGetDashboardsFromSingleFolderQuery,
	useInsertFolderMutation,
	useReorderDashboardMutation,
} from 'src/generated/graphql';
import { DashboardPath } from 'src/pages/DashboardPage';
import { DashboardGetSingle, DashboardGetSingleByOrder } from 'src/queries/dashboards';
import useNavigation from 'src/services/useNavigation';
import useUser from '../stores/useUser';
import { useHasuraSubscriptionWithCache } from './useSubscription';

export function useGetDashboardSubscription({
	id,
}: {
	id: string | undefined;
}): [DashboardGetSingleQuery | undefined, ApolloError | undefined, boolean] {
	const [{ id: my_id }] = useUser();
	const { navigate } = useNavigation();

	const isDashboardFoldersEnable = useFeatureFlag('pulse.sightfull2.dashboard.folders.enable');

	const document = id ? DashboardGetSingle : DashboardGetSingleByOrder;
	const variables = id ? { id, my_id } : { offset: 0, my_id };

	const isDashboardFromList = isDashboardFoldersEnable && !id;
	const isFolderPage = window.location.href.includes(`${DashboardPath}/folder/`);

	const { data, error, loading } = useHasuraSubscriptionWithCache(document, {
		variables,
	});

	const { data: foldersList, loading: isFoldersListLoading } = useGetDashboardsFromSingleFolderQuery({
		variables: { offset: 0, my_id: my_id },
		fetchPolicy: 'no-cache',
	});

	const sortedFolders = useMemo(() => _.sortBy(foldersList?.folders, 'order'), [foldersList?.folders]);

	useEffect(() => {
		if (isDashboardFromList && !isFolderPage) {
			const firstFolder = sortedFolders[0];
			if (firstFolder && !firstFolder.dashboards.length) {
				navigate({ path: `dashboard/folder/${firstFolder.id}` });
			} else if (firstFolder && firstFolder.dashboards[0].id) {
				navigate({ path: `dashboard/${_.sortBy(firstFolder.dashboards, 'order')[0].id}` });
			}
		}
	}, [sortedFolders, navigate, isDashboardFromList, isFolderPage]);

	const response = isFolderPage ? [] : data;

	const isLoading = loading || isFoldersListLoading;

	return [response, error, isLoading];
}

export function useMigrateDashboardsToFolder() {
	const [{ id: my_id }] = useUser();
	const [getFolders] = useGetAllFoldersLazyQuery({ variables: { my_id } });
	const [getCollections] = useGetCollectionsWithoutFoldersLazyQuery({ variables: { collection_type: 'dashboard' } });
	const [reorderDashboard] = useReorderDashboardMutation();
	const [insertFolder] = useInsertFolderMutation();
	const wasFolderCreatedRef = useRef(false);

	const migrateFolders = useCallback(async () => {
		const dashboardsWithoutFoldersResult = await getCollections();
		if (!dashboardsWithoutFoldersResult.data?.workspaces.length) return;

		const result = await getFolders();

		let folderId: string = result.data?.folders?.[0]?.id;
		if (wasFolderCreatedRef.current) return;
		if (!folderId) {
			wasFolderCreatedRef.current = true;
			folderId = (
				await insertFolder({
					variables: {
						name: 'General',
						emoji: DEFAULT_EMOJIPICKER_VALUE,
					},
				})
			).data?.insert_folders_one?.id;
		}

		dashboardsWithoutFoldersResult.data?.workspaces.forEach(async (dashboard) => {
			await reorderDashboard({
				variables: {
					dashboard_id: dashboard.id,
					new_folder_id: folderId,
					new_order: dashboard.order,
				},
			});
		});
	}, [getCollections, getFolders, insertFolder, reorderDashboard]);

	return migrateFolders;
}
