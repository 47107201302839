import Flex from '@components/Flex';
import colors from 'src/style/colors';
import Typography from '../Typography';

export function DropdownButton({ onClick, label }: { onClick: VoidFunction; label: string }) {
	return (
		<Flex
			position={'absolute'}
			bottom={0}
			left={0}
			onClick={onClick}
			transition={'0.2s'}
			cursor={'pointer'}
			backgroundColor={colors.blue[600]}
			_hover={{ backgroundColor: colors.blue[700] }}
			padding={'12px 0px'}
			borderBottomRadius={'8px'}
			width={'100%'}
		>
			<Typography width={'100%'} textAlign={'center'} variant={'DesktopH8Medium'} color={'white'}>
				{label}
			</Typography>
		</Flex>
	);
}
