import Box from '@components/Box';
import Button from '@components/Button';
import Flex from '@components/Flex';
import { BarChart16, ChevronRightSmallBold, Code16, Copy16 } from '@components/Icons';
import Markdown from '@components/Markdown';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import { Provider } from 'jotai';
import * as yaml from 'js-yaml';
import React, { useCallback, useMemo, useState } from 'react';
import { format, SqlLanguage, supportedDialects } from 'sql-formatter';

import { Code } from '@chakra-ui/react';
import {
	IsMetricPageURLBasedAtom,
	MetricPageSearchParamsAtom,
} from '../../../../../pages/MetricPage/atoms/MetricPageSearchParams';
import { useReportEvent } from '../../../../../services/analytics';
import { useOnCopy } from '../../../../hooks/interaction/useOnCopy';
import { useOmniAgent } from '../../hooks/useOmniAgent';
import {
	AskAIChatCardProps,
	AskAIChatMessage,
	AskAIOmniChatMessage,
	ChatEvent,
	ChatEventStepCalcMetric,
	ChatEventStepSuccessfulCalcMetricPayload,
	isAskAIOmniChatMessage,
	isChatEventStepCalcMetric,
	isSuccessfulCalcMetricPayload,
} from '../../types';
import { AskAIChatEvents } from '../AskAIChatEvents';
import { AskAIOmniMetricChartPreview } from './AskAIOmniChatMetricPreview';

const MAX_CONTENT_WIDTH = '600px';
type ViewPanelType = 'chart' | 'semantic' | 'sql';

function isSqlLanguage(value: string): value is SqlLanguage {
	return supportedDialects.includes(value);
}

export function AskAIOmniCardContent({ chatMessageAtom, chatIndex, isFullChat }: AskAIChatCardProps) {
	const { chatMessage } = useOmniAgent({ chatMessageAtom });
	const isOmniMessage = isAskAIOmniChatMessage(chatMessage);
	const result = isOmniMessage ? chatMessage.result : undefined;
	// const reasoningText = useMemo(
	// 	() => (chatMessage.deltaText && result?.text ? chatMessage.deltaText.replace(result.text, '').trim() : undefined),
	// 	[chatMessage.deltaText, result?.text]
	// );
	if (!isOmniMessage) return;

	return (
		<Typography variant="Paragraph14R" color={'gray.1000'} maxWidth={MAX_CONTENT_WIDTH}>
			{/* <AskAIOmniChatReasoningText reasoningText={reasoningText} chatIndex={chatIndex} isFullChat={isFullChat} /> */}
			<AskAIOmiChatText chatMessageAtom={chatMessageAtom} chatIndex={chatIndex} isFullChat={isFullChat} />
			<AskAIOmniChatMessages
				showPreview={!!result}
				chatMessage={chatMessage}
				chatIndex={chatIndex}
				chatEvents={chatMessage.events}
				isRunning={!result}
				isFullChat={isFullChat}
			/>
		</Typography>
	);
}

// function AskAIOmniChatReasoningText({
// 	reasoningText,
// 	isFullChat,
// }: {
// 	reasoningText?: string;
// 	chatIndex: number;
// 	isFullChat?: boolean;
// }) {
// 	const { reportEvent } = useReportEvent();
// 	if (!reasoningText) return null;

// 	return (
// 		<Box padding={isFullChat ? '0px 0px 16px 0px' : '0 16px 16px 16px'} width={isFullChat ? '512px' : undefined}>
// 			<Accordion
// 				allowToggle={true}
// 				reduceMotion={false}
// 				padding={'0'}
// 				border={'1px solid'}
// 				borderColor={'gray.300'}
// 				borderRadius={'8px'}
// 				onChange={(expandedIndex: number) => {
// 					reportEvent({
// 						event: 'ask-ai-agent-chat-reasoning-toggle',
// 						metaData: { state: expandedIndex === 0 ? 'open' : 'close' },
// 					});
// 				}}
// 			>
// 				<AccordionItem padding={'0'}>
// 					<AccordionButton padding={'8px 16px'}>
// 						<Box flex={1} textAlign={'left'}>
// 							<Typography variant={'DesktopH8Regular'} color={'gray.1000'}>
// 								Reasoning
// 							</Typography>
// 						</Box>
// 						<AccordionIcon />
// 					</AccordionButton>
// 					<AccordionPanel padding={'8px 16px'}>
// 						<Markdown isAI message={reasoningText} />
// 					</AccordionPanel>
// 				</AccordionItem>
// 			</Accordion>
// 		</Box>
// 	);
// }

function AskAIOmiChatText({ chatMessageAtom, chatIndex, isFullChat }: AskAIChatCardProps) {
	const { chatMessage } = useOmniAgent({ chatMessageAtom });
	const isOmniMessage = isAskAIOmniChatMessage(chatMessage);
	const result = isOmniMessage ? chatMessage.result : undefined;
	const resultText = useMemo(() => result?.text || chatMessage.deltaText, [chatMessage.deltaText, result?.text]);

	return (
		resultText && (
			<Box
				data-testid={`ask-ai-chat-card-content-${chatIndex}`}
				padding={isFullChat ? '0px 0px 16px 0px' : '0 16px 16px 16px'}
				overflowX={'auto'}
			>
				<Markdown isAI message={resultText} />
			</Box>
		)
	);
}

function SingleMetricPreview({
	calcEvent,
	chatMessage,
	chatIndex,
	isFullChat = false,
}: {
	calcEvent: ChatEventStepCalcMetric;
	chatMessage: AskAIOmniChatMessage;
	chatIndex: number;
	isFullChat?: boolean;
}) {
	const calcPayload = useMemo(
		() => (isSuccessfulCalcMetricPayload(calcEvent.data.payload) ? calcEvent.data.payload : undefined),
		[calcEvent.data.payload]
	);
	const semanticQuery = useMemo(
		() => (calcPayload?.calcParams ? yaml.dump(calcPayload?.calcParams) : ''),
		[calcPayload?.calcParams]
	);
	const sqlText = useMemo(() => {
		if (calcPayload?.sqlQuery.sql) {
			const language = isSqlLanguage(calcPayload.sqlQuery.dialect) ? calcPayload.sqlQuery.dialect : 'sql';
			return format(calcPayload.sqlQuery.sql, {
				tabWidth: 2,
				dataTypeCase: 'upper',
				keywordCase: 'upper',
				functionCase: 'lower',
				identifierCase: 'lower',
				language,
			});
		}
		return '';
	}, [calcPayload?.sqlQuery]);

	return (
		calcPayload && (
			<MetricPreviewGuard metricName={calcPayload.calcParamVariables.metricName}>
				<MetricResultsPanel
					calcPayload={calcPayload}
					semanticQuery={semanticQuery}
					sqlText={sqlText}
					chatMessage={chatMessage}
					chatIndex={chatIndex}
					isFullChat={isFullChat}
				/>
			</MetricPreviewGuard>
		)
	);
}

function CardMetricPreviews({
	calcMetricEvents,
	shouldShowPreview,
	chatIndex,
	chatMessage,
	isFullChat = false,
}: {
	calcMetricEvents: ChatEventStepCalcMetric[];
	shouldShowPreview: boolean;
	chatMessage: AskAIOmniChatMessage;
	chatIndex: number;
	isFullChat?: boolean;
}) {
	const DEFAULT_NUMBER_OF_METRICS_TO_SHOW = 3;
	const [isExpanded, setIsExpanded] = useState(false);

	const onClickLoadMore = useCallback(() => {
		setIsExpanded(true);
	}, [setIsExpanded]);

	const metricsToShow = useMemo(() => {
		if (isExpanded) {
			return calcMetricEvents;
		}
		return calcMetricEvents.slice(0, DEFAULT_NUMBER_OF_METRICS_TO_SHOW);
	}, [calcMetricEvents, isExpanded]);

	return (
		<Flex direction={'column'} gap={'20px'} paddingBottom={'8px'}>
			<Flex direction={'column'} gap={'32px'}>
				{shouldShowPreview &&
					metricsToShow.map((calcEvent, index) => (
						<SingleMetricPreview
							key={`metric-calc-event-${index}`}
							calcEvent={calcEvent}
							chatMessage={chatMessage}
							chatIndex={chatIndex}
							isFullChat={isFullChat}
						/>
					))}
			</Flex>
			{!isExpanded && calcMetricEvents.length > DEFAULT_NUMBER_OF_METRICS_TO_SHOW && (
				<Button
					margin={'0 16px'}
					padding={'0'}
					borderColor={'gray.300'}
					variant={'outline'}
					width={'100%'}
					color={'gray.1000'}
					minHeight={0}
					_hover={{ backgroundColor: 'none', color: 'gray.800' }}
					colorScheme={'lightGray'}
					size={'small'}
					onClick={onClickLoadMore}
					rightIcon={<ChevronRightSmallBold />}
					justifyContent={'flex-start'}
					gap={'4px'}
				>
					Show {calcMetricEvents.length - DEFAULT_NUMBER_OF_METRICS_TO_SHOW} more metrics
				</Button>
			)}
		</Flex>
	);
}

function AskAIOmniChatMessages({
	showPreview,
	chatMessage,
	chatIndex,
	chatEvents,
	isRunning,
	isFullChat = false,
}: {
	showPreview: boolean;
	chatMessage: AskAIOmniChatMessage;
	chatIndex: number;
	chatEvents?: ChatEvent[];
	isRunning: boolean;
	isFullChat?: boolean;
}) {
	const calcMetricEvents = useMemo(() => {
		return chatEvents?.filter((event) => isChatEventStepCalcMetric(event));
	}, [chatEvents]);

	return (
		<Flex gap={'16px'} direction={'column'}>
			{showPreview && calcMetricEvents && (
				<CardMetricPreviews
					calcMetricEvents={calcMetricEvents}
					shouldShowPreview={showPreview}
					chatMessage={chatMessage}
					chatIndex={chatIndex}
					isFullChat={isFullChat}
				/>
			)}
			{isRunning && <AskAIChatEvents chatEvents={chatEvents} isRunning={true} isFullChat={isFullChat} />}
		</Flex>
	);
}

function MetricPreviewGuard({ metricName, children }: { metricName: string; children: React.ReactNode }) {
	return (
		<Provider
			initialValues={[
				[
					MetricPageSearchParamsAtom,
					{
						metricName: metricName,
						searchParams: new URLSearchParams(),
					},
				],
				[IsMetricPageURLBasedAtom, false],
			]}
		>
			{children}
		</Provider>
	);
}

function MetricResultsPanel({
	calcPayload,
	semanticQuery,
	sqlText,
	chatMessage,
	chatIndex,
	isFullChat = false,
}: {
	calcPayload: ChatEventStepSuccessfulCalcMetricPayload;
	semanticQuery?: string;
	sqlText?: string;
	chatIndex: number;
	chatMessage: AskAIChatMessage;
	isFullChat?: boolean;
}) {
	const [selectedView, setSelectedView] = useState<ViewPanelType>('chart');

	return (
		<Flex direction={'column'} gap={'12px'} padding={isFullChat ? '0px' : '0px 16px'}>
			<Flex justifyContent={'space-between'}>
				<Flex gap={'8px'} alignItems={'center'}>
					<ViewPanelSelectButton
						isActive={selectedView === 'chart'}
						viewName={'chart'}
						onClick={setSelectedView}
						icon={<BarChart16 />}
					>
						Preview
					</ViewPanelSelectButton>
					<ViewPanelSelectButton
						isActive={selectedView === 'semantic'}
						viewName={'semantic'}
						onClick={setSelectedView}
						icon={<Code16 />}
					>
						Semantic Query
					</ViewPanelSelectButton>
					<ViewPanelSelectButton
						isActive={selectedView === 'sql'}
						viewName={'sql'}
						onClick={setSelectedView}
						icon={<Code16 />}
					>
						SQL
					</ViewPanelSelectButton>
				</Flex>
			</Flex>
			<Box maxHeight={'260px'}>
				<Box display={selectedView === 'chart' ? 'unset' : 'none'}>
					<AskAIOmniMetricChartPreview
						calcPayload={calcPayload}
						testId={`ask-ai-chat-${chatIndex}-metric-preview`}
						chatMessage={chatMessage}
					/>
				</Box>
				<Box display={selectedView === 'semantic' ? 'unset' : 'none'}>
					<Code
						backgroundColor={'unset'}
						whiteSpace={'pre-wrap'}
						fontSize={'12px'}
						lineHeight={'16px'}
						width={'100%'}
						height={'260px'}
						padding={'8px 12px'}
						border={'1px solid'}
						borderRadius={'8px'}
						borderColor={'gray.300'}
						overflow={'auto'}
						position={'relative'}
					>
						<CopyToClipboardButton text={semanticQuery} viewName={selectedView} />
						{semanticQuery}
					</Code>
				</Box>
				<Box display={selectedView === 'sql' ? 'unset' : 'none'}>
					<Code
						backgroundColor={'unset'}
						whiteSpace={'pre-wrap'}
						fontSize={'12px'}
						lineHeight={'16px'}
						height={'260px'}
						width={'100%'}
						padding={'8px 12px'}
						border={'1px solid'}
						borderRadius={'8px'}
						borderColor={'gray.300'}
						overflow={'auto'}
						position={'relative'}
					>
						<CopyToClipboardButton text={sqlText} viewName={selectedView} />
						{sqlText ?? 'No SQL query found'}
					</Code>
				</Box>
			</Box>
		</Flex>
	);
}

function ViewPanelSelectButton({
	isActive,
	viewName,
	onClick,
	icon,
	children,
}: {
	onClick: (viweName: ViewPanelType) => void;
	isActive: boolean;
	viewName: ViewPanelType;
	icon: React.ReactElement;
	children: React.ReactNode;
}) {
	const { wrapWithReport } = useReportEvent({ viewName, isActive });
	return (
		<Button
			variant={'outline'}
			colorScheme={'lightGray'}
			size={'xs'}
			onClick={wrapWithReport(() => onClick(viewName), `ask-ai-discovery-view-panel-select-${viewName}-button`)}
			backgroundColor={isActive ? 'gray.200' : undefined}
			leftIcon={icon}
		>
			{children}
		</Button>
	);
}

function CopyToClipboardButton({ text, viewName }: { text?: string; viewName: string }) {
	const { onCopyText } = useOnCopy();
	const { wrapWithReport } = useReportEvent();

	const onCopyClicked = useCallback(
		(text: string) => {
			void onCopyText({ contentToCopy: text, message: 'Copied to clipboard', variant: 'ok' });
		},
		[onCopyText]
	);

	return (
		<Box position={'absolute'} right={'8px'} top={'8px'}>
			<Tooltip label={'Copy to clipboard'} size={'md'} variant={'fluid'}>
				<Button
					variant={'outline'}
					colorScheme={'gray'}
					size={'xs'}
					onClick={wrapWithReport(() => text && onCopyClicked(text), 'ask-ai-discovery-copy-to-clipboard', {
						viewName,
					})}
					isIconOnly={true}
				>
					<Copy16 />
				</Button>
			</Tooltip>
		</Box>
	);
}
