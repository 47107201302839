import Flex from '@components/Flex';
import Spacer from '@components/Spacer';
import { ArrowLeft16, CloseLarge16, SightfullAIChatLogo2 } from '@components/Icons';
import Button from '../../Button';
import Box from '../../Box';
import { useAIPowerMode } from '../hooks/useAIPowerMode';

export type AskAIChatHeaderProps = {
	onBack: () => void;
	onClose: () => void;
};

export function AskAIChatHeader({ onBack, onClose }: AskAIChatHeaderProps) {
	const { togglePowerMode } = useAIPowerMode();
	return (
		<Flex flex={'1'} gap={'8px'} alignItems={'center'} padding={'8px'}>
			<Button variant={'outline'} size={'xs'} colorScheme={'gray'} isIconOnly={true} onClick={onBack}>
				<ArrowLeft16 />
			</Button>
			<Box onDoubleClick={togglePowerMode}>
				<SightfullAIChatLogo2 />
			</Box>
			<Spacer flexGrow={1} />
			<Button variant={'outline'} size={'xs'} colorScheme={'gray'} isIconOnly={true} onClick={onClose}>
				<CloseLarge16 />
			</Button>
		</Flex>
	);
}
