import { Box, Flex } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Spinner from 'src/common/components/Spinner';
import { useSchemasDataQuery } from 'src/generated/graphql';
import { TSchemaTable } from '../AddMultipleEntitiesModal';
import { useSemanticDefinitions } from 'src/common/hooks/stores/useSemanticDefinitions';
import Input from 'src/common/components/Input';
import { Search16 } from 'src/common/components/Icons';
import ListItem from 'src/common/components/ListItem';
import Checkbox from 'src/common/components/Checkbox';

interface ModalFormProps {
	onSelectTables: (data: TSchemaTable[]) => void;
}

export const ModalForm = ({ onSelectTables }: ModalFormProps) => {
	const { data: schemas, loading: areSchemasLoading } = useSchemasDataQuery();
	const { semanticDefinitions } = useSemanticDefinitions();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [selectedTables, setSelectedTables] = useState<string[]>([]);

	useEffect(() => {
		const data = selectedTables.map(getSchemaTableDataFromValue);

		onSelectTables(data);
	}, [selectedTables]);

	const onChangeSearchTerm = (value: string) => {
		setSearchTerm(value);
	};

	const alreadyCreatedEntities = (schema: string) => {
		return (
			semanticDefinitions?.entities.filter(
				(entity) => 'schema' in entity.data_source && entity.data_source.schema.name === schema
			) || []
		);
	};

	const sourceSchemasTables = useMemo(
		() =>
			schemas?.schemasData.map((schema) => {
				const alreadyCreatedEntitiesOnSchema = alreadyCreatedEntities(schema.name);
				const availableTables = schema.tables.filter(
					(table) =>
						!alreadyCreatedEntitiesOnSchema.some(
							(entity) => 'table' in entity.data_source && entity.data_source.table === table.name
						)
				);
				return availableTables.map((table) => {
					const key = `${schema.name} - ${table.name}`;
					return {
						value: key,
						title: key,
					};
				});
			}),
		[schemas?.schemasData]
	);

	const getSchemaTableDataFromValue = (value: string) => {
		const [schema, table] = value.split(' - ');
		return getSchemaTableData(schema, table);
	};

	const onSetSelectedValues = useCallback((values: (string | null)[]) => {
		setSelectedTables(values.filter((value) => value !== null) as string[]);
	}, []);

	const getSchemaTableData = useCallback(
		(schema: string, table: string) => {
			const schemaData = schemas?.schemasData.find((s) => s.name === schema);
			if (!schemaData) throw new Error(`Schema ${schema} not found`);
			const tableData = schemaData.tables.find((t) => t.name === table);

			return {
				schema,
				table: {
					name: table,
					description: tableData?.description,
					columns:
						tableData?.columns.map(({ name, description, dataType, isPrimaryKey }) => ({
							name,
							description,
							dataType,
							isPrimaryKey,
						})) || [],
				},
			};
		},
		[schemas?.schemasData]
	);

	if (areSchemasLoading) {
		return (
			<Flex width={'100%'} height={'416px'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
				<Spinner thickness="3px" color="blue.600" size={'60px'} />
			</Flex>
		);
	}

	const onClickLabel = (chosenLabel: string) => {
		if (selectedTables.includes(chosenLabel)) {
			onSetSelectedValues(selectedTables.filter((table) => table !== chosenLabel));
		} else {
			onSetSelectedValues([...selectedTables, chosenLabel]);
		}
	};

	const onSelectAll = () => {
		const allTables = sourceSchemasTables?.flat().map((schemaTable) => schemaTable.title);

		if (selectedTables.length === allTables?.length) {
			onSetSelectedValues([]);
		} else if (allTables) {
			onSetSelectedValues(allTables);
		}
	};

	const getSearchedTables = () => {
		if (!searchTerm || searchTerm === '') return sourceSchemasTables?.flat() || [];

		return (
			sourceSchemasTables
				?.flat()
				.filter((schemaTable) => schemaTable.title.toLowerCase().includes(searchTerm.toLowerCase())) || []
		);
	};

	return (
		<Box maxH={'566px'} overflowY={'scroll'} padding={'24px'}>
			<Box marginBottom={'32px'}>
				<Box width={'100%'}>
					<Input
						placeholder="Search"
						value={searchTerm}
						onChange={onChangeSearchTerm}
						leftComponent={<Search16 />}
						size="md"
						testId="modal-input"
						isErasable
					/>
				</Box>
				<Flex direction={'column'} marginTop={'16px'}>
					{sourceSchemasTables && sourceSchemasTables.length > 0 && !searchTerm && (
						<ListItem
							label={'Select all'}
							prefixComponent={
								<Checkbox
									checkboxColor="blue.700"
									onChange={onSelectAll}
									isChecked={selectedTables.length === sourceSchemasTables?.flat().length}
								/>
							}
							onClick={onSelectAll}
							size="md"
						/>
					)}
					{getSearchedTables().map((schemaTable) => (
						<ListItem
							key={schemaTable.title}
							label={schemaTable.title}
							prefixComponent={
								<Checkbox checkboxColor="blue.700" isChecked={selectedTables.includes(schemaTable.title)} />
							}
							onClick={() => onClickLabel(schemaTable.title)}
							size="md"
						/>
					))}
				</Flex>
			</Box>
		</Box>
	);
};
