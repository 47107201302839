import Box from '@components/Box';
import { ReactNode } from 'react';

export type WidgetProps = {
	children: ReactNode;
	isEditMode: boolean;
	hasShadow?: boolean;
	hasBorder?: boolean;
	hasBorderOnHoverOnly?: boolean;
};
export function Widget({
	children,
	isEditMode,
	hasBorder = true,
	hasShadow = false,
	hasBorderOnHoverOnly = false,
}: WidgetProps) {
	const classNames = [];
	if (!hasBorder) classNames.push('noBorder');
	if (!hasShadow) classNames.push('noShadow');
	if (hasBorderOnHoverOnly) classNames.push('borderOnHoverOnly');

	return (
		<Box
			width="100%"
			height="100%"
			className={isEditMode ? 'editMode' : classNames.join(' ')}
			overflow="hidden"
			borderRadius="8px"
			backgroundColor="white"
		>
			{children}
		</Box>
	);
}
