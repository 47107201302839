import Divider from 'src/common/components/Divider';
import { BinSolid16, DuplicateSolid16 } from 'src/common/components/Icons';
import ListItem from 'src/common/components/ListItem';
import classes from '../../../GridLayout/GridLayout.module.scss';
import { WidgetHeader } from '../../WidgetHeader/WidgetHeader';
import Box from '@components/Box';
import { WidgetLoader } from '@pages/DashboardPage/components/Widget/WidgetLoader';

export type TextWidgetHeaderProps = {
	isEditMode: boolean;
	isLoading: boolean;
	onDuplicate?: VoidFunction;
	onDelete?: VoidFunction;
};
export function TextWidgetHeader({ isEditMode, isLoading, onDuplicate, onDelete }: TextWidgetHeaderProps) {
	return (
		<Box className={classes.textWidgetHeader}>
			<WidgetHeader
				isEditMode={isEditMode}
				isOnlyControlsView={true}
				dragHandleClassName={classes.draggableHandle}
				actionItems={isLoading ? [<WidgetLoader key="text-signal-loading-indicator" />] : undefined}
				menu={
					isLoading || (!onDuplicate && !onDelete) ? null : (
						<>
							{onDuplicate && (
								<ListItem label="Duplicate" size="sm" prefixIcon={<DuplicateSolid16 />} onClick={onDuplicate} />
							)}
							{onDelete && (
								<>
									<Divider direction={'horizontal'} marginY="8px" />
									<ListItem label="Delete" size="sm" prefixIcon={<BinSolid16 />} onClick={onDelete} />
								</>
							)}
						</>
					)
				}
			/>
		</Box>
	);
}
