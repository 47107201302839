import { WatchQueryFetchPolicy } from '@apollo/client';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useToast from 'src/common/hooks/ui/useToast';
import { useGetEntityDefinitionQuery } from 'src/generated/graphql';
import { useReportEvent } from 'src/services/analytics';
import {
	OntologyStateAtomDerived,
	writeFullErrorOntologyState,
	writeFullSuccessOntologyState,
	writePartialOntologyState,
} from '../atoms/OntologyState';
import { useUpdateEntity } from '@pages/OntologyPage/hooks/useUpdateEntity';
import { useDeleteEntity } from '@pages/OntologyPage/hooks/useDeleteEntity';

export type OntologyEditorStateInput = {
	entityName?: string;
	onSaveAndRunNormalizationStart: () => void;
	onNormalizationSuccess: () => void;
	setColumns?: (val: string[]) => void;
};
const SAVE_FAILED_ERROR_MESSAGE = 'Save Failed, please review and try again';

const POLLING_VERSION_INTERVAL_MILLISECONDS = 3000;
export default function useOntologyEditorState({
	entityName,
	onSaveAndRunNormalizationStart,
	onNormalizationSuccess,
	setColumns,
}: OntologyEditorStateInput) {
	const [areChangesNormalized, setAreChangesNormalized] = useState<boolean>(true);
	const toast = useToast();
	const { reportEvent } = useReportEvent();

	const setPartialOntologyState = useSetAtom(writePartialOntologyState);
	const setSuccessOntologyState = useSetAtom(writeFullSuccessOntologyState);
	const setErrorOntologyState = useSetAtom(writeFullErrorOntologyState);
	const ontologyState = useAtomValue(OntologyStateAtomDerived);

	const getEntityDefinitionBaseOptions: {
		variables: { entityName: string };
		skip: boolean;
		fetchPolicy: WatchQueryFetchPolicy;
	} = {
		variables: { entityName: entityName ?? '' },
		skip: entityName === undefined,
		fetchPolicy: 'no-cache',
	};

	useEffect(() => {
		if (!entityName)
			setSuccessOntologyState({
				entityName: entityName ?? '',
				savedYaml: '',
				savedEntityVersion: '',
				currentEntityVersion: '',
			});
	}, [entityName, setSuccessOntologyState]);

	const { loading: loadingFiles } = useGetEntityDefinitionQuery({
		...getEntityDefinitionBaseOptions,
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			const newValue = data?.getEntityDefinition.entityDefinition;
			const savedEntityVersion = data?.getEntityDefinition.entityDefinitionVersion.definitionHash;
			const value = newValue ? newValue?.join('\n') : '';
			setSuccessOntologyState({
				entityName: entityName ?? '',
				savedYaml: value,
				errorMessage: ontologyState.loading ? '' : ontologyState?.errorMessage || '',
				editorRequestMessageState: ontologyState.loading ? 'NONE' : ontologyState?.errorMessage ? 'ERROR' : 'NONE',
				savedEntityVersion,
				currentEntityVersion: savedEntityVersion,
			});
		},
		onError: (error) => {
			setErrorOntologyState({ errorMessage: error.message });
		},
	});

	const pollingVersionResponse = useGetEntityDefinitionQuery({
		...getEntityDefinitionBaseOptions,
		pollInterval: POLLING_VERSION_INTERVAL_MILLISECONDS,
		notifyOnNetworkStatusChange: false,
		skipPollAttempt: () => !ontologyState.loading && ontologyState.hasStaleVersion,
	});

	useEffect(() => {
		const savedEntityVersion = pollingVersionResponse.data?.getEntityDefinition.entityDefinitionVersion.definitionHash;
		if (!ontologyState.loading && savedEntityVersion != ontologyState.savedEntityVersion) {
			setPartialOntologyState({
				savedEntityVersion,
			});
		}
	}, [
		ontologyState,
		pollingVersionResponse.data?.getEntityDefinition.entityDefinitionVersion.definitionHash,
		setPartialOntologyState,
	]);

	// ******** ACTIONS *********

	const handleSaveError = useCallback(
		(errorMessage: string) => {
			if (ontologyState.loading) {
				return;
			}

			setPartialOntologyState({
				loading: false,
				entityName,
				hasYamlErrors: false,
				errorMessage,
				editorRequestMessageState: 'ERROR',
				saveInProgress: false,
			});
			toast({
				variant: 'error',
				message: SAVE_FAILED_ERROR_MESSAGE,
			});
			reportEvent({
				event: 'save-ontology-error',
				metaData: {
					feature: 'YAML Editor',
					entity: ontologyState.entityName,
				},
			});
		},
		[entityName, ontologyState, reportEvent, setPartialOntologyState, toast]
	);

	const handleNormalizationError = useCallback(
		(errorMessage: string) => {
			if (ontologyState.loading) {
				return;
			}
			setPartialOntologyState({
				loading: false,
				entityName,
				hasYamlErrors: false,
				errorMessage,
				editorRequestMessageState: 'ERROR',
				normalizationInProgress: false,
			});
			toast({
				variant: 'error',
				message: 'Failed, please review and try again',
			});
			reportEvent({
				event: 'run-ontology-error',
				metaData: {
					feature: 'YAML Editor',
					entity: ontologyState.entityName,
				},
			});
		},
		[entityName, ontologyState, reportEvent, setPartialOntologyState, toast]
	);

	const handleSaveSuccess = useCallback(
		(savedYaml: string[], savedEntityVersion: string) => {
			const savedYamlString = savedYaml.join('\n');
			setPartialOntologyState({
				currentEntityVersion: savedEntityVersion,
				savedEntityVersion,
				editorYaml: savedYamlString,
				savedYaml: savedYamlString,
				saveInProgress: false,
			});
		},
		[setPartialOntologyState]
	);

	const handleNormalizationSuccess = useCallback(
		({ additionalColumns }: { additionalColumns?: string[] }) => {
			setAreChangesNormalized(true);
			setPartialOntologyState({ normalizationInProgress: false });
			if (additionalColumns?.length) setColumns?.(additionalColumns);
			else onNormalizationSuccess();
		},
		[onNormalizationSuccess, setPartialOntologyState, setColumns]
	);

	const handleValidationSuccess = useCallback(() => {
		setPartialOntologyState({
			editorRequestMessageState: 'SUCCESS',
			validationInProgress: false,
		});
		toast({
			variant: 'ok',
			message: 'Successfully saved',
		});
	}, [setPartialOntologyState, toast]);

	const handleValidationError = useCallback(
		(error: string) => {
			setPartialOntologyState({
				editorRequestMessageState: 'ERROR',
				errorMessage: error,
				validationInProgress: false,
			});
			toast({
				variant: 'error',
				message: error,
			});
		},
		[setPartialOntologyState, toast]
	);

	const onValidationStart = useCallback(() => {
		setPartialOntologyState({ validationInProgress: true });
	}, [setPartialOntologyState]);

	// ONLY CONSUME updateEntity THROUGH onUpdateEntity callback!!!
	const {
		updateEntity,
		updateEntityInProgress: updateEntityUpdateInProgress,
		normalizationInProgress: updateEntityNormalizationInProgress,
		isValidationInProgress: isUpdateEntityValidationInProgress,
	} = useUpdateEntity({
		onUpdateStart: () => {
			setPartialOntologyState({ editorRequestMessageState: 'NONE', saveInProgress: true });
			onSaveAndRunNormalizationStart();
		},
		entityName: ontologyState.loading ? '' : ontologyState.entityName,
		currentEntityVersion: ontologyState.loading ? '' : ontologyState.currentEntityVersion,
		handleSaveError,
		handleSaveSuccess,
		onNormalizationStart: () => {
			setPartialOntologyState({ normalizationInProgress: true });
			onSaveAndRunNormalizationStart();
		},
		handleNormalizationError,
		handleNormalizationSuccess,
		onValidationStart,
		handleValidationError,
		handleValidationSuccess,
		onNormalizationSkip: () => {
			setAreChangesNormalized(false);
		},
	});
	// ONLY CONSUME updateEntity THROUGH onUpdateEntity callback!!!
	const onUpdateEntity = useCallback(
		(shouldSkipNormalization: boolean, entityDefinitionLines?: string[], additionalTableColumns?: string[]) => {
			if (ontologyState.loading) {
				console.error('Cannot save while loading');
				return;
			}

			return updateEntity(
				shouldSkipNormalization,
				entityDefinitionLines || ontologyState.editorYaml.split('\n'),
				additionalTableColumns
			);
		},
		[ontologyState, updateEntity]
	);

	// ONLY CONSUME deleteEntity THROUGH onDelete callback!!!
	const {
		deleteEntity,
		deleteEntityInProgress,
		normalizationInProgress: deleteEntityNormalizationInProgress,
	} = useDeleteEntity({
		onNormalizationSuccess: () => {
			setPartialOntologyState({ normalizationInProgress: false });
		},
		onDeleteStart: () => {
			setPartialOntologyState({ editorRequestMessageState: 'NONE', saveInProgress: true });
			onSaveAndRunNormalizationStart();
		},
		entityName: ontologyState.loading ? '' : ontologyState.entityName,
		handleNormalizationError,
		handleSaveError,
		onNormalizationStart: () => {
			setPartialOntologyState({ normalizationInProgress: true });
		},
		onSaveSuccess: () => {
			toast({ variant: 'ok', message: 'Successfully deleted' });
		},
	});
	// ONLY CONSUME deleteEntity THROUGH onDelete callback!!!
	const onDelete = useCallback(
		({
			onErrorCallback,
			onSuccessCallback,
			outerOnNormalizationSuccess,
		}: {
			onErrorCallback?: VoidFunction;
			onSuccessCallback?: VoidFunction;
			outerOnNormalizationSuccess?: VoidFunction;
		}) => {
			if (ontologyState.loading) {
				console.error('Cannot delete while loading');
				return;
			}
			return deleteEntity(onErrorCallback, onSuccessCallback, outerOnNormalizationSuccess);
		},
		[ontologyState.loading, deleteEntity]
	);

	const state = useMemo(() => {
		return [
			{
				areChangesNormalized,
				ontologyState: ontologyState.loading === true ? null : ontologyState,
				normalizationInProgress: updateEntityNormalizationInProgress || deleteEntityNormalizationInProgress,
				loadingFiles,
				isValidationInProgress: isUpdateEntityValidationInProgress,
				updateEntityInProgress: updateEntityUpdateInProgress,
				deleteEntityInProgress,
			},
			{ onUpdateEntity, onDelete },
		] as const;
	}, [
		ontologyState,
		updateEntityNormalizationInProgress,
		deleteEntityNormalizationInProgress,
		areChangesNormalized,
		loadingFiles,
		isUpdateEntityValidationInProgress,
		updateEntityUpdateInProgress,
		deleteEntityInProgress,
		onUpdateEntity,
		onDelete,
	]);

	return state;
}
