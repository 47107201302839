export type Comment = {
	message?: string;
	created_at: string;
	edited_at?: string;
	id: any;
	author_id: any;
	author: Author;
};

export type Signal = {
	attachment?: string;
	created_at: string;
	edited_at?: string;
	title?: string;
	message: string;
	id: any;
	widget_type?: string;
	author_id: any;
	author: Author;
	comments: Array<Comment>;
	insight_type?: string;
	feed_signals: Array<{ feed: { workspace: { collection_type: CollectionType; id?: string } }; resolved?: boolean }>;
};

export type SignalType = 'text' | 'signal' | 'divider';

export type Author = {
	email?: string;
	first_name?: string;
	last_name?: string;
	picture?: string;
};

export type HeaderName = 'Metric' | 'Signal';

export const collectionTypes = ['dashboard', 'workspace'] as const;
export const collectionView = ['graph', 'table'] as const;
export type CollectionType = typeof collectionTypes[number];
export type CollectionView = typeof collectionView[number];
