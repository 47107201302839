import { fontFamily, styleByVariant } from 'src/common/components/Typography/Typography';
import colors from 'src/style/colors';

const normalVariantFields = {
	color: colors.gray['1000'],
	borderRadius: '8px',
	border: `1px solid ${colors.gray['400']}`,
	paddingLeft: '12px',
	paddingRight: '12px',
	_hover: {
		borderColor: colors.gray['500'],
	},
	_placeholder: {
		color: colors.gray['600'],
	},
	_focus: {
		_placeholder: {
			color: colors.gray['500'],
		},
	},
	_focusVisible: {
		boxShadow: 'none',
		borderColor: colors.gray['900'],
	},
};

export default {
	fontFamily: fontFamily,
	sizes: {
		inline: {
			field: {
				height: '32px',
				...styleByVariant['DesktopH8Regular'],
			},
			element: {
				height: '32px',
				...styleByVariant['DesktopH8Regular'],
			},
		},
		xxs: {
			field: {
				height: '30px',
				...styleByVariant['DesktopH10Regular'],
			},
			element: {
				height: '30px',
				...styleByVariant['DesktopH10Regular'],
			},
		},
		xs: {
			field: {
				height: '32px',
				...styleByVariant['DesktopH8Regular'],
			},
			element: {
				height: '32px',
				...styleByVariant['DesktopH8Regular'],
			},
		},
		sm: {
			field: {
				height: '34px',
				...styleByVariant['DesktopH8Regular'],
			},
			element: {
				height: '34px',
				...styleByVariant['DesktopH8Regular'],
			},
		},
		md: {
			field: {
				height: '42px',
				...styleByVariant['DesktopH7Regular'],
			},
			element: {
				height: '42px',
				...styleByVariant['DesktopH7Regular'],
			},
		},
		lg: {
			field: {
				height: '48px',
				...styleByVariant['Paragraph20R'],
			},
			element: {
				height: '48px',
				...styleByVariant['Paragraph20R'],
			},
		},
		xl: {
			field: {
				height: '58px',
				...styleByVariant['Paragraph16R'],
			},
			element: {
				height: '58px',
				...styleByVariant['Paragraph16R'],
			},
		},
	},
	variants: {
		normal: {
			field: {
				...normalVariantFields,
			},
			element: {
				color: colors.gray['700'],
			},
		},
		tight: {
			field: {
				...normalVariantFields,
				paddingLeft: '8px',
				paddingRight: '8px',
			},
			element: {
				color: colors.gray['700'],
			},
		},
	},
};
