import pluralize from 'pluralize';
import AliceCarousel from 'react-alice-carousel';
import Box from '@components/Box';
import Button from '@components/Button';
import { ChevronLeftSmallBold, ChevronRightSmallBold } from '@icons/index';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { useReportEvent } from '@services/analytics';
import { MetricMissingDependency } from 'src/types/metric';
import { useCarousel } from '@hooks/ui/useCarousel';
import { MissingDependencyCard } from './MissingDependencyCard';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

type MetricMissingDependenciesProps = {
	metricMissingDependencies: MetricMissingDependency[];
	metricName: string;
	metricEntity: string;
};

export const MetricMissingDependencies = ({
	metricMissingDependencies,
	metricName,
	metricEntity,
}: MetricMissingDependenciesProps) => {
	const { reportEvent } = useReportEvent();
	const {
		carouselElementKey,
		isPrevSlideDisabled,
		isNextSlideDisabled,
		isOnlyOneSlide,
		carouselRef,
		goToNextSlide,
		goToPrevSlide,
		debouncedCarouselResize,
		setCarouselProps,
	} = useCarousel();

	pluralize.addPluralRule(/require$/i, 'require');
	pluralize.addSingularRule(/require$/i, 'requires');

	const CarouselArrowButtons = () => {
		return (
			<Box>
				<Button
					variant={'outline'}
					size={'xs'}
					colorScheme={'gray'}
					isIconOnly={true}
					onClick={() => {
						reportEvent({
							event: 'metric-page-missing-dependency-carousel-arrow-button-click',
							metaData: { action: 'prevSlide', metric: metricName },
						});
						goToPrevSlide();
					}}
					isDisabled={isPrevSlideDisabled}
					cursor={'pointer'}
					width={'28px'}
				>
					<ChevronLeftSmallBold />
				</Button>
				<Button
					variant={'outline'}
					size={'xs'}
					colorScheme={'gray'}
					isIconOnly={true}
					onClick={() => {
						reportEvent({
							event: 'metric-page-missing-dependency-carousel-arrow-button-click',
							metaData: { action: 'nextSlide', metric: metricName },
						});
						goToNextSlide(metricMissingDependencies);
					}}
					isDisabled={isNextSlideDisabled}
					cursor={'pointer'}
					width={'28px'}
				>
					<ChevronRightSmallBold />
				</Button>
			</Box>
		);
	};

	const TitleBlock = () => {
		return (
			<Flex justifyContent={'flex-start'} alignItems={'center'} gap={'4px'} height={'28px'}>
				<Typography variant={'DesktopH8Medium'} color="gray.1000">
					{metricMissingDependencies.length} {pluralize('issue', metricMissingDependencies.length)}&nbsp;
					{pluralize('require', metricMissingDependencies.length)} you attention
				</Typography>
				{!isOnlyOneSlide && <CarouselArrowButtons />}
			</Flex>
		);
	};

	return (
		<>
			<Flex
				{...buildIntercomAttributes({
					type: 'main',
					target: 'missing-dependencies',
				})}
				p={'0 0 20px 32px'}
				flexDirection={'column'}
				gap={'12px'}
				width="0"
				minWidth="100%"
				id={'carousel-component'}
			>
				<TitleBlock />

				<AliceCarousel
					key={carouselElementKey}
					ref={carouselRef}
					disableButtonsControls
					disableDotsControls
					responsive={{
						0: { items: 2, itemsFit: 'fill' },
						1160: { items: 3, itemsFit: 'fill' },
						1500: { items: 4, itemsFit: 'fill' },
						1800: { items: 5, itemsFit: 'contain' },
					}}
					onSlideChanged={setCarouselProps}
					onInitialized={setCarouselProps}
					onResized={() => debouncedCarouselResize('carousel-component')}
					paddingRight={70}
					mouseTracking
					items={metricMissingDependencies.map((missingDependency) => (
						<MissingDependencyCard
							missingDependency={missingDependency}
							key={`missing-dependency-${missingDependency.type}-${missingDependency.description}`}
							metricName={metricName}
							metricEntity={metricEntity}
						/>
					))}
				></AliceCarousel>
			</Flex>
		</>
	);
};
