import { Flex } from '@chakra-ui/react';
import { useEffect } from 'react';

import IconButton from 'src/common/components/IconButton';
import { ChevronLeft16, ChevronRight16 } from 'src/common/components/Icons';
import { useScrollableFiltersBar } from 'src/pages/DashboardPage/Header/GlobalFilters/useScrollableFiltersBar';
import { FilterV2 } from 'src/pages/MetricPage/utils/state.types';
import shadows from 'src/style/shadows';
import { FilterDropdownButton } from 'src/common/components/FilterDropdownButton/FilterDropdownButton';

export function GlobalFiltersBar({
	filterObjects: availableFilterObjects,
	globalFilters,
	setGlobalFilters,
	isLoading,
}: {
	filterObjects: string[];
	globalFilters: FilterV2[];
	setGlobalFilters: (filters: FilterV2[]) => void;
	isLoading: boolean;
}) {
	const {
		scrollState: { isStartShadowShown, isEndShadowShown, isScrolling },
		calculateScrollState,
		scrollComponentRef,
	} = useScrollableFiltersBar();
	const scrollOnClickPixels = 250;

	useEffect(calculateScrollState, [calculateScrollState, globalFilters.length]);

	return (
		<Flex dir="row" width={0} flex={1} alignItems={'center'} gap="12px" transition={'all 150ms'}>
			<FilterDropdownButton
				isLoading={isLoading}
				initialFilters={globalFilters}
				eventMetaData={{ feature: 'filters', page: 'Dashboard Page', application: 'dashboard' }}
				onFilterSubmit={setGlobalFilters}
				availableEntities={availableFilterObjects}
			/>
			<Flex direction={'row'} flex={1} width={0} justifyContent="center">
				{isScrolling && (
					<Flex
						height={'36px'}
						minWidth={'32px'}
						boxShadow={isStartShadowShown ? shadows['a-22'] : undefined}
						zIndex={1}
						clipPath="inset(0px -25px 0px 0px)"
						alignItems="center"
						onClick={() => {
							scrollComponentRef.current?.scrollBy({ left: -scrollOnClickPixels, behavior: 'smooth' });
						}}
					>
						<IconButton icon={<ChevronLeft16 />} size="xxs" colorScheme="black" isDisabled={!isStartShadowShown} />
					</Flex>
				)}
				{isScrolling && (
					<Flex
						height={'36px'}
						minWidth={'32px'}
						boxShadow={isEndShadowShown ? shadows['a-22'] : undefined}
						zIndex={1}
						clipPath="inset(0px 0px 0px -25px)"
						alignItems="center"
						justifyContent={'end'}
					>
						<IconButton
							icon={<ChevronRight16 />}
							size="xxs"
							colorScheme="black"
							isDisabled={!isEndShadowShown}
							onClick={() => {
								scrollComponentRef.current?.scrollBy({ left: scrollOnClickPixels, behavior: 'smooth' });
							}}
						/>
					</Flex>
				)}
			</Flex>
		</Flex>
	);
}
