import { Box } from '@chakra-ui/react';
import pluralize from 'pluralize';
import { ConfirmationModal } from 'src/common/components/ConfirmationModal';
import Flex from 'src/common/components/Flex';
import { capitalizedFirstLetter } from 'src/common/utils/format';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { NormalizedOntology } from '../../hooks/useOntologyPageState';
import { OntologyDimensionInfo, OntologyRelationshipInfo } from '../../utils/normalizeYaml';
import { BuilderTypes } from '../../utils/types';
import { isOntologyDimensionInfo, isOntologyRelationshipInfo } from '../../utils/utils';
import { TestIDs } from 'src/common/types/test-ids';
import { AffectedMetricsButton } from '@components/AffectedMetricsButton/AffectedMetricsButton';

export default function DeleteItemsModal({
	propertyToDelete,
	affectedMetricsList,
	onClose,
	type,
	onSubmit,
	isLoading = false,
}: {
	type: BuilderTypes;
	propertyToDelete?: OntologyDimensionInfo | OntologyRelationshipInfo | NormalizedOntology;
	affectedMetricsList: string[];
	onClose: VoidFunction;
	onSubmit: VoidFunction;
	isLoading?: boolean;
}) {
	const name =
		isOntologyRelationshipInfo(propertyToDelete) || isOntologyDimensionInfo(propertyToDelete)
			? propertyToDelete?.meta?.display_name || removeUnderscoresAndCapitalize(propertyToDelete?.name || '')
			: propertyToDelete?.displayName || removeUnderscoresAndCapitalize(propertyToDelete?.name || '');

	const descriptionMaintext = `${pluralize(type, 1)} ${
		type === 'entity'
			? 'will be permanently deleted from the ontology with its related dimensions and relationships.'
			: 'will be permanently deleted from the ontology.'
	}`;

	return (
		<ConfirmationModal
			testId={TestIDs.ENTITY_DELETE_MODAL}
			isLoading={isLoading}
			contentMaxWidth="auto"
			primaryButtonLabel={'Delete'}
			isOpen={!!propertyToDelete}
			modalText={
				<Flex direction="column" gap="10px">
					<Box>
						<Box as="span" fontWeight="600">
							{name}
						</Box>
						&nbsp;
						{descriptionMaintext}
					</Box>
				</Flex>
			}
			modalTitle={`Delete ${pluralize(capitalizedFirstLetter(type), 1)}`}
			onSubmit={onSubmit}
			onClose={onClose}
			submitColorScheme={'red'}
			minimumModalHeight="300px"
			additionalButton={
				affectedMetricsList.length > 0 ? (
					<AffectedMetricsButton
						label={`Affected ${pluralize('metric', affectedMetricsList.length)} (${affectedMetricsList.length})`}
						colorScheme={'gray'}
						size={'small'}
						variant={'ghost'}
						metricNames={affectedMetricsList}
					/>
				) : null
			}
		/>
	);
}
