import { Box, Flex } from '@chakra-ui/react';
import { CheckboxCheck10 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import colors from 'src/style/colors';

interface CompletionBoxProps {
	isCompleted: boolean;
	isFocused: boolean;
}

export function CompletionBoxWithLabel({ isCompleted, isFocused, label }: CompletionBoxProps & { label: string }) {
	return (
		<Flex direction="column" alignItems="center" width="143px">
			<CompletionBox isCompleted={isCompleted} isFocused={isFocused} />
			<Typography variant="DesktopH8Medium" color={colors.gray[1000]} marginTop="16px">
				{label}
			</Typography>
		</Flex>
	);
}

function CompletionBox({ isCompleted, isFocused }: CompletionBoxProps) {
	return (
		<Flex
			width="20px"
			height="20px"
			borderRadius="4px"
			alignItems="center"
			justifyContent="center"
			transition="all 0.1s ease-in-out"
			outline={isFocused ? `3px solid ${colors.blue[300]}` : `none`}
			backgroundColor={isFocused || isCompleted ? colors.blue[500] : 'white'}
			border={isFocused || isCompleted ? `none` : `1px solid ${colors.gray[400]}`}
		>
			{isCompleted && <CheckboxCheck10 color={colors.white} />}
			{!isCompleted && isFocused && <Box width="6px" height="6px" backgroundColor="white" borderRadius="1px" />}
		</Flex>
	);
}
