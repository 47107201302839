import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { computePosition } from '@floating-ui/react-dom';
import { $getSelection, $isRangeSelection } from 'lexical';
import ToolbarPlugin from './Toolbar';
import { usePointerInteractions } from './hooks/usePointerInteractions';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import classes from '@components/TextEditor/styles/TextEditor.module.scss';

const DOM_ELEMENT = document.body;
const TOOLBAR_MARGIN_BOTTOM = 10;
const HIDDEN_TOOLBAR_COORDS = { x: 0, y: -50 };

type FloatingMenuCoords = { x: number; y: number };

export function FloatingToolbarPlugin() {
	const ref = useRef<HTMLDivElement>(null);
	const [coords, setCoords] = useState<FloatingMenuCoords>(HIDDEN_TOOLBAR_COORDS);
	const [editor] = useLexicalComposerContext();

	const { isPointerDown, isPointerReleased } = usePointerInteractions();

	const calculatePosition = useCallback(() => {
		const domSelection = getSelection();
		const domRange = domSelection?.rangeCount !== 0 && domSelection?.getRangeAt(0);

		if (!domRange || !ref.current || isPointerDown) return setCoords(HIDDEN_TOOLBAR_COORDS);

		computePosition(domRange, ref.current, { placement: 'top' })
			.then((pos) => {
				setCoords({ x: pos.x, y: pos.y - TOOLBAR_MARGIN_BOTTOM });
			})
			.catch(() => {
				setCoords(HIDDEN_TOOLBAR_COORDS);
			});
	}, [isPointerDown]);

	const $handleSelectionChange = useCallback(() => {
		if (editor.isComposing() || editor.getRootElement() !== document.activeElement) {
			setCoords(HIDDEN_TOOLBAR_COORDS);
			return;
		}

		const selection = $getSelection();

		if ($isRangeSelection(selection) && !selection.anchor.is(selection.focus)) {
			calculatePosition();
		} else {
			setCoords(HIDDEN_TOOLBAR_COORDS);
		}
	}, [editor, calculatePosition]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const target = event.target;

			if (!(target instanceof HTMLElement)) {
				return;
			}

			if (ref.current?.contains(target)) {
				return;
			}

			if (target.closest(`.${classes.selectContainer}`)) {
				return;
			}

			setCoords(HIDDEN_TOOLBAR_COORDS);
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [editor]);

	useEffect(() => {
		return editor.registerUpdateListener(({ editorState }) => {
			editorState.read(() => $handleSelectionChange());
		});
	}, [editor, $handleSelectionChange]);

	const show = coords.x !== HIDDEN_TOOLBAR_COORDS.x && coords.y !== HIDDEN_TOOLBAR_COORDS.y;

	useEffect(() => {
		if (!show && isPointerReleased) {
			const timeoutId = setTimeout(() => {
				editor.getEditorState().read(() => $handleSelectionChange());
			}, 0);
			return () => clearTimeout(timeoutId);
		}
	}, [isPointerReleased, $handleSelectionChange, editor, show]);

	return createPortal(<ToolbarPlugin ref={ref} coords={coords} editor={editor} isFloating={true} />, DOM_ELEMENT);
}
