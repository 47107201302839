import { Flex } from '@chakra-ui/react';
import Typography from 'src/common/components/Typography';
import colors from 'src/style/colors';
import { CompletionBoxWithLabel } from './CompletionBox';

const steps = ['ARR amount', 'ARR recognition start', 'ARR recognition end'];

export function PreviewStepper({
	focusedIndex,
	completedIndexes,
}: {
	focusedIndex: number;
	completedIndexes: number[];
}) {
	const enabledIndexes = [...completedIndexes, focusedIndex];
	const isFirstLineEnabled = enabledIndexes.includes(0) && enabledIndexes.includes(1);
	const isSecondLineEnabled = enabledIndexes.includes(1) && enabledIndexes.includes(2);

	return (
		<Flex direction={'column'} gap={'44px'} alignItems={'center'}>
			<Typography variant="DesktopH8Regular" color={colors.gray[1000]}>
				Fill in the required fields to view your ARR metrics.
			</Typography>
			<Flex position={'relative'}>
				<Flex direction={'row'} gap={'28px'}>
					{steps.map((step, index) => (
						<CompletionBoxWithLabel
							key={index}
							isCompleted={completedIndexes.includes(index)}
							isFocused={focusedIndex === index}
							label={step}
						/>
					))}
				</Flex>
				<Flex
					position={'absolute'}
					top={'9px'}
					left={'90px'}
					right={'260px'}
					height={'1px'}
					backgroundColor={isFirstLineEnabled ? colors.blue[600] : colors.gray[400]}
				></Flex>
				<Flex
					position={'absolute'}
					top={'9px'}
					right={'90px'}
					left={'260px'}
					height={'1px'}
					backgroundColor={isSecondLineEnabled ? colors.blue[600] : colors.gray[400]}
				></Flex>
			</Flex>
		</Flex>
	);
}
