import useRESTAuthInfo from '@hooks/useRESTAuthInfo';
import { useCallback, useMemo } from 'react';
import {
	AIAgentName,
	ChatEventListener,
	CreateAIAgentThreadResponse,
	isChatEventResultType,
	isChatEvent,
	ChatEventData,
	ChatEvent,
	isChatEventStart,
	isChatEventDelta,
} from '../types';
import { atom, useAtom, Provider } from 'jotai';
import useToast from '../../../hooks/ui/useToast';
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';

const AGENT_CALL_TIMEOUT = 90000;
const PING_TIMEOUT = 5000;

type AIAgentsContext = {
	threadId?: string;
	abortController?: AbortController;
};

export class AIAgentAbortError extends Error {
	constructor(message?: string) {
		super(message);
		this.name = 'AbortError';
	}
}

export class AIAgentTimeoutError extends Error {
	constructor(message?: string) {
		super(message);
		this.name = 'TimeoutError';
	}
}

export function isAIAgentAbortError(error: any): error is DOMException {
	return error.name === 'AbortError';
}

const aiAgentsContextScope = Symbol();
const AIAgentsContextAtom = atom<AIAgentsContext>({});

export function AIAgentsContextBoundary({ children }: { children: React.ReactNode }) {
	return (
		<Provider initialValues={[[AIAgentsContextAtom, {}]]} scope={aiAgentsContextScope}>
			{children}
		</Provider>
	);
}

export function useAIAgents() {
	const toast = useToast();
	const { accessToken, tenantId, role, apiUrl } = useRESTAuthInfo();
	const [aiAgentsContext, setAIAgentsContext] = useAtom(AIAgentsContextAtom, aiAgentsContextScope);

	const isBusy = useMemo(() => !!aiAgentsContext.abortController, [aiAgentsContext.abortController]);

	const handleAIAgentError = useCallback(
		(error: string) => {
			toast({ variant: 'error', message: error, duration: 5000 });
		},
		[toast]
	);

	const abortAIAgent = useCallback(
		(reason?: string) => {
			if (aiAgentsContext?.abortController) {
				aiAgentsContext.abortController.abort(reason ? new AIAgentAbortError(reason) : undefined);
			}
		},
		[aiAgentsContext.abortController]
	);

	const callAIAgent = useCallback(
		async <T,>({
			agentName,
			threadId,
			body,
		}: {
			agentName: AIAgentName;
			threadId?: string;
			body?: any;
		}): Promise<T | undefined> => {
			try {
				let url = `${apiUrl}/ai/agents/${agentName}/threads`;
				if (threadId) {
					url = `${url}/${threadId}`;
				}
				const abortController = new AbortController();
				const timeoutHandle = setTimeout(
					() => abortController.abort(new AIAgentAbortError('There was a timeout generating the response')),
					AGENT_CALL_TIMEOUT
				);
				setAIAgentsContext((cur) => ({ ...cur, abortController }));
				const response = await fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
						'X-Role': role,
						'X-Tenant-Id': `${tenantId}`,
					},
					body: typeof body === 'object' ? JSON.stringify(body) : body,
					signal: abortController.signal,
				});
				clearTimeout(timeoutHandle);

				if (response.status >= 400) {
					throw new Error(response.statusText.toLowerCase());
				}
				return response.json();
			} finally {
				setAIAgentsContext((cur) => ({ ...cur, abortController: undefined }));
			}
		},
		[accessToken, apiUrl, role, setAIAgentsContext, tenantId]
	);

	const callStreamingAIAgent = useCallback(
		({
			agentName,
			threadId,
			body,
			events,
		}: {
			agentName: AIAgentName;
			threadId?: string;
			body?: any;
			events: ChatEventListener;
		}) => {
			let url = `${apiUrl}/ai/agents/stream/${agentName}/threads`;
			if (threadId) {
				url = `${url}/${threadId}`;
			}
			const abortController = new AbortController();
			const timeoutHandle = setTimeout(
				() => abortController.abort(new AIAgentTimeoutError('Timeout generating the response')),
				AGENT_CALL_TIMEOUT
			);

			let pingTimeoutHandle: NodeJS.Timeout;
			const onPing = (renew = true) => {
				clearTimeout(pingTimeoutHandle);
				if (!renew) return;
				pingTimeoutHandle = setTimeout(
					() => abortController.abort(new AIAgentTimeoutError('Communication timeout')),
					PING_TIMEOUT
				);
			};
			onPing();

			setAIAgentsContext((cur) => ({ ...cur, abortController }));
			fetchEventSource(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-Role': role,
					'X-Tenant-Id': `${tenantId}`,
				},
				body: typeof body === 'object' ? JSON.stringify(body) : body,
				signal: abortController.signal,
				async onopen(response) {
					if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
						events.onOpen?.();
					} else if (response.status >= 400) {
						throw new Error(response.statusText.toLowerCase());
					}
				},
				onmessage(msg) {
					onPing();
					if (!isChatEvent(msg)) {
						return;
					}

					if (msg.event === 'ping' || msg.event === 'end') {
						return;
					}

					const parsedData: ChatEventData = JSON.parse(msg.data);
					if (msg.event === 'error') {
						throw new Error(parsedData.text);
					}

					if (isChatEventStart(msg)) {
						return events.onStart?.({ promptId: parsedData.promptId, text: parsedData.text });
					}

					if (isChatEventDelta(msg)) {
						return events.onDelta?.({ text: parsedData.text });
					}

					if (msg.event === 'result') {
						const { type } = parsedData;
						if (!isChatEventResultType(type)) {
							throw new Error('Invalid result type');
						}
						return events.onResult?.({ type, result: parsedData });
					}

					if (['step', 'thinking', 'tool'].includes(msg.event)) {
						const chatEvent: ChatEvent = { event: msg.event, data: parsedData };
						events.onEvent?.(chatEvent);
					}
				},
				onerror(err) {
					throw err;
				},
			})
				.catch((error) => {
					abortController.abort(error);
				})
				.finally(() => {
					onPing(false);
					clearTimeout(timeoutHandle);
					setAIAgentsContext((cur) => ({ ...cur, abortController: undefined }));
					if (abortController.signal.aborted) {
						events.onError?.(abortController.signal.reason?.message);
					}
					events.onClose?.();
				});
		},
		[accessToken, apiUrl, role, setAIAgentsContext, tenantId]
	);

	const createAIAgentThread = useCallback(
		async ({ agentName }: { agentName: AIAgentName }) => {
			const data = await callAIAgent<CreateAIAgentThreadResponse>({ agentName });
			if (data) {
				setAIAgentsContext((cur) => ({ ...cur, threadId: data.threadId }));
			}
			return data;
		},
		[callAIAgent, setAIAgentsContext]
	);

	const deleteAIAgentPrompt = useCallback(
		async ({ threadId, promptId }: { threadId: string; promptId: string }) => {
			await fetch(`${apiUrl}/ai/agents/threads/${threadId}/prompts/delete/${promptId}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-Role': role,
					'X-Tenant-Id': `${tenantId}`,
				},
			});
		},
		[accessToken, apiUrl, role, tenantId]
	);

	const getOrCreateAIAgentThread = useCallback(
		async ({ agentName }: { agentName: AIAgentName }) => {
			if (aiAgentsContext.threadId) {
				return aiAgentsContext.threadId;
			}
			const response = await createAIAgentThread({ agentName });
			return response?.threadId;
		},
		[aiAgentsContext.threadId, createAIAgentThread]
	);

	const clearAIAgentsContext = useCallback(() => setAIAgentsContext({}), [setAIAgentsContext]);

	return {
		isBusy,
		threadId: aiAgentsContext.threadId,
		deleteAIAgentPrompt,
		createAIAgentThread,
		getOrCreateAIAgentThread,
		callAIAgent,
		callStreamingAIAgent,
		abortAIAgent,
		handleAIAgentError,
		clearAIAgentsContext,
	};
}
