import { Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import classes from './FeatureSwitcher.module.scss';
import classNames from 'classnames';

export type SwitchProps = {
	options: ReactNode[];
	onChildClicked?: (index: number) => void;
	isDisabled?: boolean;
	selectedIndex: number;
	marginBottom?: string;
	isToggle?: boolean;
};
export default function FeatureSwitcher({
	options,
	onChildClicked,
	isDisabled = false,
	selectedIndex,
	isToggle = false,
}: SwitchProps) {
	const onClick = (index: number) => {
		if (isDisabled) {
			return;
		}
		onChildClicked?.(index);
	};
	return (
		<>
			<Flex
				flexDirection={'column'}
				className={classNames(classes.switch, {
					[classes.toggle]: isToggle,
				})}
			>
				<Flex
					width={'100%'}
					justifyContent={'space-between'}
					alignItems={'center'}
					alignContent={'center'}
					cursor={'default'}
				>
					{options.map((el, index) => {
						return (
							<Flex
								onClick={() => onClick(index)}
								className={classNames(classes.switcherButton, {
									[classes.isActive]: selectedIndex === index,
									[classes.isDisabled]: isDisabled,
									[classes.isLeftButton]: index === 0,
									[classes.isRightButton]: index === 1,
								})}
								key={index}
							>
								{el}
							</Flex>
						);
					})}
				</Flex>
			</Flex>
		</>
	);
}
