import { ChartBar24 } from '@components/Icons';
import { NodeProps } from '@xyflow/react';
import colors from '../../../../style/colors';
import { SemanticHeader } from '../components/SemanticHeader';
import { SemanticNode } from '../components/SemanticNode';
import { MetricNodeType, SemanticMetricDimensionType } from '../types';
import { SemanticNodeContent, SemanticPopupContent } from '../components/SemanticContent';
import Flex from '../../Flex';
import { SemanticFooter } from '../components/SemanticFooter';
import { SemanticParagraph } from '../components/SemanticParagraph';
import { SemanticBody } from '../components/SemanticBody';
import { MetricPagePath } from '../../../../pages/MetricPage/pageRoutesPaths';
import { ExploreSemanticObjectButton } from '../components/ExploreSemanticObjectButton';
import { SemanticField } from '../components/SemanticField';
import { SemanticDimensions } from '../components/SemanticDimensions';
import { useState } from 'react';
import { SemanticTooltip } from '../components/SemanticTooltip';

export function MetricNode(props: NodeProps<MetricNodeType>) {
	const { data } = props;
	const { displayName, metricName, description, dimensions, direction } = data;
	const [hoveredDimension, setHoveredDimension] = useState<SemanticMetricDimensionType | null>(null);

	const header = (
		<SemanticHeader
			title={displayName ?? metricName}
			subtitle={'Metric'}
			icon={<ChartBar24 />}
			iconColor={colors.violet[500]}
			iconBgColor={colors.violet[100]}
		/>
	);

	const onLabelHover = (dimension: string) => {
		const dimensionObject = dimensions.find((d) => d.name === dimension);
		if (dimensionObject) {
			setHoveredDimension(dimensionObject);
		}
	};

	const onLabelLeave = () => setHoveredDimension(null);

	return (
		<SemanticNode
			popupContent={
				<SemanticPopupContent>
					<SemanticBody>
						<SemanticParagraph fallbackContent={'No description'}>{description}</SemanticParagraph>
						<SemanticField name={'Token name'} value={`$metric__${metricName}`} />
						{dimensions.length > 0 ? (
							<>
								<Flex direction={'column'} gap={'8px'}>
									<SemanticDimensions
										dimensions={dimensions.map((dimension) => dimension.name)}
										withHeader={true}
										onDimensionHover={onLabelHover}
										onDimensionLeave={onLabelLeave}
									/>
								</Flex>
								{hoveredDimension && (
									<SemanticTooltip dimensionName={hoveredDimension.name} entityName={hoveredDimension.entity} />
								)}
							</>
						) : undefined}
					</SemanticBody>
					<SemanticFooter>
						<ExploreSemanticObjectButton
							path={`/${MetricPagePath}/${metricName}`}
							text={'Explore metric'}
							metadata={{ objectType: 'metric', objectName: metricName, direction }}
						/>
					</SemanticFooter>
				</SemanticPopupContent>
			}
			{...props}
		>
			<SemanticNodeContent>{header}</SemanticNodeContent>
		</SemanticNode>
	);
}
