import Box from '@components/Box';
import Flex from '@components/Flex';
import { ConnectorMockData, useMockData } from '@pages/ConnectorsPage/components/ConnectorsTable/useMockData';
import pluralize from 'pluralize';
import { useCallback, useMemo, useState } from 'react';
import Button from 'src/common/components/Button';
import { GridTableBody } from 'src/common/components/GridTable';
import { ConnectorsEmptyState, Plus16, Search16 } from 'src/common/components/Icons';
import Input from 'src/common/components/Input';
import Tooltip from 'src/common/components/Tooltip';
import Typography from 'src/common/components/Typography';
import { useKeyPress } from 'src/common/hooks/interaction/useKeyPress';
import useTenantConfig from 'src/common/hooks/stores/useTenantConfig';
import { checkForMatch } from 'src/common/utils/format';
import { ListConnectorsQuery, useListConnectorsQuery } from 'src/generated/graphql';
import { useReportEvent } from 'src/services/analytics';
import { connectorsTableMetadata } from '../../utils/constants';
import { serializeConnectorsTableColumnDef, serializeConnectorsTableRowDef } from '../../utils/utils';
import { SkeletonTableLayout } from '../SkeletonComponents/SkeletonTableLayout';
import { AddDataSourceButton } from '@pages/ConnectorsPage/components/AddDataSourceButton/AddDataSourceButton';
import { TestIDs } from 'src/common/types/test-ids';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

const MAX_ROWS_NUMBER = 7;

export const ConnectorsTable = () => {
	const tenantConfig = useTenantConfig();
	const [searchValue, setSearchValue] = useState<string | undefined>();
	const { reportEvent } = useReportEvent();

	const { isMockTenant, mockData } = useMockData();

	const { data: connectorsArray, loading: isLoadingConnectorsList } = useListConnectorsQuery({
		fetchPolicy: 'no-cache',
		skip: isMockTenant,
	});

	const connectors: ListConnectorsQuery['listConnectors']['connectors'] | undefined =
		connectorsArray?.listConnectors?.connectors;

	const connectorsList: ConnectorMockData[] | ListConnectorsQuery['listConnectors']['connectors'] = useMemo(() => {
		return (isMockTenant ? mockData : connectors) || [];
	}, [connectors, isMockTenant, mockData]);

	const columnDefs = useMemo(
		() => serializeConnectorsTableColumnDef(connectorsTableMetadata, tenantConfig) || [],
		[tenantConfig]
	);

	const rowData = useMemo(() => {
		const checkAllProps = (obj: unknown): boolean => {
			if (typeof obj === 'object' && obj !== null) {
				return Object.values(obj).some((prop) => {
					if (typeof prop === 'string') return checkForMatch({ subject: prop, value: searchValue || '' });
					if (typeof prop === 'object' && prop !== null) return checkAllProps(prop);
					return false;
				});
			}
			return false;
		};

		return serializeConnectorsTableRowDef(connectorsList)?.filter((connector) => checkAllProps(connector));
	}, [connectorsList, searchValue]);

	const isActiveSearch = useMemo(() => searchValue !== undefined, [searchValue]);

	const onCloseSearchInput = () => !searchValue && onSearch?.(undefined);

	useKeyPress(['Escape'], onCloseSearchInput);

	const onSearch = useCallback(
		(val?: string) => {
			setSearchValue(val);
			reportEvent({
				event: 'connectors-object-search-type',
				metaData: { objectType: 'connectors', searchTerm: val },
			});
		},
		[reportEvent]
	);

	const AddConnectorButton = () => (
		<div
			{...buildIntercomAttributes({
				area: 'main',
				type: 'main',
				target: 'data-connectors-modal-button',
			})}
		>
			<AddDataSourceButton
				colorScheme={'blue'}
				label={'Add new data source'}
				leftIcon={<Plus16 />}
				reportOnClick={() => {
					reportEvent({
						event: 'connectors-add-connector',
					});
				}}
				dataTestId={TestIDs.ADD_NEW_DATA_SOURCE_BUTTON}
			/>
		</div>
	);

	const SearchBar = () => (
		<Flex gap={'16px'} alignItems={'center'} marginBottom={'20px'}>
			<Flex width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
				{isActiveSearch ? (
					<Flex maxW={'280px'} width={'100%'} borderRadius={'8px'}>
						<Input
							borderRadius="4px"
							borderColor="gray.300"
							isErasable={!!searchValue}
							autoFocus
							size="xs"
							leftComponent={<Search16 />}
							placeholder="Search"
							value={searchValue}
							onEnter={onCloseSearchInput}
							onBlur={onCloseSearchInput}
							onChange={(val: string) => onSearch?.(val)}
						/>
					</Flex>
				) : (
					<Flex alignItems={'center'}>
						<Tooltip label="Search" size="md" variant="fluid" background="black" hasArrow={true} marginTop="8px">
							<Button isIconOnly variant="outline" onClick={() => setSearchValue('')} size="inline" colorScheme="black">
								<Search16 />
							</Button>
						</Tooltip>
						{rowData && (
							<Typography variant={'DesktopH7Medium'} color={'gray.1000'} textAlign="start">
								{rowData.length} Data {pluralize('sources', rowData.length)}
							</Typography>
						)}
					</Flex>
				)}
				{<AddConnectorButton />}
			</Flex>
		</Flex>
	);

	return (
		<Box h="100%">
			{isLoadingConnectorsList ? (
				<SkeletonTableLayout />
			) : connectorsList.length > 0 ? (
				<>
					<SearchBar />
					<Box paddingBottom={'32px'}>
						{columnDefs && rowData && (
							<GridTableBody
								data={rowData}
								maxRowsToShow={MAX_ROWS_NUMBER}
								headerHeight={49}
								rowHeight={80}
								columnDefs={columnDefs}
							/>
						)}
					</Box>
				</>
			) : (
				<Flex h="100%" pb="80px" alignItems={'center'} justifyContent={'center'}>
					<NoConnectors addConnectorButton={<AddConnectorButton />} />
				</Flex>
			)}
		</Box>
	);
};

function NoConnectors({ addConnectorButton }: { addConnectorButton: JSX.Element }) {
	return (
		<Flex direction={'column'} alignItems={'center'} justifyContent={'center'} height={'100%'}>
			<ConnectorsEmptyState />
			<Flex alignItems={'center'} direction={'column'} pt="24px" pb="20px">
				<Typography pb="4px" variant={'DesktopH7Medium'} color={'gray.1000'} textAlign="center">
					Add your first data source.
				</Typography>
				<Typography variant={'Paragraph16R'} color={'gray.1000'} textAlign="center">
					Every source you add unlocks additional insights.
				</Typography>
				<Box pt="20px">{addConnectorButton}</Box>
			</Flex>
		</Flex>
	);
}
