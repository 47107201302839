import { ModalHeader } from '@chakra-ui/react';
import Flex from '@components/Flex';
import { PageSkeleton } from './SkeletonComponents';
import Modal from 'src/common/components/Modal';
import Button from '../Button';
import { CloseLarge16, Lock25 } from '../Icons';
import Typography from '../Typography';
import colors from 'src/style/colors';
import { useResourceRoles } from '../ResourceAccessModal/useResourceRoles';
import { SpecificResource } from '../Can/types';
import { ReactNode, useMemo } from 'react';
import { UsersList } from './UsersList';

type RBACNoAccessModalProps = {
	isPageLoading?: boolean;
	resource: SpecificResource;
	pageLoader?: ReactNode;
	onClose: VoidFunction;
};

export function RBACNoAccessModal({
	isPageLoading,
	resource,
	pageLoader = <PageSkeleton />,
	onClose,
}: RBACNoAccessModalProps) {
	const { roles, isLoading } = useResourceRoles({ resource });

	const owners = useMemo(() => roles?.users?.filter((user) => user.role === 'owner') || [], [roles?.users]);

	if (isPageLoading) return pageLoader;

	return (
		<Modal maxWidth={'492px'} isOpen onClose={onClose} isCentered>
			<>
				<ModalHeader color="gray.1000" p="16px 16px 0px 16px">
					<Flex justifyContent="start" alignItems="center">
						<Button variant="outline" isIconOnly onClick={onClose} size="inline" colorScheme="black">
							<CloseLarge16 />
						</Button>
					</Flex>
				</ModalHeader>
				<Flex padding={'24px 32px 32px 32px'} width={'100%'}>
					<Flex
						padding={'0px 20px 16px 20px'}
						flexDirection={'column'}
						alignContent={'center'}
						alignItems={'center'}
						width={'100%'}
					>
						<Lock25 color={colors.blue[600]} />
						<Typography marginTop={'12px'} marginBottom={'6px'} variant="DesktopH5" color="gray.1000">
							A private metric.
						</Typography>
						<Typography variant="Paragraph14R" color="gray.800">
							You don&quot;t have access to view this metric.
						</Typography>
						<Typography marginY={'16px'} variant="Paragraph14R" color="gray.800">
							The metric owners are:
						</Typography>
						<Flex padding={'0px 25px'} width={'100%'}>
							<UsersList metricName={resource.resourceId} owners={owners} isLoading={isLoading} />
						</Flex>
					</Flex>
				</Flex>
			</>
		</Modal>
	);
}
