export type AskAIPageSuggestion = {
	prompt?: string;
	display?: string;
};

type AISettings = {
	omniPage?: {
		subtitle?: string;
		suggestions?: AskAIPageSuggestion[];
	};
};

type TenantConfigType = {
	fiscalYearStart: string;
	logoUrl: string;
	companyName: string;
	trialEnd: string;
	graphColors: string[];
	decimalDigits?: number;
	lastEtlSync?: Date;
	lookbackMonths?: number;
	schemasVersion?: string;
	tenantState?: string;
	plan: PlanConfig;
	isSightfullTenant?: boolean; // TA2.0: make this non-optional
	aiSettings?: AISettings;
};

type PlanConfig = {
	resource_rbac: boolean;
};

export const defaultPlanConfig: PlanConfig = {
	resource_rbac: false,
};

export default TenantConfigType;
