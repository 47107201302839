import { Box, Popover, PopoverAnchor, PopoverContent } from '@chakra-ui/react';
import { HoverTooltip } from '../../FilterDropdownButton/HoverTooltip';

interface SemanticTooltipProps {
	dimensionName: string;
	entityName: string;
}

export function SemanticTooltip({ dimensionName, entityName }: SemanticTooltipProps) {
	return (
		<Popover placement={'right-start'} isOpen={true}>
			<PopoverAnchor>
				<Box position={'absolute'} right={0} top={0} height={'100%'} />
			</PopoverAnchor>
			<PopoverContent>
				<HoverTooltip
					dimensionName={dimensionName}
					dimensionsEntity={entityName}
					entityName={entityName}
					label={dimensionName}
				/>
			</PopoverContent>
		</Popover>
	);
}
