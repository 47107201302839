import Flex from 'src/common/components/Flex';
import { ColDef, ShouldRowBeSkippedParams } from 'ag-grid-community';
import isEqual from 'lodash/isEqual';
import 'src/common/components/Table/Table.scss';
import useTenantState from 'src/common/hooks/ui/useTenantState';
import FiltersAndBreakdownsModal from 'src/pages/MetricPage/components/FiltersAndBreakdown/FiltersAndBreakdownsModal';
import { useCoreNodeScheme } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { COLUMN_MODEL_TYPE } from 'src/pages/MetricPage/components/FiltersAndBreakdown/consts';
import { FiltersAndBreakdownResponseType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import useFiltersAndBreakdown from 'src/pages/MetricPage/components/FiltersAndBreakdown/useFiltersAndBreakdown';
import { useReportEvent } from 'src/services/analytics';
import { ColumnsAndFilters } from '../../utils/types';
import { GridTable, GridTableBody, GridTableHeader, GridTableApi } from '@components/GridTable';
import {
	GridTableHeaderActionAddColumn,
	GridTableHeaderActionDivider,
	GridTableHeaderActionExport,
	GridTableHeaderActions,
} from '@components/GridTable/GridTableHeaderAction';
import { ErrorButton } from '@components/ErrorButton';
import { useCallback } from 'react';
import { useMenuItemsParams } from '../../../../common/components/Table/config';
import { MAX_ITEMS_TO_FETCH } from '../../utils/consts';
import { getColumnsFromFilterAndBreakdownResult } from './utils';
import { SkeletonTable } from '../SkeletonComponents';
import { FilterDropdownButton } from 'src/common/components/FilterDropdownButton/FilterDropdownButton';
import { useFilterV2Logic } from 'src/common/components/FilterDropdownButton/useFilterV2Logic';
import pluralize from 'pluralize';

const TRIAL_LIMIT = 25;
const LIMITED_EXPORT_MSG =
	'Export is limited to 25 lines during the trial period. Please upgrade to access the full data.';

export type OntologyTableV2Params = {
	data: Record<string, any>[];
	columnDefs?: ColDef[];
	isErrorActive?: boolean;
	errorMessage?: string;
	columnsAndFilters: ColumnsAndFilters;
	setColumnsAndFilters: (val: ColumnsAndFilters) => void;
	entityName: string;
	onRowClicked?: (recordId: string) => void;
	isTableLoading?: boolean;
};

export function OntologyGridTable({
	data,
	columnDefs,
	isErrorActive,
	errorMessage,
	columnsAndFilters,
	setColumnsAndFilters,
	entityName,
	onRowClicked,
	isTableLoading,
}: {
	data: Record<string, any>[];
	columnDefs?: ColDef[];
	isErrorActive?: boolean;
	errorMessage?: string;
	columnsAndFilters: ColumnsAndFilters;
	setColumnsAndFilters: (val: ColumnsAndFilters) => void;
	entityName: string;
	onRowClicked?: (recordId: string) => void;
	isTableLoading?: boolean;
}) {
	return (
		<OntologyTable
			entityName={entityName}
			columnsAndFilters={columnsAndFilters}
			setColumnsAndFilters={setColumnsAndFilters}
			errorMessage={errorMessage}
			isErrorActive={isErrorActive}
			data={data}
			columnDefs={columnDefs}
			onRowClicked={onRowClicked}
			isTableLoading={isTableLoading}
		/>
	);
}

export function OntologyTable({
	data,
	columnDefs,
	isErrorActive,
	errorMessage,
	columnsAndFilters,
	setColumnsAndFilters,
	entityName,
	onRowClicked,
	isTableLoading,
}: OntologyTableV2Params) {
	const { reportEvent } = useReportEvent();
	const [modalState, modalActions] = useFiltersAndBreakdown();
	const { additionalColumns, filter } = columnsAndFilters;

	const { serializeFilter } = useFilterV2Logic({});

	const { shouldLimitExports } = useTenantState();
	const exportTableDataAsCSV = (gridApi: GridTableApi) => {
		const shouldRowBeSkipped = shouldLimitExports
			? (params: ShouldRowBeSkippedParams) => !!params.node.rowIndex && params.node.rowIndex >= TRIAL_LIMIT
			: undefined;

		gridApi.api?.exportDataAsCsv?.({
			shouldRowBeSkipped,
			appendContent: shouldLimitExports ? LIMITED_EXPORT_MSG : undefined,
		});
	};

	const handleAddColumns = useCallback(
		(result: FiltersAndBreakdownResponseType) => {
			if (!result?.items?.length) return;
			const columns = getColumnsFromFilterAndBreakdownResult(result, entityName);
			if (isEqual(additionalColumns, columns)) return;
			setColumnsAndFilters({ ...columnsAndFilters, additionalColumns: [...additionalColumns, ...columns] });
		},
		[additionalColumns, columnsAndFilters, entityName, setColumnsAndFilters]
	);

	const contextMenuItems = useMenuItemsParams(filter, (filterObj: { key: string; value: string }) => {
		reportEvent({
			event: 'add-filter-from-table',
			metaData: {
				key: filterObj?.key,
				value: filterObj?.value,
				entity: entityName,
			},
		});

		const newFilter = serializeFilter({
			key: filterObj?.key,
			values: [filterObj?.value],
			label: filterObj?.key,
			baseEntity: entityName,
		});

		return setColumnsAndFilters({
			...columnsAndFilters,
			filter: [...filter, newFilter],
		});
	});

	const coreNodeScheme = useCoreNodeScheme({
		objectsTypes: [entityName],
		schemeType: 'global',
		fetchPolicy: 'no-cache',
		readyToFetch: !isTableLoading && !!entityName,
	});

	return (
		<GridTable padding={'0 32px 22px 32px'}>
			<GridTableHeader
				title={`Showing ${data.length === MAX_ITEMS_TO_FETCH ? 'first ' : ''}${data.length} ${pluralize(
					'record',
					data.length
				)}`}
				tableActions={
					<GridTableHeaderActions>
						{isErrorActive && (
							<ErrorButton
								errorMessage={errorMessage}
								reportEventData={{ event: 'entity-edit-error-show', feature: 'Ontology Editor' }}
							/>
						)}
						<FilterDropdownButton
							key={entityName}
							isLoading={isTableLoading}
							initialFilters={filter}
							onFilterSubmit={(filter) => setColumnsAndFilters({ ...columnsAndFilters, filter })}
							entityName={entityName}
							eventMetaData={{
								feature: 'filters',
								page: 'Ontology Page',
								application: 'entity table',
							}}
						/>
						<GridTableHeaderActionDivider />
						<Flex gap={'4px'}>
							<GridTableHeaderActionExport
								colorScheme="black"
								size="inline"
								isDisabled={isTableLoading}
								onClick={exportTableDataAsCSV}
							/>
							<GridTableHeaderActionAddColumn
								colorScheme="black"
								size="inline"
								isDisabled={isTableLoading}
								onClick={() => {
									reportEvent({
										event: 'entity-add-column-clicked',
										metaData: { feature: 'Entity table', entity: entityName },
									});
									modalActions.onOpen(COLUMN_MODEL_TYPE, handleAddColumns);
								}}
							/>
						</Flex>
					</GridTableHeaderActions>
				}
			/>
			{isTableLoading ? (
				<Flex width="100%" flex={1}>
					<SkeletonTable withHeader={false} />
				</Flex>
			) : (
				<GridTableBody
					data={data}
					columnDefs={columnDefs}
					onRowClicked={(event) => {
						onRowClicked?.(event?.data?._row_id);
					}}
					contextMenuItems={contextMenuItems}
				/>
			)}
			<FiltersAndBreakdownsModal
				type={COLUMN_MODEL_TYPE}
				isOpen={modalState.isOpen}
				onClose={modalActions.onClose}
				onAddItems={handleAddColumns}
				nodeScheme={coreNodeScheme}
				initialFilter={modalState.initialFilter}
			/>
		</GridTable>
	);
}
