import { SelectOption } from 'src/common/components/Select/types';
import { FiltersAndBreakdownItemType } from './types';

export const BREAKDOWN_MODEL_TYPE = 'breakdown';
export const FILTER_MODEL_TYPE = 'filter';
export const COLUMN_MODEL_TYPE = 'column';
export const DIMENSION_SELECT_MODEL_TYPE = 'dimension-select';
export const DEFAULT_PATH_VALUE = [
	{
		key: `Opportunity`,
		label: 'Opportunity',
	},
] as FiltersAndBreakdownItemType[];

export const PUSH = 'push';
export const POP = 'pop';
export const INIT = 'init';

export const DIMENSIONS_RENDER_TYPES = ['string', 'number'];

export const NORMALIZED_PROPS_OPTIONS: SelectOption[] = [
	{ value: 'default-dimensions', label: 'Default dimensions' },
	{ value: 'all-dimensions', label: 'All dimensions' },
];

export const NORMALIZED_PROPS_OPTIONS_V2: SelectOption[] = [
	{ value: 'all-dimensions', label: 'All dimensions' },
	{ value: 'ontology-dimensions', label: 'Ontology dimensions' },
];
