export const waitForElementToAppear = (
	elementGetter: () => any,
	onAppear: (element: any) => void,
	timeoutMs: number
) => {
	const interval = 100;

	let time = 0;
	const intervalId = setInterval(() => {
		const element = elementGetter();

		if (element) {
			onAppear(element);
			clearInterval(intervalId);
			return;
		}

		time += interval;
		if (time >= timeoutMs) {
			clearInterval(intervalId);
			return;
		}
	}, interval);
};

export const buildIntercomAttributes = ({
	area = 'metric',
	type,
	target,
	sequence,
}: {
	area?: string;
	type?: string;
	target?: string;
	sequence?: number;
}) => {
	const attributes: Record<`data-intercom-${string}`, string | number> = {};
	attributes['data-intercom-area'] = area;
	if (type) attributes['data-intercom-type'] = type;
	if (target) attributes['data-intercom-target'] = target;
	if (sequence) attributes['data-intercom-sequence'] = sequence;

	return attributes;
};
