import Box from '@components/Box';
import Typography from '@components/Typography';
import { BreakdownOutline16 } from '@components/Icons';
import { BreakdownDrilldownCard } from '@components/LeftExpandCollapsePanel/Drilldown';
import { DisabledBreakdownCard } from '@components/LeftExpandCollapsePanel/Drilldown/DisabledBreakdownCard';
import { AddDrillDownCard } from '@components/LeftExpandCollapsePanel/Drilldown/AddDrillDownCard';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { useReportEvent } from '@services/analytics';
import useDimensionsState from '../InvestigatePanel/useDimensionsState';
import { FiltersAndBreakdownResponseType } from './types';
import { Title } from '../PanelTitle/Title';
import * as CONSTS from 'src/pages/MetricPage/components/FiltersAndBreakdown/consts';
import classes from '../InvestigatePanel/InvestigatePanel.module.scss';
import { useCallback, useState } from 'react';
import { useMetricDerivedState } from '@pages/MetricPage/hooks/useMetricDerivedState';
import FiltersAndBreakdownsModal from './FiltersAndBreakdownsModal';
import useFiltersAndBreakdown from './useFiltersAndBreakdown';
import { convertModalResultToValidCoreValues, useCoreNodeScheme } from './NodeScheme/useCoreNodeScheme';

export function BreakdownsPanel({
	isShowingFullSize,
	isDisabled,
}: {
	isShowingFullSize: boolean;
	isDisabled?: boolean;
}) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const { isLoading: isRulesEngineLoading, objectsTypes } = useMetricDerivedState();
	const [{ breakdowns }, { removeAllParamsByType, removeParamByKey, addDimensionsParamsByType, replaceParamByKey }] =
		useDimensionsState();
	const [activeBreakdownKey, setActiveBreakdownKey] = useState<string | undefined>();
	const [modalState, modalActions] = useFiltersAndBreakdown();
	const { wrapWithReport } = useReportEvent();
	const shouldAllowAnotherBreakdown = isSightfull2 || breakdowns.values.length < 1;
	const { isLoading } = useMetricDerivedState();

	const onAddItems = useCallback(
		(result: FiltersAndBreakdownResponseType) => {
			const paramValue = isSightfull2 ? convertModalResultToValidCoreValues(result, objectsTypes[0]) : result.items;
			if (activeBreakdownKey) {
				replaceParamByKey('groupBy', activeBreakdownKey, paramValue);
				return;
			}
			addDimensionsParamsByType('groupBy', paramValue);
		},
		[addDimensionsParamsByType, isSightfull2, objectsTypes, activeBreakdownKey, replaceParamByKey]
	);

	const onAddBreakdownModalOpen = useCallback(
		(breakdownKey?: string) => {
			setActiveBreakdownKey(breakdownKey);
			modalActions.onOpen(CONSTS.BREAKDOWN_MODEL_TYPE, modalActions.onClose);
		},
		[modalActions]
	);

	const coreNodeScheme = useCoreNodeScheme({
		objectsTypes,
		readyToFetch: !isRulesEngineLoading,
	});

	return (
		<>
			<Box>
				<Box
					whiteSpace="nowrap"
					maxHeight={isShowingFullSize ? 'min-content' : '0'}
					opacity={isShowingFullSize ? '1' : '0'}
				>
					<Title
						onAction={() => removeAllParamsByType('groupBy')}
						text={'Breakdown'}
						showAction={!!breakdowns?.values?.length}
					/>
					{!isDisabled &&
						breakdowns.values.map((breakdown) => (
							<BreakdownDrilldownCard
								onModalOpen={() => onAddBreakdownModalOpen(breakdown.key)}
								key={breakdown.key}
								breakdown={breakdown}
								onRemove={wrapWithReport(
									() => removeParamByKey('groupBy', breakdown.key),
									'metric-breakdowns-delete-clicked'
								)}
							/>
						))}
					{shouldAllowAnotherBreakdown &&
						(breakdowns.isDisabled ? (
							<DisabledBreakdownCard />
						) : (
							<AddDrillDownCard
								isDisabled={isDisabled || isLoading}
								type={CONSTS.BREAKDOWN_MODEL_TYPE}
								onClick={wrapWithReport(() => onAddBreakdownModalOpen(), 'metric-breakdowns-clicked')}
								dataIntercomTarget={'Add breakdown'}
							/>
						))}
				</Box>
			</Box>
			{!isShowingFullSize && (
				<Box display="flex" width="32px" height="32px" position="relative" justifyContent="center" alignItems="center">
					<BreakdownOutline16 />
					{!!breakdowns.values.length && (
						<Box className={classes.badgeNumber}>
							<Typography variant="Paragraph12M">{breakdowns.values.length}</Typography>
						</Box>
					)}
				</Box>
			)}
			<FiltersAndBreakdownsModal
				type={CONSTS.BREAKDOWN_MODEL_TYPE}
				isOpen={modalState.isOpen}
				onClose={modalActions.onClose}
				onAddItems={onAddItems}
				nodeScheme={coreNodeScheme}
				initialFilter={modalState.initialFilter}
			/>
		</>
	);
}
