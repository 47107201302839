import { isMetricType } from '@hooks/MetricHandling/types';
import { AGGREGATE_METRIC_EMPTY_YAML, FORMULA_METRIC_EMPTY_YAML } from '@hooks/MetricHandling/useMetricEdit';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { useMetricDerivedState } from '@pages/MetricPage/hooks/useMetricDerivedState';
import { useMetricEditorState } from '@pages/MetricPage/hooks/useMetricEditorState';
import { useCallback, useEffect } from 'react';
import { MetricType } from 'src/common/types/common';
import { useGetSpecificMetricDefinitionLazyQuery } from 'src/generated/graphql';

export type InitialMetricEditorStateProps =
	| { isNewMetric: false; metricKind: null }
	| {
			isNewMetric: true;
			metricKind: MetricType;
	  };

export function useInitialMetricEditorState({ isNewMetric, metricKind }: InitialMetricEditorStateProps) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const [getSpecificMetricDefinition] = useGetSpecificMetricDefinitionLazyQuery();
	const { metricNameWithFlavor } = useMetricDerivedState();
	const { setMetricEditorState } = useMetricEditorState();

	const loadExistingMetricDefinition = useCallback(async () => {
		const res = await getSpecificMetricDefinition({ variables: { metricName: metricNameWithFlavor } });
		const editorValue = res?.data?.getSpecificMetricDefinition?.metricDefinition;
		const metricType = res?.data?.getSpecificMetricDefinition?.metricType;
		if (!isMetricType(metricType)) return;
		const value = editorValue ? editorValue?.join('\n') : '';
		setMetricEditorState((s) => ({
			...s,
			isLoading: false,
			previewValue: '',
			savedValue: value,
			kind: metricType,
			userDefinedValue: value,
		}));
	}, [getSpecificMetricDefinition, metricNameWithFlavor, setMetricEditorState]);

	const loadEmptyMetricState = useCallback(
		(metricType: MetricType) => {
			const emptyMetric = metricType === 'formula' ? FORMULA_METRIC_EMPTY_YAML : AGGREGATE_METRIC_EMPTY_YAML;
			const savedValue = emptyMetric.join('\n');
			setMetricEditorState((s) => ({
				...s,
				isLoading: false,
				previewValue: '',
				savedValue,
				kind: metricType,
			}));
		},
		[setMetricEditorState]
	);

	const loadMetricDefinition = useCallback(async () => {
		if (isNewMetric) {
			loadEmptyMetricState(metricKind);
		} else {
			await loadExistingMetricDefinition();
		}
	}, [isNewMetric, loadEmptyMetricState, loadExistingMetricDefinition, metricKind]);

	useEffect(() => {
		if (!isSightfull2) return;
		void loadMetricDefinition();
	}, [isSightfull2, loadMetricDefinition]);
}
