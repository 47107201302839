import { StyleFunctionProps, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const commonTransition = {
	transitionProperty: 'box-shadow',
	transitionDuration: 'normal',
};

const commonBorder = {
	borderWidth: '1px',
	borderStyle: 'solid',
	borderRadius: '4px',
	borderColor: 'gray.400',
};

const commonShadow = {
	default: '0px 1px 0px 0px rgba(237, 242, 247, 1), 0px 0px 0px 3px rgba(237, 242, 247, 1)',
	blue: '0px 1px 0px 0px rgba(208, 229, 247, 1), 0px 0px 0px 3px rgba(208, 229, 247, 1)',
};

const getInvalidStyles = () => ({
	borderColor: 'red.700',
	'&&, &&:hover, &&:active': {
		borderColor: 'red.700',
		boxShadow: 'none',
	},
	_checked: {
		borderColor: 'red.700',
		'&&, &&:hover, &&:active': {
			borderColor: 'red.700',
			boxShadow: 'none',
		},
	},
	'&[data-disabled], &[data-disabled]:hover, &[data-disabled]:active': {
		borderColor: 'red.700',
		boxShadow: 'none',
	},
});

const getDisabledStyles = (colorScheme?: string) => ({
	bg: 'gray.100',
	borderColor: 'gray.300',
	...(colorScheme && { color: colorScheme }),
	'&&, &&:hover, &&:active': {
		bg: 'gray.100',
		borderColor: 'gray.300',
		boxShadow: 'none',
		...(colorScheme && { color: colorScheme }),
	},
});

const getIndeterminateStyles = (props: StyleFunctionProps) => {
	const { colorScheme } = props;
	const isDefault = colorScheme === 'blue.600';

	return {
		bg: 'transparent',
		fill: isDefault ? colorScheme : colorScheme,
		strokeWidth: '1px',
		stroke: 'transparent',
		color: colorScheme,

		_hover: {
			fill: colorScheme,
			opacity: isDefault ? 1 : 0.7,
			...(isDefault && { color: 'blue.700' }),
		},

		_active: {
			fill: colorScheme,
			filter: 'drop-shadow(0px 0px 0px #F4F5F6) drop-shadow(0px 0px 0px #F4F5F6)',
			opacity: 1,
		},

		_disabled: {
			fill: isDefault ? 'gray.100' : colorScheme,
			...(isDefault && { color: 'blue.300' }),
			opacity: isDefault ? 1 : 0.2,
			...(isDefault ? {} : { borderColor: 'gray.400' }),
			...(!isDefault && {
				'&&, &&:hover, &&:active': {
					borderColor: 'gray.400',
				},
			}),
		},
	};
};

const fluidStyles = (props: StyleFunctionProps) => {
	const { colorScheme } = props;
	const isDefault = colorScheme === 'blue.600';

	return {
		...commonTransition,
		...commonBorder,
		bg: isDefault ? 'whiteAlpha.100' : 'transparent',
		color: colorScheme,

		_hover: {
			borderColor: isDefault ? 'blue.400' : 'gray.600',
			bg: isDefault ? 'whiteAlpha.100' : 'transparent',
		},

		'&:active': {
			boxShadow: isDefault ? commonShadow.blue : commonShadow.default,
			borderColor: isDefault ? 'blue.400' : 'gray.400',
			bg: isDefault ? 'whiteAlpha.100' : 'transparent',
		},

		_invalid: getInvalidStyles(),
		_disabled: getDisabledStyles(),

		_checked: {
			position: 'relative',
			bg: isDefault ? 'blue.600' : colorScheme,
			borderColor: isDefault ? 'blue.600' : colorScheme,
			color: 'white',

			'&:hover': {
				bg: isDefault ? 'blue.700' : colorScheme,
				borderColor: isDefault ? 'blue.700' : colorScheme,
				color: 'white',
				opacity: isDefault ? 1 : 0.7,
			},

			'&:active': {
				bg: isDefault ? 'blue.600' : colorScheme,
				borderColor: isDefault ? 'blue.600' : colorScheme,
				boxShadow: isDefault ? commonShadow.blue : commonShadow.default,
				opacity: 1,
			},

			_disabled: {
				bg: isDefault ? 'blue.300' : colorScheme,
				borderColor: isDefault ? 'blue.300' : colorScheme,
				opacity: isDefault ? 1 : 0.2,
				color: 'white',
				'&[data-checked], &[data-checked]:hover, &[data-checked]:active': {
					bg: isDefault ? 'blue.300' : colorScheme,
					borderColor: isDefault ? 'blue.300' : colorScheme,
					opacity: isDefault ? 1 : 0.2,
				},
			},
		},

		_indeterminate: getIndeterminateStyles(props),
	};
};

const baseStyleControl: SystemStyleFunction = (props) => {
	const { colorScheme } = props;

	return {
		...commonTransition,
		...commonBorder,
		bg: 'whiteAlpha.100',
		color: colorScheme,

		_hover: {
			borderColor: 'gray.600',
			bg: 'whiteAlpha.100',
		},

		'&&': {
			_active: {
				boxShadow: commonShadow.default,
				borderColor: 'gray.400',
				bg: 'transparent',
			},
		},

		_invalid: getInvalidStyles(),
		_disabled: getDisabledStyles(colorScheme),
		_indeterminate: getIndeterminateStyles(props),

		_checked: {
			position: 'relative',
			...commonBorder,
			bg: 'whiteAlpha.100',
			color: colorScheme,

			'&&': {
				_hover: {
					borderColor: 'gray.400',
					opacity: 0.7,
				},
				_active: {
					borderColor: 'natural.400',
					boxShadow: 'none',
					opacity: 1,
				},
			},

			_disabled: {
				borderColor: colorScheme,
				opacity: 0.2,
				'&&, &&:hover, &&:active': {
					borderColor: colorScheme,
					boxShadow: 'none',
					opacity: 0.2,
				},
			},
		},

		'.chakra-checkbox.isFluid &': fluidStyles(props),
	};
};

const rounded = definePartsStyle({
	control: defineStyle({
		'&&': {
			borderRadius: '50%',
			'.chakra-checkbox.isFluid &': {
				borderRadius: '50%',
			},
		},
	}),
});

const sizes = {
	xs: {
		control: { h: '16px', w: '16px' },
		label: { fontSize: 'xs' },
		icon: { fontSize: '8px' },
	},
	sm: {
		control: { h: '16px', w: '16px' },
		label: { fontSize: 'sm' },
		icon: { fontSize: '8px' },
	},
	md: {
		control: { h: '20px', w: '20px' },
		label: { fontSize: 'md' },
		icon: { fontSize: '12px' },
	},
};

const baseStyleContainer = () => ({
	_disabled: { cursor: 'unset' },
});

export default defineMultiStyleConfig({
	variants: { rounded },
	baseStyle: (props: StyleFunctionProps) => ({
		control: baseStyleControl(props),
		container: baseStyleContainer(),
	}),
	sizes,
});
