import { Flex } from '@chakra-ui/react';
import { useMemo } from 'react';
import { ArrModelOutputState } from '../types';
import { PreviewFrameSkeleton } from './components/PreviewFrameSkeleton';
import { PreviewStepper } from './components/PreviewStepper';

export function MappingPreviewFrame({
	focusedInputBoxIndex,
	arrModelOutputState,
	isLoading,
}: {
	focusedInputBoxIndex: number;
	arrModelOutputState: ArrModelOutputState;
	isLoading: boolean;
}) {
	const completedIndexes = useMemo(() => {
		const indexes = [];
		if (
			arrModelOutputState.granularEntityArrAmountDefinitions.length > 0 &&
			arrModelOutputState.granularEntityArrAmountDefinitions[0] !== ''
		) {
			indexes.push(0);
		}
		if (
			arrModelOutputState.granularEntityRecognitionStartDefinitions.length > 0 &&
			arrModelOutputState.granularEntityRecognitionStartDefinitions[0] !== ''
		) {
			indexes.push(1);
		}
		if (
			arrModelOutputState.granularEntityRecognitionEndDefinitions.length > 0 &&
			arrModelOutputState.granularEntityRecognitionEndDefinitions[0] !== ''
		) {
			indexes.push(2);
		}
		return indexes;
	}, [arrModelOutputState]);

	if (isLoading) {
		return (
			<Flex width={'100%'} alignItems={'center'} justifyContent={'center'}>
				<PreviewFrameSkeleton />
			</Flex>
		);
	}

	return (
		<Flex width={'100%'} alignItems={'center'} justifyContent={'center'} padding={'24px'}>
			<Flex
				width={'100%'}
				height={'100%'}
				backgroundColor={'gray.200'}
				alignItems={'center'}
				justifyContent={'center'}
				borderRadius={'12px'}
			>
				<PreviewStepper focusedIndex={focusedInputBoxIndex} completedIndexes={completedIndexes} />
			</Flex>
		</Flex>
	);
}
