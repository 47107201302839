import { ReactNode } from 'react';
import Tooltip from '../../Tooltip';
import classes from '../styles/ToolbarButton.module.scss';
import { ChevronDownSmall, ChevronUpSmall } from '@icons/index';
import classnames from 'classnames';
import colors from 'src/style/colors';

type ToolbarButtonVariant = 'button' | 'select';

type Props = {
	children: ReactNode;
	onClick: () => void;
	isSelected?: boolean;
	isActive?: boolean;
	variant?: ToolbarButtonVariant;
	tooltipLabel?: ReactNode;
	isFloatingToolbar?: boolean;
	isDisabled?: boolean;
};

const ToolbarButton = ({
	children,
	onClick,
	variant = 'button',
	isActive,
	isSelected,
	tooltipLabel,
	isFloatingToolbar,
	isDisabled,
}: Props) => {
	const variantClass = getVariantClass(variant);
	const isSelect = variant === 'select';

	return (
		<button
			onClick={onClick}
			className={classnames(classes.toolbarButton, variantClass, {
				[classes.isActive]: isActive,
				[classes.isButtonSelected]: isSelected,
				[classes.isFloatingToolbar]: isFloatingToolbar,
				[classes.isDisabled]: isDisabled,
			})}
			disabled={isDisabled}
		>
			<Tooltip
				label={tooltipLabel}
				size="md"
				placement={'top'}
				bottom={'8px'}
				backgroundColor={colors.black}
				padding={'10px 12px'}
				borderRadius={'8px'}
			>
				{children}
			</Tooltip>
			{!isSelect ? null : isSelected ? <ChevronUpSmall /> : <ChevronDownSmall />}
		</button>
	);
};

const getVariantClass = (variant: ToolbarButtonVariant) => {
	switch (variant) {
		case 'select':
			return classes.variantSelect;
	}

	return '';
};

export default ToolbarButton;
