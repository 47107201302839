import Flex from '@components/Flex';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import Link from '../Link';
import Typography from '../Typography';
import classes from './NavigationTab.module.scss';
import { buildIntercomAttributes } from 'src/common/utils/domElements';
import colors from 'src/style/colors';

export const NavigationTab = ({
	label,
	isSelected,
	path,
	onClick,
	rightIcon,
	aiLabeled = false,
}: {
	label: string;
	isSelected: boolean;
	dataIntercomSequence?: number;
	path: string;
	onClick?: VoidFunction;
	rightIcon?: ReactNode;
	aiLabeled?: boolean;
}) => {
	const { wrapWithReport } = useReportEvent();
	const { navigate, getHref } = useNavigation();

	const handleOnClick = (e: React.MouseEvent) => {
		if (onClick) {
			return onClick();
		}

		e.preventDefault();
		navigate({ path });
	};

	const Tab = () => {
		return (
			<Flex
				className={classNames(classes.navigationTab, { [classes.selected]: isSelected })}
				onClick={wrapWithReport(handleOnClick, 'top-navigation-main-item-clicked', {
					item: label,
					feature: 'Main Navigation Bar',
				})}
				{...buildIntercomAttributes({
					area: 'topbar',
					type: 'button',
					target: label,
				})}
				userSelect={'none'}
				alignItems={'center'}
				gap={'4px'}
				cursor={'pointer'}
			>
				<Flex>
					<Typography variant="DesktopH8Medium" color={'inherit'}>
						{label}
					</Typography>
					{aiLabeled && (
						<Typography variant={'DesktopH8Medium'} color={colors.violet[400]}>
							AI
						</Typography>
					)}
				</Flex>
				{rightIcon}
			</Flex>
		);
	};

	return (
		<>
			{!onClick ? (
				<Link href={getHref({ path })} _hover={{ textDecoration: 'none' }}>
					<Tab />
				</Link>
			) : (
				<Tab />
			)}
		</>
	);
};
