import Image from '@components/Image';
import ResizableIcon from '@components/ResizableIcon';
import { Database16 } from '@components/Icons';
import { NodeProps } from '@xyflow/react';
import colors from '../../../../style/colors';
import { useSemanticDefinitions } from '../../../hooks/stores/useSemanticDefinitions';
import { getSourcesInfo } from '../../../hooks/useSourceInfo';
import { SemanticHeader } from '../components/SemanticHeader';
import { EntityNodeType } from '../types';
import { SemanticNode } from '../components/SemanticNode';
import { useMemo, useState } from 'react';
import { SemanticNodeContent, SemanticPopupContent } from '../components/SemanticContent';
import { SemanticDimensions } from '../components/SemanticDimensions';
import { SemanticParagraph } from '../components/SemanticParagraph';
import { SemanticBody } from '../components/SemanticBody';
import { SemanticFooter } from '../components/SemanticFooter';
import { OntologyPagePath } from '../../../../pages/OntologyPage/OntologyBuilderPage';
import { ExploreSemanticObjectButton } from '../components/ExploreSemanticObjectButton';
import { Label } from '../../Labels';
import { SemanticTooltip } from '../components/SemanticTooltip';

export function EntityNode(props: NodeProps<EntityNodeType>) {
	const { semanticDefinitions } = useSemanticDefinitions();
	const { data } = props;
	const { entityName, displayName, dimensions = new Set<string>(), description } = data;
	const [hoveredDimension, setHoveredDimension] = useState<string | null>(null);

	const dimensionsArray = useMemo(() => Array.from(dimensions), [dimensions]);

	const entity = useMemo(
		() => semanticDefinitions?.entities?.find((entity) => entity.name === entityName),
		[entityName, semanticDefinitions?.entities]
	);

	const header = useMemo(() => {
		const dataSource = entity?.data_source;
		let icon = <ResizableIcon icon={Database16} size={'24px'} />;
		if (dataSource && 'schema' in dataSource && dataSource.schema !== null) {
			const sourcesInfo = getSourcesInfo({ source: dataSource.schema?.meta?.source });
			if (sourcesInfo?.connectorInfo?.icon) {
				icon = <Image src={sourcesInfo?.connectorInfo?.icon} height={'24px'} width={'24px'} />;
			}
		}

		return (
			<SemanticHeader
				title={displayName ?? entityName}
				subtitle={'Entity'}
				icon={icon}
				iconColor={colors.gray[500]}
				iconBgColor={colors.gray[100]}
			/>
		);
	}, [entityName, displayName, entity?.data_source]);

	const onLabelHover = (dimension: string) => setHoveredDimension(dimension);
	const onLabelLeave = () => setHoveredDimension(null);

	return (
		<SemanticNode
			popupContent={
				<SemanticPopupContent>
					<SemanticBody>
						<SemanticParagraph fallbackContent={'No description'}>{description}</SemanticParagraph>
						<SemanticDimensions
							dimensions={dimensionsArray}
							withHeader={true}
							onDimensionHover={onLabelHover}
							onDimensionLeave={onLabelLeave}
						/>
						{hoveredDimension && <SemanticTooltip dimensionName={hoveredDimension} entityName={entityName} />}
					</SemanticBody>
					<SemanticFooter>
						<ExploreSemanticObjectButton
							path={`/${OntologyPagePath}/entity/${entityName}`}
							text={'Explore entity'}
							metadata={{ objectType: 'entity', objectName: entityName }}
						/>
					</SemanticFooter>
				</SemanticPopupContent>
			}
			{...props}
		>
			<SemanticNodeContent>
				{header}
				{dimensionsArray.length > 0 && (
					<SemanticBody>
						<Label variant={'filled'} colorScheme={'dark'} size={'small'}>
							Includes {dimensionsArray.length} {dimensionsArray.length > 1 ? 'dimensions' : 'dimension'}
						</Label>
					</SemanticBody>
				)}
			</SemanticNodeContent>
		</SemanticNode>
	);
}
