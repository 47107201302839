import { useState } from 'react';
import { Box, Flex, ModalHeader } from '@chakra-ui/react';
import { ModalForm } from './components/ModalForm';
import Modal from 'src/common/components/Modal';
import Spinner from 'src/common/components/Spinner';
import Button from 'src/common/components/Button';
import { CloseLarge16 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import { TestIDs } from 'src/common/types/test-ids';
import shadows from 'src/style/shadows';
import { useCreateMultipleEntities } from './hooks/useCreateMultipleEntities';

interface ModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
}

export type TTableColumn = {
	name: string;
	description?: string | null;
	dataType: string;
	isPrimaryKey: boolean;
};

export type TSchemaTable = {
	schema: string;
	table: {
		name: string;
		description?: string | null;
		columns: TTableColumn[];
	};
};

export function AddMultipleEntitiesModal({ isOpen, onClose }: ModalProps) {
	const modalTitle = 'Importing entities';
	const [selectedSchemaTables, setSelectedSchemaTables] = useState<TSchemaTable[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { createEntityYAMLs } = useCreateMultipleEntities({ onClose });

	const onSelectTables = (data: TSchemaTable[]) => {
		setSelectedSchemaTables(data);
	};

	const onClickCreate = async () => {
		setIsLoading(true);
		await createEntityYAMLs(selectedSchemaTables);
		setIsLoading(false);
	};

	const modalHeader = (
		<ModalHeader boxShadow={shadows.borderBottom} color="gray.1000" padding={` ${isLoading ? '22px' : '14px'} 16px`}>
			<Flex justifyContent="space-between" alignItems="center">
				<Button variant="outline" isIconOnly onClick={onClose} size="small" colorScheme="black" disabled={isLoading}>
					<CloseLarge16 />
				</Button>
				<Typography width={'100%'} variant={'DesktopH7Medium'} textAlign={'center'}>
					{modalTitle}
				</Typography>
			</Flex>
		</ModalHeader>
	);

	const modalFooter = (
		<Box shadow={shadows.borderTop} padding={'16px 24px'}>
			<Flex flexDirection="row" justifyContent={'space-between'} alignItems="center">
				<Button variant="ghost" borderColor={'gray.500'} colorScheme="black" onClick={onClose} disabled={isLoading}>
					Cancel
				</Button>
				<Button variant="solid" colorScheme="blue" onClick={onClickCreate} disabled={isLoading}>
					Create
				</Button>
			</Flex>
		</Box>
	);

	const spinner = (
		<Flex
			width={'100%'}
			height={'656px'}
			justifyContent={'center'}
			alignItems={'center'}
			flexDirection={'column'}
			data-testid={TestIDs.ENTITY_MODAL_LOADER}
		>
			<Spinner thickness="3px" color="blue.600" size={'60px'} />
		</Flex>
	);

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} maxWidth={'460px'} isCentered={true}>
				{modalHeader}
				{isLoading ? spinner : <ModalForm onSelectTables={onSelectTables} />}
				{modalFooter}
			</Modal>
		</>
	);
}
