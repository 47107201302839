import debounce from 'lodash/debounce';
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

export const useKeyPress = (
	keys: string[],
	callback: () => any,
	{
		isWithMetaKey,
		isWithCtrlKey,
		isWithoutShiftKey,
	}: { isWithMetaKey?: boolean; isWithCtrlKey?: boolean; isWithoutShiftKey?: boolean } = {}
) => {
	const debouncedCallback = debounce(callback, 20);

	const callbackRef = useRef(debouncedCallback);
	useLayoutEffect(() => {
		callbackRef.current = debouncedCallback;
	});

	const handleKeyPress = useCallback(
		(event: KeyboardEvent) => {
			const isCtrlKeySatisfied = isWithCtrlKey ? event.ctrlKey : true;
			const isMetaKeySatisfied = isWithMetaKey ? event.metaKey : true;
			const isWithoutShiftKeySatisfied = isWithoutShiftKey ? !event.shiftKey : true;

			if (
				keys.some((key) => event.key === key && isMetaKeySatisfied && isCtrlKeySatisfied && isWithoutShiftKeySatisfied)
			) {
				callbackRef.current();
				event.preventDefault();
			} else if (keys.includes(event.code)) callbackRef.current();
		},
		[isWithCtrlKey, isWithMetaKey, isWithoutShiftKey, keys]
	);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyPress);
		return () => document.removeEventListener('keydown', handleKeyPress);
	}, [handleKeyPress]);
};
