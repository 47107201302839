import Flex from '@components/Flex';
import Typography from '@components/Typography';
import colors from 'src/style/colors';
import Progress from '@components/Progress';
import shadows from 'src/style/shadows';
import { TestIDs } from 'src/common/types/test-ids';
const RUNNING_VALIDATIONS_TEXT = 'Running validations, almost there...';
const RUNNING_NORMALIZATION_TEXT = 'Applying your changes, please wait...';

export function SaveLoadingBar({
	progress,
	updateInProgress,
	normalizationInProgress,
	isValidationInProgress,
}: {
	progress: number;
	updateInProgress: boolean;
	normalizationInProgress: boolean;
	isValidationInProgress: boolean;
}) {
	const isShowing = normalizationInProgress || isValidationInProgress || updateInProgress;
	return (
		<Flex
			data-testid={TestIDs.ONTOLOGY_SAVE_LOADING_BAR}
			paddingX={'32px'}
			gap={'16px'}
			flexDirection={'column'}
			justifyContent={'center'}
			boxShadow={shadows['a-22']}
			clipPath={'polygon(0 -10%, 100% -10%, 100% 100%, 0 100%);'}
			opacity={isShowing ? '1' : '0'}
			visibility={isShowing ? 'visible' : 'hidden'}
			minHeight={isShowing ? '88px' : '0px'}
			maxHeight={isShowing ? '88px' : '0px'}
			transition={'all 0.250s ease'}
			zIndex={2}
		>
			<Typography variant={'DesktopH7Medium'} color={colors.gray['1000']} paddingTop={'20px'}>
				{normalizationInProgress || updateInProgress ? RUNNING_NORMALIZATION_TEXT : RUNNING_VALIDATIONS_TEXT}
			</Typography>
			<Progress
				colorScheme="blue.600"
				bgColor="gray.200"
				borderRadius="40px"
				h="8px"
				value={progress}
				marginBottom={'24px'}
			/>
		</Flex>
	);
}
