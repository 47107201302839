import Flex from '@components/Flex';
import colors from 'src/style/colors';
import { SelectLabel } from '../CalculatePanel/Builder/components';
import { AdvancedSelect, DropdownButton } from 'src/common/components/AdvancedSelect';
import { useReportEvent } from 'src/services/analytics';
import { ModalDetails } from './FillDetailsModal';
import { collectionTypes, collectionView } from 'src/types/signal';
import { useMemo } from 'react';
import { capitalize, sortBy } from 'lodash';
import { NameField } from 'src/common/components/NameField';
import { GetCollectionsSubscription } from 'src/generated/graphql';
import { useHasuraSubscriptionWithCache } from 'src/common/hooks/fetching/useSubscription';
import { ListAllFolders } from 'src/queries/folders';
import useUser from 'src/common/hooks/stores/useUser';
import FeatureSwitcher from 'src/common/components/FeatureSwitcher';
import Typography from 'src/common/components/Typography';
import { ChartColumns16, GoogleSheetsTransparent16 } from 'src/common/components/Icons';
import DescriptionField from 'src/pages/OntologyPage/components/DescriptionField';
import { DashboardData } from 'src/common/components/LeftSidePanelV2/DashboardFolder/types';

export function FillDetailsForm({
	isShowingPublishAs,
	modalData,
	setModalData,
	metricTitle,
	shouldShowSelectCollection = false,
	updatedRawCollections,
	onOpenUpsertCollection,
}: {
	isShowingPublishAs?: boolean;
	modalData: ModalDetails;
	setModalData: (modalData: ModalDetails) => void;
	metricTitle: string;
	shouldShowSelectCollection?: boolean;
	updatedRawCollections?: GetCollectionsSubscription;
	onOpenUpsertCollection: VoidFunction;
}) {
	const { collectionType } = modalData;

	const isWorkspace = collectionType === 'workspace';

	const { reportEvent } = useReportEvent({ destination: collectionType, feature: 'Publish flow' });

	const [{ id: my_id }] = useUser();
	const { data: foldersList } = useHasuraSubscriptionWithCache(ListAllFolders, {
		variables: { my_id: my_id },
	});

	const isCollectionType = (val: string): val is 'dashboard' | 'workspace' => {
		return val === 'dashboard' || val === 'workspace';
	};

	const typeOptions = useMemo(
		() =>
			collectionTypes.map((el) => ({
				value: el,
				label: capitalize(el),
			})),

		[]
	);

	const collectionTypePicker = (
		<Flex direction={'column'}>
			<SelectLabel marginBottom={'12px'} color={colors.gray[1100]} text={`Publish to`} />
			<AdvancedSelect
				onReportEvent={(value) => {
					reportEvent({
						event: 'metric-publish-destination-selected',
						metaData: { destination: value },
					});
				}}
				entityName={metricTitle}
				onChange={(value) => {
					if (isCollectionType(value.value))
						setModalData({ ...modalData, collectionType: value.value, collectionName: '' });
				}}
				placeholder="Select"
				options={typeOptions}
				controlledValue={collectionType}
			/>
		</Flex>
	);

	const workspaceCollectionOptions = useMemo(
		() =>
			updatedRawCollections?.workspaces?.map((collection) => ({
				value: collection.name,
				label: collection.name,
			})) ?? [],
		[updatedRawCollections?.workspaces]
	);

	const dashboardCollectionOptions = useMemo(
		() =>
			sortBy(foldersList?.folders, 'order').map((folder) => ({
				value: sortBy(folder?.dashboards, 'order')?.map((dashboard: DashboardData[number]) => ({
					value: dashboard.name,
					label: dashboard.name,
				})),
				label: folder.name,
				icon: folder.emoji,
			})) ?? [],
		[foldersList]
	);

	const workspaceOptionsProps = {
		options: workspaceCollectionOptions,
	};

	const dashboardOptionsProps = {
		nestedOptions: dashboardCollectionOptions,
	};

	const onSearchReportEvent = (searchTerm?: string) =>
		reportEvent({
			event: `metric-publish-search-${collectionType}`,
			metaData: { searchTerm },
		});

	const collectionPicker = (
		<Flex direction={'column'}>
			<SelectLabel marginBottom={'12px'} color={colors.gray[1100]} text={`Pick a ${collectionType}`} />
			<AdvancedSelect
				onReportEvent={(input) => {
					reportEvent({
						event: 'metric-publish-input-provided',
						metaData: { field: `pick a ${collectionType}`, input },
					});
				}}
				onSearchReportEvent={onSearchReportEvent}
				entityName={metricTitle}
				onChange={(option) => setModalData({ ...modalData, collectionName: option.value })}
				controlledValue={modalData.collectionName}
				placeholder="Select"
				bottomDropdownContent={
					<DropdownButton
						onClick={() => {
							reportEvent({ event: `metric-publish-create-new-${collectionType}` });
							onOpenUpsertCollection();
						}}
						label={`Create new ${collectionType}`}
					/>
				}
				{...(isWorkspace ? workspaceOptionsProps : dashboardOptionsProps)}
			/>
		</Flex>
	);

	const switcherOptions = [
		<Typography cursor={'inherit'} key={'yaml'} variant="DesktopH8Medium">
			<Flex gap={'8px'} alignItems={'center'}>
				<ChartColumns16 />
				Chart
			</Flex>
		</Typography>,
		<Typography cursor={'inherit'} key={'builder'} variant="DesktopH8Medium">
			<Flex gap={'8px'} alignItems={'center'}>
				<GoogleSheetsTransparent16 />
				Table
			</Flex>
		</Typography>,
	];

	const publishAsSwitcher = (
		<Flex direction={'column'}>
			<SelectLabel marginBottom={'12px'} color={colors.gray[1100]} text={'Publish as'} />
			<FeatureSwitcher
				isToggle
				selectedIndex={collectionView.findIndex((item) => item === modalData.publishAs)}
				onChildClicked={(index) => {
					reportEvent({
						event: 'metric-publish-input-provided',
						metaData: { field: 'publish as', input: collectionView[index] },
					});
					setModalData({ ...modalData, publishAs: collectionView[index] });
				}}
				options={switcherOptions}
			/>
		</Flex>
	);

	return (
		<Flex direction={'column'} padding={'20px 24px 24px 24px'} gap={'24px'}>
			{isShowingPublishAs && collectionTypePicker}
			<NameField
				isWithDefaultText={false}
				isAutoFocused
				isEditable
				label="Name"
				onNameUpdate={(title: string) => setModalData({ ...modalData, title })}
				propertyDefinitionValueName={metricTitle}
				value={modalData.title}
				isWithNameReport={false}
				onBlur={() =>
					reportEvent({
						event: 'metric-publish-input-provided',
						metaData: { field: 'name', input: modalData.title },
					})
				}
				propertyType={'metric'}
			/>
			{shouldShowSelectCollection && collectionPicker}
			{isShowingPublishAs && publishAsSwitcher}
			<DescriptionField
				onBlur={() =>
					reportEvent({
						event: 'metric-publish-input-provided',
						metaData: { field: 'description', input: modalData.description },
					})
				}
				minHeigh="90px"
				placeholder={'Describe this signal'}
				value={modalData.description}
				onChange={(d) => setModalData({ ...modalData, description: d })}
			/>
		</Flex>
	);
}
