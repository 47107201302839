import Box from '@components/Box';
import Modal from '@components/Modal';
import { useCollectionsSubscriptions } from '@hooks/fetching/useCollectionsSubscriptions';
import { ReactNode, useEffect, useState } from 'react';
import { useModal } from 'src/common/hooks/ui/useModal';
import useToast from 'src/common/hooks/ui/useToast';
import { useShareSignalApi } from 'src/common/hooks/useShareSignalApi';
import { GetCollectionsSubscription } from 'src/generated/graphql';
import { ScrollToBottomSearchParam } from 'src/pages/DashboardPage/hooks/useDashboardPageSearchParams';
import { FillDetailsModal, ModalDetails } from 'src/pages/MetricPage/components/ShareSignalModal/FillDetailsModal';
import { useTableColumnStateAsSearchParam } from 'src/pages/MetricPage/components/ShareSignalModal/useTableColumnStateAsSearchParam';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import { CollectionType, HeaderName } from 'src/types/signal';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { cleanSearchParams, useMetricPageSearchParams } from '../../hooks/useMetricPageSearchParams';

export function ShareSignalModal({
	metricTitle,
	description = '',
	isModalOpen,
	onModalClose = () => void 0,
	children,
	headerName,
}: {
	metricTitle: string;
	description?: string;
	isModalOpen: boolean;
	onModalClose: () => void;
	children?: ReactNode;
	headerName: HeaderName;
}) {
	const { reportEvent } = useReportEvent();

	const {
		isOpen: isOpenUpsertCollection,
		onOpen: onOpenUpsertCollection,
		onClose: onCloseUpsertCollection,
	} = useModal();
	const { rawDashboardsList, rawWorkspacesList } = useCollectionsSubscriptions();
	const [collectionType, setCollectionType] = useState<CollectionType>('dashboard');
	const { navigate } = useNavigation();
	const { getColumnStateSearchParam } = useTableColumnStateAsSearchParam();

	const rawCollections: GetCollectionsSubscription | undefined =
		collectionType &&
		{
			workspace: rawWorkspacesList,
			dashboard: rawDashboardsList,
		}[collectionType];
	const [shareSignal, { mutationError, isLoading }] = useShareSignalApi();
	const showToast = useToast();
	const { metricNameWithFlavor, dangerousRawSearchParams } = useMetricPageSearchParams();
	const { periodRange, chartType } = useMetricDerivedState();

	useEffect(() => {
		if (mutationError) showToast({ variant: 'error', message: `Error sharing signal: ${mutationError.message}` });
	}, [mutationError, showToast]);

	const onClose = () => {
		onModalClose?.apply(null);
		setCollectionType('dashboard');
		reportEvent({ event: 'signal-draft-discarded', metaData: { feature: `${headerName} header` } });
	};

	function onSubmit(
		{ collectionName, publishAs, description, title }: ModalDetails,
		rawCollectionsParam?: GetCollectionsSubscription
	) {
		const collections = rawCollections ?? rawCollectionsParam;
		const selectedCollectionIds = collections?.workspaces.filter((e) => e.name == collectionName)?.map((e) => e.id);
		if (!selectedCollectionIds?.length) return;

		const metricName = metricNameWithFlavor;
		const searchParams = new URLSearchParams(dangerousRawSearchParams);
		cleanSearchParams(searchParams);

		if (collectionType == 'dashboard' && periodRange.isRelativeSupported) {
			const relativeRange = periodRange.asRelativeRange;
			searchParams.set('periodRange', JSON.stringify(relativeRange.toUrlParams().periodRange));
		} else {
			const absoluteRange = periodRange.asAbsoluteRange;
			searchParams.set('from', absoluteRange.startPeriod.id);
			searchParams.set('to', absoluteRange.endPeriod.id);
			searchParams.set('periodRange', JSON.stringify(absoluteRange.toUrlParams().periodRange));
		}

		if (publishAs == 'table') {
			searchParams.set('chartType', 'table');
			searchParams.set('tableColumnState', getColumnStateSearchParam());
		} else {
			searchParams.set('chartType', chartType.selectedValue);
			searchParams.delete('selectedXAxisElements');
		}

		const encodedMetricName = encodeURIComponent(metricName);
		const currentMetricURL = encodeURI(`metric/${encodedMetricName}?${decodeURI(searchParams.toString())}`);

		void shareSignal({
			attachmentContentURL: currentMetricURL,
			message: description,
			collectionToPin: selectedCollectionIds ?? [],
			collectionToUnPin: [],
			title: title,
		}).then(() => {
			reportEvent({
				event: 'submit-signal-draft',
				metaData: { message: description, userAttachment: currentMetricURL },
			});
			showToast({ variant: 'ok', message: 'Signal Saved' });
			navigate({
				path: `${collectionType}/${selectedCollectionIds}`,
				willIncludeAllSearchParams: false,
				additionalSearchParams: new URLSearchParams({ [ScrollToBottomSearchParam]: 'true' }),
			});
		});
	}

	const modalBody = (
		<FillDetailsModal
			upsertCollectionModalState={{
				isOpenUpsertCollection,
				onOpenUpsertCollection,
				onCloseUpsertCollection,
			}}
			metricTitle={metricTitle}
			description={description}
			onSubmit={onSubmit}
			collectionType={collectionType}
			shouldShowSelectCollection
			rawCollections={rawCollections}
			isLoading={isLoading}
			onClose={onClose}
			isShowingPublishAs
		/>
	);

	return (
		<>
			<Box>{children}</Box>
			<Modal
				maxHeight={isOpenUpsertCollection ? '0' : 'auto'}
				isOpen={isModalOpen}
				onClose={onClose}
				closeOnOverlayClick={false}
				maxWidth="460px"
				isCentered
			>
				{modalBody}
			</Modal>
		</>
	);
}
