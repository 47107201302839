import { Flex } from '@chakra-ui/react';
import Box from '@components/Box';
import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import Typography from 'src/common/components/Typography';
import { useMetricCategoriesV2 } from 'src/pages/MetricCatalog/hooks/useMetricCategoriesV2';
import { useReportEvent } from 'src/services/analytics';
import { getIsNavDrawerViewCollection } from 'src/stores/utils';
import NavigationDrawerFooter from './NavDrawerFooter';
import classes from './NavigationDrawerV2.module.scss';
import { AddSmall16 } from '@icons/index';
import Center from '@components/Center';
import { CreateEditCategoryModal } from '@pages/MetricCatalog/CreateEditCategoryModal/CreateEditCategoryModal';
import { useModal } from '@hooks/ui/useModal';
import { CategoryMoreButton } from '@layout/Menu/NavigationDrawer/CategoryMoreButton';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import { useCategoriesWithBrokenMetrics } from 'src/pages/MetricCatalog/hooks/useCategoriesWithBrokenMetric';
import { CategoryV2 } from 'src/types/metric';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import Tooltip from 'src/common/components/Tooltip';
import { getCategoryKey } from '@pages/MetricCatalog/utils';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

type NavigationDrawerProps = {
	isShowingFullSize: boolean;
	scrollToSelectedCategory: (categoryName: string) => void;
	categoriesWithFilteredMetrics: CategoryV2[];
};

function NavigationDrawerV2({
	isShowingFullSize,
	scrollToSelectedCategory,
	categoriesWithFilteredMetrics,
}: NavigationDrawerProps) {
	const { reportEvent } = useReportEvent();
	const [metricCategories] = useMetricCategoriesV2();
	const [selectedCategory, setSelectedCategory] = useState('');
	const { categoriesWithBrokenMetrics } = useCategoriesWithBrokenMetrics();
	const {
		isOpen: isCreateCategoryModalOpen,
		onOpen: onCreateCategoryModalOpen,
		onClose: onCreateCategoryModalClose,
	} = useModal({ defaultIsOpen: false });
	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);
	const hasDeletePermission = usePermissionCheck().isHavingPermission(Permissions.deleteMetric);

	const onAddCategoryClick = () => {
		reportEvent({ event: 'drawer-metric-catalog-category-add-new-clicked' });
		onCreateCategoryModalOpen();
	};

	const CategoryName = ({ categoryName, isEmptyCategory }: { categoryName: string; isEmptyCategory: boolean }) => {
		const nameRef = useRef(null);
		const isOverflowingCategoryName = useOnOverflow(nameRef, [categoryName], undefined, false);
		return (
			<Tooltip size="md" variant="fluid" label={isOverflowingCategoryName && categoryName}>
				<Typography noOfLines={1} cursor={isEmptyCategory ? 'not-allowed' : 'pointer'} variant="DesktopH8Regular">
					<Box ref={nameRef}>{categoryName}</Box>
				</Typography>
			</Tooltip>
		);
	};

	const collectionItems = useMemo(
		() =>
			metricCategories.map((category, index) => {
				const { name: categoryName } = category;
				const isNotFullyDefined =
					!category?.isFullyDefined && category?.metrics?.some((metric) => !metric.isFullyDefined && !metric.hide);
				const isThreeDotMenuVisible = hasEditPermission || (hasDeletePermission && category?.metrics?.length === 0);
				const categoryKey = getCategoryKey(category);
				const isEmptyCategory = !categoriesWithFilteredMetrics.find((el) => el.name === categoryName);
				const hasNewMetrics = category.metrics.some((metric) => metric.isNew);

				return (
					<Flex
						id={`${categoryName}-drawer`}
						key={categoryKey}
						className={classNames(classes.menuIndicator, {
							[classes.selected]: categoryKey == selectedCategory,
							[classes.badgeCategoryWithNewMetrics]: hasNewMetrics,
							[classes.badgeError]: categoriesWithBrokenMetrics.some((el) => el.categoryName === categoryName),
							[classes.badgePendingSetup]: isNotFullyDefined,
							[classes.emptyCategory]: isEmptyCategory,
						})}
						onClick={() => {
							if (isEmptyCategory) return;
							setSelectedCategory(categoryKey);
							reportEvent({ event: 'drawer-metric-catalog-category-clicked', metaData: { categoryName } });
							scrollToSelectedCategory(categoryKey);
						}}
						{...buildIntercomAttributes({
							area: 'metrics-sidebar',
							type: 'button',
							target: categoryName,
							sequence: index + 1,
						})}
						justifyContent={'space-between'}
					>
						<Box className={classes.text}>
							<CategoryName isEmptyCategory={isEmptyCategory} categoryName={categoryName} />
						</Box>
						{isThreeDotMenuVisible && (
							<CategoryMoreButton
								className={classes.moreButton}
								category={category}
								isDeleteOptionVisible={hasDeletePermission && category?.metrics?.length === 0}
							/>
						)}
					</Flex>
				);
			}),
		[
			metricCategories,
			hasEditPermission,
			hasDeletePermission,
			selectedCategory,
			categoriesWithBrokenMetrics,
			categoriesWithFilteredMetrics,
			reportEvent,
			scrollToSelectedCategory,
		]
	);

	return (
		<Box height="100%" left="0" bottom="0" position="relative">
			<Flex
				h="100%"
				justifyContent="flex-start"
				className={classNames(classes.navigationDrawer, { [classes.isVisible]: isShowingFullSize })}
				flexDir="column"
				minHeight="100%"
				overflow="hidden"
				zIndex="1"
				px="8px"
				paddingTop={'12px'}
			>
				<Flex padding={'4px 0 4px 16px'} justifyContent="space-between" alignItems="center">
					<Typography variant="DesktopH10Regular" color="gray.800">
						Categories
					</Typography>
					{hasEditPermission && (
						<Center
							p={'4px'}
							cursor={'pointer'}
							onClick={onAddCategoryClick}
							borderRadius={'4px'}
							mixBlendMode={'multiply'}
							_hover={{ backgroundColor: 'gray.200' }}
							{...buildIntercomAttributes({
								area: 'metrics-sidebar',
								type: 'button',
								target: 'add-category',
							})}
						>
							<AddSmall16 color={'gray.800'} />
						</Center>
					)}
				</Flex>
				<Box overflowX="hidden" overflowY="auto" pt="4px" pb="55px">
					{collectionItems}
				</Box>
				{getIsNavDrawerViewCollection('metrics') ? <NavigationDrawerFooter collectionType={'metrics'} /> : null}
			</Flex>

			{isCreateCategoryModalOpen && (
				<CreateEditCategoryModal isOpen={isCreateCategoryModalOpen} onClose={onCreateCategoryModalClose} />
			)}
		</Box>
	);
}

export default NavigationDrawerV2;
