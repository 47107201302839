import { useMemo } from 'react';
import Button from 'src/common/components/Button';
import { AddSmall16 } from 'src/common/components/Icons';
import useUser from 'src/common/hooks/stores/useUser';
import { useModal } from 'src/common/hooks/ui/useModal';
import { EditWorkspaceSignalModal } from 'src/pages/WorkspacePage/components/EditWorkspaceSignalModal/EditWorkspaceSignalModal';
import { useReportEvent } from 'src/services/analytics';
import { Signal } from 'src/types/signal';
import Box from '@components/Box';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

export function AddWidgetButton({ workspaceId, isTiny = false }: { workspaceId: string; isTiny?: boolean }) {
	const { reportEvent } = useReportEvent();
	const { isOpen, onOpen, onClose } = useModal();
	const emptySignal = useEmptySignal();

	const onClick = () => {
		reportEvent({
			event: 'workspace-add-text-widget',
			metaData: {
				workspaceId: workspaceId,
			},
		});
		onOpen();
	};

	return (
		<Box
			{...buildIntercomAttributes({
				area: 'workspace',
				type: 'button',
				target: 'Add text box',
			})}
		>
			<EditWorkspaceSignalModal
				modalHeaderTitle="New insight"
				isOpen={isOpen}
				onClose={onClose}
				signal={emptySignal}
				workspaceId={workspaceId}
				intialTitle={''}
			/>
			{isTiny ? (
				<Button variant="outline" isIconOnly onClick={onClick} size="inline" colorScheme="black">
					<AddSmall16 />
				</Button>
			) : (
				<Button onClick={onClick} colorScheme={'black'} variant="outline" size="inline" leftIcon={<AddSmall16 />}>
					Add text box
				</Button>
			)}
		</Box>
	);
}
function useEmptySignal(): Signal {
	const [{ firstName: first_name, lastName: last_name, id: author_id, picture, email }] = useUser();
	return useMemo(() => {
		return {
			created_at: '',
			message: '',
			id: undefined,
			author_id,
			author: {
				email,
				first_name,
				last_name,
				picture,
			},
			comments: [],
			feed_signals: [],
		};
	}, [author_id, email, first_name, last_name, picture]);
}
