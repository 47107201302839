import Box from '@components/Box';
import Flex from '@components/Flex';
import classNames from 'classnames';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import classes from './CustomPeriodPickerDialog.module.scss';
import { TabItem } from './TabItem';

export type TabBarProps = {
	tabPosition: number;
	disabledPositions: boolean[];
	onTabClicked: (position: number) => void;
	tabToolTips: string[];
};
export function TabBar({ tabPosition, disabledPositions, onTabClicked, tabToolTips = [] }: TabBarProps) {
	const isSightfull2Enabled = useFeatureFlag('pulse.sightfull2.enable');
	const isAbsolutePeriodEnabled = isSightfull2Enabled;

	const tabClass = classes.tabIndicator + ' ' + classes['pos' + tabPosition.toString()];

	return (
		<Flex direction="column" padding={'8px'} paddingBottom={0}>
			<Flex direction="row" justifyContent="stretch" textAlign="center">
				<TabItem
					tooltip={tabToolTips[0]}
					isSelected={tabPosition == 1}
					onTabClicked={() => onTabClicked(1)}
					isDisabled={disabledPositions[0]}
				>
					Weeks
				</TabItem>
				<TabItem
					tooltip={tabToolTips[1]}
					isSelected={tabPosition == 2}
					onTabClicked={() => onTabClicked(2)}
					isDisabled={disabledPositions[1]}
				>
					Months
				</TabItem>
				<TabItem
					tooltip={tabToolTips[2]}
					isSelected={tabPosition == 3}
					onTabClicked={() => onTabClicked(3)}
					isDisabled={disabledPositions[2]}
				>
					Quarters
				</TabItem>
				<TabItem
					tooltip={tabToolTips[3]}
					isSelected={tabPosition == 4}
					onTabClicked={() => onTabClicked(4)}
					isDisabled={disabledPositions[3]}
				>
					Years
				</TabItem>
				{isAbsolutePeriodEnabled && (
					<TabItem
						tooltip={tabToolTips[4]}
						isSelected={tabPosition == 5}
						onTabClicked={() => !disabledPositions[4] && onTabClicked(5)}
						isDisabled={disabledPositions[4]}
					>
						Absolute
					</TabItem>
				)}
			</Flex>
			<Box position="relative" height="2px">
				<Box
					className={classNames(tabClass, {
						[classes.withAbsolute]: isAbsolutePeriodEnabled,
					})}
				></Box>
			</Box>
		</Flex>
	);
}
