import Box from '@components/Box';
import { $createHeadingNode } from '@lexical/rich-text';
import { $wrapNodes } from '@lexical/selection';
import {
	$createParagraphNode,
	$createTextNode,
	$getRoot,
	$getSelection,
	$isElementNode,
	$isRangeSelection,
	$isRootNode,
	$isTextNode,
	LexicalEditor,
	TextNode,
} from 'lexical';
import ListItem from 'src/common/components/ListItem';
import { blockTypeToBlockName } from '../consts';
import { H1, H2, H3, Paragraph } from '../icons';
import { BlockType } from '../types';

import classes from '../styles/TextEditor.module.scss';
import { getSelectedNode } from '../utils';
import Flex from '@components/Flex';
import { OkSmall16 } from '@icons/index';
import classnames from 'classnames';

const { selectContainer, darkTheme } = classes;

function BlockOptionsDropdownList({
	editor,
	blockType,
	setShowBlockOptionsDropDown,
	isFloatingToolbar,
}: {
	editor: LexicalEditor;
	blockType: BlockType | '';
	setShowBlockOptionsDropDown: (show: boolean) => void;
	isFloatingToolbar: boolean;
}) {
	const formatParagraph = () => {
		if (blockType != 'paragraph' && blockType != 'root') {
			editor.update(() => {
				const selection = $getSelection();

				if ($isRangeSelection(selection)) {
					const node = getSelectedNode(selection) as TextNode;
					const focusOffset = node.__text.length + 1;
					selection.setTextNodeRange(node, 0, node, focusOffset);
					selection.formatText('bold');

					$wrapNodes(selection, () => $createParagraphNode());
				}
			});
		}
		setShowBlockOptionsDropDown(false);
	};

	const handleHeadingStyle = (headingType: 'h1' | 'h2' | 'h3') => {
		const selection = $getSelection();

		if ($isRangeSelection(selection)) {
			let node = getSelectedNode(selection);
			if (!$isTextNode(node)) {
				node = $createTextNode();
			}
			const headingNode = $createHeadingNode(headingType);
			const parentNode = node.getParent() ?? $getRoot();

			if ($isElementNode(parentNode) && !$isRootNode(parentNode)) {
				parentNode.replace(headingNode, true);
			} else {
				headingNode.append(node);
				parentNode.append(headingNode);
			}

			const focusOffset = node.__text?.length || 1;
			selection.setTextNodeRange(node, 0, node, focusOffset);

			if (!selection.hasFormat('bold')) {
				selection.formatText('bold');
			}
		}
	};

	const formatH1 = () => {
		if (blockType !== 'h1') {
			editor.update(() => handleHeadingStyle('h1'));
		}
		setShowBlockOptionsDropDown(false);
	};

	const formatH2 = () => {
		if (blockType !== 'h2') {
			editor.update(() => handleHeadingStyle('h2'));
		}
		setShowBlockOptionsDropDown(false);
	};

	const formatH3 = () => {
		if (blockType !== 'h3') {
			editor.update(() => handleHeadingStyle('h3'));
		}
		setShowBlockOptionsDropDown(false);
	};

	const { h1, h2, h3, paragraph } = blockTypeToBlockName;

	const prefixIcon = (label: 'text' | 'h1' | 'h2' | 'h3') => {
		const blockTypeToIcon = {
			text: <Paragraph />,
			h1: <H1 />,
			h2: <H2 />,
			h3: <H3 />,
		};

		return (
			<Flex justifyContent={'flex-end'} alignItems={'center'} w={'40px'} gap={'8px'}>
				{blockType === label && <OkSmall16 />}
				{blockTypeToIcon[label]}
			</Flex>
		);
	};

	const textColor = isFloatingToolbar ? 'white' : 'gray.700';
	const hoverColor = isFloatingToolbar ? 'gray.1000' : 'gray.200';

	return (
		<Box className={classnames(selectContainer, { [darkTheme]: isFloatingToolbar })}>
			<ListItem
				key={paragraph}
				label={paragraph}
				prefixIcon={prefixIcon('text')}
				onClick={formatParagraph}
				size="sm"
				color={textColor}
				hoverColor={hoverColor}
			/>
			<ListItem
				key={h1}
				label={h1}
				prefixIcon={prefixIcon('h1')}
				onClick={formatH1}
				size="sm"
				color={textColor}
				hoverColor={hoverColor}
			/>
			<ListItem
				key={h2}
				label={h2}
				prefixIcon={prefixIcon('h2')}
				onClick={formatH2}
				size="sm"
				color={textColor}
				hoverColor={hoverColor}
			/>
			<ListItem
				key={h3}
				label={h3}
				prefixIcon={prefixIcon('h3')}
				onClick={formatH3}
				size="sm"
				color={textColor}
				hoverColor={hoverColor}
			/>
		</Box>
	);
}

export default BlockOptionsDropdownList;
