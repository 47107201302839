import { PeriodRange } from '@sightfull/period-ranges';
import React, { forwardRef, ReactNode, Ref, useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { BrokenThumbnail } from 'src/common/components/BrokenThumbnail';
import { ChartComponentRef } from 'src/common/components/Chart/Chart';
import Flex from 'src/common/components/Flex';
import { ErrorYellow16 } from 'src/common/components/Icons';
import Spinner from 'src/common/components/Spinner';
import useMutation from 'src/common/hooks/fetching/useMutation';
import useUser from 'src/common/hooks/stores/useUser';
import { DashboardGetSingleQuery } from 'src/generated/graphql';
import { FilterV2 } from 'src/pages/MetricPage/utils/state.types';
import { DashboardGetSingle, UpdateDashboardLayout } from 'src/queries/dashboards';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import { FeedSignal } from 'src/types/spaces';
import EmptyPage from '../components/EmptyPage';
import { DashboardDividerWidget } from '../components/Widget/DividerWidget/DashboardDividerWidget';
import { DashboardSignalWidget, ObjectTypesFunction } from '../components/Widget/SignalWidget/DashboardSignalWidget';
import { DashboardTextWidget } from '../components/Widget/TextWidget/DashboardTextWidget';
import {
	BREAKPOINT_GRACE_PX,
	BreakpointName,
	BREAKPOINTS,
	COLUMN_COUNT,
	GRID_CONTAINER_PADDING,
	GRID_ITEM_MARGIN_BREAKPOINTS,
	ROW_HEIGHT_PX,
} from '../constants';
import { addLayoutLimitations, getSignalWidgetThumbnailHeight } from '../utils/utils';
import classes from './GridLayout.module.scss';
import './GridLayout.scss';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import FolderDashboardEmptyPage from '../components/FolderDashboardEmptyPage';

const ResponsiveGridLayout = WidthProvider(Responsive);

export interface GridLayoutRef {
	getFilterObjects: () => string[];
}

export function GridLayout({
	isEditMode,
	feedSignals,
	layout,
	dashboardId,
	onSignalWidgetLoaded,
	globalFilters,
	globalPeriodRange,
	currentBreakpoint,
	setCurrentBreakpoint,
	setIsFiltersLoading,
}: {
	isEditMode: boolean;
	feedSignals: FeedSignal[];
	layout: Layout[];
	dashboardId: string;
	onSignalWidgetLoaded: ObjectTypesFunction;
	globalFilters: FilterV2[];
	globalPeriodRange?: PeriodRange;
	currentBreakpoint: BreakpointName;
	setCurrentBreakpoint: (breakpoint: BreakpointName) => void;
	setIsFiltersLoading: (isLoading: boolean) => void;
}) {
	const isDashboardFoldersEnable = useFeatureFlag('pulse.sightfull2.dashboard.folders.enable');
	const [currentDraggingId, setCurrentDraggingId] = useState<string | null>(null);
	const { reportEvent } = useReportEvent();
	const [updateDashboardLayout] = useMutation(UpdateDashboardLayout);
	const chartRefs = React.useRef<Record<string, ChartComponentRef>>({});
	const [{ id: my_id }] = useUser();

	const updateSignalWidget = useCallback((id: string, height: number, breakpoint: BreakpointName) => {
		setTimeout(() => {
			chartRefs.current[id]?.reflow();
			chartRefs.current[id]?.setHeight(
				getSignalWidgetThumbnailHeight(height, GRID_ITEM_MARGIN_BREAKPOINTS[breakpoint][0])
			);
		}, 100);
	}, []);

	useEffect(() => {
		layout.forEach((e) => {
			updateSignalWidget(e.i, e.h, currentBreakpoint);
		});
	}, [layout, updateSignalWidget, currentBreakpoint]);

	const children: ReactNode[] = useMemo(
		() =>
			feedSignals.map((feedSignal) => (
				<div key={feedSignal.signal_id}>
					<ErrorBoundary
						fallback={<FallbackWidget />}
						onError={(error) => {
							console.error('Error in widget', feedSignal, error);
						}}
						key={`dashboard-signal-${feedSignal.signal_id}`}
					>
						<WidgetByType
							setIsFiltersLoading={setIsFiltersLoading}
							isEditMode={isEditMode}
							feedSignal={feedSignal}
							ref={(element: ChartComponentRef) => {
								chartRefs.current[feedSignal.signal_id] = element;
							}}
							onLoaded={onSignalWidgetLoaded}
							globalFilters={globalFilters}
							globalPeriodRange={globalPeriodRange}
							currentDraggingId={currentDraggingId}
							setCurrentDraggingId={setCurrentDraggingId}
						/>
					</ErrorBoundary>
				</div>
			)),
		[
			feedSignals,
			globalFilters,
			globalPeriodRange,
			isEditMode,
			onSignalWidgetLoaded,
			currentDraggingId,
			setIsFiltersLoading,
		]
	);

	const emptyPagePlaceholder = isDashboardFoldersEnable ? <FolderDashboardEmptyPage /> : <EmptyPage />;

	return (
		<>
			<ResponsiveGridLayout
				breakpoint={currentBreakpoint}
				useCSSTransforms={true}
				allowOverlap={false}
				preventCollision={false}
				autoSize={true}
				draggableHandle={'.' + classes.draggableHandle}
				rowHeight={ROW_HEIGHT_PX}
				onLayoutChange={(current, all) => {
					if (current == all.tiny) return;
					updateDashboardLayout({
						variables: { layout: current, id: dashboardId },
						optimisticResponse: { update_workspaces_by_pk: { layout: true } },
						update: (cache) => {
							const dashboardFromCache = cache.readQuery<DashboardGetSingleQuery>({
								query: DashboardGetSingle,
								variables: {
									id: dashboardId,
									my_id,
								},
							});

							if (dashboardFromCache) {
								cache.writeQuery({
									query: DashboardGetSingle,
									data: { dashboards: [{ ...dashboardFromCache.dashboards[0], layout: current }] },
								});
							}
						},
					});
				}}
				onResizeStop={(value, oldItem, newItem) => {
					const newItemId = newItem.i;
					reportEvent({
						event: 'dashboard-widget-resize',
						metaData: {
							'dashboard-id': dashboardId,
							'signal-id': newItemId,
							'widget-type': feedSignals.find((s) => s.signal_id == newItemId)?.signal.widget_type,
							width: newItem.w,
							height: newItem.h,
						},
					});
					reportEvent({
						event: 'dashboard-layout-changed',
						metaData: {
							'dashboard-id': dashboardId,
						},
					});
					updateSignalWidget(newItemId, newItem.h, currentBreakpoint);
				}}
				onWidthChange={(containerWidth) => {
					const newBreakpoint =
						Object.entries(BREAKPOINTS)
							.sort(([, w1], [, w2]) => w2 - w1)
							.find(([, minWidth]) => minWidth < containerWidth)?.[0] ?? 'tiny';
					const newBreakpointWidth = BREAKPOINTS[newBreakpoint];
					if (Math.abs(newBreakpointWidth - containerWidth) > BREAKPOINT_GRACE_PX) {
						setCurrentBreakpoint(newBreakpoint);
					}
				}}
				layouts={{ xl: addLayoutLimitations(layout, feedSignals) }}
				margin={GRID_ITEM_MARGIN_BREAKPOINTS}
				containerPadding={GRID_CONTAINER_PADDING}
				breakpoints={BREAKPOINTS}
				cols={{ xl: COLUMN_COUNT, lg: COLUMN_COUNT, md: COLUMN_COUNT, sm: COLUMN_COUNT, tiny: 1 }}
				onDragStop={() =>
					reportEvent({
						event: 'dashboard-layout-changed',
						metaData: {
							'dashboard-id': dashboardId,
						},
					})
				}
				onDrag={(layout, child, newItem) => {
					if (child.x != newItem.x || child.y != newItem.y) {
						setCurrentDraggingId(child.i);
					}
				}}
			>
				{children}
			</ResponsiveGridLayout>
			{!children.length && emptyPagePlaceholder}
		</>
	);
}

const WidgetByType = forwardRef(
	(
		{
			feedSignal,
			isEditMode,
			onLoaded,
			globalFilters,
			globalPeriodRange,
			currentDraggingId,
			setCurrentDraggingId,
			setIsFiltersLoading,
		}: {
			feedSignal: FeedSignal;
			isEditMode: boolean;
			onLoaded: ObjectTypesFunction;
			globalFilters: FilterV2[];
			globalPeriodRange?: PeriodRange;
			currentDraggingId: string | null;
			setCurrentDraggingId: React.Dispatch<React.SetStateAction<string | null>>;
			setIsFiltersLoading: (isLoading: boolean) => void;
		},
		ref: Ref<ChartComponentRef>
	) => {
		switch (feedSignal.signal.widget_type) {
			case 'signal':
				return (
					<DashboardSignalWidget
						setIsFiltersLoading={setIsFiltersLoading}
						isEditMode={isEditMode}
						feedSignal={feedSignal}
						ref={ref}
						onLoaded={onLoaded}
						globalFilters={globalFilters}
						globalPeriodRange={globalPeriodRange}
						currentDraggingId={currentDraggingId}
						setCurrentDraggingId={setCurrentDraggingId}
					/>
				);
			case 'text':
				return <DashboardTextWidget isEditMode={isEditMode} feedSignal={feedSignal} />;
			case 'divider':
				return <DashboardDividerWidget isEditMode={isEditMode} feedSignal={feedSignal} />;
			default:
				return (
					<Flex height={'100%'} width="100%" justifyContent={'center'} alignItems={'center'}>
						<Spinner />
					</Flex>
				);
		}
	}
);
WidgetByType.displayName = 'WidgetByType';

function FallbackWidget() {
	return (
		<Flex height={'100%'} width="100%" justifyContent={'center'} alignItems={'center'} bg={'white'}>
			<BrokenThumbnail
				title={'Broken widget'}
				subtitle={`Unknown error occurred with the widget.`}
				icon={<ErrorYellow16 color={colors.gray[600]} />}
			/>
		</Flex>
	);
}
