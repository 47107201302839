import Button from 'src/common/components/Button';
import classes from './Header.module.scss';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { TestIDs } from 'src/common/types/test-ids';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

export function ShareSignalButton({ onClick }: { onClick: () => any }) {
	const { errorMessage } = useMetricDerivedState();
	return (
		<Button
			isDisabled={!!errorMessage}
			variant="solid"
			onClick={onClick}
			size="inline"
			eventName="metric-publish-clicked"
			eventMetadata={{ feature: 'Publish flow' }}
			className={classes.blueButton}
			testId={TestIDs.PUBLISH_METRIC_BUTTON}
			{...buildIntercomAttributes({
				type: 'button',
				target: 'Publish',
			})}
		>
			Publish
		</Button>
	);
}
