import Box from '@components/Box';
import Flex from '@components/Flex';
import { useEffect, useRef } from 'react';
import Badge from 'src/common/components/Badge';
import Button from 'src/common/components/Button';
import { DotsSolid16, DragItemSolid16 } from 'src/common/components/Icons';
import Popover from 'src/common/components/Popover';
import Typography from 'src/common/components/Typography';
import { WIDGET_EDIT_BUTTON_WIDTH, WIDGET_HEADER_HEIGHT, WIDGET_RIGHT_GAP } from '../../constants';
import { BreakdownsBadge } from './BreakdownsBadge';
import { FiltersBadge } from './FiltersBadge';
import { WidgetHeaderProps } from './types';
import './WidgetHeader.scss';
import { SignalWidgetError } from 'src/pages/DashboardPage/components/Widget/SignalWidget/SignalWidgetError';
import useOutsideClick from '@hooks/interaction/useOutsideClick';
import colors from 'src/style/colors';
import { FlexProps, useDisclosure } from '@chakra-ui/react';
import { ParametersBadge } from './ParametersBadge';

export function WidgetHeader({
	isEditMode = false,
	isClickable = true,
	title,
	titleTooltip,
	filters,
	breakdowns,
	parameters,
	metadata,
	menu,
	actionItems,
	dragHandleClassName,
	description,
	onClickHandler,
	errorMessage,
	closeOnBlur = true,
	isOnlyControlsView = false,
	isWidgetMenuOpenControlled = true,
}: WidgetHeaderProps) {
	const height = isOnlyControlsView ? '0px' : metadata ? `${WIDGET_HEADER_HEIGHT}px` : '64px';
	const isShowingTitleTooltip = !!titleTooltip && titleTooltip != title;

	let titleContent = (
		<Box height={'20px'}>
			<Typography variant="DesktopH8Medium" textOverflow="ellipsis" noOfLines={1} cursor="pointer" color={'gray.1000'}>
				{title}
			</Typography>
		</Box>
	);
	if (isShowingTitleTooltip)
		titleContent = (
			<Popover triggerElement={titleContent} trigger="hover" placement="bottom-start">
				<Flex paddingX={'16px'} paddingY="10px" gap={'2px'} direction="column">
					<Typography cursor="pointer" variant="Disclaimer12SB" color="gray.800">
						{title}
					</Typography>
					<Typography cursor="pointer" variant="Paragraph12R" color="gray.800">
						{titleTooltip}
					</Typography>
				</Flex>
			</Popover>
		);
	const metadataContent = (
		<Flex
			overflow="hidden"
			whiteSpace="nowrap"
			textOverflow="ellipsis"
			maxWidth="100%"
			display="flex"
			flexWrap="nowrap"
			gap="4px"
		>
			<Popover
				triggerElement={
					<Box display="flex" gap="4px">
						<Typography
							overflow="hidden"
							whiteSpace="nowrap"
							textOverflow="ellipsis"
							maxWidth="100%"
							variant="Paragraph12R"
							color="gray.700"
							cursor="pointer"
						>
							{metadata}
						</Typography>
					</Box>
				}
				trigger="hover"
				placement="bottom"
			>
				<Typography cursor="pointer" variant="Paragraph12R" paddingX="16px" paddingY="10px" color="gray.800">
					{metadata}
				</Typography>
			</Popover>
			<Popover
				shouldHideOverflowText
				trigger="hover"
				triggerElement={
					<Box display="flex" gap="4px">
						{!!description?.trim() && <Box lineHeight="65%">.</Box>}
						{description && (
							<Typography maxWidth="100%" display="flex" variant="Paragraph12R" color="gray.700">
								<Flex maxWidth="100%" flexWrap="nowrap" gap="4px">
									<Box overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" paddingRight="2px" maxWidth="100%">
										{description}
									</Box>
								</Flex>
							</Typography>
						)}
					</Box>
				}
				placement="bottom-start"
			>
				{description && (
					<Typography display="flex" variant="Paragraph12R" color="gray.700">
						<Flex paddingX="16px" paddingY="10px" flexWrap="nowrap" gap="4px">
							{description}
						</Flex>
					</Typography>
				)}
			</Popover>
		</Flex>
	);

	const Header = (
		<Flex
			maxWidth={isEditMode ? `calc(100% - ${WIDGET_EDIT_BUTTON_WIDTH + WIDGET_RIGHT_GAP}px)` : '100%'}
			direction="column"
			gap={'4px'}
		>
			<Flex color="gray.1000" gap="8px">
				{titleContent}

				<Flex>
					{!!parameters?.length && <ParametersBadge parameters={parameters} />}
					{!!filters?.length && (
						<Box>
							<FiltersBadge filters={filters} />
						</Box>
					)}
					{!!breakdowns?.values.length && <BreakdownsBadge breakdowns={breakdowns} />}
				</Flex>
			</Flex>

			{metadata && <Flex direction="row">{metadataContent}</Flex>}
		</Flex>
	);

	const informationAlignment = metadata ? 'start' : 'center';
	const InformationContent = (
		<Flex
			overflow="hidden"
			maxWidth={isOnlyControlsView ? '16px' : '100%'}
			direction={'row'}
			gap="8px"
			alignItems={informationAlignment}
			className={isEditMode ? dragHandleClassName : ''}
			_active={{ cursor: isEditMode && 'grabbing' }}
			grow={1}
			zIndex={1}
			position={'relative'}
			top={isOnlyControlsView ? '30px' : '0'}
		>
			{isEditMode && (
				<Box cursor="grab" _active={{ cursor: isEditMode && 'grabbing' }}>
					<Badge
						icon={<DragItemSolid16 />}
						color={'transparent'}
						iconColor={'gray.700'}
						hoverIconColor={'gray.800'}
						isIcon16Px
					/>
				</Box>
			)}
			{Header}
		</Flex>
	);

	const { isOpen: isMenuOpen, onToggle: onMenuToggle, onClose: onMenuClose } = useDisclosure();
	const menuRef = useRef<HTMLDivElement>(null);
	useOutsideClick(menuRef, () => {
		if (closeOnBlur) onMenuClose();
	});

	useEffect(() => {
		if (!isWidgetMenuOpenControlled) onMenuClose();
	}, [isWidgetMenuOpenControlled, onMenuClose]);

	const actionsStyles = {
		top: isOnlyControlsView ? '26px' : '0',
		right: isOnlyControlsView ? '10px' : '0',
	};

	const ActionsContent = (
		// {/* TODO: Consider adding position="absolute" when in view mode for optimizing the elipssis  */}
		<Flex onClick={(e) => e.stopPropagation()} direction={'row'} gap="4px">
			<Flex
				maxWidth="100%"
				direction={'row'}
				gap="4px"
				className="react-grid-item-actions"
				position={'relative'}
				{...actionsStyles}
			>
				{actionItems}
			</Flex>
			{menu && (
				<Popover
					onClose={onMenuClose}
					placement="bottom-end"
					triggerElement={(isOpen) => (
						<Box
							onClick={onMenuToggle}
							className={!isOpen ? 'react-grid-item-actions' : ''}
							zIndex={1}
							position={'relative'}
							{...actionsStyles}
						>
							<Button isActive={isOpen} size="xs" isIconOnly variant="outline" colorScheme="lightGray">
								<DotsSolid16 />
							</Button>
						</Box>
					)}
					isOpen={isMenuOpen}
					closeOnBlur={closeOnBlur}
					popoverContentProps={{
						borderRadius: '8px',
						border: `1px solid ${colors.gray[300]}`,
					}}
				>
					<Box className="react-grid-menu" paddingY="8px" minWidth={'184px'} ref={menuRef}>
						{menu}
					</Box>
				</Popover>
			)}
		</Flex>
	);

	const clickableProps: FlexProps = {
		onClick: onClickHandler,
		cursor: 'pointer',
		_hover: { backgroundColor: 'gray.100', boxShadow: 'widgetHeaderBorderBottom' },
	};
	const nonClickableProps: FlexProps = {
		onClick: undefined,
		cursor: 'auto',
		_hover: { backgroundColor: 'transparent', boxShadow: 'none' },
	};
	const props = isClickable ? clickableProps : nonClickableProps;
	return (
		<Flex
			padding={isOnlyControlsView ? '0px' : isEditMode ? '16px 12px' : '16px 15px'}
			height={height}
			width={'100%'}
			background={isMenuOpen ? 'gray.100' : undefined}
			boxShadow={isMenuOpen ? 'widgetHeaderBorderBottom' : undefined}
			{...props}
		>
			<Flex
				position={'relative'}
				direction={'row'}
				alignItems={metadata ? 'start' : 'center'}
				justifyContent={'space-between'}
				width={'100%'}
			>
				{InformationContent}
				<Flex alignItems={'center'} direction={'row'}>
					{ActionsContent}
					{errorMessage && <SignalWidgetError errorMessage={errorMessage} />}
				</Flex>
			</Flex>
		</Flex>
	);
}
