import Flex from '@components/Flex';
import Spacer from '@components/Spacer';
import { ArrowLeft16, EditBox16, ExpandSmall16, SightfullAIChatLogo2 } from '@components/Icons';
import Button from '@components/Button';
import Box from '@components/Box';
import { useAIPowerMode } from '../../hooks/useAIPowerMode';
import useNavigation from '@services/useNavigation';
import { useCallback } from 'react';
import { useReportEvent } from '@services/analytics';
import { AskAIPagePath } from '@pages/AskAI';
import Tooltip from '@components/Tooltip';

export type AskAIChatHeaderProps = {
	onBack: () => void;
	onNewChat: () => void;
};

export function AskAIOmniChatHeader({ onBack, onNewChat }: AskAIChatHeaderProps) {
	const { reportEvent } = useReportEvent();

	const { navigate } = useNavigation();
	const { togglePowerMode } = useAIPowerMode();

	const onGoToAIPage = useCallback(() => {
		reportEvent({
			event: 'ask-ai-open-ai-page',
		});
		navigate({ path: `/${AskAIPagePath}` });
	}, [navigate, reportEvent]);

	return (
		<Flex flex={'1'} gap={'8px'} alignItems={'center'} padding={'8px'}>
			<Button variant={'outline'} size={'xs'} colorScheme={'gray'} isIconOnly={true} onClick={onBack}>
				<ArrowLeft16 />
			</Button>
			<Box onDoubleClick={togglePowerMode}>
				<SightfullAIChatLogo2 />
			</Box>
			<Spacer flexGrow={1} />
			<Tooltip size="md" variant="fluid" label={'Open in page'}>
				<Button variant={'outline'} size={'xs'} colorScheme={'gray'} isIconOnly={true} onClick={onGoToAIPage}>
					<ExpandSmall16 />
				</Button>
			</Tooltip>
			<Tooltip size="md" variant="fluid" label={'New chat'}>
				<Button variant={'outline'} size={'xs'} colorScheme={'gray'} isIconOnly={true} onClick={onNewChat}>
					<EditBox16 />
				</Button>
			</Tooltip>
		</Flex>
	);
}
