import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import classes from './Markdown.module.scss';
import classesAI from './MarkdownAI.module.scss';

export function Markdown({ message, children, isAI }: { message?: string; children?: string; isAI?: boolean }) {
	return (
		<ReactMarkdown remarkPlugins={[remarkGfm]} className={isAI ? classesAI.markdown : classes.markdown}>
			{message || children || ''}
		</ReactMarkdown>
	);
}
