import { Center, Flex, useDisclosure } from '@chakra-ui/react';
import { ChartLoader } from '@components/Chart/ChartLoader';
import useSearchParams from '@hooks/navigation/useSearchParams';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { Drawer } from 'antd';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import Typography from 'src/common/components/Typography';
import { EntityDrawerIdsAtom, EntityProfileScope } from 'src/layout/EntityProfileDrawer/atoms';
import { useReportEvent } from 'src/services/analytics';
import { usePermissionCheck } from 'src/stores/environment';
import colors from 'src/style/colors';
import { Permissions } from 'src/types/environment';
import { useGetRecordProfileByIdQuery } from '../../generated/graphql';
import { RecordProfileBody, RecordProfileHeader } from '../RecordProfileDrawer';
import { DrawerHeader, EntityProfileInner } from './components';
import { openEntitiesSearchParam, useEntityProfileDrawer } from './hooks';
import { useNormalizedData } from './hooks/useNormalizedData';

export function EntityProfileDrawer() {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');

	const hasPropertyModalPermission = usePermissionCheck().isHavingOneOfPermissions([
		Permissions.overrideReset,
		Permissions.overrideEdit,
		Permissions.overrideRead,
		Permissions.crudOverride,
	]);

	const [entities, setEntities] = useAtom(EntityDrawerIdsAtom, EntityProfileScope);
	const [searchParams] = useSearchParams();
	const openedEntitiesFromSearchParams = searchParams.get(openEntitiesSearchParam)?.split(',');

	useEffect(() => {
		const isEntitiesFromSearchParamsMismatch =
			openedEntitiesFromSearchParams && openedEntitiesFromSearchParams?.length != 0 && entities.length == 0;

		if (isEntitiesFromSearchParamsMismatch) {
			setEntities(openedEntitiesFromSearchParams);
		}
	}, [entities.length, openedEntitiesFromSearchParams, setEntities]);

	if (!entities || entities.length == 0) return <></>;

	if (!isSightfull2) {
		return <NestedDrawerV1 entityIds={entities} hasPropertyModalPermission={hasPropertyModalPermission} isFirst />;
	}

	return <NestedDrawerV2 entityIds={entities} isFirst />;
}

const featureExtraMetadata = { feature: 'Entity Drawer' };

function NestedDrawerV2({ entityIds, isFirst = false }: { entityIds: string[]; isFirst?: boolean }) {
	const { popEntity } = useEntityProfileDrawer();
	const { wrapWithReport } = useReportEvent(featureExtraMetadata);
	const currentRecordId = entityIds[0];
	const rest = entityIds.slice(1);
	const { data, loading, error } = useGetRecordProfileByIdQuery({
		variables: { id: currentRecordId },
	});
	const [searchValue, setSearchValue] = useState('');

	const isLoading = !data || loading;

	return (
		<Drawer
			bodyStyle={{ backgroundColor: colors.white, padding: 0 }}
			title={
				!isLoading && (
					<RecordProfileHeader
						onClose={wrapWithReport(popEntity, 'close-entity-profile-drawer', { id: currentRecordId })}
						recordProfile={data.recordProfile}
						showXRow
						searchValue={searchValue}
						onSearchValueChange={(newVal) => setSearchValue(newVal)}
					/>
				)
			}
			headerStyle={{ backgroundColor: colors.gray['100'], padding: 0 }}
			width={'700px'}
			onClose={wrapWithReport(popEntity, 'close-entity-profile-drawer', { id: currentRecordId })}
			mask={true}
			maskStyle={isFirst ? {} : { opacity: 0 }}
			closable={false}
			push={{ distance: 24 }}
			open
			className={'entity-profile-drawer'}
		>
			{error ? (
				<Flex flexDir="column" height={'100%'} gap={'8px'} paddingTop={'16'} paddingLeft={'4'}>
					<Typography variant="DesktopH4" color="gray.1000" noOfLines={1} width={'100%'}>
						Failed to load drawer
					</Typography>
					<Typography variant="DesktopH10Medium" color="gray.1000" noOfLines={1} width={'100%'}>
						{error.message}
					</Typography>
				</Flex>
			) : isLoading ? (
				<EntityProfileLoader />
			) : (
				<RecordProfileBody
					drawerIndex={entityIds.length}
					recordProfile={data.recordProfile}
					searchValue={searchValue}
				/>
			)}
			{rest.length > 0 && <NestedDrawerV2 entityIds={rest} />}
		</Drawer>
	);
}

export function NestedDrawerV1({
	entityIds,
	isFirst = false,
	hasPropertyModalPermission,
}: {
	entityIds: string[];
	isFirst?: boolean;
	hasPropertyModalPermission: boolean;
}) {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { popEntity } = useEntityProfileDrawer();
	const [shouldShowRawData, setShouldShowRawData] = useState(false);
	const { wrapWithReport } = useReportEvent(featureExtraMetadata);
	const { currentEntityId, node, rest } = useNormalizedData(entityIds, shouldShowRawData);
	const isLoading = !node;

	return (
		<Drawer
			bodyStyle={{ backgroundColor: colors.gray['100'] }}
			title={
				!isLoading && (
					<DrawerHeader
						header={node.header}
						onDrawerClose={wrapWithReport(popEntity, 'close-entity-profile-drawer', { id: currentEntityId })}
						source={node.source}
						shouldShowRawData={shouldShowRawData}
						onShowRawToggle={setShouldShowRawData}
					/>
				)
			}
			headerStyle={{ padding: 0 }}
			width={'700px'}
			onClose={wrapWithReport(popEntity, 'close-entity-profile-drawer', { id: currentEntityId })}
			mask={true}
			maskStyle={isFirst ? {} : { opacity: 0 }}
			closable={false}
			push={{ distance: 24 }}
			open
		>
			{isLoading ? (
				<EntityProfileLoader />
			) : (
				<EntityProfileInner
					isOpen={isOpen}
					onClose={onClose}
					onOpen={onOpen}
					currentEntityId={currentEntityId}
					hasPropertyModalPermission={hasPropertyModalPermission}
					node={node}
					rest={rest}
				/>
			)}
		</Drawer>
	);
}

export const EntityProfileLoader = () => (
	<Center position={'absolute'} zIndex={1} margin={'auto'} left={0} right={0} top={0} bottom={100}>
		<ChartLoader />
	</Center>
);
