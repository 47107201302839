import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { singleMetricToFullYaml, upsertYAMLKey, upsertYAMLObjectKey } from 'src/models/YamlUtils/yamlUtils';
import { useMetricEditorState } from 'src/pages/MetricPage/hooks/useMetricEditorState';
import { useUpdatedPreviewHashState } from 'src/pages/MetricPage/hooks/useUpdatedPreviewHashAtom';
import { MetricDefinitionState } from 'src/pages/MetricPage/utils/editor.types';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import YAML from 'yaml';
import { normalizeMetricState } from '../../../models/MetricDefinition/normalizeMetricState';
import { PeriodXAxis } from '../../completions/semanticTypes/metrics.schema';

import { useMetricBuilderAIAgent } from '../../../pages/MetricPage/hooks/useMetricBuilderAIAgent';
import {
	AGGREGATE_METRIC_EMPTY_YAML,
	FORMULA_METRIC_EMPTY_YAML,
	UNTITLED_METRIC_DISPLAY,
	UNTITLED_METRIC_NAME,
	useMetricEdit,
} from '@hooks/MetricHandling/useMetricEdit';
import { useMetricDerivedState } from '@pages/MetricPage/hooks/useMetricDerivedState';
import { hashMetricYamlEditorValueForPreview } from '@pages/MetricPage/utils/stateHelpers';

const DEFAULT_FORMULA_YAML_VALUE = FORMULA_METRIC_EMPTY_YAML.join('\n');
const DEFAULT_AGGREGATE_YAML_VALUE = AGGREGATE_METRIC_EMPTY_YAML.join('\n');

export function useBuilderDerivedState() {
	const { metricYamlEditorHashState, setMetricYamlEditorHashState } = useUpdatedPreviewHashState();
	const {
		metricEditorLoadedState,
		latestEditorValue = '',
		setMetricEditorState,
		hasChangesToPreview,
	} = useMetricEditorState();

	const { metricNameWithFlavor } = useMetricDerivedState();

	const kind = metricEditorLoadedState?.kind;
	const { requestSilentMetricChangesSuggestion } = useMetricBuilderAIAgent();
	const {
		onPreview: onPreviewMetric,
		onChange,
		setUserDefinedValue,
	} = useMetricEdit({
		metricName: metricNameWithFlavor,
	});

	//TODO: Key should be all string values / support for nested keys
	const upsertYAMLProperties = useCallback(
		(
			keys: { key: Parameters<typeof upsertYAMLKey>[1]; value?: Parameters<typeof upsertYAMLKey>[2] }[],
			options?: { shouldPreviewAfter?: boolean; shouldRequestAISuggestions?: boolean }
		) => {
			if (options?.shouldRequestAISuggestions && kind) {
				void requestSilentMetricChangesSuggestion(
					{
						definition: latestEditorValue,
						changes: keys.map((key) => ({ field: key.key.toString(), value: JSON.stringify(key.value) })),
					},
					kind
				);
			}
			const userValue = keys.reduce(
				(editorValue, keyVal) => upsertYAMLKey(editorValue, keyVal.key, keyVal.value),
				latestEditorValue
			);
			onChange(userValue, options?.shouldPreviewAfter || false);
		},
		[latestEditorValue, kind, requestSilentMetricChangesSuggestion, onChange]
	);

	const upsertYAMLProperty = useCallback(
		(
			key: Parameters<typeof upsertYAMLProperties>[0][0]['key'],
			value?: Parameters<typeof upsertYAMLProperties>[0][0]['value'],
			options?: Parameters<typeof upsertYAMLProperties>[1]
		) => {
			upsertYAMLProperties([{ key, value }], options);
		},
		[upsertYAMLProperties]
	);

	const updateMetricMetaWithoutPreview = useCallback(
		(userValue: string) => {
			if (!kind) return;
			const fullMetricYaml = singleMetricToFullYaml(userValue, kind);
			const preventLoadingHash = hashMetricYamlEditorValueForPreview(fullMetricYaml);
			setMetricYamlEditorHashState((s) => ({
				...s,
				requestedPreviewHash: preventLoadingHash,
				calculatedRulesEngineHash: preventLoadingHash,
			}));
			setMetricEditorState((s) => ({ ...s, previewValue: fullMetricYaml, userDefinedValue: userValue }));
		},
		[kind, setMetricEditorState, setMetricYamlEditorHashState]
	);

	const upsertYAMLObjectProperties = useCallback(
		(
			keys: { key: string; value: string | { key: string; value: string | PeriodXAxis[] }[] }[],
			options?: { shouldPreviewAfter?: boolean; requestAISuggestionsForFieldNames?: string[] }
		) => {
			if ((options?.requestAISuggestionsForFieldNames?.length ?? 0) > 0 && kind) {
				void requestSilentMetricChangesSuggestion(
					{
						definition: latestEditorValue,
						changes: keys
							.flatMap((key) => {
								const keyAsArray = Array.isArray(key.value) ? key.value : [key];
								return keyAsArray.map((key) => ({ field: key.key.toString(), value: JSON.stringify(key.value) }));
							})
							.filter((change) => options?.requestAISuggestionsForFieldNames?.includes(change.field)),
					},
					kind
				);
			}
			const userValue = keys.reduce(
				(editorValue, keyVal) => upsertYAMLObjectKey(editorValue, keyVal.key, keyVal.value),
				latestEditorValue
			);

			if (options?.shouldPreviewAfter) {
				onChange(userValue, true);
				return;
			}

			updateMetricMetaWithoutPreview(userValue);
		},
		[kind, latestEditorValue, updateMetricMetaWithoutPreview, requestSilentMetricChangesSuggestion, onChange]
	);

	const onPreview = useCallback(() => {
		if (!latestEditorValue || !kind || !hasChangesToPreview) return;
		onPreviewMetric();
	}, [latestEditorValue, kind, hasChangesToPreview, onPreviewMetric]);

	const replaceNameAndDisplayName = (yamlString: string, newName: string, newDisplayName: string) => {
		const yamlObject = YAML.parse(yamlString);
		yamlObject.name = newName;
		yamlObject.meta.display_name = newDisplayName;
		const newYamlString = YAML.stringify(yamlObject);
		return newYamlString;
	};

	const clearWithNameSave = useCallback(
		(newName: string, newDisplayName: string) => {
			if (metricEditorLoadedState?.savedValue) {
				const updatedValue = replaceNameAndDisplayName(metricEditorLoadedState?.savedValue, newName, newDisplayName);
				setMetricEditorState((s) => ({
					...s,
					previewValue: '',
					userDefinedValue: updatedValue,
				}));
			}
		},
		[setMetricEditorState, metricEditorLoadedState]
	);

	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);
	const location = useLocation();
	const shouldUseDraftName = !hasEditPermission && location.pathname.includes('create-new-metric');
	const clearState = useCallback(() => {
		setMetricEditorState((s) => ({
			...s,
			previewValue: '',
			userDefinedValue: '',
		}));
		setMetricYamlEditorHashState(() => ({
			requestedPreviewHash: '',
			calculatedRulesEngineHash: '',
		}));
	}, [setMetricEditorState, setMetricYamlEditorHashState]);
	const resetYAMLObjectProperties = useCallback(
		(keys: { key: string; value: string | { key: string; value: string }[] }[]) => {
			const userValue = keys
				.reduce(
					(editorValue, keyVal) => upsertYAMLObjectKey(editorValue, keyVal.key, keyVal.value),
					kind === 'formula' ? DEFAULT_FORMULA_YAML_VALUE : DEFAULT_AGGREGATE_YAML_VALUE
				)
				.trim();

			clearState();
			setUserDefinedValue(userValue);
		},
		[clearState, kind, setUserDefinedValue]
	);
	const resetYAMLValue = useCallback(
		({
			name = shouldUseDraftName ? 'untitled_metric' : UNTITLED_METRIC_NAME,
			display_name = shouldUseDraftName ? 'Untitled metric' : UNTITLED_METRIC_DISPLAY,
		}: {
			name?: string;
			display_name?: string;
		} = {}) => {
			const commonValues = [
				{ key: 'name', value: name },
				{ key: 'entity', value: '' },
			];
			const meta = {
				key: 'meta',
				value: [
					{
						key: 'display_name',
						value: display_name,
					},
				],
			};
			if (kind === 'formula') resetYAMLObjectProperties([...commonValues, { key: 'formula', value: '' }, meta]);
			if (kind === 'aggregate')
				resetYAMLObjectProperties([...commonValues, { key: 'operation', value: 'count' }, meta]);
		},
		[resetYAMLObjectProperties, kind, shouldUseDraftName]
	);

	const metricBuilderState: MetricDefinitionState | null = useMemo<MetricDefinitionState | null>(() => {
		try {
			if (!latestEditorValue || !kind) return null;
			const metricDataRaw: unknown = YAML.parse(latestEditorValue || '');
			return normalizeMetricState(metricDataRaw, kind);
		} catch (e) {
			console.log('Error parsing YAML', e);
			return null;
		}
	}, [kind, latestEditorValue]);

	const isCalculatingPreview = useMemo(
		() => metricYamlEditorHashState?.requestedPreviewHash !== metricYamlEditorHashState?.calculatedRulesEngineHash,
		[metricYamlEditorHashState]
	);

	return {
		metricBuilderState,
		isCalculatingPreview,
		onPreview,
		clearState,
		clearWithNameSave,
		upsertYAMLProperty,
		upsertYAMLProperties,
		upsertYAMLObjectProperties,
		resetYAMLObjectProperties,
		resetYAMLValue,
	};
}
