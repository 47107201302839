import Flex from '@components/Flex';
import Box from '@components/Box';
import Skeleton from 'src/common/components/Skeleton';
import classes from './RBACSkeleton.module.scss';
import colors from 'src/style/colors';
import { Fragment } from 'react/jsx-runtime';
import { HeaderSkeleton, SkeletonLegendPanel } from 'src/pages/MetricPage/components/SkeletonComponents';
import { LoadingMetricViewLayout } from 'src/pages/SignalPage/SkeletonComponents/LoadingSignalPage';
const { buttonSkeleton, contentSkeleton } = classes;
const PANEL_ITEMS_LENGTH = 6;

function LeftPanelSkeleton() {
	return (
		<Box borderRight={`1px solid ${colors.gray[300]}`} width={'399px'}>
			<Flex padding={'4px'} gap={'4px'} width={'100%'} alignItems={'center'}>
				<Skeleton className={buttonSkeleton} />
				<Skeleton className={buttonSkeleton} />
			</Flex>
			<Flex flexDirection={'column'} gap={'20px'} padding={'20px'}>
				{Array.from({ length: PANEL_ITEMS_LENGTH }, (_, i) => (
					<Fragment key={i}>
						<Skeleton className={contentSkeleton} />
						<Skeleton width="75%" className={contentSkeleton} />
					</Fragment>
				))}
			</Flex>
		</Box>
	);
}

export function PageSkeleton() {
	return (
		<Flex width={'100%'}>
			<LeftPanelSkeleton />
			<Flex flex={1} flexDirection={'column'}>
				<Flex width={'100%'} height={'56px'}>
					<HeaderSkeleton />
				</Flex>
				<Flex>
					<Flex marginTop={'24px'} flex={1} flexDir={'column'}>
						<LoadingMetricViewLayout />
					</Flex>
					<Box padding={'24px 16px 16px 16px'} boxShadow="borderLeft" width={'252px'} textAlign={'center'}>
						<SkeletonLegendPanel />
					</Box>
				</Flex>
			</Flex>
		</Flex>
	);
}
