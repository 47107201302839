import { useCallback } from 'react';
import Flex from '@components/Flex';
import Divider from '@components/Divider';
import Tooltip from '@components/Tooltip';
import Button from '@components/Button';
import { AddColumn16, ChevronLeftSolid16, DownloadSolid16 } from '@components/Icons';
import { GridTableApi, useGridTableApi } from './GridTableHooks';
import { ButtonColorSchemaType, ButtonSizeType } from '../Button/types';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

export type GridTableHeaderIconActionProps = {
	icon: React.ReactElement;
	text?: string;
	tooltip?: React.ReactNode;
	onClick?: (gridTableApi: GridTableApi) => void;
	colorScheme?: ButtonColorSchemaType;
	children?: React.ReactNode | React.ReactNode[];
	dataIntercomArea?: string;
	dataIntercomType?: string;
	dataIntercomTarget?: string;
	isDisabled?: boolean;
	size?: ButtonSizeType;
};

export function GridTableHeaderIconAction({
	icon,
	text,
	tooltip,
	onClick,
	children,
	dataIntercomArea,
	dataIntercomType,
	dataIntercomTarget,
	isDisabled,
	size = 'small',
	colorScheme = 'lightGray',
}: GridTableHeaderIconActionProps) {
	const { gridTableApi } = useGridTableApi();

	const onClickWithApi = useCallback(() => {
		onClick?.(gridTableApi);
	}, [gridTableApi, onClick]);

	return (
		<>
			<Tooltip size={'md'} label={tooltip}>
				<Button
					isDisabled={isDisabled}
					variant={'outline'}
					colorScheme={colorScheme}
					isIconOnly={!text}
					size={size}
					onClick={onClickWithApi}
					leftIcon={text ? icon : undefined}
					{...buildIntercomAttributes({ area: dataIntercomArea, type: dataIntercomType, target: dataIntercomTarget })}
				>
					{text ?? icon}
				</Button>
			</Tooltip>
			{children}
		</>
	);
}

export function GridTableHeaderActions({ children }: { children: React.ReactNode | React.ReactNode[] }) {
	return (
		<Flex alignItems={'center'} justifyContent={'flex-start'} paddingLeft={'16px'}>
			{children}
		</Flex>
	);
}

export function GridTableHeaderActionDivider() {
	return <Divider color={'gray.300'} direction={'vertical'} paddingY={'10px'} marginX={'8px'} />;
}

export function GridTableHeaderActionAddColumn({
	onClick,
	isDisabled,
	size,
	colorScheme,
}: Pick<GridTableHeaderIconActionProps, 'onClick' | 'isDisabled' | 'size' | 'colorScheme'>) {
	return (
		<GridTableHeaderIconAction
			isDisabled={isDisabled}
			tooltip={'Add column'}
			icon={<AddColumn16 />}
			onClick={onClick}
			size={size}
			colorScheme={colorScheme}
		/>
	);
}

export function GridTableHeaderActionExport({
	onClick,
	isDisabled,
	size,
	colorScheme,
}: Pick<GridTableHeaderIconActionProps, 'onClick' | 'isDisabled' | 'size' | 'colorScheme'>) {
	return (
		<GridTableHeaderIconAction
			isDisabled={isDisabled}
			tooltip={'Export table'}
			icon={<DownloadSolid16 />}
			onClick={onClick}
			size={size}
			colorScheme={colorScheme}
		/>
	);
}

export function GridTableHeaderActionBack({ onClick }: Pick<GridTableHeaderIconActionProps, 'onClick'>) {
	return <GridTableHeaderIconAction tooltip={'Back'} icon={<ChevronLeftSolid16 />} onClick={onClick} />;
}

export type GridTableHeaderActions = React.ReactNode;
