import { Flex } from '@chakra-ui/react';
import Button from 'src/common/components/Button';
import shadows from 'src/style/shadows';

interface MappingFooterProps {
	onCancel: () => void;
	onBack: () => void;
	onSave: () => void;
	isSaveDisabled?: boolean;
}

export function MappingFooter({ onCancel, onBack, onSave, isSaveDisabled = false }: MappingFooterProps) {
	return (
		<Flex boxShadow={shadows.borderTop} alignItems={'center'} justifyContent={'space-between'} padding={'12px 20px'}>
			<Button variant="outline" colorScheme="lightGray" size="medium" onClick={onCancel}>
				Cancel
			</Button>
			<Flex gap={'8px'}>
				<Button variant="ghost" colorScheme="lightGray" size="medium" onClick={onBack}>
					Back
				</Button>
				<Button variant="solid" colorScheme="blue" size="medium" onClick={onSave} isDisabled={isSaveDisabled}>
					Save
				</Button>
			</Flex>
		</Flex>
	);
}
