import { Img } from '@chakra-ui/react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useMemo, useRef } from 'react';
import PendingSetupMetricPreview from 'src/assets/images/pending-setup-metric-preview.svg';
import Button from 'src/common/components/Button';
import ListItem from 'src/common/components/ListItem';
import { useModal } from 'src/common/hooks/ui/useModal';
import { getSourceInfoLogo, getSourcesInfo } from 'src/common/hooks/useSourceInfo';
import { calcMetricTable } from 'src/lib/metricRules/DerivedStateCalculators/calcTable';
import { HorizontalLegend } from 'src/pages/DashboardPage/components/HorizontalLegend/HorizontalLegend';
import { WidgetHeader } from 'src/pages/DashboardPage/components/WidgetHeader/WidgetHeader';
import { usePeriodRangeMetadata } from 'src/pages/DashboardPage/hooks/usePeriodRangeMetadata';
import { ExportPopover } from 'src/pages/MetricPage/components/Header/ExportTableModal/ExportPopover';
import RulesEngineRunner from 'src/pages/MetricPage/components/RulesEngineRunner';
import { ShareSignalModal } from 'src/pages/MetricPage/components/ShareSignalModal/ShareSignalModal';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useMetricSearchParamsBuilderV2 } from '../../../../../pages/MetricPage/hooks/useMetricSearchParamsBuilderV2';
import Box from '../../../Box';
import Center from '../../../Center';
import Chart from '../../../Chart';
import Flex from '../../../Flex';
import { ArrowUp16, BrokenChart70, DownloadSolid16, ExpandSmall16 } from '../../../Icons';
import Tooltip from '../../../Tooltip';
import { useOpenMetricInNewTab } from '../../hooks/useOpenInNewTab';
import { AskAIChatMessage, ChatEventStepSuccessfulCalcMetricPayload } from '../../types';
import classes from './AskAIOmniChatMetricPreview.module.scss';

export function AskAIOmniMetricChartPreview({
	calcPayload,
	chatMessage,
	testId,
}: {
	calcPayload: ChatEventStepSuccessfulCalcMetricPayload;
	chatMessage: AskAIChatMessage;
	testId: string;
}) {
	const [isDoneBuildingSearchParams, { searchParams }] = useMetricSearchParamsBuilderV2(calcPayload);
	const { onOpenInNewTab } = useOpenMetricInNewTab({ calcPayload, userPrompt: chatMessage.userPrompt });
	if (!isDoneBuildingSearchParams) return;

	return (
		<RulesEngineRunner>
			<MetricChartPreview onOpenInNewTab={onOpenInNewTab} testId={testId} searchParams={searchParams} />
		</RulesEngineRunner>
	);
}

function MetricChartPreview({
	testId,
	onOpenInNewTab,
	searchParams,
}: {
	onOpenInNewTab: () => void;
	testId: string;
	searchParams: URLSearchParams;
}) {
	return (
		<Box>
			<Flex
				border={'1px solid'}
				borderColor={'gray.300'}
				borderRadius={'8px'}
				direction={'column'}
				data-testid={testId}
				data-search-params={searchParams}
				overflow={'hidden'}
				className={classes.askAiOmniChatMetricPreview}
			>
				<PreviewHeader onOpenInNewTab={onOpenInNewTab} />
				<ChartPreview />
			</Flex>
		</Box>
	);
}

function ChartPreview() {
	const {
		chartOptions,
		errorMessage = '',
		isFullyDefined,
		displayedLegendItems,
		metricSource,
	} = useMetricDerivedState();
	const sourceInfo = useMemo(() => getSourcesInfo({ source: metricSource }), [metricSource]);
	const SourceLogo = useMemo(
		() => getSourceInfoLogo({ bigLogo: true, dataConnectorInfo: sourceInfo?.connectorInfo }),
		[sourceInfo?.connectorInfo]
	);

	if (!isFullyDefined) {
		return <Img src={PendingSetupMetricPreview} objectFit="cover" width={'100%'} height={'162px'} />;
	}

	const isMetricBroken = !!errorMessage;
	if (isMetricBroken)
		return (
			<Center width={'100%'} height={'162px'}>
				<BrokenChart70 />
			</Center>
		);

	return (
		<Flex width="100%" padding={0} direction={'column'}>
			<Box maxHeight={'152px'}>
				<Chart
					isTooltipEnabled={true}
					height={'152px'}
					spacing={[0, 0, 0, 0]}
					isEntityPage={false}
					xAxisLabelsVisible={true}
				/>
			</Box>
			<Flex p={`0 16px 12px 16px`} justifyContent={'space-between'} alignItems={'center'}>
				<HorizontalLegend
					series={chartOptions.series}
					bubbles={chartOptions.bubbles}
					displayedLegendItems={displayedLegendItems}
				/>
				{SourceLogo && (
					<Box paddingLeft={'24px'}>
						<Tooltip label={sourceInfo?.connectorInfo?.name} size="sm">
							{SourceLogo}
						</Tooltip>
					</Box>
				)}
			</Flex>
		</Flex>
	);
}

function PreviewHeader({ onOpenInNewTab }: { onOpenInNewTab: VoidFunction }) {
	const {
		isOpen: isOpenPublishSignalModal,
		onOpen: onOpenPublishSignalModal,
		onClose: onClosePublishSignalModal,
	} = useModal();
	const {
		isOpen: isExportTableModalOpen,
		onOpen: onOpenExportTableModal,
		onClose: onCloseExportTableModal,
	} = useModal();

	const metricDerivedState = useMetricDerivedState();
	const {
		filters,
		breakdowns,
		parameters,
		periodRange,
		metricDisplayName,
		isLoading: isMetricLoading,
		errorMessage,
		decimalDigits,
		displayUnits,
	} = metricDerivedState;
	const filtersWithValues = filters?.filter((filter) => filter.values?.length);
	const gridApi = useRef<GridApi | null>(null);

	const metadata = usePeriodRangeMetadata(periodRange);

	const onCsvExport = () => {
		gridApi.current?.exportDataAsCsv();
	};
	const onGridReady = (params: GridReadyEvent) => {
		gridApi.current = params.api;
	};

	const { tableColDefs, tableRowsData } = useMemo(
		() =>
			calcMetricTable(metricDerivedState, {
				decimalDigits,
				displayUnits,
			}),
		[decimalDigits, displayUnits, metricDerivedState]
	);

	return (
		<>
			<ExportPopover
				isOpen={isExportTableModalOpen}
				onClose={onCloseExportTableModal}
				onCsvExport={onCsvExport}
				placement="right-start"
				popoverContentProps={{ width: '360px', borderRadius: '8px', top: '-1px' }}
				tableTypeOverride={'MetricTable'}
			/>
			<AgGridReact rowData={tableRowsData} columnDefs={tableColDefs} onGridReady={onGridReady} />
			<WidgetHeader
				isClickable={true}
				onClickHandler={onOpenInNewTab}
				isEditMode={false}
				title={metricDisplayName}
				titleTooltip={metricDisplayName}
				filters={filtersWithValues}
				breakdowns={breakdowns}
				parameters={parameters}
				errorMessage={errorMessage}
				metadata={isMetricLoading ? '' : metadata}
				hasBottomPadding={false}
				actionItems={[
					<Tooltip label={'Open in metric page'} size={'md'} variant={'fluid'} key="open-metric-button">
						<Button
							isIconOnly
							size="xs"
							colorScheme="lightGray"
							variant="outline"
							onClick={onOpenInNewTab}
							className={classes.actionItem}
						>
							<ExpandSmall16 />
						</Button>
					</Tooltip>,
				]}
				menu={
					<>
						<ListItem
							size="sm"
							label="Publish Signal"
							prefixIcon={<ArrowUp16 />}
							color="gray.1000"
							hoverColor="blue.100"
							onClick={onOpenPublishSignalModal}
						/>
						<ListItem
							size="sm"
							label="Export"
							prefixIcon={<DownloadSolid16 />}
							color="gray.1000"
							hoverColor="blue.100"
							onClick={onOpenExportTableModal}
						/>
					</>
				}
				isWidgetMenuOpenControlled={true}
				dragHandleClassName={''}
			/>
			<ShareSignalModal
				isModalOpen={isOpenPublishSignalModal}
				onModalClose={onClosePublishSignalModal}
				metricTitle={metricDisplayName}
				headerName="Metric"
			/>
		</>
	);
}
