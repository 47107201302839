import every from 'lodash/every';
import isString from 'lodash/isString';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSemanticDefinitionsForEntity } from 'src/common/hooks/stores/useSemanticDefinitions';
import { EntityDefinitionState, isRawSQLDataSource } from 'src/lib/completions/semanticTypes';
import { Filters } from 'src/lib/completions/semanticTypes/metrics.schema';
import { RawSQLDataSource, TableDataSource } from 'src/lib/completions/semanticTypes/normalization.schema';
import { isRecord } from 'src/models/MetricDefinition/normalizeMetricState';
import { transformObjectIntoKeysArray, upsertYAMLKey, upsertYAMLObjectKey } from 'src/models/YamlUtils/yamlUtils';
import YAML from 'yaml';
import { isValidYamlEntity } from '../utils/normalizeYaml';
import { isTableDataSource } from '../utils/utils';
import { UNTITLED_METRIC_DISPLAY, UNTITLED_METRIC_NAME } from '@hooks/MetricHandling/useMetricEdit';

export type KeysType = {
	key: string;
	value: string | string[] | { key: string; value: string | undefined | any[] }[];
}[];

export type SimpleKeysType = {
	key: Parameters<typeof upsertYAMLKey>[1];
	value?: Parameters<typeof upsertYAMLKey>[2];
};

const INITIAL_ENTITY_VALUE_ARRAY = [
	`name: ${UNTITLED_METRIC_NAME}`,
	'meta:',
	'  description: ""',
	`  display_name: ${UNTITLED_METRIC_DISPLAY}`,
	'data_source:',
	'  schema: ""',
	'  table: ""',
	'primary_keys:',
	'  - ""',
];

const isStringArray = (arr: unknown[]): arr is string[] => {
	return every(arr, isString);
};

const INITIAL_ENTITY_VALUE = INITIAL_ENTITY_VALUE_ARRAY.join('\n');

export default function useOntologyUpsertEntity({
	isEditEntity = false,
	selectedEntityName,
	ontologyEditorValue,
	isAdvancedMode = false,
}: {
	isEditEntity?: boolean;
	selectedEntityName?: string;
	ontologyEditorValue?: string;
	isAdvancedMode?: boolean;
}) {
	const [entityYAMLValue, setUpsertEntityYAMLValue] = useState(INITIAL_ENTITY_VALUE);
	const [usersYAMLValue, setUsersYAMLValue] = useState(ontologyEditorValue || '');

	const entityWithSemantic = useSemanticDefinitionsForEntity(isEditEntity ? selectedEntityName : undefined);

	const writeValuesIntoYAML = useCallback(
		(keys: KeysType, yamlValue: string) =>
			keys.reduce((editorValue, keyVal) => {
				const { key, value } = keyVal;
				if (Array.isArray(value))
					return isStringArray(value)
						? upsertYAMLKey(editorValue, key, value)
						: upsertYAMLObjectKey(editorValue, key, value as { key: string; value: string | unknown[] }[]);
				return upsertYAMLKey(editorValue, key, value);
			}, yamlValue),
		[]
	);

	const getDataSourceValue = (
		dataSource: TableDataSource | RawSQLDataSource
	): { key: string; value: string | Filters }[] => {
		const dataSourceValue: { key: string; value: string | Filters }[] = [];
		if (isTableDataSource(dataSource)) {
			dataSourceValue.push({ key: 'schema', value: dataSource?.schema?.name });
			dataSourceValue.push({ key: 'table', value: dataSource.table });
			if (dataSource.filters && dataSource.filters.length > 0) {
				dataSourceValue.push({ key: 'filters', value: dataSource.filters });
			}
		} else if (isRawSQLDataSource(dataSource)) {
			dataSourceValue.push({ key: 'raw_sql', value: dataSource.raw_sql });
		}
		return dataSourceValue;
	};

	const initialYAMLValue = useMemo(() => {
		if (!isEditEntity) return INITIAL_ENTITY_VALUE;

		const dataSource: TableDataSource | RawSQLDataSource | undefined = entityWithSemantic?.data_source;

		const dataSourceValue = dataSource ? getDataSourceValue(dataSource) : [];

		const isDataSourceAvailable =
			dataSource &&
			isTableDataSource(dataSource) &&
			dataSource?.schema?.name &&
			dataSource.table &&
			entityWithSemantic?.primary_keys;

		const valuesToUpdate: KeysType = [
			{ key: 'name', value: entityWithSemantic?.name || '' },
			{
				key: 'data_source',
				value: dataSourceValue,
			},
			{
				key: 'meta',
				value: [
					{ key: 'description', value: entityWithSemantic?.meta?.description || '' },
					{ key: 'display_name', value: entityWithSemantic?.meta?.display_name || undefined },
				],
			},
		];

		if (isDataSourceAvailable || (isAdvancedMode && entityWithSemantic?.primary_keys)) {
			valuesToUpdate.push({ key: 'primary_keys', value: entityWithSemantic?.primary_keys || [] });
		}
		return writeValuesIntoYAML(valuesToUpdate, INITIAL_ENTITY_VALUE);
	}, [
		isEditEntity,
		isAdvancedMode,
		entityWithSemantic?.data_source,
		entityWithSemantic?.meta?.description,
		entityWithSemantic?.meta?.display_name,
		entityWithSemantic?.name,
		entityWithSemantic?.primary_keys,
		writeValuesIntoYAML,
	]);

	const updateYAMLValue = useCallback(
		(keys: KeysType) => {
			const updatedYaml = writeValuesIntoYAML(keys, entityYAMLValue);
			const newUsersVal = writeValuesIntoYAML(keys, usersYAMLValue);
			setUpsertEntityYAMLValue(updatedYaml);
			setUsersYAMLValue(newUsersVal);
		},
		[entityYAMLValue, usersYAMLValue, writeValuesIntoYAML]
	);

	const initializeEntityValues = useCallback(() => setUpsertEntityYAMLValue(initialYAMLValue), [initialYAMLValue]);

	useEffect(() => initializeEntityValues(), [initializeEntityValues]);

	const normalizeEntityState = useCallback((entityDataRaw: unknown) => {
		if (!isValidYamlEntity(entityDataRaw)) {
			throw new Error('Invalid entity yaml data');
		}
		const verifiedEntityData = entityDataRaw as EntityDefinitionState;
		const metricDataFormula: EntityDefinitionState = {
			name: verifiedEntityData['name'],
			data_source: verifiedEntityData['data_source'],
			primary_keys: verifiedEntityData['primary_keys'],
			meta: verifiedEntityData['meta'],
		};

		return metricDataFormula;
	}, []);

	const resetToDefault = useCallback(() => {
		if (isEditEntity) {
			initializeEntityValues();
			return;
		}
		const shalowArray = [...INITIAL_ENTITY_VALUE_ARRAY];
		shalowArray.push('dimensions: []');
		setUsersYAMLValue(shalowArray.join('\n'));
		setUpsertEntityYAMLValue(INITIAL_ENTITY_VALUE);
	}, [isEditEntity, initializeEntityValues]);

	const setEditorYAMLValue = (yamlValue: string) => {
		setUpsertEntityYAMLValue(yamlValue);
		const parsedYamlValue = YAML.parse(yamlValue);
		if (!parsedYamlValue || !isRecord(parsedYamlValue)) return;
		const updatedYaml = writeValuesIntoYAML(
			transformObjectIntoKeysArray(parsedYamlValue).filter((el) => !['dimensions', 'relationships'].includes(el.key)),
			usersYAMLValue
		);
		setUsersYAMLValue(updatedYaml);
	};

	useEffect(() => {
		if (ontologyEditorValue && ontologyEditorValue.includes('name') && isEditEntity) {
			setUsersYAMLValue(ontologyEditorValue);
		} else {
			resetToDefault();
		}
	}, [ontologyEditorValue, resetToDefault, isEditEntity]);

	const hasUnsavedChanges = initialYAMLValue !== entityYAMLValue;

	const entityState: EntityDefinitionState | null = useMemo<EntityDefinitionState | null>(() => {
		try {
			if (!entityYAMLValue) return null;
			const entityDataRaw: unknown = YAML.parse(entityYAMLValue || '');
			return normalizeEntityState(entityDataRaw);
		} catch (e) {
			console.log('Error parsing YAML', e);
			return null;
		}
	}, [entityYAMLValue, normalizeEntityState]);

	return {
		entityState,
		entityYAMLValue,
		usersYAMLValue,
		setEditorYAMLValue,
		initializeEntityValues,
		updateYAMLValue,
		resetToDefault,
		hasUnsavedChanges,
	};
}
