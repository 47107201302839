import Flex from '@components/Flex';
import { ReactNode } from 'react';
import Typography from 'src/common/components/Typography';
import classes from './CustomPeriodPickerDialog.module.scss';
import Tooltip from '@components/Tooltip';

export function TabItem({
	isSelected,
	onTabClicked,
	children,
	isDisabled,
	tooltip,
}: {
	isSelected: boolean;
	onTabClicked: () => void;
	children: ReactNode;
	isDisabled: boolean;
	tooltip?: string;
}) {
	const classNames = [classes.tabLabel];
	if (isSelected) classNames.push(classes.selected);
	if (isDisabled) classNames.push(classes.disabled);
	return (
		<Flex className={classNames.join(' ')}>
			<Tooltip shouldWrapChildren={false} label={tooltip} size={'sm'}>
				<button onClick={onTabClicked} style={{ cursor: isDisabled ? 'default' : 'pointer' }}>
					<Typography variant="DesktopH8Medium" textAlign="center">
						{children}
					</Typography>
				</button>
			</Tooltip>
		</Flex>
	);
}
