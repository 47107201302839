import Flex from '@components/Flex';
import Skeleton from 'src/common/components/Skeleton';
import classes from './HeaderSkeleton.module.scss';
import colors from 'src/style/colors';

export function HeaderSkeleton() {
	return (
		<Flex borderBottom={`1px solid ${colors.gray[300]}`} height={'auto'} flex={1} padding={'10px 30px'}>
			<Skeleton className={classes.headerSkeleton} />
		</Flex>
	);
}
