import {
	FILTER_EMPTY_STRING_OPTION_NAME,
	FILTER_NULL_OPTION_NAME,
	typeIcon,
} from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/constants';
import { FiltersAndBreakdownItemType } from '@pages/MetricPage/components/FiltersAndBreakdown/types';
import { capitalizedFirstLetter } from 'src/common/utils/format';
import { SupportedDimensionTypes } from 'src/generated/graphql';
import { FilterV2Value } from 'src/pages/MetricPage/utils/state.types';

export const getSubstringBeforeDot = (str: string): string => {
	return str.split('.')[0];
};

export const getSubstringAfterDot = (str: string): string => {
	return str.split('.')[1] ? str.split('.')[1] : str;
};

export const getLastSubstringAfterDot = (str: string): string => {
	const namesArray = str.split('.');
	const lastName = namesArray[namesArray.length - 1];
	return lastName ? lastName : str;
};

export const getRelationship = (str: string): string => {
	return str.split('>')[1] ? str.split('>')[1] : str;
};

export const formatDimensionLabelV2 = (input: string): string => {
	if (input.charAt(0) !== '$') {
		const formattedInput = input?.split(' / ')?.pop() || '';
		if (formattedInput.startsWith('Src')) return formattedInput.replace('Src', '');
		return formattedInput;
	}
	const parts = getParts(input);
	return parts[parts.length - 1];
};

export const formatDimensionLabel = (input: string): string => {
	if (input.charAt(0) !== '$') return input;
	const parts = getParts(input);
	return parts.join(' / ');
};

const getParts = (input: string) => {
	return input.split('.').map((part) => {
		if (part.startsWith('$src__') || part.startsWith('src__')) {
			const remainingPart = part.replace('$src__', '').replace('src__', '').split('_').join(' ');
			return capitalizedFirstLetter(remainingPart);
		}

		const subParts = part
			.split(/(\$|___|__|_)/)
			.filter((subPart) => subPart && !['$', '___', '__', '_'].includes(subPart));

		return subParts.map((subPart) => capitalizedFirstLetter(subPart)).join(' ');
	});
};

export const replaceLastCommaWithOr = (str: string): string => {
	const lastCommaIndex = str.lastIndexOf(',');
	if (lastCommaIndex === -1) return str;
	return str.slice(0, lastCommaIndex) + ' or' + str.slice(lastCommaIndex + 1);
};

export const formatMetricFilterLabel = (input: string | null): string => {
	if (input === '') return FILTER_EMPTY_STRING_OPTION_NAME;
	if (input === null) return FILTER_NULL_OPTION_NAME;
	return input;
};

export const findEntityToFetch = ({
	entity,
	filterKey,
	relationships,
}: {
	entity: string;
	filterKey?: string;
	relationships: FiltersAndBreakdownItemType[];
}) => {
	const parsedEntity = filterKey?.indexOf('.') !== -1 ? getSubstringBeforeDot(filterKey || '') : entity;
	const parsedEntityThroughRelationship =
		relationships.length > 0
			? getRelationship(relationships.find((item) => item.key.includes(`${parsedEntity}>`))?.key || '')
			: '';

	return parsedEntityThroughRelationship || entity;
};

export const removeLastFilterLabelItem = (label: string) => label.replace(/\/[^/]*$/, '').trim();

export const getIcon = (type: SupportedDimensionTypes) => typeIcon[type];

export const getText = (values: FilterV2Value[], formattedLabel: string) => {
	const firstCommaSeparatedValuesLabels = values
		?.map((v) => formatMetricFilterLabel(v?.toString() ?? null))
		?.filter((v) => v !== '')
		?.map(formatMetricFilterLabel)
		.join(', ');
	const displayValue = values && replaceLastCommaWithOr(firstCommaSeparatedValuesLabels);

	return {
		title: formattedLabel,
		displayValue,
	};
};
