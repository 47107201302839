import Flex from '@components/Flex';
import colors from 'src/style/colors';
import Typography from '@components/Typography';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

export function HeaderNavigationButton({
	title,
	Icon,
	onClick,
	dataIntercomArea,
	dataIntercomType,
	dataIntercomTarget,
	isActive = false,
	testId,
}: {
	title: string;
	Icon: React.FC;
	onClick: () => void;
	isActive?: boolean;
	testId?: string;
} & { dataIntercomArea?: string; dataIntercomType?: string; dataIntercomTarget?: string }) {
	return (
		<Flex
			border={'4px solid'}
			borderColor={isActive ? colors['gray']['300'] : colors['white']}
			borderRadius={'158px'}
			_hover={{
				borderColor: isActive ? colors['gray']['300'] : colors['blue']['200'],
			}}
		>
			<Flex
				padding={'8px 12px 8px 8px'}
				alignItems={'center'}
				gap={'8px'}
				borderRadius={'158px'}
				backgroundColor={isActive ? colors['gray']['300'] : colors['gray']['200']}
				_hover={isActive ? { cursor: 'pointer' } : { backgroundColor: colors['white'], cursor: 'pointer' }}
				onClick={onClick}
				{...buildIntercomAttributes({
					area: dataIntercomArea,
					type: dataIntercomType,
					target: dataIntercomTarget,
				})}
				data-testid={testId}
			>
				<Flex
					width={'16px'}
					height={'16px'}
					padding={'1px'}
					justifyContent={'center'}
					alignItems={'center'}
					color={colors['gray']['1000']}
				>
					<Icon />
				</Flex>
				<Typography variant={'DesktopH8Regular'} color={colors['gray']['1000']}>
					{title}
				</Typography>
			</Flex>
		</Flex>
	);
}
