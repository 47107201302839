import Popover from '@components/Popover';
import { ReactNode, useCallback, useState } from 'react';
import { SearchDropdown } from '../../../SearchDropdown';
import { SearchDropdownProps } from '../../../SearchDropdown/SearchDropdown';
import { SearchDropdownBottomButton } from '../../../SearchDropdown/SearchDropdownBottomButton';
import { EditableValueContainer } from './EditableValueContainer';
import { StringValueDisplay } from './StringValueDisplay';

export type DropdownStringEditorProps = {
	displayValue: string;
	values: (string | null)[];
	onApply: (values: (string | null)[]) => void;
	onEdit?: VoidFunction;
	onClose?: VoidFunction;
	tooltip?: string;
	isEnabled?: boolean;
	applyButtonLabel?: string;
	prefixComponent?: ReactNode;
	defaultIsEditing?: boolean;
	eventName?: string;
	eventMetaData?: object;
	isEditing: boolean;
	onOpenEditor: VoidFunction;
	onCloseEditor: VoidFunction;
	isIntercomDetectable?: boolean;
} & Omit<SearchDropdownProps, 'setSelectedValues' | 'selectedValues'>;

export function DropdownStringEditor({
	displayValue,
	values,
	onEdit,
	onApply,
	onClose,
	tooltip,
	isEnabled = true,
	applyButtonLabel = 'Apply',
	prefixComponent,
	defaultIsEditing,
	eventName,
	eventMetaData,
	isEditing,
	onOpenEditor,
	onCloseEditor,
	isIntercomDetectable = false,
	...dropdownProps
}: DropdownStringEditorProps) {
	const [activeValues, setActiveValues] = useState<(string | null)[] | null>(defaultIsEditing ? values : null);

	const onStartEditing = useCallback(() => {
		onEdit?.();
		onOpenEditor();
	}, [onEdit, onOpenEditor]);

	const onDoneEditing = useCallback(() => {
		onCloseEditor();
		setActiveValues(null);
		onClose?.();
	}, [onClose, onCloseEditor]);

	const onApplySelection = useCallback(() => {
		onCloseEditor();
		if (!activeValues) return;
		onApply(activeValues);
		setActiveValues(null);
	}, [onApply, onCloseEditor, activeValues]);

	const onSetSelectedValues = useCallback((values: (string | null)[]) => {
		setActiveValues(values);
	}, []);

	return (
		<EditableValueContainer prefixComponent={prefixComponent}>
			<Popover
				autoFocus={true}
				isOpen={isEditing}
				placement={'bottom-start'}
				onClose={onDoneEditing}
				isWithPortal={false}
				popoverContentProps={{
					borderRadius: '8px',
				}}
				triggerElement={
					<StringValueDisplay
						tooltip={tooltip}
						value={displayValue}
						hint={'Choose values'}
						onStartEditing={onStartEditing}
						isEditing={isEditing}
						isEnabled={isEnabled}
						isIntercomDetectable={isIntercomDetectable}
					/>
				}
			>
				<SearchDropdown
					{...dropdownProps}
					setSelectedValues={onSetSelectedValues}
					selectedValues={activeValues ?? values}
				>
					<SearchDropdownBottomButton
						onClick={onApplySelection}
						eventName={eventName}
						eventMetaData={eventMetaData}
						isDisabled={!activeValues?.length}
					>
						{applyButtonLabel}
					</SearchDropdownBottomButton>
				</SearchDropdown>
			</Popover>
		</EditableValueContainer>
	);
}
