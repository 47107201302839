import { ReactElement, ReactNode } from 'react';
import Box from 'src/common/components/Box';
import Flex from 'src/common/components/Flex';
import Typography from 'src/common/components/Typography';
import Button from 'src/common/components/Button';
import { AddSmall10 } from 'src/common/components/Icons';

export interface SecondaryButtonProps {
	label: string;
	onClick: VoidFunction;
	icon: ReactElement;
	isDisabled?: boolean;
}

export interface ListHeaderProps {
	icon: ReactNode;
	description: string;
	buttonLabel?: string;
	secondaryButton?: SecondaryButtonProps;
	onClick?: VoidFunction;
}

export default function NoResults({ icon, description, buttonLabel, secondaryButton, onClick }: ListHeaderProps) {
	return (
		<Flex
			marginTop={'130px'}
			textAlign={'center'}
			justifyContent={'center'}
			flexDirection={'column'}
			alignItems={'center'}
		>
			{icon}
			<Box gap="8px">
				<Typography maxWidth={'280px'} variant="Paragraph14R" color={'natural.800'}>
					{description}
				</Typography>
			</Box>
			{buttonLabel && (
				<Button
					leftIcon={<AddSmall10 />}
					marginTop={'20px'}
					variant="solid"
					onClick={onClick}
					size="small"
					colorScheme="blue"
					width={'80%'}
				>
					{buttonLabel}
				</Button>
			)}
			{secondaryButton && (
				<Button
					marginTop={'10px'}
					variant="ghost"
					onClick={secondaryButton.onClick}
					leftIcon={secondaryButton.icon}
					isDisabled={secondaryButton.isDisabled}
					size="small"
					width={'80%'}
				>
					{secondaryButton.label}
				</Button>
			)}
		</Flex>
	);
}
