import { TABLE } from 'src/common/components/TextEditor/nodes/table';

import { Transformer, TRANSFORMERS } from '@lexical/markdown';
import { EOL_SLASH } from 'src/common/components/TextEditor/nodes/eol';
import { BlockType } from '@components/TextEditor/types';

export const LowPriority = 1;

export const supportedBlockTypes = new Set(['paragraph', 'h1', 'h2', 'h3']);

export const blockTypeToBlockName: Partial<Record<BlockType, string>> = {
	paragraph: 'Normal',
	h1: 'Big heading',
	h2: 'Medium heading',
	h3: 'Small heading',
};

export const markdownTransformers: Array<Transformer> = [EOL_SLASH, TABLE, ...TRANSFORMERS];
