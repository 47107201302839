import { YAxisOptions } from 'highcharts';
import omit from 'lodash/omit';
import {
	CalculateMetricCoreReaderQuery,
	ChartSeries as ChartSeriesGraphQL,
	SeriesDataPoint as SeriesDataPointQL,
} from 'src/generated/graphql';
import { nameMainSeries } from 'src/lib/metricRules/DerivedStateCalculators';

import { MetricPeriod } from '@sightfull/period-ranges';
import { percentageFormatter } from 'src/lib/metricRules/DerivedStateCalculators/CoreReader/calcCoreFormatting';
import { xAxisFormatter } from 'src/lib/metricRules/utils';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { CoreMetricUnit } from 'src/types/metric';
import {
	ChartOptions,
	ChartSeries,
	ChartType,
	ID_PREFIX_SERIES_TIME_SPAN_PRIMARY,
	ID_PREFIX_SERIES_TIME_SPAN_SECONDARY,
	SeriesCustomField,
	SeriesDataPoint,
	SeriesType,
} from '../components/Chart/types';
import pipe from 'lodash/fp/pipe';
import filter from 'lodash/fp/filter';
import startsWith from 'lodash/fp/startsWith';
import get from 'lodash/fp/get';
import overEvery from 'lodash/fp/overEvery';
import eq from 'lodash/fp/eq';
import negate from 'lodash/fp/negate';
import identity from 'lodash/fp/identity';
import matches from 'lodash/fp/matches';
import orderBy from 'lodash/fp/orderBy';
import isNumber from 'lodash/fp/isNumber';

const LETTERS_AND_NUMBERS = 36;

type SorterType = (series: ChartSeries[]) => ChartSeries[];

const getSorter = function (sortOrder: MetricDerivedState['sortOrder'], orderedComponents: string[] = []): SorterType {
	return (
		{
			Default: identity as SorterType,
			Manual: ((series) =>
				orderedComponents?.flatMap((componentName) => series.filter(matches({ name: componentName })))) as SorterType,
			'Value large to small': orderBy(['data.0.y'], ['asc']) as SorterType,
			'Value small to large': orderBy(['data.0.y'], ['desc']) as SorterType,
			'Label A to Z': orderBy(['name'], ['asc']) as SorterType,
			'Label Z to A': orderBy(['name'], ['desc']) as SorterType,
		}[sortOrder.selectedValue] ?? (identity as SorterType)
	);
};

const generateRandomId = () =>
	Math.random()
		.toString(LETTERS_AND_NUMBERS)
		.match(/[a-z0-9]+$/)?.[0] ?? '0';

const generateSecondarySeriesFactory = function (
	isPopEnabled: boolean,
	displayUnits: MetricDerivedState['displayUnits']
) {
	return (series: ChartSeriesGraphQL) => {
		const seriesLocalId = generateRandomId();

		return [
			series,
			isPopEnabled && {
				...series,
				data: series.data.map(({ secondaryY, ...seriesData }) => ({
					...seriesData,
					y: secondaryY ?? 0,
				})),
			},
		]
			.filter((series) => !!series)
			.map((series, index) => ({
				...series,
				id: [
					index === 0 ? ID_PREFIX_SERIES_TIME_SPAN_PRIMARY : ID_PREFIX_SERIES_TIME_SPAN_SECONDARY,
					seriesLocalId,
				].join('_'),
			}))
			.map((currentSeries) => buildChartSeries(currentSeries as Partial<ChartSeries>, displayUnits));
	};
};

export function buildCoreReaderChartOptions(
	calcMetricResult: NonNullable<CalculateMetricCoreReaderQuery['calcMetricV2']['chartOptions']>,
	{
		metricDisplayName,
		displayUnits,
		sortOrder,
	}: Pick<MetricDerivedState, 'metricDisplayName' | 'displayUnits' | 'sortOrder'>,
	orderedComponents?: string[]
): ChartOptions {
	const isPopEnabled = calcMetricResult.series.some(({ data }) => data.some(pipe(get('secondaryY'), isNumber)));

	const chartSeries = calcMetricResult.series?.flatMap(generateSecondarySeriesFactory(isPopEnabled, displayUnits));
	const withNamedMain = nameMainSeries(metricDisplayName, chartSeries);
	const setVisibility = (series: ChartSeries): ChartSeries => ({
		...series,
		visible: true,
	});
	const withVisibility = withNamedMain.map(setVisibility);

	const getOrderedSeries = (series: ChartSeries[]): ChartSeries[] => {
		const componentsSeries = filter(
			overEvery<ChartSeries>([
				pipe(get('id'), startsWith(ID_PREFIX_SERIES_TIME_SPAN_PRIMARY)),
				pipe(get('custom.seriesType'), negate(eq('main'))),
			]),
			series
		);
		const mainSeries = series.filter((s) => s.custom.seriesType == 'main');

		const sorter = getSorter(sortOrder, orderedComponents);

		return (sorter(componentsSeries) ?? [])
			.filter((x) => !!x)
			.flatMap(({ id }) => {
				const absId = id?.match(/([^_]+)$/)?.[0];
				return [ID_PREFIX_SERIES_TIME_SPAN_PRIMARY, ID_PREFIX_SERIES_TIME_SPAN_SECONDARY]
					.map((prefix) => series.find(pipe(get('id'), eq([prefix, absId].join('_')))))
					.filter((seriesItem) => !!seriesItem);
			})
			.concat(mainSeries);
	};

	const seriesWithSortOrder = getOrderedSeries(withVisibility).map((s, index) => {
		s.custom.seriesOrder = index;
		return s;
	});

	return {
		series: seriesWithSortOrder,
		xAxis: {
			values: calcMetricResult.xAxis.map((x) => MetricPeriod.fromIdString(x.id, fiscalYearOffset())) ?? [],
			formatter: xAxisFormatter,
			plotBands: [],
		},
		yAxis: buildYAxis(seriesWithSortOrder),
	};
}

function buildChartSeries(
	{ name, custom: customSource, ...series }: Partial<ChartSeries & ChartSeriesGraphQL>,
	displayUnits: MetricDerivedState['displayUnits']
): ChartSeries {
	const chartType = series.chartType as ChartType;
	const isTargetAttainment = chartType == 'attainment';
	const data: SeriesDataPoint[] | undefined = series.data?.map(
		({ name, y, custom, secondaryName, secondaryGrowth, secondaryDelta }): SeriesDataPoint & SeriesDataPointQL => {
			const customData = Object.entries(displayUnits).reduce((acc, [unit, { value }]) => {
				if (!value) return omit(acc, unit);
				return acc;
			}, custom ?? {}) as SeriesCustomField;
			const formatLabelToPercentage = isTargetAttainment && y != undefined;
			return {
				name: MetricPeriod.fromIdString(name, fiscalYearOffset()).pretty,
				secondaryName:
					(secondaryName && MetricPeriod.fromIdString(secondaryName, fiscalYearOffset()).pretty) ?? undefined,
				growth: secondaryGrowth,
				delta: secondaryDelta,
				y: y ?? undefined,
				custom: {
					label: (formatLabelToPercentage ? percentageFormatter(y, 2) : y ?? undefined)?.toString(), // TODO: restructure the core reader response to return this as custom too, when doing this take target attainment into consideration
					...customData,
				},
			};
		}
	);

	const custom: SeriesCustomField['custom'] = {
		cleanName: customSource?.cleanName ?? undefined,
		rawName: customSource?.rawName ?? undefined,
		seriesType: customSource?.seriesType as SeriesType,
		unit: isTargetAttainment ? 'percentage' : (customSource?.unit as CoreMetricUnit) ?? undefined,
		value: customSource?.value ?? undefined,
		appliedParameters: customSource?.appliedParameters ?? undefined,
		parametersOverride: customSource?.parametersOverride ?? undefined,
	};

	return {
		id: series.id,
		yAxis: series.yAxis ?? undefined,
		chartType,
		name: name ?? '',
		data: data ?? [],
		custom,
	};
}

function buildYAxis(chartSeries: ChartSeries[]): YAxisOptions[] {
	const yAxisIds: string[] = [
		...new Set(chartSeries.map((series) => series.yAxis).filter((yAxis) => yAxis != undefined)),
	];

	return yAxisIds.map((yAxisId) => ({
		visible: false,
		id: yAxisId,
	}));
}
