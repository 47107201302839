import Button from '@components/Button';
import { AffectedMetricsButtonProps } from '@components/AffectedMetricsButton/types';
import { useModal } from '@hooks/ui/useModal';
import { AffectedMetricsModal } from '@components/AffectedMetricsButton/AffectedMetricsModal';

export const AffectedMetricsButton = ({
	size,
	colorScheme,
	variant,
	label,
	metricNames,
}: AffectedMetricsButtonProps) => {
	const {
		isOpen: isAffectedMetricModalOpen,
		onOpen: onAffectedMetricModalOpen,
		onClose: onAffectedMetricModalClose,
	} = useModal();

	return (
		<>
			<Button size={size} variant={variant} colorScheme={colorScheme} onClick={onAffectedMetricModalOpen}>
				{label}
			</Button>
			<AffectedMetricsModal
				isOpen={isAffectedMetricModalOpen}
				onClose={onAffectedMetricModalClose}
				metricNames={metricNames}
			/>
		</>
	);
};
