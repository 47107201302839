import { WidgetLoader } from '@pages/DashboardPage/components/Widget/WidgetLoader';
import { usePeriodRangeMetadata } from 'src/pages/DashboardPage/hooks/usePeriodRangeMetadata';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import classes from '../../../GridLayout/GridLayout.module.scss';
import { WidgetHeader } from '../../WidgetHeader/WidgetHeader';

export type SignalWidgetHeaderProps = {
	isClickable?: boolean;
	isEditMode: boolean;
	title?: string;
	metricTitle: string;
	isLoading: boolean;
	menu?: React.ReactNode;
	signalDescription?: string;
	onClick?: VoidFunction;
	closeOnBlur?: boolean;
	isWidgetMenuOpenControlled?: boolean;
};
export function SignalWidgetHeader({
	isClickable = true,
	isEditMode,
	title,
	metricTitle,
	isLoading,
	menu,
	signalDescription,
	onClick,
	closeOnBlur = true,
	isWidgetMenuOpenControlled,
}: SignalWidgetHeaderProps) {
	const {
		filters,
		breakdowns,
		parameters,
		periodRange,
		metricDisplayName,
		isLoading: isMetricLoading,
		errorMessage,
	} = useMetricDerivedState();
	const filtersWithValues = filters?.filter((filter) => filter.values?.length);

	const metadata = usePeriodRangeMetadata(periodRange);

	return (
		<WidgetHeader
			isClickable={isClickable}
			onClickHandler={onClick}
			description={signalDescription}
			isEditMode={isEditMode}
			title={title || metricDisplayName}
			titleTooltip={metricTitle}
			filters={filtersWithValues}
			breakdowns={breakdowns}
			parameters={parameters}
			metadata={isMetricLoading ? '' : metadata}
			hasBottomPadding={false}
			actionItems={isLoading ? [<WidgetLoader key="text-signal-loading-indicator" />] : undefined}
			dragHandleClassName={classes.draggableHandle}
			menu={isLoading ? undefined : menu}
			errorMessage={errorMessage}
			closeOnBlur={closeOnBlur}
			isWidgetMenuOpenControlled={isWidgetMenuOpenControlled}
		/>
	);
}
