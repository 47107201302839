import { PeriodUnit } from 'src/generated/graphql';
import {
	COMPARE_TO_UNIT_SELECTOR_CUSTOM,
	COMPARE_TO_UNIT_SELECTOR_MONTH,
	COMPARE_TO_UNIT_SELECTOR_QUARTER,
	COMPARE_TO_UNIT_SELECTOR_WEEK,
	COMPARE_TO_UNIT_SELECTOR_YEAR,
} from '@components/MetricView/CompareToSelector';

export const PeriodUnitWeight = {
	[PeriodUnit.Day]: 1,
	[PeriodUnit.Week]: 2,
	[PeriodUnit.Fweek]: 2,
	[PeriodUnit.Month]: 3,
	[PeriodUnit.Quarter]: 4,
	[PeriodUnit.Fquarter]: 4,
	cquarter: 4,
	[PeriodUnit.Fyear]: 5,
	[PeriodUnit.Custom]: 0,
};

export const PeriodUnitToCompareToComponent = {
	[PeriodUnit.Day]: COMPARE_TO_UNIT_SELECTOR_CUSTOM,
	[PeriodUnit.Week]: COMPARE_TO_UNIT_SELECTOR_WEEK,
	[PeriodUnit.Month]: COMPARE_TO_UNIT_SELECTOR_MONTH,
	[PeriodUnit.Quarter]: COMPARE_TO_UNIT_SELECTOR_QUARTER,
	[PeriodUnit.Fyear]: COMPARE_TO_UNIT_SELECTOR_YEAR,
	[PeriodUnit.Custom]: COMPARE_TO_UNIT_SELECTOR_CUSTOM,
	[PeriodUnit.Fquarter]: COMPARE_TO_UNIT_SELECTOR_QUARTER,
	[PeriodUnit.Fweek]: COMPARE_TO_UNIT_SELECTOR_WEEK,
	cquarter: COMPARE_TO_UNIT_SELECTOR_CUSTOM,
};

export const PeriodCaptions = {
	[PeriodUnit.Month]: 'month',
	[PeriodUnit.Week]: 'week',
	[PeriodUnit.Quarter]: 'quarter',
	[PeriodUnit.Fquarter]: 'quarter',
	[PeriodUnit.Fyear]: 'year',
	[PeriodUnit.Custom]: 'custom',
};

export const CompareToComponentToPeriodUnit = {
	[COMPARE_TO_UNIT_SELECTOR_WEEK]: PeriodUnit.Week,
	[COMPARE_TO_UNIT_SELECTOR_MONTH]: PeriodUnit.Month,
	[COMPARE_TO_UNIT_SELECTOR_QUARTER]: PeriodUnit.Quarter,
	[COMPARE_TO_UNIT_SELECTOR_YEAR]: PeriodUnit.Fyear,
	[COMPARE_TO_UNIT_SELECTOR_CUSTOM]: PeriodUnit.Custom,
};
