import Box from '@components/Box';
import { useEffect, useMemo, useState } from 'react';
import Tooltip from 'src/common/components/Tooltip';
import { AddDrillDownCard } from 'src/common/components/LeftExpandCollapsePanel/Drilldown/AddDrillDownCard';
import { FILTER_MODEL_TYPE } from 'src/pages/MetricPage/components/FiltersAndBreakdown/consts';
import { FilterV2 } from 'src/pages/MetricPage/utils/state.types';
import { FilterDrillDownCard } from './FilterDrillDownCard';
import { isFilterComplete } from './useFilter';

export function FilterDropdownPanel({
	filters,
	onModalOpen,
	onCloseDropdown,
	editFilter,
	removeFilter,
	isLoading = false,
	eventMetaData,
}: {
	filters: FilterV2[];
	onModalOpen: (filterIndex?: number) => void;
	onCloseDropdown: VoidFunction;
	editFilter: (newFilter: FilterV2, index: number) => void;
	removeFilter: (filterIndex: number) => void;
	isLoading?: boolean;
	eventMetaData: object;
}) {
	const [hasIncompleteFilters, setHasIncompleteFilters] = useState(false);

	useEffect(() => setHasIncompleteFilters(!filters.every(isFilterComplete)), [filters]);

	const filterCards = useMemo(
		() =>
			filters.map((filter, filterIndex) => (
				<FilterDrillDownCard
					isLoading={isLoading}
					filterIndex={filterIndex}
					onModalOpen={() => onModalOpen(filterIndex)}
					editFilter={(newFilter: FilterV2) => editFilter(newFilter, filterIndex)}
					key={filter.key + filter.values.join() + filter.operator + filter.baseEntity}
					entityName={filter.baseEntity || ''}
					filter={filter}
					onRemove={() => removeFilter(filterIndex)}
					isEditorAutoOpened={filter.values.length === 0}
					eventMetaData={eventMetaData}
					onFilterCompletenessChange={(isComplete) => {
						setHasIncompleteFilters(!isComplete);
					}}
				/>
			)),
		[filters, eventMetaData, isLoading, removeFilter, editFilter, onModalOpen]
	);

	return (
		<>
			<Box whiteSpace="nowrap" height={'min-content'} opacity={'1'}>
				{filterCards}
				<Tooltip label={hasIncompleteFilters ? 'Select a value to add another filter.' : undefined} size={'sm'}>
					<Box width={'100%'}>
						<AddDrillDownCard
							isDisabled={hasIncompleteFilters || isLoading}
							type={FILTER_MODEL_TYPE}
							onClick={() => {
								onModalOpen();
								onCloseDropdown();
							}}
							dataIntercomTarget={'Add filter'}
						/>
					</Box>
				</Tooltip>
			</Box>
		</>
	);
}
