import { useCallback, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import Box from '@components/Box';
import useUser from 'src/common/hooks/stores/useUser';
import { DashboardFolder } from './index';
import _ from 'lodash';
import { SkeletonFolder } from './Skeletons/SkeletonFolder';
import { useDashboardFolders } from 'src/common/hooks/useDashboardFolders';
import { useReportEvent } from 'src/services/analytics';
import './DraggableFolders.scss';
import { ListAllFolders } from 'src/queries/folders';
import { useHasuraSubscriptionWithCache } from 'src/common/hooks/fetching/useSubscription';
import { DashboardData, FolderData } from './types';
import { SpaceListItem } from '../SpacesNavigationDrawer/SpacesListDrawer/SpaceListItem';
import { TestIDs } from 'src/common/types/test-ids';
import NoResults from 'src/pages/OntologyPage/components/BuilderPanel/NoResults';
import { NoSearchResults } from '../../Icons';
import { checkForMatch } from 'src/common/utils/format';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

export const DraggableFolders = ({ searchValue }: { searchValue: string }) => {
	const [{ id: my_id }] = useUser();
	const { data: foldersList, loading: isFoldersLoading } = useHasuraSubscriptionWithCache(ListAllFolders, {
		variables: { my_id: my_id },
	});

	const { reorderDashboardFolder, reorderFolderDashboard } = useDashboardFolders();

	const { reportEvent } = useReportEvent({ feature: 'Sidebar' });

	const sortedFolders: FolderData[] = useMemo(
		() =>
			_.sortBy(foldersList?.folders, 'order')
				.map((folder) => ({
					id: folder.id,
					folderName: folder.name,
					emoji: folder.emoji,
					dashboards: folder.dashboards,
					order: folder.order,
				}))
				.filter((folder) => checkForMatch({ subject: folder.folderName, value: searchValue })),
		[foldersList?.folders, searchValue]
	);

	const sortedDashboardNodes = useMemo(
		() => sortedFolders?.map((folder) => folder.dashboards)?.map((el) => _.sortBy(el, 'order')),
		[sortedFolders]
	);

	const filteredDashboards = useMemo(
		() =>
			_.sortBy(
				foldersList?.folders.flatMap((folder: FolderData) => folder.dashboards || []),
				(dashboard) => dashboard.name?.toLowerCase() || ''
			).filter((dashboard: DashboardData[number]) => checkForMatch({ subject: dashboard?.name, value: searchValue })),
		[foldersList?.folders, searchValue]
	);

	const getOrder = (items: FolderData[] | DashboardData, itemId: string) => {
		const index = items.findIndex((item) => item.id === itemId);
		if (index === -1) return;
		if (index === 0) {
			const order = items[1].order;
			if (!order) return;
			return order / 2;
		}
		if (index === items.length - 1) return Date.now() * 1000;
		const prevOrder = items[index - 1].order;
		const nextOrder = items[index + 1].order;
		if (!prevOrder || !nextOrder) return;
		return (prevOrder + nextOrder) / 2;
	};

	const reorderFolders = useCallback(
		async (items: FolderData[], folderId: string) => {
			reportEvent({ event: 'sidebar-reorder-item', metaData: { itemType: 'folder' } });
			const newOrder = getOrder(items, folderId);
			if (newOrder) await reorderDashboardFolder(folderId, newOrder);
		},
		[reorderDashboardFolder, reportEvent]
	);

	const reorderDashboards = useCallback(
		async (items: DashboardData, dashboarId: string, folderId?: string) => {
			if (!items || !dashboarId || !folderId) return;
			reportEvent({ event: 'sidebar-reorder-item', metaData: { itemType: 'dashboard' } });
			const newOrder = getOrder(items, dashboarId);
			if (newOrder) await reorderFolderDashboard(dashboarId, folderId, newOrder);
		},
		[reorderFolderDashboard, reportEvent]
	);

	const onDragEnd = useCallback(
		async (result: DropResult) => {
			if (!result.destination) return;
			if (result.type === 'folders') {
				const items = [...sortedFolders];
				const [reorderedItem] = items.splice(result.source.index, 1);
				items.splice(result.destination.index, 0, reorderedItem);
				await reorderFolders(items, result.draggableId);
			} else if (result.type.includes('dashboards-subItem')) {
				const foundDashboardNode = sortedDashboardNodes?.find((dashboardNode) =>
					dashboardNode?.some((dashboard) => dashboard.id === result.draggableId)
				);
				const items = foundDashboardNode ? [...foundDashboardNode] : [];
				const [reorderedItem] = items.splice(result.source.index, 1);
				items.splice(result.destination.index, 0, reorderedItem);
				const foundDashboardNodeIndex = sortedDashboardNodes?.findIndex((dashboardNode) =>
					dashboardNode?.some((dashboard) => dashboard.id === result.draggableId)
				);
				if (foundDashboardNodeIndex !== undefined && foundDashboardNodeIndex !== -1) {
					const updatedDashboardNodes = [...sortedDashboardNodes];
					updatedDashboardNodes[foundDashboardNodeIndex] = items;
					const folder = sortedFolders.find((folder) =>
						folder?.dashboards?.some((dashboard) => dashboard.id === result.draggableId)
					);
					await reorderDashboards(items, result.draggableId, folder?.id);
				}
			}
		},
		[sortedDashboardNodes, sortedFolders, reorderDashboards, reorderFolders]
	);

	const isSearchActive = useMemo(() => !!searchValue, [searchValue]);

	const droppableContent = (
		<DragDropContext autoScrollerOptions={{ disabled: true }} onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable-folders" type="folders">
				{(provided) => (
					<Box
						{...buildIntercomAttributes({
							area: 'dashboards',
							type: 'panel',
							target: 'dashboards-panel',
						})}
						ref={provided.innerRef}
					>
						{sortedFolders.map((folder, index) => (
							<Draggable isDragDisabled={isSearchActive} key={folder.id} draggableId={`${folder.id}`} index={index}>
								{(provided, snapshot) => (
									<Box {...provided.dragHandleProps}>
										<Box ref={provided.innerRef} {...provided.draggableProps}>
											<DashboardFolder
												isDragDisabled={isSearchActive}
												isDragging={snapshot.isDragging}
												folderIndex={index}
												dashboards={sortedDashboardNodes[index]}
												folder={folder}
											/>
										</Box>
									</Box>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</Box>
				)}
			</Droppable>
		</DragDropContext>
	);

	const searchedDashboards = filteredDashboards.map((dashboard: DashboardData[number]) => (
		<SpaceListItem
			padding="8px 6px 8px 36px"
			key={dashboard.id}
			currentDrawerView={'dashboard-folders'}
			collection={{ ...dashboard, collection_type: 'dashboard' }}
			index={dashboard.id}
			testId={TestIDs.SIDEBAR_ITEM('dashboard')}
		/>
	));

	if (isFoldersLoading) return <SkeletonFolder />;

	if (sortedFolders.length === 0 && filteredDashboards.length === 0 && isSearchActive)
		return (
			<NoResults
				icon={
					<Box marginBottom={'20px'}>
						<NoSearchResults />
					</Box>
				}
				description="We didn’t find any results, try adjusting your search to find what you're looking for."
			/>
		);

	return (
		<>
			{droppableContent}
			{isSearchActive && searchedDashboards}
		</>
	);
};
