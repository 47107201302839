import { Droppable, Draggable, DroppableProvided } from '@hello-pangea/dnd';
import Flex from '@components/Flex';
import Box from '@components/Box';
import Popover from '@components/Popover';
import { UpsertFolderModal } from './UpsertFolderModal';
import Typography from '../../Typography';
import Button from '@components/Button';
import {
	DashboardNew18,
	DeleteTrash16,
	EditFull16,
	More16,
	NumberStepperDown6,
	NumberStepperRight6,
} from '../../Icons';
import { AccessController } from '../../AccessController';
import { Permissions } from 'src/types/environment';
import Divider from '../../Divider';
import ListItem from '../../ListItem';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import { useEffect, useMemo, useRef, useState } from 'react';
import Tooltip from '../../Tooltip';
import { PopoverContent, useDisclosure } from '@chakra-ui/react';
import shadows from 'src/style/shadows';
import colors from 'src/style/colors';
import { useModal } from 'src/common/hooks/ui/useModal';
import { DeleteFolderModal } from '.';
import { CollectionUpsertModal } from 'src/layout/Menu/NavigationDrawer/CollectionUpsertModal';
import { useReportEvent } from 'src/services/analytics';
import { SpaceListItem } from '../SpacesNavigationDrawer/SpacesListDrawer/SpaceListItem';
import { TestIDs } from 'src/common/types/test-ids';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import useNavigation from 'src/services/useNavigation';
import classes from './DashboardFolder.module.scss';
import { DashboardPath } from 'src/pages/DashboardPage';
import { DashboardData, FolderData } from './types';
import { capitalizeIfStartsFromLowerCase } from 'src/normalize';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

const {
	folderWrapper,
	isActive,
	dashboardsListWrapper,
	isExpanded,
	isDraggingFolder,
	actionsWrapper,
	isButtonActive,
	arrowElement,
	emojiElement,
} = classes;

export const DashboardFolder = ({
	folder,
	dashboards,
	folderIndex,
	isDragging,
	isDragDisabled = true,
}: {
	folder: FolderData;
	dashboards: DashboardData | [];
	folderIndex: number;
	isDragging?: boolean;
	isDragDisabled?: boolean;
}) => {
	const titleRef = useRef(null);
	const isOverflowingTitle = useOnOverflow(titleRef, [folder.folderName], undefined, false);
	const { reportEvent } = useReportEvent({ feature: 'Sidebar' });
	const { navigate } = useNavigation();
	const { isOpen, onToggle, onClose } = useDisclosure();
	const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useModal();
	const {
		isOpen: isCreateFolderModalOpen,
		onOpen: onCreateFolderModalOpen,
		onClose: onCreateFolderModalClose,
	} = useModal();
	const {
		isOpen: isCreateEditCollectionModalOpen,
		onOpen: onCreateEditCollectionModalOpen,
		onClose: onCreateEditCollectionModalClose,
	} = useModal();

	const useIsFolderActive = () => {
		const location = useLocation();
		const isFolderActive = useMemo(() => {
			return (
				(folder.id && location.pathname.includes(folder.id)) ||
				folder?.dashboards?.some((dashboard) => location.pathname.includes(dashboard.id)) ||
				(location.pathname === `/${DashboardPath}` && folderIndex === 0)
			);
		}, [location.pathname]);
		return isFolderActive;
	};

	const isFolderActive = useIsFolderActive();

	const [isFolderOpen, setIsFolderOpen] = useState(isFolderActive && !isDragDisabled);

	useEffect(() => setIsFolderOpen(isFolderActive && !isDragDisabled), [isDragDisabled, isFolderActive]);

	const dashboardsList = useMemo(
		() => (
			<Droppable droppableId={`droppable-dashboard-${folder.id}`} type={`dashboards-subItem-${folder.id}`}>
				{(provided: DroppableProvided) => (
					<Box ref={provided.innerRef}>
						{dashboards.map((dashboard, index) => (
							<Draggable
								isDragDisabled={isDragDisabled}
								key={dashboard.id}
								draggableId={`${dashboard.id}`}
								index={index}
							>
								{(provided, snapshot) => (
									<Box {...provided.dragHandleProps}>
										<Box {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
											<SpaceListItem
												isDragging={snapshot.isDragging}
												padding="8px 6px 8px 36px"
												key={dashboard.id}
												currentDrawerView={'dashboard-folders'}
												collection={{ ...dashboard, folder_id: folder.id, collection_type: 'dashboard' }}
												index={index + folderIndex}
												testId={TestIDs.SIDEBAR_ITEM('dashboard')}
											/>
										</Box>
									</Box>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</Box>
				)}
			</Droppable>
		),
		[folder, dashboards, folderIndex, isDragDisabled]
	);

	if (!folder.id) return;

	const onCreateDashboard = () => {
		reportEvent({ event: 'sidebar-item-clicked', metaData: { itemType: 'dashboard', name: 'Empty dashboard' } });
		navigate({ path: `${DashboardPath}/folder/${folder.id}` });
	};

	const handleDelete = () => {
		onDeleteModalOpen();
	};

	const handleEdit = () => {
		reportEvent({ event: 'sidebar-edit-item', metaData: { itemType: 'folder', name: folder.folderName } });
		onCreateFolderModalOpen();
	};

	const hasDashboards = folder?.dashboards && folder?.dashboards?.length > 0;

	const handleCreateDashboard = () => {
		reportEvent({ event: 'sidebar-create-new-dashboard-in-folder', metaData: { name: folder.folderName } });
		onCreateEditCollectionModalOpen();
	};

	const arrowIcon = isFolderOpen ? <NumberStepperDown6 /> : <NumberStepperRight6 />;

	const createDashboardPlaceholder = (
		<Flex
			_hover={{
				backgroundColor: 'collectionHoverColor',
			}}
			backgroundColor={isFolderActive ? 'collectionActiveColor' : 'transparent'}
			onClick={onCreateDashboard}
			padding={'8px 8px 8px 36px'}
			borderRadius={'6px'}
		>
			<Typography color={'natural.500'} variant={'DesktopH8Regular'}>
				Empty dashboard
			</Typography>
		</Flex>
	);

	const listWrapper = (
		<Box
			className={classNames(dashboardsListWrapper, {
				[isExpanded]: isFolderOpen,
			})}
		>
			{hasDashboards ? dashboardsList : createDashboardPlaceholder}
		</Box>
	);

	return (
		<Box>
			<Flex
				{...(folderIndex === 0 &&
					buildIntercomAttributes({
						area: 'dashboards',
						type: 'panel',
						target: 'dashboards-first-folder',
					}))}
				className={classNames(folderWrapper, {
					[isActive]: !isFolderOpen && isFolderActive,
					[isDraggingFolder]: isDragging,
					[isButtonActive]: isOpen,
				})}
				onClick={() => {
					const newState = !isFolderOpen;
					reportEvent({
						event: 'sidebar-item-clicked',
						metaData: { itemType: 'folder', name: folder.folderName, newState: newState ? 'expanded' : 'collapsed' },
					});
					setIsFolderOpen(newState);
				}}
				justifyContent={'space-between'}
				alignItems={'center'}
				width={'100%'}
				borderRadius={'6px'}
				transition={'0.2s'}
				_hover={{
					backgroundColor: 'collectionHoverColor',
				}}
				padding={'8px'}
			>
				<Flex alignItems={'center'} gap={'8px'}>
					<Flex width={'20px'} height={'20px'} alignItems={'center'}>
						<Box className={arrowElement} fontSize={'20px'} lineHeight={'20px'} color={colors.gray[1000]}>
							{arrowIcon}
						</Box>
						<Box className={emojiElement} fontSize={'18px'} lineHeight={'18px'}>
							{folder.emoji}
						</Box>
					</Flex>
					<Box>
						<Tooltip size="md" variant="fluid" label={isOverflowingTitle && folder.folderName}>
							<Typography wordBreak={'break-all'} noOfLines={1} variant={'DesktopH8Regular'} color={'natural.1000'}>
								<Box ref={titleRef}>{capitalizeIfStartsFromLowerCase(folder.folderName)}</Box>
							</Typography>
						</Tooltip>
					</Box>
				</Flex>
				<Flex className={actionsWrapper}>
					<Popover
						placement="bottom-start"
						variant="solid"
						isOpen={isOpen}
						onClose={onClose}
						triggerElement={
							<Flex position="relative">
								<Button
									onClick={(e) => {
										e.stopPropagation();
										onToggle();
									}}
									size="xxxs"
									variant="outline"
									colorScheme="gray"
									isActive={isOpen}
									isIconOnly={true}
									color={'natural.800'}
									_hover={{ bgColor: 'collectionButtonHoverColor' }}
									blendMode={'multiply'}
								>
									<More16 />
								</Button>
							</Flex>
						}
						popoverContentProps={{
							boxShadow: shadows['panelShadow'],
							borderRadius: '8px',
							border: `1px solid ${colors.gray[300]}`,
						}}
					>
						<PopoverMenuItems onCreateDashboard={handleCreateDashboard} onEdit={handleEdit} onDelete={handleDelete} />
					</Popover>
				</Flex>
			</Flex>
			{listWrapper}
			<CollectionUpsertModal
				folderId={folder.id}
				isOpen={isCreateEditCollectionModalOpen}
				onClose={onCreateEditCollectionModalClose}
				collectionType={'dashboard'}
			/>
			<UpsertFolderModal folder={folder} isOpen={isCreateFolderModalOpen} onClose={onCreateFolderModalClose} />
			<DeleteFolderModal folder={folder} isOpen={isDeleteModalOpen} onClose={onDeleteModalClose} />
		</Box>
	);
};

const PopoverMenuItems = ({
	onEdit,
	onDelete,
	onCreateDashboard,
}: {
	onEdit: VoidFunction;
	onDelete: VoidFunction;
	onCreateDashboard: VoidFunction;
}) => (
	<>
		<PopoverContent>
			<Flex alignSelf="center" direction={'column'} py="8px" width="224px" borderRadius="4px">
				<AccessController permission={Permissions.createCollection}>
					<ListItem
						color="gray.1000"
						hoverColor={'gray.200'}
						size="sm"
						label="Create a dashboard..."
						prefixIcon={<DashboardNew18 />}
						onClick={onCreateDashboard}
					/>
					<Box px={'12px'}>
						<Divider direction="horizontal" my="8px" />
					</Box>
				</AccessController>
				<AccessController permission={Permissions.updateCollection}>
					<ListItem
						color="gray.1000"
						hoverColor={'gray.200'}
						size="sm"
						label="Edit"
						prefixIcon={<EditFull16 />}
						onClick={onEdit}
					/>
				</AccessController>
				<Box px={'12px'}>
					<Divider direction="horizontal" my="8px" />
				</Box>
				<AccessController permission={Permissions.deleteCollection}>
					<ListItem
						color="gray.1000"
						hoverColor={'gray.200'}
						size="sm"
						label="Delete"
						prefixIcon={<DeleteTrash16 />}
						onClick={onDelete}
					/>
				</AccessController>
			</Flex>
		</PopoverContent>
	</>
);
