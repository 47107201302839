import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { AskAIChatCardsList } from '@components/AskAI/Chat/AskAIChatCardList';
import Flex from '@components/Flex';
import { useAskAIChatMessages } from '@components/AskAI/hooks/useAskAIChatMessages';
import { AskAIInput } from '@components/AskAI/AskAIInput';
import { useAIAgents } from '@components/AskAI/hooks/useAIAgents';
import { AppPanel, EditBox16, SearchSmall12, OmniAIBySightfull } from '@icons/index';
import { useCallback, useMemo } from 'react';
import Typography from 'src/common/components/Typography';
import useUser from '@hooks/stores/useUser';
import colors from 'src/style/colors';
import Page from '@layout/Page';
import { LeftSidePanelV2 } from '@components/LeftSidePanelV2';
import Tooltip from '@components/Tooltip';
import Button from '@components/Button';
import shadows from 'src/style/shadows';
import useTenantConfig from '../../common/hooks/stores/useTenantConfig';
import { AskAIPageSuggestion } from '../../types/tenantConfig';
import { useReportEvent } from '../../services/analytics';

type OnAddPrompt = (prompt?: string) => void;

export default function AskAIPageGuard() {
	const isArrModelPageEnabled = useFeatureFlag('pulse.sightfull2.aiOmniPage.enable');
	const { resetChatMessages } = useAskAIChatMessages();

	if (!isArrModelPageEnabled) return <></>;
	return (
		<Page page="AI Agent">
			<Flex direction={'row'} height={'100%'}>
				<LeftSidePanelV2
					renderItem={(handleCollapsePanelClick, isCollapsed) => (
						<Flex direction={'column'} gap={'20px'} paddingLeft={'8px'} paddingRight={'8px'}>
							<Flex height={'100%'} width={'100%'} mb={'8px'} justifyContent={'flex-end'}>
								<Tooltip size="md" variant="fluid" label={isCollapsed ? 'Expand' : 'Collapse'} placement={'left'}>
									<Button
										size={'small'}
										variant={'outline'}
										colorScheme={'gray'}
										onClick={handleCollapsePanelClick}
										isIconOnly={true}
										_hover={{ bgColor: colors.hoverPanelItemColor }}
									>
										<AppPanel color={isCollapsed ? colors.gray['900'] : colors.gray['1000']} />
									</Button>
								</Tooltip>
							</Flex>
							<Flex direction={'column'} alignItems={'flex-start'} gap={'4px'} alignSelf={'stretch'}>
								<Flex justifyContent={'space-between'} alignItems={'center'} alignSelf={'stretch'}>
									<Typography variant={'DesktopH10Medium'} color={colors.natural[700]}>
										Chats
									</Typography>
									<Flex>
										<Button isIconOnly={true} variant={'outline'} size={'xs'} colorScheme={'gray'} isDisabled={true}>
											<SearchSmall12 />
										</Button>
										<Tooltip size="md" variant="fluid" label={'New chat'}>
											<Button
												variant={'outline'}
												size={'xs'}
												colorScheme={'lightGray'}
												isIconOnly={true}
												onClick={resetChatMessages}
											>
												<EditBox16 />
											</Button>
										</Tooltip>
									</Flex>
								</Flex>
								<Flex direction={'column'} alignItems={'flex-start'} alignSelf={'stretch'}>
									<Flex
										height={'36px'}
										padding={'8px'}
										alignItems={'center'}
										gap={'12px'}
										alignSelf={'stretch'}
										borderRadius={'6px'}
									>
										<Typography variant={'DesktopH8Regular'} color={colors.natural[600]}>
											Coming soon
										</Typography>
									</Flex>
								</Flex>
							</Flex>
						</Flex>
					)}
				/>
				<AskAIPage />
			</Flex>
		</Page>
	);
}

function AskAIPage() {
	const { chatMessages } = useAskAIChatMessages();
	const { addChatMessage } = useAskAIChatMessages();
	const hasMessages = chatMessages.length > 0;

	const onAddPrompt = useCallback(
		(prompt?: string) => {
			if (!prompt) return;
			addChatMessage({ userPrompt: prompt, type: 'omni' });
		},
		[addChatMessage]
	);

	return (
		<Flex
			borderRadius={'8px'}
			boxShadow={shadows.mainCanvas}
			backgroundColor={colors.white}
			direction={'column'}
			alignItems={'center'}
			flex={1}
		>
			<Flex
				padding={'20px 32px'}
				flexDirection={'row'}
				justifyContent={'center'}
				alignItems={'start'}
				alignSelf={'start'}
			>
				<OmniAIBySightfull />
			</Flex>
			{hasMessages ? (
				<AskAIPageWithMessages onAddPrompt={onAddPrompt} />
			) : (
				<AskAIPageEmptyState onAddPrompt={onAddPrompt} />
			)}
		</Flex>
	);
}

function AskAIPageWithMessages({ onAddPrompt }: { onAddPrompt: OnAddPrompt }) {
	return (
		<>
			<Flex
				flex={1}
				overflowY={'auto'}
				overflowX={'hidden'}
				direction={'column'}
				alignItems={'stretch'}
				justifyContent={'start'}
				width={'100%'}
			>
				<Flex alignItems={'center'} flex={1} direction={'column'}>
					<Flex alignItems={'start'} maxWidth={'800px'} width={'100%'} flex={1}>
						<AskAIChatCardsList isFullChat={true} />
					</Flex>
				</Flex>
			</Flex>
			<AskAIPageInput onAddPrompt={onAddPrompt} paddingBottom={'24px'} />
		</>
	);
}

function AskAIPageEmptyState({ onAddPrompt }: { onAddPrompt: OnAddPrompt }) {
	const { aiSettings } = useTenantConfig();
	const [user] = useUser();

	const prettyName = useMemo(() => {
		return user.firstName ?? user.email?.split('@')[0];
	}, [user.firstName, user.email]);

	return (
		<Flex
			direction={'column'}
			alignItems={'center'}
			justifyContent={'center'}
			gap={'32px'}
			flex={1}
			width={'100%'}
			paddingBottom={'10%'}
		>
			<Flex direction={'column'} alignItems={'center'} justifyContent={'center'} gap={'12px'}>
				<Typography variant={'DesktopH2'}>
					<Flex background={'linear-gradient(106deg, #D4C7FD 0%, #9B7BF9 74.33%)'} backgroundClip={'text'}>
						Hey, {prettyName}.
					</Flex>
				</Typography>
				<Typography variant={'DesktopH8Regular'} textAlign={'center'} color={colors.gray[1000]}>
					{aiSettings?.omniPage?.subtitle ?? 'Ask, analyze and visualize your data with semantic AI.'}
				</Typography>
			</Flex>
			<Flex direction={'column'} alignItems={'center'} justifyContent={'center'} gap={'16px'} width={'100%'}>
				<AskAIPageInput onAddPrompt={onAddPrompt} />
				<AskAIInitialSuggestions suggestions={aiSettings?.omniPage?.suggestions} onAddPrompt={onAddPrompt} />
			</Flex>
		</Flex>
	);
}

function AskAIInitialSuggestions({
	suggestions,
	onAddPrompt,
}: {
	suggestions?: AskAIPageSuggestion[];
	onAddPrompt: OnAddPrompt;
}) {
	if (!suggestions || suggestions.length === 0) return;

	return (
		<Flex alignItems={'center'} justifyContent={'center'} gap={'12px'} maxWidth={'800px'} flexWrap={'wrap'}>
			{suggestions.map((suggestion, index) => (
				<AskAIInitialSuggestion key={index} suggestion={suggestion} onAddPrompt={onAddPrompt} />
			))}
		</Flex>
	);
}

function AskAIInitialSuggestion({
	suggestion,
	onAddPrompt,
}: {
	suggestion: AskAIPageSuggestion;
	onAddPrompt: OnAddPrompt;
}) {
	const { wrapWithReport } = useReportEvent();

	if (!suggestion.prompt) return;
	return (
		<Button
			borderRadius={'32px'}
			size={'small'}
			colorScheme={'lightGray'}
			variant={'ghost'}
			onClick={wrapWithReport(() => onAddPrompt(suggestion.prompt), 'ask-ai-quick-prompt', {
				prompt: suggestion.prompt,
			})}
		>
			{suggestion.display ?? suggestion.prompt}
		</Button>
	);
}

function AskAIPageInput({ onAddPrompt, paddingBottom }: { onAddPrompt: OnAddPrompt; paddingBottom?: string }) {
	const { abortAIAgent } = useAIAgents();

	return (
		<Flex
			paddingBottom={paddingBottom}
			boxShadow={'0px -16px 24px 0px rgba(255, 255, 255, 0.80)'}
			backgroundColor={'white'}
			maxWidth={'800px'}
			width={'90%'}
			justifyContent={'center'}
			zIndex={99}
		>
			<AskAIInput
				hasBorder={true}
				reportEvent={'ask-ai-chat-query'}
				reportFeature={'Ask AI'}
				placeholder={'Ask Omni'}
				autoFocus={true}
				borderRadius={'32px'}
				submitButtonBorderRadius={'26px'}
				clearInputOnSubmit={true}
				onSubmit={onAddPrompt}
				onCancel={() => abortAIAgent('Canceled')}
				isCancellable={true}
				size={'xl'}
			/>
		</Flex>
	);
}
