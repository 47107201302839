import { useSemanticDefinitions } from '@hooks/stores/useSemanticDefinitions';
import {
	ARR_AMOUNT_DIMENSION_NAME,
	ARR_END_DATE_DIMENSION_NAME,
	ARR_ROLLUP_ID_DIMENSION_NAME,
	ARR_START_DATE_DIMENSION_NAME,
	ArrModelEntityMetaField,
	GRANULAR_ENTITY_META_FIELD_NAME,
	GRANULAR_METRIC_NAME,
	REPORTING_ENTITY_META_FIELD_NAME,
} from '@pages/ArrModelPage/consts';
import { ArrModelOutputState } from '@pages/ArrModelPage/types';
import { isExplicitDimension } from '@pages/ArrModelPage/utils';
import { useEffect, useState } from 'react';
import { EnrichedMetric, EntityWithMetrics } from 'src/lib/completions/semanticTypes';
import { MetricOperation } from 'src/lib/completions/semanticTypes/metrics.schema';
import { ExplicitDimension } from 'src/lib/completions/semanticTypes/normalization.schema';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';

const EMPTY_STATE: ArrModelOutputState = {
	granularEntityArrAmountDefinitions: [],
	granularEntityRecognitionEndDefinitions: [],
	granularEntityRecognitionStartDefinitions: [],
	granularMetricFilters: [],
};

export function extractEntityByMetaFieldName(
	metaFieldName: ArrModelEntityMetaField,
	entities: EntityWithMetrics[]
): EntityWithMetrics | undefined {
	return entities.find(
		(entity) => entity?.meta != undefined && metaFieldName in entity.meta && entity.meta[metaFieldName]
	);
}

export function extractMetricByName(metricName: string, metrics: EnrichedMetric[]): EnrichedMetric | undefined {
	return metrics.find((metric) => metric.name === metricName);
}

export function extractExplicitDimensionByName(
	entity: EntityWithMetrics,
	dimensionName: string
): ExplicitDimension | undefined {
	return entity.dimensions
		.filter((dim) => isExplicitDimension(dim))
		.find((dimension) => dimension.name === dimensionName);
}

export function useArrModelOutputState(): {
	arrModelOutputState: ArrModelOutputState;
	savedModelState: ArrModelOutputState;
	isLoading: boolean;
} & {
	setGranularEntityName: (granularEntityName: string) => void;
	setReportingEntityName: (reportingEntityName: string) => void;
	setGranularToReportingEntitiesPath: (granularToReportingEntitiesPath: string) => void;
	setGranularEntityArrAmountDefinitions: (granularEntityArrAmountDefinitions: string[]) => void;
	setGranularEntityRecognitionStartDefinitions: (granularEntityRecognitionStartDefinitions: string[]) => void;
	setGranularEntityRecognitionEndDefinitions: (granularEntityRecognitionEndDefinitions: string[]) => void;
	setGranularMetricFilters: (granularMetricFilters: string[]) => void;
	setGranularMetricOperation: (granularMetricOperation: MetricOperation) => void;
	setGranularMetricMeasure: (granularMetricMeasure: string) => void;
	setGranularMetricXAxis: (granularMetricXAxis: string) => void;
	setSavedModelState: (savedModelState: ArrModelOutputState) => void;
} {
	const [isLoading, setIsLoading] = useState(true);
	const { upsertYAMLProperty } = useBuilderDerivedState();
	const { semanticDefinitions } = useSemanticDefinitions();

	const [arrModelOutputState, setArrModelOutputState] = useState<ArrModelOutputState>(EMPTY_STATE);
	const [savedModelState, setSavedModelState] = useState<ArrModelOutputState>(EMPTY_STATE);

	useEffect(() => {
		setIsLoading(true);
		if (!semanticDefinitions) return;
		const granularEntity = extractEntityByMetaFieldName(
			GRANULAR_ENTITY_META_FIELD_NAME,
			semanticDefinitions?.entities || []
		);
		const reportingEntity = extractEntityByMetaFieldName(
			REPORTING_ENTITY_META_FIELD_NAME,
			semanticDefinitions?.entities || []
		);
		const granularMetric = extractMetricByName(GRANULAR_METRIC_NAME, semanticDefinitions?.metrics || []);
		const granularEntityRollupIdDimension =
			granularEntity && extractExplicitDimensionByName(granularEntity, ARR_ROLLUP_ID_DIMENSION_NAME);
		const granularEntityArrAmountDimension =
			granularEntity && extractExplicitDimensionByName(granularEntity, ARR_AMOUNT_DIMENSION_NAME);
		const granularEntityStartDateDimension =
			granularEntity && extractExplicitDimensionByName(granularEntity, ARR_START_DATE_DIMENSION_NAME);
		const granularEntityEndDateDimension =
			granularEntity && extractExplicitDimensionByName(granularEntity, ARR_END_DATE_DIMENSION_NAME);

		const initialState = {
			granularEntityName: granularEntity?.name,
			reportingEntityName: reportingEntity?.name,
			granularToReportingEntitiesPath: granularEntityRollupIdDimension?.sources?.[0].sql,
			granularEntityArrAmountDefinitions: granularEntityArrAmountDimension?.sources?.map((source) => source.sql) || [],
			granularEntityRecognitionStartDefinitions:
				granularEntityStartDateDimension?.sources.map((source) => source.sql) || [],
			granularEntityRecognitionEndDefinitions:
				granularEntityEndDateDimension?.sources.map((source) => source.sql) || [],
			granularMetricFilters: [],
			granularMetricOperation: granularMetric?.operation,
			granularMetricMeasure: granularMetric?.measure,
			granularMetricXAxis: granularMetric?.x_axis?.period?.[0].sql,
		};

		setSavedModelState(initialState);
		setArrModelOutputState(initialState);
		setIsLoading(false);
	}, [semanticDefinitions]);

	const setGranularEntityName = (granularEntityName: string) => {
		setArrModelOutputState((s) => ({ ...s, granularEntityName }));
	};

	const setReportingEntityName = (reportingEntityName: string) => {
		setArrModelOutputState((s) => ({ ...s, reportingEntityName }));
	};

	const setGranularToReportingEntitiesPath = (granularToReportingEntitiesPath: string) => {
		setArrModelOutputState((s) => ({ ...s, granularToReportingEntitiesPath }));
	};

	const setGranularEntityArrAmountDefinitions = (granularEntityArrAmountDefinitions: string[]) => {
		setArrModelOutputState((s) => ({ ...s, granularEntityArrAmountDefinitions }));
	};

	const setGranularEntityRecognitionStartDefinitions = (granularEntityRecognitionStartDefinitions: string[]) => {
		setArrModelOutputState((s) => ({ ...s, granularEntityRecognitionStartDefinitions }));
	};

	const setGranularEntityRecognitionEndDefinitions = (granularEntityRecognitionEndDefinitions: string[]) => {
		setArrModelOutputState((s) => ({ ...s, granularEntityRecognitionEndDefinitions }));
	};

	const setGranularMetricFilters = (granularMetricFilters: string[]) => {
		upsertYAMLProperty('filters', granularMetricFilters, {
			shouldPreviewAfter: false,
			shouldRequestAISuggestions: false,
		});
		setArrModelOutputState((s) => ({ ...s, granularMetricFilters }));
	};

	const setGranularMetricOperation = (granularMetricOperation: MetricOperation) => {
		upsertYAMLProperty('operation', granularMetricOperation, {
			shouldPreviewAfter: false,
			shouldRequestAISuggestions: false,
		});
		setArrModelOutputState((s) => ({ ...s, granularMetricOperation }));
	};

	const setGranularMetricMeasure = (granularMetricMeasure: string) => {
		upsertYAMLProperty('measure', granularMetricMeasure, {
			shouldPreviewAfter: false,
			shouldRequestAISuggestions: false,
		});
		setArrModelOutputState((s) => ({ ...s, granularMetricMeasure }));
	};

	const setGranularMetricXAxis = (granularMetricXAxis: string) => {
		upsertYAMLProperty(
			'x_axis',
			{ period: granularMetricXAxis },
			{
				shouldPreviewAfter: false,
				shouldRequestAISuggestions: false,
			}
		);
		setArrModelOutputState((s) => ({ ...s, granularMetricXAxis }));
	};

	return {
		arrModelOutputState,
		setGranularEntityName,
		setReportingEntityName,
		setGranularToReportingEntitiesPath,
		setGranularEntityRecognitionStartDefinitions,
		setGranularEntityArrAmountDefinitions,
		setGranularMetricFilters,
		setGranularMetricOperation,
		setGranularMetricMeasure,
		setGranularMetricXAxis,
		setGranularEntityRecognitionEndDefinitions,
		savedModelState,
		setSavedModelState,
		isLoading,
	};
}
