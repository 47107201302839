import Flex from '@components/Flex';
import Markdown from '@components/Markdown';
import Typography from '@components/Typography';
import { useCallback, useEffect, useState } from 'react';

import { useMetricResultsAIAgent } from '../../hooks/useMetricResultsAIAgent';
import {
	AskAIChatCardProps,
	OnCalcResultsReceived,
	OnCalcResultsStarted,
	isAskAIMetricDiscoveryChatMessage,
} from '../../types';
import { AskAIMetricDiscoveryChatSuggestions } from './AskAIMetricDiscoveryChatSuggestions';

export function AskAIMetricDiscoveryCardContent({ chatMessageAtom, chatIndex }: AskAIChatCardProps) {
	const { chatMessage, getMetricResultsDescription, addChatEvent } = useMetricResultsAIAgent({ chatMessageAtom });
	const isMetricDiscoveryMessage = isAskAIMetricDiscoveryChatMessage(chatMessage);
	const result = isMetricDiscoveryMessage ? chatMessage.result : undefined;
	const [finalMessage, setFinalMessage] = useState<string | undefined>();

	useEffect(() => {
		if (result?.examples) {
			setFinalMessage(result.reasoning);
		}
	}, [result]);

	const onCalcResultsReceived: OnCalcResultsReceived = useCallback(
		({ metric, calcResults, sqlQuery }) => {
			if (finalMessage) return;

			if (!result) {
				setFinalMessage('Something went wrong.');
				return;
			}

			if (result.classification !== 'results') {
				setFinalMessage(result.reasoning);
				return;
			}

			const suggestedMetric = result.metrics?.find((m) => m.metric === metric);
			if (suggestedMetric?.resultDescription) {
				setFinalMessage(suggestedMetric.resultDescription);
				return;
			}

			addChatEvent({
				event: 'analyzing',
				data: { text: 'Starting results analysis', results: { calcResults, sqlQuery } },
			});
			getMetricResultsDescription({ metric, calcResults })
				.then((value) => setFinalMessage(value))
				.catch((error) => {
					console.log('Error getting metric results description', error);
				});
		},
		[addChatEvent, getMetricResultsDescription, result, finalMessage]
	);

	const onCalcResultsStarted: OnCalcResultsStarted = useCallback(
		({ metric }) => {
			addChatEvent({
				event: 'calculating',
				data: { text: 'Calculating metric', metric: { ...metric, resultDescription: undefined } },
			});
		},
		[addChatEvent]
	);

	if (!isMetricDiscoveryMessage) return;

	return (
		<Typography variant="Paragraph14R" color={'gray.1000'}>
			{finalMessage && (
				<Flex
					direction={'column'}
					data-testid={`ask-ai-chat-card-content-${chatIndex}`}
					padding={'0 16px 16px 16px'}
					gap={'12px'}
				>
					<Markdown isAI message={finalMessage} />
				</Flex>
			)}
			<AskAIMetricDiscoveryChatSuggestions
				metrics={result?.metrics}
				classification={result?.classification}
				onCalcResultsReceived={onCalcResultsReceived}
				onCalcResultsStarted={onCalcResultsStarted}
				examples={result?.examples}
				chatMessageAtom={chatMessageAtom}
				chatIndex={chatIndex}
				chatEvents={chatMessage.events}
				isRunning={!finalMessage}
			/>
		</Typography>
	);
}
