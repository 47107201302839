import { requiredValuesCountPerOperator } from 'src/common/components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/constants';
import { CoreReaderFiltersCallback } from 'src/common/hooks/fetching/useCoreReaderFiltersApi';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import {
	CalculateMetricCoreReaderQuery,
	CloudSupportedOperators,
	Filter as QueryInputFilter,
	SupportedDimensionTypes,
} from 'src/generated/graphql';
import {
	addPrefixDollarSignIfNeeded,
	removeDollarSigns,
} from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { getFirstObjectType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/utils';
import { convertToFilterV2 } from 'src/pages/MetricPage/components/InvestigatePanel/useMetricFiltersV2';

import { FilterV2, MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';

export function calcFiltersForQuery({ filterBy, filterByV2 }: MetricSearchParams): QueryInputFilter[] {
	const filterQueryInputs = [];

	if (filterBy) {
		filterQueryInputs.push(
			...Object.entries(filterBy)
				.filter(([, values]) => values != null && values.length > 0)
				.map<QueryInputFilter>(([key, values]) => {
					return {
						key: addPrefixDollarSignIfNeeded(key),
						values: values ?? [],
						operator: CloudSupportedOperators.OneOfSensitive,
						type: SupportedDimensionTypes.String,
					};
				})
		);
	}

	if (filterByV2) {
		filterQueryInputs.push(
			...filterByV2.filter((filter) => filter.values.length >= requiredValuesCountPerOperator[filter.operator])
		);
	}

	return filterQueryInputs;
}

export type FilterInput = { key: string; values: (string | null)[] };

export async function calcCoreValidFilters(
	filtersFromQuery: NonNullable<CalculateMetricCoreReaderQuery['calcMetricV2']['chartOptions']>['filters'],
	{ objectsTypes }: Pick<MetricDerivedState, 'objectsTypes'>,
	filterOptionsQuery: CoreReaderFiltersCallback,
	{ filterBy, filterByV2 }: MetricSearchParams
): Promise<Pick<MetricDerivedState, 'filters'>> {
	const filterOptions = (await filterOptionsQuery(objectsTypes)) || [];

	const filterDimensions = filterOptions.flatMap((option) => {
		return [
			...option.dimensions.map((dimension) => {
				return removeDollarSigns(dimension.identifier);
			}),
		];
	});

	const filterRelationshipsIndentifiers = filterOptions.flatMap((option) => {
		return [
			...option.relationships.map((relationship) => {
				return removeDollarSigns(relationship.identifier);
			}),
		];
	});

	const filterIdentifiers = [...filterDimensions, ...filterRelationshipsIndentifiers];
	const isFilterObjectValid = ({ key }: { key: string }) => filterIdentifiers.includes(getFirstObjectType(key));

	const filters: FilterV2[] = filtersFromQuery.filter(isFilterObjectValid).map<FilterV2>((filter) => {
		return convertToFilterV2(
			{
				...filter,
				type: filter.type ?? SupportedDimensionTypes.String,
				operator: filter.operator ?? CloudSupportedOperators.OneOfSensitive,
				baseEntity: objectsTypes[0],
			},
			objectsTypes[0]
		);
	});

	const filterQueryInputs = [];

	if (filterBy) {
		filterQueryInputs.push(
			...Object.entries(filterBy)
				.map<QueryInputFilter>(([key, values]) => {
					return {
						key: addPrefixDollarSignIfNeeded(key),
						values: values ?? [],
						operator: CloudSupportedOperators.OneOfSensitive,
						type: SupportedDimensionTypes.String,
					};
				})
				.filter((filter) => filter.values.length <= 0)
		);
	}

	if (filterByV2) {
		filterQueryInputs.push(
			...filterByV2.filter((filter) => filter.values.length < requiredValuesCountPerOperator[filter.operator])
		);
	}

	filters.push(
		...filterQueryInputs.map((filter) => {
			return convertToFilterV2(
				{
					...filter,
					label: filter.key,
					type: filter.type ?? SupportedDimensionTypes.String,
					operator: filter.operator ?? CloudSupportedOperators.OneOfSensitive,
					baseEntity: objectsTypes[0],
				},
				objectsTypes[0]
			);
		})
	);

	return { filters };
}
