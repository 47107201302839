import Flex from 'src/common/components/Flex';
import { LeftSidePanelV2 } from 'src/common/components/LeftSidePanelV2';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useModal } from 'src/common/hooks/ui/useModal';
import Page from 'src/layout/Page';
import { useReportEvent } from 'src/services/analytics';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import { OntologyPanel } from './components';
import { EntitiesOntologyPageContent } from './components/OntologyPageContent/EntitiesOntologyPageContent';
import { ParametersOntologyPageContent } from './components/OntologyPageContent/ParametersOntologyPageContent';
import { SkeletonOntologyPanel } from './components/SkeletonComponents';
import { useOntologyPageState } from './hooks/useOntologyPageState';

export const OntologyPagePath = 'ontology';

export function OntologyPage() {
	const isOntologyPageEnabled = useFeatureFlag('pulse.sightfull2.enable');
	const hasEntitiesPermission = usePermissionCheck().isHavingOneOfPermissions([
		Permissions.readEntities,
		Permissions.writeEntities,
	]);
	if (!isOntologyPageEnabled || !hasEntitiesPermission) return null;

	return (
		<Page page="Ontology Page">
			<OntologyBuilderPage />
		</Page>
	);
}

export function OntologyBuilderPage() {
	const {
		ontologyPageState: { isLoading, ontologyType, allOntology, selectedOntology, entities },
	} = useOntologyPageState();

	const {
		isOpen: isUpsertEntityModalOpened,
		onOpen: openUpsertEntityModal,
		onClose: onCloseUpsertEntityModal,
	} = useModal();
	const {
		isOpen: isAddMultipleEntitiesModalOpened,
		onOpen: openAddMultipleEntitiesModal,
		onClose: onCloseAddMultipleEntitiesModal,
	} = useModal();

	const { reportEvent } = useReportEvent();

	const onClickAddMultipleEntities = () => {
		reportEvent({
			event: 'ontology-create-multiple-objects-clicked',
			metaData: { objectType: 'entity', parentEntity: selectedOntology?.name },
		});
		openAddMultipleEntitiesModal();
	};

	return (
		<Flex direction={'row'} height={'100%'}>
			<LeftSidePanelV2
				renderItem={(handleCollapsePanelClick, isCollapsed) =>
					isLoading ? (
						<SkeletonOntologyPanel />
					) : (
						<OntologyPanel
							isUpsertEntityModalOpened={isUpsertEntityModalOpened}
							handleCollapsePanelClick={handleCollapsePanelClick}
							isCollapsed={isCollapsed}
							onCreateNewOpen={() => {
								reportEvent({
									event: 'ontology-create-new-object-clicked',
									metaData: { objectType: 'entity', parentEntity: selectedOntology?.name },
								});
								openUpsertEntityModal();
							}}
							onCreateMultipleOpen={onClickAddMultipleEntities}
						/>
					)
				}
			/>
			{ontologyType == 'entity' && (
				<EntitiesOntologyPageContent
					isLoading={isLoading}
					entities={entities}
					normalizedOntology={allOntology}
					ontologyName={selectedOntology?.name ?? ''}
					selectedOntology={selectedOntology}
					isUpsertEntityModalOpened={isUpsertEntityModalOpened}
					onCreateNewOpen={() => {
						reportEvent({
							event: 'ontology-create-new-object-clicked',
							metaData: { objectType: 'entity', parentEntity: selectedOntology?.name },
						});
						openUpsertEntityModal();
					}}
					onCloseUpsertEntityModal={onCloseUpsertEntityModal}
					onCreateMultipleOpen={onClickAddMultipleEntities}
					onCloseAddMultipleEntitiesModal={onCloseAddMultipleEntitiesModal}
					isAddMultipleEntitiesModalOpened={isAddMultipleEntitiesModalOpened}
				/>
			)}
			{ontologyType == 'parameter' && (
				<ParametersOntologyPageContent isLoading={isLoading} selectedOntology={selectedOntology} />
			)}
		</Flex>
	);
}
