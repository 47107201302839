import { FiltersAndBreakdownItemType } from './types';

// TODO: Move this to OntologyPage utils
export const filterAlreadyPromotedSourceDimensions = (dimensions: FiltersAndBreakdownItemType[]) => {
	const TOKEN_PREFIX = '$';
	const SOURCE_DIMENSION_PREFIX = '$src__';
	const sourceDimensions = dimensions.filter(({ isNormalized }) => !isNormalized);
	const nonSourceDimensions = dimensions
		.filter(({ isNormalized }) => isNormalized)
		.map((dimension) => ({ ...dimension, key: dimension.key.replace(TOKEN_PREFIX, SOURCE_DIMENSION_PREFIX) }));

	return sourceDimensions.filter(
		(sourceDimension) => !nonSourceDimensions.some((dimension) => dimension.key === sourceDimension.key)
	);
};
