import { useCallback, useMemo } from 'react';
import {
	InitiateMetricInteractiveSessionV2MutationVariables,
	useInitiateMetricInteractiveSessionV2Mutation,
} from 'src/generated/graphql';
import {
	addPrefixDollarSignIfNeeded,
	buildFilters,
} from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useMetricEditorState } from 'src/pages/MetricPage/hooks/useMetricEditorState';

export const useInitiateMetricInteractiveV2Session = () => {
	const { metricNameWithFlavor, breakdowns, periodRange, filters, objectsTypes } = useMetricDerivedState();
	const { previewValue } = useMetricEditorState();
	const [initiateMetricInteractiveV2Session] = useInitiateMetricInteractiveSessionV2Mutation();

	const openPeriod = useMemo(
		() => (periodRange.asAbsoluteRange.endPeriod.isOpen ? periodRange.asAbsoluteRange.endPeriod.id : undefined),
		[periodRange]
	);

	const variables = useMemo(() => {
		const groupBy = breakdowns.values.map((breakdown) => addPrefixDollarSignIfNeeded(breakdown.key));
		const filterBy = buildFilters(filters, objectsTypes[0]);
		const userMetricDefinitions = previewValue.length > 0 ? { metricDefinition: previewValue } : undefined;

		const variables: InitiateMetricInteractiveSessionV2MutationVariables = {
			metric_data: {
				metricName: metricNameWithFlavor,
				openPeriod,
				start: periodRange.asAbsoluteRange.startPeriod.id,
				end: periodRange.asAbsoluteRange.endPeriod.id,
				groupBy,
				filterBy,
				userMetricDefinitions,
			},
		};
		return variables;
	}, [metricNameWithFlavor, breakdowns, periodRange, filters, openPeriod, previewValue, objectsTypes]);

	const handleInitiateMetricInteractiveV2Session = useCallback(async () => {
		try {
			const res = await initiateMetricInteractiveV2Session({ variables });
			const sessionId: string = res.data?.generative_initiate_metric_interactive_v2?.response.requestId;

			if (!sessionId) {
				throw new Error(`Session ID not found in response: ${JSON.stringify(res)}`);
			}

			return sessionId;
		} catch (err) {
			throw new Error(`Failed to initiate interactive session: ${err instanceof Error ? err.message : err}`);
		}
	}, [variables, initiateMetricInteractiveV2Session]);

	return { handleInitiateMetricInteractiveV2Session };
};
