import { Flex } from '@chakra-ui/react';
import Box from '@components/Box';
import Divider from '@components/Divider';
import { Fragment } from 'react';
import Skeleton from 'src/common/components/Skeleton';
import classes from './SkeletonOntologyPanel.module.scss';

const { titleSkeleton, iconSkeleton, avatarSkeleton, descriptionSkeleton } = classes;
const REPEAT_ENTITIES_NUMBER = 7;

function SkeletonOntologyPanelPart() {
	return (
		<Flex padding={'14px 12px'} alignItems={'center'}>
			<Skeleton className={avatarSkeleton} />
			<Box width={'100%'}>
				<Skeleton className={titleSkeleton} />
				<Skeleton className={descriptionSkeleton} />
			</Box>
		</Flex>
	);
}

function SkeletonEntitiesSearch() {
	return (
		<Flex marginBottom={'16px'} alignItems={'center'} justifyContent={'space-between'}>
			<Flex gap={'8px'}>
				<Skeleton className={iconSkeleton} />
				<Skeleton className={iconSkeleton} />
			</Flex>
			<Skeleton className={iconSkeleton} />
		</Flex>
	);
}

export function SkeletonOntologyPanel() {
	return (
		<Box paddingRight={'16px'}>
			<SkeletonEntitiesSearch />
			{[...Array(REPEAT_ENTITIES_NUMBER)].map((_, index) => (
				<Fragment key={index}>
					<SkeletonOntologyPanelPart />
					<Box>
						<Divider direction={'horizontal'} />
					</Box>
				</Fragment>
			))}
		</Box>
	);
}
