import { Popover, PopoverAnchor, PopoverContent } from '@chakra-ui/react';
import Box from '@components/Box';
import Flex from '@components/Flex';
import { Subtitle } from '@components/LeftExpandCollapsePanel/Drilldown/DrillDownCard/Subtitle';
import Tooltip from '@components/Tooltip';
import { useReportEvent } from '@services/analytics';
import { ReactNode, useState } from 'react';
import classes from '../../LeftExpandCollapsePanel.module.scss';
import { Title } from './Title';

export function DrilldownCard({
	title,
	value,
	icon,
	valueEditor,
	tooltip,
	removeTooltip,
	onRemove,
	onClick,
	onModalOpen,
	titleHoverTooltipBuilder,
	isEditing,
	eventMetaData,
}: {
	title: string;
	value?: string | null;
	valueEditor?: ReactNode;
	tooltip?: string;
	icon: React.ReactNode;
	removeTooltip?: string;
	onRemove?: VoidFunction;
	onClick?: VoidFunction;
	onModalOpen?: VoidFunction;
	eventMetaData?: object;
	titleHoverTooltipBuilder?: () => ReactNode;
	isEditing?: boolean;
}) {
	const { reportEvent } = useReportEvent();
	const [isTitleTooltipOpen, setIsTitleTooltipOpen] = useState(false);

	const showTitleTooltip = () => setIsTitleTooltipOpen(true);
	const hideTitleTooltip = () => setIsTitleTooltipOpen(false);

	return (
		<Box paddingBottom={'8px'}>
			<Box style={{ width: '100%' }}>
				<Flex className={classes.card} direction={'column'}>
					<Popover placement="right-start" isOpen={isTitleTooltipOpen && !isEditing} autoFocus={false}>
						<PopoverContent
							autoFocus={false}
							background={'0'}
							boxShadow={'0'}
							bottom={'9px'}
							left={'9px'}
							onMouseEnter={showTitleTooltip}
							onMouseLeave={hideTitleTooltip}
						>
							{titleHoverTooltipBuilder?.()}
						</PopoverContent>
						<PopoverAnchor>
							<Box onMouseEnter={showTitleTooltip} onMouseLeave={hideTitleTooltip}>
								<Title
									onModalOpen={onModalOpen}
									removeTooltip={removeTooltip}
									icon={icon}
									title={title}
									onRemove={
										onRemove
											? () => {
													reportEvent({ event: 'filters-remove-filter', metaData: eventMetaData });
													onRemove?.();
											  }
											: undefined
									}
								/>
							</Box>
						</PopoverAnchor>
					</Popover>
					{value && (
						<Tooltip size={'md'} label={tooltip} variant="fluid" placement={'right'}>
							<Subtitle onClick={onClick} subtitle={value} />
						</Tooltip>
					)}
					{valueEditor}
				</Flex>
			</Box>
		</Box>
	);
}
