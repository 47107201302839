import { AskAIModalItem } from '@components/AskAI/AskAIModalItem';
import { AskAIChatHeader } from '@components/AskAI/Chat/AskAIChatHeader';

export function AIChatHeader({ onBack, onClose }: { onBack: () => void; onClose: () => void }) {
	return (
		<AskAIModalItem borderBottom={true}>
			<AskAIChatHeader onBack={onBack} onClose={onClose} />
		</AskAIModalItem>
	);
}
