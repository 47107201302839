import Box from '@components/Box';
import Flex from '@components/Flex';
import Skeleton from 'src/common/components/Skeleton';
import Page from 'src/layout/Page';
import {
	SkeletonDrillDownPanel,
	SkeletonLegendPanel,
	SkeletonMetricChart,
	SkeletonPeriodPicker,
	SkeletonTableLayout,
	SkeletonViewDivider,
} from '../../MetricPage/components/SkeletonComponents';
import classes from '../SignalPage.module.scss';

export function LoadingSignalPage() {
	return (
		<Page page="Signal Page">
			<Flex direction={'column'} height={'100%'}>
				<Box height="72px">
					<Skeleton className={classes.headerSkeleton} />
				</Box>
				<Flex grow={1} justify={'space-between'} height={'0'}>
					<Box
						as="aside"
						overflowY="auto"
						padding={'24px 16px 16px 16px'}
						display={'block'}
						boxShadow="borderRight"
						minWidth={'280px'}
						maxWidth={'300px'}
					>
						<SkeletonDrillDownPanel />
					</Box>
					<Flex grow={1} flexDirection="column" paddingY="24px">
						<LoadingMetricViewLayout />
					</Flex>
					<Box padding={'24px 16px 16px 16px'} boxShadow="borderLeft" width={'252px'} textAlign={'center'}>
						<SkeletonLegendPanel />
					</Box>
				</Flex>
			</Flex>
		</Page>
	);
}

export function LoadingMetricViewLayout() {
	return (
		<>
			<Flex flexDirection="row" justifyContent="space-between" paddingBottom="16px" paddingX={'32px'}>
				<SkeletonPeriodPicker />
				<SkeletonPeriodPicker />
			</Flex>
			<Flex flexDirection="column" overflowY="hidden" grow={1}>
				<Box
					minHeight={'calc(50% - 16px)'}
					maxHeight={'calc(50% - 16px)'}
					transitionProperty="min-height, max-height"
					transitionDuration="0.5s"
					paddingX={'32px'}
				>
					<SkeletonMetricChart />
				</Box>
				<Box width="0" minWidth="100%">
					<SkeletonViewDivider />
				</Box>
				<Flex
					minHeight={'calc(50% - 16px)'}
					maxHeight={'calc(50% - 16px)'}
					transitionProperty="min-height, max-height"
					transitionDuration="0.5s"
					overflowY={'hidden'}
					paddingX={'32px'}
					width="0"
					minWidth="100%"
				>
					<SkeletonTableLayout />
				</Flex>
			</Flex>
		</>
	);
}
