import Box from '@components/Box';
import Flex from '@components/Flex';
import Button from '../Button';
import { ButtonColorSchemaType, ButtonSizeType } from '../Button/types';
import { SpaceProps } from '@chakra-ui/react';
import { TestIDs } from 'src/common/types/test-ids';

export type FooterSize = 'lg' | 'md' | 'sm';
export type ModalFooterProps = {
	size: FooterSize;
	onCancel?: () => void;
	onPrimaryClick?: () => void;
	onBack?: () => void;
	primaryButtonLabel: string;
	isPrimaryEnabled?: boolean;
	isPrimaryLoading?: boolean;
	placement?: string;
	color?: ButtonColorSchemaType;
	cancelButtonColor?: ButtonColorSchemaType;
	testId?: string;
	padding?: string;
} & SpaceProps;

export default function ModalFooter({
	size,
	onCancel,
	onPrimaryClick,
	onBack,
	primaryButtonLabel,
	isPrimaryEnabled = true,
	isPrimaryLoading = false,
	placement = 'space-between',
	color = 'gray',
	cancelButtonColor = 'gray',
	testId,
	padding = '16px 24px',
	...props
}: ModalFooterProps) {
	return (
		<Flex
			width={'auto'}
			flexDirection="row"
			padding={padding}
			justifyContent={placement}
			alignItems="center"
			gap="12px"
			{...props}
		>
			{onBack && (
				<Box>
					<Button
						size={buttonSizeByFooterSize[size]}
						variant="link"
						colorScheme={color}
						onClick={onBack}
						testId={testId && TestIDs.MODAL_FOOTER_BACK_BUTTON(testId)}
					>
						Back
					</Button>
				</Box>
			)}

			<Button
				size={buttonSizeByFooterSize[size]}
				variant="outline"
				colorScheme={cancelButtonColor}
				onClick={onCancel}
				eventName="signal-draft-cancelled"
				testId={testId && TestIDs.MODAL_FOOTER_CANCEL_BUTTON(testId)}
			>
				Cancel
			</Button>
			<Button
				size={buttonSizeByFooterSize[size]}
				variant="solid"
				colorScheme={color}
				onClick={onPrimaryClick}
				isDisabled={!isPrimaryEnabled}
				isLoading={isPrimaryLoading}
				eventName={`signal-draft-${primaryButtonLabel.toLowerCase()}d`}
				testId={testId && TestIDs.MODAL_FOOTER_PRIMARY_BUTTON(testId)}
			>
				{primaryButtonLabel}
			</Button>
		</Flex>
	);
}
const buttonSizeByFooterSize: { [key in FooterSize]: ButtonSizeType } = {
	lg: 'large',
	md: 'medium',
	sm: 'small',
};
