import React, { useState } from 'react';
import { CreateNewMetricPagePath } from '@pages/CreateNewMetricPage/CreateNewMetricPage';
import Modal from '@components/Modal';
import { ModalHeader } from '@chakra-ui/react';
import Flex from '@components/Flex';
import Button from '@components/Button';
import { CloseLarge16, CreateMetricFromScratchOption, CreateMetricFromTemplateOption } from '@icons/index';
import colors from 'src/style/colors';
import Box from '@components/Box';
import Typography from '@components/Typography';
import shadows from 'src/style/shadows';
import Center from '@components/Center';
import useNavigation from '@services/useNavigation';
import { useReportEvent } from '@services/analytics';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

type CreateMetricOption = 'fromScratch' | 'fromTemplate';

export const CreateMetricModal = ({
	isOpen,
	onClose,
	missingMetricName,
	metricName,
}: {
	isOpen: boolean;
	onClose: VoidFunction;
	missingMetricName: string;
	metricName: string;
}) => {
	const { navigate } = useNavigation();
	const { reportEvent, wrapWithReport } = useReportEvent();
	const [selectedCreateMetricOption, setSelectedCreateMetricOption] = useState<CreateMetricOption>();

	const onModalClose = () => {
		setSelectedCreateMetricOption(undefined);
		onClose();
	};

	const onSubmit = () => {
		if (selectedCreateMetricOption === 'fromScratch') {
			const additionalSearchParams = new URLSearchParams();
			additionalSearchParams.append('metricName', missingMetricName);

			navigate({
				path: `${CreateNewMetricPagePath}/aggregate`,
				additionalSearchParams,
				isNewTab: true,
			});
		} else if (selectedCreateMetricOption === 'fromTemplate') {
			window.open(`https://www.sightfull.com/metrics-library/browse/?text=${missingMetricName}`, '_blank');
		}
		onModalClose();
	};

	const CreateMetricOption = ({ createMetricOption }: { createMetricOption: CreateMetricOption }) => {
		const isSelected = createMetricOption === selectedCreateMetricOption;

		return (
			<Flex
				height={'224px'}
				width={'288px'}
				flexDirection={'column'}
				borderRadius={'8px'}
				border={isSelected ? `1px solid ${colors.gray['800']}` : `1px solid ${colors.gray['300']}`}
				boxShadow={isSelected ? shadows.focus.medium.gray[100] : 'none'}
				_hover={{ borderColor: isSelected ? 'gray.800' : 'gray.500' }}
				_active={{ boxShadow: shadows.focus.medium.gray[100], borderColor: 'gray.800' }}
				cursor={'pointer'}
				onClick={() => {
					reportEvent({
						event: 'create-metric-modal-option-click',
						metaData: { option: createMetricOption, metric: metricName },
					});
					setSelectedCreateMetricOption(createMetricOption);
				}}
			>
				<Center height={'148px'} borderRadius={'8px 8px 4px 4px'} backgroundColor={'gray.100'}>
					{createMetricOption === 'fromScratch' ? (
						<CreateMetricFromScratchOption />
					) : (
						<CreateMetricFromTemplateOption />
					)}
				</Center>
				<Flex flexDirection={'column'} gap={'8px'} padding={'16px'}>
					<Typography variant={'DesktopH7Medium'} color={'gray.1000'} cursor={'pointer'}>
						{createMetricOption === 'fromScratch' ? 'Start from scratch.' : 'Use a template.'}
					</Typography>
					<Typography variant={'DesktopH10Regular'} color={'gray.800'} cursor={'pointer'}>
						{createMetricOption === 'fromScratch'
							? 'Create your own custom metric.'
							: 'Explore our Metric Library for best practices.'}
					</Typography>
				</Flex>
			</Flex>
		);
	};

	return (
		<Modal
			maxWidth="640px"
			isOpen={isOpen}
			onClose={wrapWithReport(onModalClose, 'create-metric-modal', { action: 'close', metric: metricName })}
			closeOnOverlayClick={false}
			isCentered
		>
			<div
				{...buildIntercomAttributes({
					type: 'main',
					target: 'create-metric-modal',
				})}
			>
				<ModalHeader boxShadow={'borderBottom'} p="16px">
					<Flex textAlign={'center'} justifyContent="space-between" alignItems="center">
						<Button
							isIconOnly
							variant="outline"
							onClick={wrapWithReport(onModalClose, 'create-metric-modal', { action: 'x', metric: metricName })}
							size="inline"
							colorScheme="black"
						>
							<CloseLarge16 color={colors.gray[900]} />
						</Button>

						<Box margin={'0 auto'}>
							<Typography color={'gray.1100'} marginLeft={'-32px'} variant="DesktopH7Medium">
								Create new metric
							</Typography>
						</Box>
					</Flex>
				</ModalHeader>
				<Flex width={'100%'} justifyContent={'space-between'} boxShadow={'borderBottom'} padding={'24px'}>
					<CreateMetricOption createMetricOption={'fromScratch'} />
					<CreateMetricOption createMetricOption={'fromTemplate'} />
				</Flex>
				<Flex py="16px" px="24px" justifyContent={'space-between'}>
					<Button
						color={'gray.1000'}
						size={'medium'}
						colorScheme="gray"
						variant="ghost"
						onClick={wrapWithReport(onModalClose, 'create-metric-modal', {
							action: 'cancel',
							metric: metricName,
						})}
					>
						Cancel
					</Button>
					<Button
						{...buildIntercomAttributes({
							area: 'create-metric-modal',
							type: 'button',
							target: 'start',
						})}
						isDisabled={!selectedCreateMetricOption}
						size={'medium'}
						variant="solid"
						colorScheme={'blue'}
						onClick={wrapWithReport(onSubmit, 'create-metric-modal', {
							action: 'submit',
							metric: metricName,
						})}
					>
						Start
					</Button>
				</Flex>
			</div>
		</Modal>
	);
};
