import { useMemo } from 'react';
import Typography from '../../Typography';
import Flex from '../../Flex';
import colors from '../../../../style/colors';
import { Label } from '../../Labels';
import { SemanticTitle } from './SemanticTitle';
import { Box } from '@chakra-ui/react';

export function SemanticDimensions({
	dimensions,
	withHeader,
	maxDimensionsToShow,
	onDimensionHover,
	onDimensionLeave,
}: {
	dimensions: string[];
	withHeader: boolean;
	maxDimensionsToShow?: number;
	displayTooltipOnHover?: boolean;
	onDimensionHover: (dimension: string) => void;
	onDimensionLeave: () => void;
}) {
	const dimensionsToShow = useMemo(
		() => (maxDimensionsToShow ? dimensions.slice(0, maxDimensionsToShow) : dimensions),
		[dimensions, maxDimensionsToShow]
	);

	const remainingDimensions = useMemo(
		() => dimensions.length - dimensionsToShow.length,
		[dimensions.length, dimensionsToShow.length]
	);

	if (dimensions.length === 0) return;

	return (
		<Flex direction={'column'} gap={'8px'}>
			{withHeader ? <SemanticTitle>Dimensions ({dimensions.length})</SemanticTitle> : undefined}
			<Flex gap={'6px'} wrap={'wrap'} maxWidth={'220px'}>
				{dimensionsToShow.map((dimension, index) => {
					return (
						<Box key={index} onMouseEnter={() => onDimensionHover(dimension)} onMouseLeave={onDimensionLeave}>
							<Label variant={'filled'} colorScheme={'dark'} size={'small'} hoverColor={colors.gray[300]}>
								{dimension}
							</Label>
						</Box>
					);
				})}
			</Flex>

			{remainingDimensions > 0 ? (
				<Typography variant={'Paragraph12R'} color={colors.gray[700]}>
					+{remainingDimensions} more
				</Typography>
			) : undefined}
		</Flex>
	);
}
