import { AllowedColors, ValidationState } from '@components/BuilderTextInput/types';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { ErrorYellow16, Plus16 } from '@icons/index';
import { ReactNode } from 'react';
import colors from 'src/style/colors';

type ButtonValidationState = Omit<ValidationState, 'Error'>;

export const BuilderTextInputButton = ({
	placeholder,
	validation,
	onClick,
	borderColor,
	backgroundColor,
	rightContent,
	rightIcon,
	isEditable,
	hasPrefixIcon,
}: {
	placeholder: string;
	validation: ButtonValidationState;
	onClick: () => void;
	borderColor: ({ isHover }: { isHover: boolean }) => AllowedColors;
	backgroundColor: string;
	rightContent?: ReactNode;
	rightIcon?: ReactNode;
	isEditable?: boolean;
	hasPrefixIcon?: boolean;
}) => {
	return (
		<Flex
			position={'relative'}
			width={'100%'}
			alignItems={'center'}
			borderColor={borderColor({ isHover: false })}
			borderWidth={'1px'}
			borderStyle={'solid'}
			borderRadius={'8px'}
			padding={'11px 11px'}
			justifyContent={'space-between'}
			gap={'12px'}
			_hover={{
				borderColor: borderColor({ isHover: true }),
			}}
			boxShadow={'none'}
			onClick={onClick}
			backgroundColor={backgroundColor}
		>
			<Flex
				width={'100%'}
				maxWidth={rightContent ? 'calc(100% - 84px)' : '100%'}
				display={'flex'}
				alignItems={'center'}
				alignSelf={'stretch'}
				gap={'8px'}
				justifyContent={'space-between'}
			>
				<Flex
					className={isEditable ? '' : 'builder-filter-button'}
					onFocus={onClick}
					as={isEditable ? 'div' : 'button'}
					alignItems={'center'}
					gap={'8px'}
				>
					{hasPrefixIcon && <Plus16 color={colors.gray[600]} />}
					<Typography variant={'DesktopH8Regular'} style={{ userSelect: 'none' }} color={colors.gray[600]}>
						{placeholder}
					</Typography>
				</Flex>
				<Flex direction={'row'} gap="8px">
					{validation == 'Missing' && <ErrorYellow16 />}
					{rightIcon}
				</Flex>
			</Flex>
			{rightContent && (
				<Flex
					position={'absolute'}
					right={0}
					height={'100%'}
					paddingX={'12px'}
					backgroundColor={'gray.100'}
					borderLeft={'1px solid'}
					borderColor={'gray.400'}
					borderRadius={'8px'}
					borderTopLeftRadius={0}
					borderBottomLeftRadius={0}
					justifyContent={'center'}
					flexDirection={'column'}
					alignItems={'center'}
				>
					{rightContent}
				</Flex>
			)}
		</Flex>
	);
};

export default BuilderTextInputButton;
