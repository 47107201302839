import isString from 'lodash/fp/isString';
import { FC, useEffect, useMemo, useState } from 'react';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { useParameters } from '@services/useParameters';
import Box from '@components/Box';
import {
	ID_PREFIX_SERIES_TIME_SPAN_SECONDARY,
	MultiTooltipPeriodType,
	MultiToolTipPropsType,
} from '@components/Chart/types';
import Divider from '@components/Divider';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import classes from './MultiTooltip.module.css';
import classNames from 'classnames';

const maxLength = 12;
const periodAverage = 'Average';

function TooltipContent({ data }: { data: MultiTooltipPeriodType }) {
	const { getParameterDisplayNameWithValue } = useParameters();
	const hasAppliedParameters = useMemo(
		() => data.appliedParameters && data.appliedParameters.length > 0,
		[data.appliedParameters]
	);

	return (
		<Flex flexDirection={'column'} key={data.name} gap="4px">
			<Flex justifyContent="space-between" align="center" gap="24px">
				<Flex align="center" gap="8px">
					<Box
						borderRadius={'1px'}
						width={'8px'}
						height={'8px'}
						background={isString(data.color) ? data.color : data.color?.pattern?.color ?? ''}
						className={classNames({ [classes.secondary]: !isString(data.color) })}
					/>
					<MultiToolTipNameField
						name={[
							data.name,
							data.id?.startsWith(ID_PREFIX_SERIES_TIME_SPAN_SECONDARY) ? ` (Previous Period)` : ``,
						].join('')}
					/>
				</Flex>
				<Typography color="white" variant="DesktopH10Regular">
					{data.value === '0' && '$'}
					{data.value}
				</Typography>
			</Flex>
			{hasAppliedParameters && (
				<Flex flexDirection={'column'} key={data.name} gap="2px" paddingLeft={'16px'}>
					{data.appliedParameters?.map((parameter) => (
						<Typography color="gray.600" variant={'DesktopH10Regular'} key={parameter.key}>
							{getParameterDisplayNameWithValue(parameter.key, parameter.value)}
						</Typography>
					))}
				</Flex>
			)}
		</Flex>
	);
}

export const MultiToolTip: FC<MultiToolTipPropsType> = ({
	currPeriod,
	currPeriodData,
	bubbles,
	mainTitles,
	onMouseLeave,
}) => {
	const isMainValue = (name: string) => mainTitles?.includes(name);
	const [sectionsData, setSectionsData] = useState<{
		bubbles?: MultiTooltipPeriodType[] | null;
		stats?: MultiTooltipPeriodType[] | null;
	}>();

	// getting main periods from general period data
	const mainCurrPeriodData = currPeriodData
		?.filter((period) => isMainValue(period.name))
		.filter((period) => period.value !== '0');
	useEffect(() => {
		// removing main periods from stats
		const filteredStatsData = currPeriodData
			?.map((period) => !mainTitles?.includes(period.name) && period.name !== periodAverage && period)
			.filter((period) => !!period);

		const filteredBubblesData = currPeriodData?.find((period) => period.name === periodAverage);
		const bubbleData = bubbles && filteredBubblesData ? [...bubbles, filteredBubblesData] : null;

		setSectionsData({
			bubbles: bubbleData ? bubbleData : bubbles,
			stats: filteredStatsData,
		});
	}, [currPeriodData, mainTitles, bubbles]);

	const shouldShowFirstDivider =
		(!!mainCurrPeriodData?.length && !!sectionsData?.stats?.length) ||
		(!!sectionsData?.bubbles?.length && !!mainCurrPeriodData?.length);
	const shouldShowSecondDivider = !!(sectionsData?.bubbles?.length && sectionsData?.stats?.length);

	const totalCount =
		Number(mainCurrPeriodData?.length ?? 0) +
		Number(sectionsData?.bubbles?.length ?? 0) +
		Number(sectionsData?.stats?.length ?? 0);

	const [statsLimit, setStatsLimit] = useState<number | null>(null);

	useEffect(() => {
		if (!isNaN(totalCount) && totalCount >= maxLength && !statsLimit) {
			const mainCurrPeriodDataCount = mainCurrPeriodData?.length || 0;
			const bubblesCount = sectionsData?.bubbles?.length || 0;
			setStatsLimit(maxLength - mainCurrPeriodDataCount - bubblesCount);
		}
	}, [mainCurrPeriodData, sectionsData, statsLimit, totalCount]);

	const statsSectionData = useMemo(() => {
		if (statsLimit) {
			return sectionsData?.stats?.slice(0, statsLimit);
		}

		return sectionsData?.stats;
	}, [sectionsData, statsLimit]);

	return (
		<Flex
			maxWidth={'308px'}
			minWidth={'200px'}
			onMouseLeave={onMouseLeave}
			id="#multiToolTip"
			boxShadow={shadows['z5']}
			background="black"
			borderRadius="8px"
			direction="column"
			zIndex={50}
			padding="16px"
			boxSizing="content-box"
		>
			<Typography variant="Tags10B" color={colors.gray[400]} marginBottom="8px">
				{currPeriod}
			</Typography>
			<Flex direction="column">
				{!!mainCurrPeriodData && (
					<Flex direction="column" gap="6px">
						{mainCurrPeriodData?.map((data) => (
							<TooltipContent data={data} key={data.id} />
						))}
					</Flex>
				)}

				{shouldShowFirstDivider && <Divider opacity="24%" marginY="16px" color="white" direction="horizontal" />}
				{!!sectionsData?.stats && (
					<Flex direction="column" gap="6px">
						{statsSectionData?.map((data) => (
							<TooltipContent data={data} key={data.id} />
						))}
						{statsLimit && sectionsData.stats.length - statsLimit > 0 && (
							<Typography color="gray.400" variant="DesktopH10Regular">
								+ {sectionsData.stats.length - statsLimit} more
							</Typography>
						)}
					</Flex>
				)}
				{shouldShowSecondDivider && <Divider opacity="24%" marginY="16px" color="white" direction="horizontal" />}
				{!!sectionsData?.bubbles?.length && (
					<Flex direction="column" gap="6px">
						{sectionsData.bubbles?.map((data) => (
							<TooltipContent data={data} key={data.name} />
						))}
					</Flex>
				)}
			</Flex>
		</Flex>
	);
};

function MultiToolTipNameField({ name }: { name: string }) {
	return (
		<Typography
			whiteSpace="nowrap"
			overflow="hidden"
			textOverflow="ellipsis"
			maxWidth="200px"
			color="white"
			variant="DesktopH10Medium"
		>
			{name}
		</Typography>
	);
}
