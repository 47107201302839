import { Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { useArrModelOutputState } from '../hooks/useArrModelOutputState';
import { MappingFooter } from './components/MappingFooter';
import { MappingBuilderPanel } from './MappingBuilderPanel';
import { MappingPreviewFrame } from './MappingPreviewFrame';

export function MappingFrame() {
	const [focusedInputBoxIndex, setFocusedInputBoxIndex] = useState(0);
	const {
		arrModelOutputState,
		isLoading,
		setGranularEntityArrAmountDefinitions,
		setGranularEntityRecognitionEndDefinitions,
		setGranularEntityRecognitionStartDefinitions,
	} = useArrModelOutputState();
	return (
		<Flex flexDirection={'column'} width={'100%'} height={'100%'} minH={'100%'} maxH={'100%'}>
			<Flex flexDir={'row'} flexGrow={'1'} height={'0'}>
				<Flex width={'344px'}>
					<MappingBuilderPanel
						onFocusedInputBoxIndexChange={setFocusedInputBoxIndex}
						setGranularEntityArrAmountDefinitions={setGranularEntityArrAmountDefinitions}
						setGranularEntityRecognitionEndDefinitions={setGranularEntityRecognitionEndDefinitions}
						setGranularEntityRecognitionStartDefinitions={setGranularEntityRecognitionStartDefinitions}
						arrModelOutputState={arrModelOutputState}
						isLoading={isLoading}
					/>
				</Flex>
				<Flex flex={'1'}>
					<MappingPreviewFrame
						focusedInputBoxIndex={focusedInputBoxIndex}
						arrModelOutputState={arrModelOutputState}
						isLoading={isLoading}
					/>
				</Flex>
			</Flex>
			<MappingFooter onCancel={() => {}} onBack={() => {}} onSave={() => {}} isSaveDisabled={isLoading} />
		</Flex>
	);
}
