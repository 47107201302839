import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { BrokenChart70 } from '../../../Icons';
import RulesEngineRunner from 'src/pages/MetricPage/components/RulesEngineRunner';
import Chart from '../../../Chart';
import Flex from '../../../Flex';
import Box from '../../../Box';
import Center from '../../../Center';
import { Img } from '@chakra-ui/react';
import { SignalWidgetHeader } from 'src/pages/DashboardPage/components/Widget/SignalWidget/SignalWidgetHeader';
import { DiscoverMetricsSuggested, OnCalcResultsReceived, OnCalcResultsStarted } from '../../types';
import { useEffect, useMemo, useState } from 'react';
import { HorizontalLegend } from 'src/pages/DashboardPage/components/HorizontalLegend/HorizontalLegend';
import { getSourcesInfo, getSourceInfoLogo } from 'src/common/hooks/useSourceInfo';
import Tooltip from '../../../Tooltip';
import { useMetricSearchParamsBuilder } from 'src/pages/MetricPage/hooks/useMetricSearchParamsBuilder';
import PendingSetupMetricPreview from 'src/assets/images/pending-setup-metric-preview.svg';
import { useSemanticsGetMetricDisplayName } from '../../../../hooks/stores/useSemanticDefinitions';

export function AskAIMetricChartPreview({
	metric,
	showPreview,
	onCalcResultsReceived,
	onCalcResultsStarted,
	testId,
	onClick,
}: {
	metric: DiscoverMetricsSuggested;
	showPreview: boolean;
	onCalcResultsReceived?: OnCalcResultsReceived;
	onCalcResultsStarted?: OnCalcResultsStarted;
	onClick: () => void;
	testId: string;
}) {
	const [isDoneBuildingSearchParams, { searchParams }] = useMetricSearchParamsBuilder(metric);
	if (!isDoneBuildingSearchParams) return;

	return (
		<RulesEngineRunner>
			<MetricChartPreview
				metric={metric}
				showPreview={showPreview}
				onCalcResultsReceived={onCalcResultsReceived}
				onCalcResultsStarted={onCalcResultsStarted}
				onClick={onClick}
				testId={testId}
				searchParams={searchParams}
			/>
		</RulesEngineRunner>
	);
}

function MetricChartPreview({
	metric,
	showPreview,
	onCalcResultsReceived,
	onCalcResultsStarted,
	testId,
	onClick,
	searchParams,
}: {
	metric: DiscoverMetricsSuggested;
	showPreview: boolean;
	onCalcResultsReceived?: OnCalcResultsReceived;
	onCalcResultsStarted?: OnCalcResultsStarted;
	onClick: () => void;
	testId: string;
	searchParams: URLSearchParams;
}) {
	const getMetricDisplayName = useSemanticsGetMetricDisplayName();
	const displayName = getMetricDisplayName(metric.metric);
	const { isLoading, isRulesEngineRunning, chartOptions, sqlQuery } = useMetricDerivedState();
	const [alreadyNotifiedCalcResultsReceived, setAlreadyNotifiedCalcResultsReceived] = useState(false);
	const [alreadyNotifyCalcResultsStarted, setAlreadyNotifiedCalcResultsStarted] = useState(false);

	useEffect(() => {
		if (alreadyNotifyCalcResultsStarted || !isLoading) return;
		setAlreadyNotifiedCalcResultsStarted(true);
		onCalcResultsStarted?.({ metric });
	}, [isLoading, metric, onCalcResultsStarted, alreadyNotifyCalcResultsStarted]);

	useEffect(() => {
		if (alreadyNotifiedCalcResultsReceived || isLoading || isRulesEngineRunning) return;
		setAlreadyNotifiedCalcResultsReceived(true);
		const calcResults = {
			dataSeries: chartOptions.series.map((series) => ({
				id: series.id,
				name: series.name,
				chartType: series.chartType,
				yAxis: series.yAxis,
				options: series.options,
				seriesMetadata: series.custom ? { ...series.custom, seriesDataPointYFormatter: undefined } : undefined,
				dataPoints: series.data.map((dataPoint) => ({
					yValue: dataPoint.y,
					name: dataPoint.name,
					isSum: dataPoint.isSum,
					stats: dataPoint.custom
						? {
								count: dataPoint.custom.count,
								percentage: dataPoint.custom.percentage,
								percentagePrev: dataPoint.custom.percentagePrev,
								percentageFirst: dataPoint.custom.percentageFirst,
								label: dataPoint.custom.label,
						  }
						: undefined,
				})),
			})),
		};
		onCalcResultsReceived?.({ metric: metric.metric, calcResults, sqlQuery });
	}, [
		isLoading,
		isRulesEngineRunning,
		metric,
		onCalcResultsReceived,
		chartOptions.series,
		sqlQuery,
		alreadyNotifiedCalcResultsReceived,
	]);

	if (isLoading || isRulesEngineRunning || !showPreview) return;

	return (
		<Box>
			<Flex
				border={'1px solid'}
				borderColor={'gray.300'}
				borderRadius={'8px'}
				direction={'column'}
				data-testid={testId}
				data-search-params={searchParams}
				overflow={'hidden'}
			>
				<SignalWidgetHeader
					isClickable={true}
					onClick={onClick}
					isEditMode={false}
					metricTitle={displayName}
					title={displayName}
					isLoading={false}
				/>
				<ChartPreview />
			</Flex>
		</Box>
	);
}

function ChartPreview() {
	const {
		chartOptions,
		errorMessage = '',
		isFullyDefined,
		displayedLegendItems,
		metricSource,
	} = useMetricDerivedState();
	const sourceInfo = useMemo(() => getSourcesInfo({ source: metricSource }), [metricSource]);
	const SourceLogo = useMemo(
		() => getSourceInfoLogo({ bigLogo: true, dataConnectorInfo: sourceInfo?.connectorInfo }),
		[sourceInfo?.connectorInfo]
	);

	if (!isFullyDefined) {
		return <Img src={PendingSetupMetricPreview} objectFit="cover" width={'100%'} height={'162px'} />;
	}

	const isMetricBroken = !!errorMessage;
	if (isMetricBroken)
		return (
			<Center width={'100%'} height={'162px'}>
				<BrokenChart70 />
			</Center>
		);

	return (
		<Flex width="100%" padding={0} direction={'column'}>
			<Box maxHeight={'152px'}>
				<Chart
					isTooltipEnabled={true}
					height={'152px'}
					spacing={[0, 0, 0, 0]}
					isEntityPage={false}
					xAxisLabelsVisible={true}
				/>
			</Box>
			<Flex p={`0 16px 12px 16px`} justifyContent={'space-between'} alignItems={'center'}>
				<HorizontalLegend
					series={chartOptions.series}
					bubbles={chartOptions.bubbles}
					displayedLegendItems={displayedLegendItems}
				/>
				{SourceLogo && (
					<Box paddingLeft={'24px'}>
						<Tooltip label={sourceInfo?.connectorInfo?.name} size="sm">
							{SourceLogo}
						</Tooltip>
					</Box>
				)}
			</Flex>
		</Flex>
	);
}
