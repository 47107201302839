import { PeriodRange } from '@sightfull/period-ranges';
import { useCallback, useMemo } from 'react';
import useRESTAuthInfo from 'src/common/hooks/useRESTAuthInfo';
import { buildParametersForApi } from 'src/common/utils/parameters';
import { getSelectedPeriodRange, prepareDetailedTableParams } from 'src/lib/metricRules/DerivedStateCalculators';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';
import { TableType } from 'src/pages/MetricPage/utils/state.types';
import { buildFilters } from '../../FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { buildExcelPowerQueryFile, buildGoogleSheetsFormula } from './buildExportLiveUrl';
import { useCacheMetricParams } from './useCacheMetricParams';

const DetailedTableDataExportBase = '/v2/export/detailed-table';
const MetricTableDataExportBase = '/v2/export/metric-table';

export const useCoreReaderDetailedExport = (shouldUseToken = true, overrideTableType?: TableType) => {
	const { accessToken, apiUrl } = useRESTAuthInfo();
	const {
		metricNameWithFlavor,
		selectedXAxisElements,
		filters,
		breakdowns,
		collectedProps,
		tableColumnState,
		tableType: tableTypeFromDerivedState,
		periodRange,
		objectsTypes,
	} = useMetricDerivedState();
	const tableType = overrideTableType ?? tableTypeFromDerivedState;
	const { searchParams } = useMetricPageSearchParams();
	const { cacheMetricParams } = useCacheMetricParams();

	const parameters = useMemo(() => {
		if (!searchParams.parameters) return;
		return JSON.stringify(buildParametersForApi(searchParams.parameters));
	}, [searchParams.parameters]);

	const getMetricTableUrl = useCallback(async () => {
		const url = new URL(MetricTableDataExportBase, apiUrl);
		const filterBy = buildFilters(filters, objectsTypes[0]);
		const groupBy = breakdowns.values.map((breakdown) => breakdown.key);

		url.searchParams.append('metric', metricNameWithFlavor);
		url.searchParams.append('start', periodRange.asAbsoluteRange.startPeriod.id);
		url.searchParams.append('end', periodRange.asAbsoluteRange.endPeriod.id);
		url.searchParams.append('visual_table', 'true');
		url.searchParams.append('display_names', 'true');

		if (filterBy && filterBy.length > 0) url.searchParams.append('filter', JSON.stringify(filterBy));
		if (groupBy && groupBy.length > 0) url.searchParams.append('group_by', JSON.stringify(groupBy));
		if (parameters) url.searchParams.append('parameters', parameters);

		if (shouldUseToken) url.searchParams.append('token', accessToken);

		return url;
	}, [
		accessToken,
		apiUrl,
		breakdowns.values,
		filters,
		parameters,
		metricNameWithFlavor,
		periodRange.asAbsoluteRange.endPeriod.id,
		periodRange.asAbsoluteRange.startPeriod.id,
		shouldUseToken,
		objectsTypes,
	]);

	const getDetailedTableUrl = useCallback(
		async (isDownload?: boolean) => {
			const selectedPeriodRange: PeriodRange | null = getSelectedPeriodRange(selectedXAxisElements);
			if (!selectedPeriodRange) throw new Error('No selected period range');
			const { metricName, start, end, filterBy, additionalColumns } = prepareDetailedTableParams(
				metricNameWithFlavor,
				selectedPeriodRange,
				filters,
				breakdowns,
				collectedProps,
				tableColumnState,
				tableType,
				objectsTypes
			);

			const cache_metric_uuid = await cacheMetricParams();
			const url = new URL(DetailedTableDataExportBase, apiUrl);
			url.searchParams.append('metric', metricName);
			url.searchParams.append('start', start);
			url.searchParams.append('end', end);
			url.searchParams.append('visual_table', 'true');
			url.searchParams.append('display_names', 'true');

			if (filterBy && filterBy.length > 0) url.searchParams.append('filter', JSON.stringify(filterBy));
			if (parameters) url.searchParams.append('parameters', parameters);
			if (additionalColumns && additionalColumns.length > 0)
				url.searchParams.append('additional_columns', JSON.stringify(additionalColumns));

			if (shouldUseToken) url.searchParams.append('token', accessToken);
			if (isDownload) url.searchParams.append('do_not_track', 'true');
			if (cache_metric_uuid != null) url.searchParams.append('cache_metric_uuid', cache_metric_uuid);

			return url;
		},
		[
			selectedXAxisElements,
			metricNameWithFlavor,
			filters,
			breakdowns,
			parameters,
			collectedProps,
			tableColumnState,
			tableType,
			cacheMetricParams,
			apiUrl,
			shouldUseToken,
			accessToken,
			objectsTypes,
		]
	);

	const getUrl = useCallback(
		async (isDownload?: boolean) => {
			if (tableType == 'MetricTable') return getMetricTableUrl();
			return getDetailedTableUrl(isDownload);
		},
		[getDetailedTableUrl, getMetricTableUrl, tableType]
	);

	const startDownload = async () => {
		const url = await getUrl(true);
		window.open(url);
	};

	const exportToGoogleSheet = async (shouldUseNameRanges: boolean) => {
		const baseApiRequestUrl = (await getUrl()).href;
		return buildGoogleSheetsFormula({ shouldUseNameRanges, baseApiRequestUrl });
	};

	const exportToExcelPowerQueryFile = async () => {
		const baseApiRequestUrl = (await getUrl()).href;
		return buildExcelPowerQueryFile(baseApiRequestUrl);
	};

	return { startDownload, exportToGoogleSheet, exportToExcelPowerQueryFile };
};
