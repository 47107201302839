import { Popover, PopoverAnchor, PopoverArrow, PopoverContent, PopoverTrigger, Portal, theme } from '@chakra-ui/react';
import Box from '@components/Box';
import Flex from '@components/Flex';
import Input from '@components/Input';
import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useKeyPress } from 'src/common/hooks/interaction/useKeyPress';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useReportEvent } from 'src/services/analytics';
import { ConfirmationModal } from '../ConfirmationModal';
import { ChevronDown16, ChevronUp16, ErrorYellow16 } from '../Icons';
import ListItem from '../ListItem';
import { Search } from '../Search';
import { NestedSelectOption, SelectOption } from '../Select/types';
import Typography from '../Typography';
import classes from './AdvancedSelect.module.scss';
import colors from 'src/style/colors';
import { TestIDs } from '../../types/test-ids';
import useDebouncedCallback from 'src/common/hooks/useDebouncedCallback';
import Divider from '../Divider';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

const MAX_DROPDOWN_HEIGHT = 346;
const INDENT = 8;

const { advancedSelect, dropdownMenu, menuSearch, inputActive, warningIcon, optionsList, inputDisabled } = classes;

export const AdvancedSelect = ({
	bottomDropdownContent,
	leftComponentBorder = true,
	options,
	nestedOptions,
	placeholder,
	onChange,
	initialValue,
	isRequired = false,
	isWarningModalEnabled = false,
	isDisabled = false,
	controlledValue,
	dataIntercomTarget = 'selector',
	onBlur,
	label,
	onReportEvent,
	onSearchReportEvent,
	entityName = '',
	hoverTooltipBuilder,
	warningTooltip,
	testId,
}: {
	bottomDropdownContent?: ReactNode;
	leftComponentBorder?: boolean;
	controlledValue?: string;
	placeholder: string;
	onChange: (el: SelectOption) => void;
	initialValue?: SelectOption;
	isRequired?: boolean;
	isWarningModalEnabled?: boolean;
	isDisabled?: boolean;
	dataIntercomTarget?: string;
	onBlur?: VoidFunction;
	label?: string;
	onReportEvent?: (val?: string, label?: string) => void;
	onSearchReportEvent?: (val?: string) => void;
	entityName?: string;
	hoverTooltipBuilder?: (option: SelectOption) => ReactNode;
	warningTooltip?: ReactNode;
	testId?: string;
} & (
	| { options: SelectOption[] | void; nestedOptions?: never }
	| { nestedOptions: NestedSelectOption[] | void; options?: never }
)) => {
	const [menuIsOpened, setMenuIsOpened] = useState(false);
	const [position, setPosition] = useState<{ top: number; left: number; width: number; height: number }>({
		top: 0,
		left: 0,
		width: 0,
		height: 0,
	});
	const [hoverIndex, setHoverIndex] = useState<string>();
	const [searchValue, setSearchValue] = useState('');
	const [isInputFocused, setIsInputFocused] = useState(false);
	const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>(initialValue);
	const [selectedWarningOption, setSelectedWarningOption] = useState<SelectOption | undefined>();
	const inputWrapperRef = useRef<HTMLDivElement>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const scrollableDivRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const isWarn = !!warningTooltip;
	const isWarningIconVisible = (isRequired && !selectedOption?.label && !menuIsOpened) || isWarn;
	const labelLowerCase = useMemo(() => label?.toLowerCase() ?? 'advanced-search', [label]);
	const { isOpen, onOpen, onClose } = useModal();
	const { wrapWithReport, reportEvent } = useReportEvent({ feature: 'Create New Metric' });

	const closeMenu = useCallback(() => {
		setSearchValue('');
		setMenuIsOpened(false);
		setHoverIndex(undefined);
	}, [setSearchValue, setMenuIsOpened]);

	const setDropdownPlacement = () => {
		if (inputWrapperRef.current && dropdownRef.current) {
			const { top, bottom, left, width } = inputWrapperRef.current.getBoundingClientRect();
			const dropdownHeight = dropdownRef.current.getBoundingClientRect().height - INDENT;
			const dropdownPositionedHeight = dropdownHeight + INDENT;
			const topPosition =
				window.innerHeight - bottom >= dropdownPositionedHeight
					? bottom + window.scrollY
					: top + window.scrollY - dropdownPositionedHeight - INDENT;
			setPosition({
				top: topPosition,
				left: left + window.scrollX,
				width,
				height: dropdownHeight >= MAX_DROPDOWN_HEIGHT ? MAX_DROPDOWN_HEIGHT : dropdownHeight,
			});
		}
	};

	useEffect(() => {
		if (menuIsOpened) {
			setTimeout(() => setDropdownPlacement());
		}
	}, [menuIsOpened]);

	const checkForValue = useCallback(
		(value?: string) => value?.toLocaleLowerCase()?.includes(searchValue.toLowerCase()),
		[searchValue]
	);

	const optionsArray = useMemo(
		() => options?.filter((el) => checkForValue(el.label || '') || checkForValue(el.value)) || [],
		[checkForValue, options]
	);

	const nestedOptionsArray = useMemo(
		() =>
			nestedOptions
				?.map((option) => ({
					...option,
					value: option?.value?.filter((el) => checkForValue(el.label || '') || checkForValue(el.value)) || [],
				}))
				.filter((el) => el.value.length > 0) || [],
		[checkForValue, nestedOptions]
	);

	const optionArray = useMemo(
		() => (options ? optionsArray : nestedOptionsArray),
		[options, nestedOptionsArray, optionsArray]
	);

	const isElementFullyVisible = (elem: HTMLElement) => {
		if (!scrollableDivRef.current) return false;

		const { top, bottom } = elem.getBoundingClientRect();
		const { top: containerTop, bottom: containerBottom } = scrollableDivRef.current.getBoundingClientRect();

		return top >= containerTop && bottom <= containerBottom;
	};
	const scrollToElement = useCallback((index: string, isScrollingUp: boolean) => {
		const activeElement = document.getElementById(`option-${index}`);
		if (activeElement && !isElementFullyVisible(activeElement)) {
			activeElement.scrollIntoView({ behavior: 'smooth', block: isScrollingUp ? 'start' : 'end' });
		}
	}, []);

	const onItemClick = useCallback(
		(option: SelectOption) => {
			onReportEvent?.(option.value, option.label || '');
			if (selectedOption?.value === option?.value) {
				closeMenu();
				return;
			}
			if (selectedOption && isWarningModalEnabled) {
				setMenuIsOpened(false);
				onOpen();
				setSelectedWarningOption(option);
				return;
			}
			setSelectedOption(option);
			onChange(option);
			closeMenu();
		},
		[isWarningModalEnabled, onChange, onOpen, onReportEvent, selectedOption, closeMenu]
	);

	const toggleDropdown = useCallback(() => {
		if (isDisabled) return;
		setMenuIsOpened(!menuIsOpened);
	}, [isDisabled, menuIsOpened]);

	const getNestedOptions = useCallback(
		(index: number) => {
			const option = optionArray[index];
			return option && isNestedSelectOption(option) ? option.value : [];
		},
		[optionArray]
	);

	const handleKeyDown = useCallback(
		(eventName: string) => {
			if (isInputFocused && eventName === 'Space') {
				toggleDropdown();
				return;
			}
			if (!menuIsOpened) return;

			if (!hoverIndex) {
				setHoverIndex('0-0');
				return;
			}

			const [topIndex, nestedIndex] = hoverIndex.split('-').map(Number);

			const updateHoverIndex = (newTopIndex: number, newNestedIndex: number, smoothScroll: boolean) => {
				const newIndex = `${newTopIndex}-${newNestedIndex}`;
				setHoverIndex(newIndex);
				scrollToElement(newIndex, smoothScroll);
			};

			if (eventName === 'ArrowUp') {
				if (nestedIndex > 0) {
					updateHoverIndex(topIndex, nestedIndex - 1, true);
				} else if (topIndex > 0) {
					const nestedOptions = isNestedSelectOption(optionArray[topIndex - 1]) ? getNestedOptions(topIndex - 1) : [];
					updateHoverIndex(topIndex - 1, Math.max(nestedOptions.length - 1, 0), true);
				} else {
					updateHoverIndex(topIndex, 0, true);
				}
			} else if (eventName === 'ArrowDown') {
				const currentOption = optionArray[topIndex];
				if (isNestedSelectOption(currentOption) && nestedIndex + 1 < currentOption.value.length) {
					updateHoverIndex(topIndex, nestedIndex + 1, false);
				} else if (topIndex + 1 < optionArray.length) {
					updateHoverIndex(topIndex + 1, 0, false);
				}
			} else if (eventName === 'Enter') {
				const selectedItem = isNestedSelectOption(optionArray[topIndex])
					? optionArray[topIndex].value[nestedIndex]
					: optionArray[topIndex];

				if (selectedItem) onItemClick(selectedItem);
				inputRef.current?.focus();
			} else if (eventName === 'Escape') {
				closeMenu();
				inputRef.current?.focus();
			}
		},
		[
			menuIsOpened,
			hoverIndex,
			scrollToElement,
			optionArray,
			onItemClick,
			isInputFocused,
			toggleDropdown,
			closeMenu,
			getNestedOptions,
		]
	);

	useKeyPress(['ArrowUp'], () => handleKeyDown('ArrowUp'));
	useKeyPress(['ArrowDown'], () => handleKeyDown('ArrowDown'));
	useKeyPress(['Enter'], () => handleKeyDown('Enter'));
	useKeyPress(['Space'], () => handleKeyDown('Space'));
	useKeyPress(['Escape'], () => handleKeyDown('Escape'));

	const debouncedSearchReportEvent = useDebouncedCallback(
		(searchTerm: string) => onSearchReportEvent?.(searchTerm),
		500
	);

	const controlledValueOption = useMemo(
		() =>
			options?.find((el) => el.value === controlledValue) ||
			nestedOptions?.flatMap((option) => option?.value).find((dashboard) => dashboard.value === controlledValue) ||
			undefined,
		[controlledValue, nestedOptions, options]
	);
	useEffect(() => {
		if (selectedOption != controlledValueOption) setSelectedOption(controlledValueOption);
	}, [selectedOption, controlledValueOption]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				inputWrapperRef.current &&
				dropdownRef.current &&
				!inputWrapperRef.current.contains(event.target as Node) &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				onBlur?.();
				closeMenu();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onBlur, closeMenu]);

	const EmptyState = () => (
		<Flex flex={1} alignItems={'center'}>
			<Typography width={'100%'} padding={'12px 16px'} variant="DesktopH8Regular" textAlign="center">
				No options to show
			</Typography>
		</Flex>
	);

	const WarningIcon = () => (
		<Popover placement={'right'} variant="solid" arrowPadding={16} closeOnBlur={true} trigger="hover" isLazy>
			<PopoverTrigger>
				<Box className={warningIcon}>
					<ErrorYellow16 />
				</Box>
			</PopoverTrigger>
			{warningTooltip && (
				<Portal>
					<PopoverContent
						backgroundColor={'black'}
						padding={'10px 12px'}
						maxWidth={'324px'}
						borderRadius={'8px'}
						left={'54px'}
					>
						<PopoverArrow backgroundColor={'black'} height={'32px'} width={'32px'} />
						{warningTooltip}
					</PopoverContent>
				</Portal>
			)}
		</Popover>
	);

	const onSubmit = () => {
		if (!selectedWarningOption) return;
		setSelectedOption(selectedWarningOption);
		onChange(selectedWarningOption);
		onClose();
		closeMenu();
	};

	const isNestedSelectOption = (option: SelectOption | NestedSelectOption): option is NestedSelectOption =>
		'value' in option && Array.isArray(option.value);

	const NestedListItem = useCallback(
		({ item, i, isLastElement }: { item: NestedSelectOption; i: number; isLastElement: boolean }) => (
			<>
				<Flex marginY={'8px'} paddingX="16px" alignItems={'center'} gap={'8px'}>
					<Box fontSize={'16px'} lineHeight={'16px'}>
						{item.icon}
					</Box>
					<Typography color={'gray.600'} variant="DesktopH10Medium">
						{item.label}
					</Typography>
				</Flex>

				{item.value.map((nestedEl, nestedIndex) => (
					<ListItem
						id={`option-${i}-${nestedIndex}`}
						testId={TestIDs.ADVANCED_SEARCH_MENU_ITEM(labelLowerCase, nestedEl.value)}
						isHovered={hoverIndex === `${i}-${nestedIndex}`}
						hoverColor="blue.100"
						color="gray.1000"
						state={nestedEl.label === selectedOption?.label ? 'selected' : 'enabled'}
						key={`${i}-${nestedIndex}`}
						size="sm"
						label={nestedEl.label ?? nestedEl.value}
						prefixIcon={nestedEl.icon && <Box>{nestedEl.icon}</Box>}
						onClick={() => onItemClick(nestedEl)}
						noOfLines={1}
					/>
				))}
				{!isLastElement && (
					<Box paddingX={'16px'}>
						<Divider width={'100%'} marginY={'16px'} direction="horizontal" color={'gray.300'} />
					</Box>
				)}
			</>
		),
		[hoverIndex, labelLowerCase, onItemClick, selectedOption?.label]
	);

	const optionList = useMemo(() => {
		return optionArray.map((el, i) => {
			const isLastElement = i + 1 === optionArray.length;
			if (isNestedSelectOption(el)) {
				return (
					<Box key={i}>
						<NestedListItem item={el} i={i} isLastElement={isLastElement} />
					</Box>
				);
			} else {
				return (
					<ListItem
						id={`option-${i}-0`}
						testId={TestIDs.ADVANCED_SEARCH_MENU_ITEM(labelLowerCase, el.value)}
						isHovered={hoverIndex === `${i}-0`}
						hoverColor="blue.100"
						color="gray.1000"
						state={el.label === selectedOption?.label ? 'selected' : 'enabled'}
						key={i}
						size="sm"
						label={el.label ?? el.value}
						prefixIcon={el.icon && <Box>{el.icon}</Box>}
						onClick={() => onItemClick(el)}
						noOfLines={1}
					/>
				);
			}
		});
	}, [hoverIndex, onItemClick, optionArray, selectedOption?.label, labelLowerCase, NestedListItem]);

	const hoverTooltip = useMemo(() => {
		if (!hoverIndex) return;
		const [topIndex, nestedIndex] = hoverIndex.split('-').map(Number);
		const selectedOption = optionArray[topIndex];
		if (!selectedOption) return;
		if (isNestedSelectOption(selectedOption)) return hoverTooltipBuilder?.(selectedOption.value[nestedIndex]);
		return hoverTooltipBuilder?.(selectedOption);
	}, [hoverIndex, hoverTooltipBuilder, optionArray]);

	const DropdownMenu = (
		<Portal>
			<Flex
				ref={dropdownRef}
				style={{ top: position.top, left: position.left, width: position.width }}
				position={'absolute'}
				zIndex={theme.zIndices.popover}
			>
				<Popover placement="left-start" isOpen={!!hoverTooltip} autoFocus={false}>
					<PopoverContent autoFocus={false} background={'0'} boxShadow={'0'}>
						{hoverTooltip}
					</PopoverContent>
					<PopoverAnchor>
						<Flex className={dropdownMenu} paddingBottom={bottomDropdownContent ? '44px' : 0} width={position.width}>
							<Box className={menuSearch}>
								<Search
									iconColor="gray.1000"
									height="44px"
									isDeBounced={false}
									initialValue={searchValue}
									isAutoFocused
									onChange={(val) => {
										debouncedSearchReportEvent(val);
										setSearchValue(val);
									}}
									isTransparent
									width="100%"
									placeholder="Search"
								/>
							</Box>
							{!optionArray?.length ? (
								<EmptyState />
							) : (
								<Box ref={scrollableDivRef} className={optionsList}>
									{optionList}
								</Box>
							)}
							<Box onClick={closeMenu}>{bottomDropdownContent}</Box>
						</Flex>
					</PopoverAnchor>
				</Popover>
			</Flex>
		</Portal>
	);

	const valueToTest = initialValue || controlledValueOption;
	const isInvalidSelect = useMemo(
		() =>
			!!(
				valueToTest?.value &&
				!options?.some((option) => option.value === valueToTest?.value) &&
				!nestedOptions?.some((option) => option.value.some((el) => el.value === valueToTest?.value))
			),
		[nestedOptions, options, valueToTest?.value]
	);

	const isDataSource = label === 'Data source';

	return (
		<>
			<Box
				width={'100%'}
				cursor={isDisabled ? 'not-allowed' : 'pointer'}
				ref={inputWrapperRef}
				{...buildIntercomAttributes({ type: 'main', target: `${dataIntercomTarget}-selector` })}
			>
				<Box
					className={classNames(advancedSelect, {
						[inputActive]: menuIsOpened && !isInvalidSelect,
						[inputDisabled]: isDisabled && !isInvalidSelect,
					})}
				>
					<Input
						leftComponentBorder={leftComponentBorder}
						_ref={inputRef}
						testId={testId}
						isInvalid={isInvalidSelect}
						onClick={toggleDropdown}
						onFocus={() => setIsInputFocused(true)}
						onBlur={() => setIsInputFocused(false)}
						size={'md'}
						rightComponent={
							menuIsOpened ? (
								<ChevronUp16 color={colors.gray[1000]} onClick={toggleDropdown} />
							) : (
								<ChevronDown16 color={colors.gray[1000]} onClick={toggleDropdown} />
							)
						}
						leftComponent={selectedOption?.icon ? <Box>{selectedOption?.icon}</Box> : undefined}
						placeholder={placeholder}
						value={selectedOption?.label || ''}
						isWarn={isWarn}
					/>
					{isWarningIconVisible && (
						<Box onClick={toggleDropdown}>
							<WarningIcon />
						</Box>
					)}
				</Box>
				<ConfirmationModal
					submitColorScheme="blue"
					isOpen={isOpen}
					onSubmit={wrapWithReport(
						onSubmit,
						isDataSource ? 'ontology-entity-change-source-modal' : 'metric-edit-change-entity-modal',
						{
							feature: 'ontology',
							action: 'confirm',
							entityName: entityName,
						}
					)}
					onClose={() => {
						reportEvent({
							event: isDataSource ? 'ontology-entity-change-source-modal' : 'metric-edit-change-entity-modal',
							metaData: { action: 'cancel', entityName: entityName, feature: 'ontology' },
						});
						onClose();
					}}
					modalTitle={`Change ${label}.`}
					modalText={`Changing the ${label?.toLowerCase()} will clear all inputs.`}
				/>
			</Box>
			{menuIsOpened && DropdownMenu}
		</>
	);
};
