import colors from 'src/style/colors';
import Flex from '@components/Flex';
import React from 'react';

export function ArrModelTab() {
	return (
		<Flex
			height={'80px'}
			padding={'16px'}
			alignItems={'flex-start'}
			gap={'16px'}
			alignSelf={'stretch'}
			borderRadius={'8px'}
			border={'1px solid'}
			borderColor={colors.natural['1000']}
		></Flex>
	);
}
