import Box from '@components/Box';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { Link } from '@chakra-ui/react';
import { ChevronLeftSmallBold, ChevronRightSmallBold } from '@icons/index';
import classnames from 'classnames';
import classes from '@pages/MetricCatalog/MetricCatalogBodyV2.module.scss';
import AliceCarousel from 'react-alice-carousel';
import { MetricMetadataV2 } from 'src/types/metric';
import { useReportEvent } from '@services/analytics';
import { useCarousel } from '@hooks/ui/useCarousel';
import { PendingSetupMetricCard } from './MetricCards/PendingSetupMetricCard';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

type PendingSetupCategoryProps = {
	pendingSetupMetrics: MetricMetadataV2[];
	getMetricHref: (metric: MetricMetadataV2) => string;
	paddingLeft: string;
};

export const PendingSetupCategory = ({
	pendingSetupMetrics,
	getMetricHref,
	paddingLeft,
}: PendingSetupCategoryProps) => {
	const { reportEvent } = useReportEvent();
	const {
		carouselElementKey,
		isPrevSlideDisabled,
		isNextSlideDisabled,
		isOnlyOneSlide,
		carouselRef,
		goToNextSlide,
		goToPrevSlide,
		debouncedCarouselResize,
		setCarouselProps,
	} = useCarousel();

	const CarouselArrowButtons = () => {
		return (
			<Flex cursor={'pointer'} gap={'4px'} pr={'32px'}>
				<span
					className={classnames(classes.carouselButton, {
						[classes.isDisabled]: isPrevSlideDisabled,
					})}
					onClick={() => {
						reportEvent({
							event: 'catalog-pending-setup-carousel-arrow-button-click',
							metaData: { action: 'prevSlide' },
						});
						goToPrevSlide();
					}}
				>
					<ChevronLeftSmallBold />
				</span>
				<span
					className={classnames(classes.carouselButton, {
						[classes.isDisabled]: isNextSlideDisabled,
					})}
					onClick={() => {
						reportEvent({
							event: 'catalog-pending-setup-carousel-arrow-button-click',
							metaData: { action: 'nextSlide' },
						});
						goToNextSlide(pendingSetupMetrics);
					}}
				>
					<ChevronRightSmallBold />
				</span>
			</Flex>
		);
	};

	return (
		<>
			<Flex
				id={'pending-setup'}
				{...buildIntercomAttributes({
					area: 'catalog',
					type: 'category',
					target: 'pending-setup',
				})}
				pl={paddingLeft}
				flexDirection={'column'}
				gap={'16px'}
				mb="60px"
			>
				<Flex gap={'12px'}>
					<Typography variant={'DesktopH7Medium'} color="gray.1000">
						Pending metrics
					</Typography>
					{!isOnlyOneSlide && <CarouselArrowButtons />}
				</Flex>
				<AliceCarousel
					key={carouselElementKey}
					ref={carouselRef}
					disableButtonsControls
					disableDotsControls
					responsive={{
						0: { items: 1 },
						900: { items: 2, itemsFit: 'contain' },
						1160: { items: 3, itemsFit: 'contain' },
						1400: { items: 4, itemsFit: 'contain' },
					}}
					onSlideChanged={setCarouselProps}
					onInitialized={setCarouselProps}
					onResized={() => debouncedCarouselResize('pending-setup')}
					paddingRight={16}
					mouseTracking
					items={pendingSetupMetrics.map((metric, index) => (
						<Link
							href={getMetricHref(metric)}
							_hover={{ textDecoration: 'none' }}
							w={'100%'}
							key={`pending-setup-${metric.name}-${metric.entity}`}
						>
							<Box mr={'20px'}>
								<PendingSetupMetricCard metric={metric} dataIntercomSequence={index + 1} />
							</Box>
						</Link>
					))}
				/>
			</Flex>
		</>
	);
};
