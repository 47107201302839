import { useSemanticDefinitions } from 'src/common/hooks/stores/useSemanticDefinitions';
import { removeDollarSigns } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { FilterV2, FilterV2Value } from 'src/pages/MetricPage/utils/state.types';
import { getSubstringAfterDot } from 'src/common/components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/utils';
import {
	defaultOperatorByFilterType,
	dimensionTypeToFilterType,
} from 'src/common/components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/constants';
import { FiltersAndBreakdownResponseType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import { getColumnsFromFilterAndBreakdownResult } from '../../../pages/OntologyPage/components/Table/utils';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { getFirstObjectType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/utils';

export function useFilterV2Logic({
	initialFilters = [],
	onFilterSubmit,
}: {
	initialFilters?: FilterV2[];
	onFilterSubmit?: (filtersArray: FilterV2[]) => void;
}) {
	const { semanticDefinitions } = useSemanticDefinitions();

	const [hasInitializedInititlFilters, setHasInitializedInitialFilters] = useState(false);

	useEffect(() => {
		if (!hasInitializedInititlFilters && initialFilters.length > 0) {
			setFiltersArray(initialFilters);
			setHasInitializedInitialFilters(true);
		}
	}, [initialFilters, hasInitializedInititlFilters]);

	const [filtersArray, setFiltersArray] = useState<FilterV2[]>(initialFilters);

	const serializeFilter = (filter: Omit<FilterV2, 'type' | 'operator'>) => {
		const dimensionToFetch = removeDollarSigns(getSubstringAfterDot(filter?.key || '') || filter?.key || '');

		const type =
			semanticDefinitions?.entities
				.find((entity) => entity.name === filter.baseEntity)
				?.dimensions.find((dimension) => dimension.name === dimensionToFetch)?.type ?? 'string';

		const fullFilter = {
			...filter,
			type: dimensionTypeToFilterType[type],
			operator: defaultOperatorByFilterType[dimensionTypeToFilterType[type]],
		};
		return fullFilter;
	};

	const updateFilterArray = (filterObj: FilterV2, shouldApplyFilter?: boolean, index?: number) => {
		const isMatchingFilter = (el: FilterV2, i: number) => el.values.length === 0 || i === index;
		const updatedFilters = filtersArray.map((el, i) => (isMatchingFilter(el, i) ? filterObj : el));
		const newFilters = filtersArray.some((el, i) => isMatchingFilter(el, i))
			? updatedFilters
			: [...filtersArray, filterObj];
		setFiltersArray(newFilters);
		if (!_.isEqual(newFilters, initialFilters) && shouldApplyFilter) onFilterSubmit?.(newFilters);
	};

	const addFilters = (result: FiltersAndBreakdownResponseType, index?: number) => {
		const { items, entity } = result;
		if (!items.length) return;
		const columns = getColumnsFromFilterAndBreakdownResult(result, getFirstObjectType(result.items[0].key));
		const newFilter = serializeFilter({
			key: columns?.[0],
			values: items.map((i) => i?.value).filter((v): v is FilterV2Value => v !== undefined),
			label: columns?.[0],
			baseEntity: entity,
		});

		updateFilterArray(newFilter, false, index);
	};

	const editFilter = (newFilter: FilterV2, index?: number) => updateFilterArray(newFilter, true, index);

	const removeFilter = (filterIndex: number) => {
		const newFilters = filtersArray.filter((_, i) => i !== filterIndex);
		setFiltersArray(newFilters);
		onFilterSubmit?.(newFilters);
	};

	const removeAllFilters = () => {
		setFiltersArray([]);
		if (initialFilters.length > 0) onFilterSubmit?.([]);
	};

	return {
		filtersArray,
		serializeFilter,
		addFilters,
		editFilter,
		removeFilter,
		removeAllFilters,
	};
}
