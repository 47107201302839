import { useEffect } from 'react';
import useNavigation from 'src/services/useNavigation';
import useFeatureFlag from './stores/useFeatureFlag';

const HOMEPAGE_PATH = '/ai';

export function useHomepageRedirect() {
	const pathname = location?.pathname;

	const { navigate } = useNavigation();
	const isChatDefaultPage = useFeatureFlag('pulse.sightfull2.aiOmniPage.enable');

	useEffect(() => {
		if (pathname === '/' && isChatDefaultPage) {
			navigate({ path: HOMEPAGE_PATH, replace: true }); //TODO: Update with the correct path from the Page
		}
	}, [isChatDefaultPage, navigate, pathname]);
}
