import { ModalHeader } from '@chakra-ui/react';
import Box from '@components/Box';
import Button from '@components/Button';
import Flex from '@components/Flex';
import Modal from '@components/Modal';
import { TinySwitch } from '@components/TinySwitch/TinySwitch';
import Typography from '@components/Typography';
import { CloseLarge16, TagIcon24 } from '@icons/index';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ConfirmationModal } from 'src/common/components/ConfirmationModal';
import Spinner from 'src/common/components/Spinner';
import Tooltip from 'src/common/components/Tooltip';
import { useModal } from 'src/common/hooks/ui/useModal';
import useToast from 'src/common/hooks/ui/useToast';
import { useCreateEntityMutation, useRunNormalizationMutation, useUpdateEntityMutation } from 'src/generated/graphql';
import { convertToValidCoreName, removeUnderscoresAndCapitalize } from 'src/normalize';
import { useReportEvent } from 'src/services/analytics';
import { useInvalidateCache } from 'src/services/apollo';
import useNavigation from 'src/services/useNavigation';
import { usePermissionCheck } from 'src/stores/environment';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { Permissions } from 'src/types/environment';
import { OntologyPagePath } from '../../OntologyBuilderPage';
import {
	OntologyStateAtomDerived,
	writeFullSuccessOntologyState,
	writePartialOntologyState,
} from '../../atoms/OntologyState';
import { NormalizedOntology } from '../../hooks/useOntologyPageState';
import useOntologyUpsertEntity, { KeysType } from '../../hooks/useOntologyUpsertEntity';
import { UNKNOWN_SAVE_AND_RUN_ERROR } from '../../utils/consts';
import { getUpsertDefinitionHash, parseValue } from '../../utils/utils';
import { ModalForm } from './components/ModalForm';
import { ModalYAMLEditor } from './components/ModalYAMLEditor';
import { NameField } from 'src/common/components/NameField';
import { TestIDs } from 'src/common/types/test-ids';
import { AskAIChatFieldSuggestion } from '../../../../common/components/AskAI/types';
import { AskAIOntologyBuilderChatPopover } from '../../../../common/components/AskAI/Chat/OntologyBuilder/AskAIOntologyBuilderChatPopover';
import { AskAIInputAsButton } from '../../../../common/components/AskAI/AskAIInputAsButton';
import { ApolloError } from '@apollo/client';
import { UNTITLED_METRIC_DISPLAY, UNTITLED_METRIC_NAME } from '@hooks/MetricHandling/useMetricEdit';

type ModalProps = {
	isOpen: boolean;
	onClose: VoidFunction;
	isEditEntity?: boolean;
	selectedOntology?: NormalizedOntology | null;
	ontologyEditorValue?: string;
	onModalClose?: VoidFunction;
};

export function UpsertEntityModal({
	isOpen,
	onClose,
	isEditEntity = false,
	selectedOntology,
	ontologyEditorValue,
	onModalClose,
}: ModalProps) {
	const hasWritePermission = usePermissionCheck().isHavingPermission(Permissions.writeEntities);
	const modalTitle = isEditEntity ? `${hasWritePermission ? 'Edit entity' : 'Entity'} details` : 'Create new entity';
	const [isAdvancedMode, setIsAdvancedMode] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);
	const [createEntityMutation, { loading: isCreateEntityInProgress }] = useCreateEntityMutation();
	const [updateEntityMutation, { loading: isUpdateEntityInProgress }] = useUpdateEntityMutation();
	const upsertEntityInProgress = isCreateEntityInProgress || isUpdateEntityInProgress;
	const [runNormalizationMutation, { loading: normalizationInProgress }] = useRunNormalizationMutation();
	const saveAndRunInProgress = upsertEntityInProgress || normalizationInProgress;

	const { invalidateCache } = useInvalidateCache();
	const setSuccessOntologyState = useSetAtom(writeFullSuccessOntologyState);
	const { isOpen: isWarningModalOpen, onOpen: onWarningModalOpen, onClose: onWarningModalClose } = useModal();
	const {
		isOpen: isUpdateWarningModalOpen,
		onOpen: onUpdateWarningModalOpen,
		onClose: onUpdateWarningModalClose,
	} = useModal();
	const { reportEvent, wrapWithReport } = useReportEvent({
		entityName: isEditEntity ? selectedOntology?.name : '',
		feature: 'Upsert entity modal',
	});

	const toast = useToast();

	const {
		entityState,
		entityYAMLValue,
		usersYAMLValue,
		hasUnsavedChanges,
		resetToDefault,
		setEditorYAMLValue,
		updateYAMLValue,
		initializeEntityValues,
	} = useOntologyUpsertEntity({
		selectedEntityName: selectedOntology?.name || '',
		isEditEntity,
		ontologyEditorValue,
		isAdvancedMode,
	});

	const { name, meta, primary_keys, data_source } = entityState || {};
	const { display_name = undefined } = meta || {};
	const ontologyState = useAtomValue(OntologyStateAtomDerived);
	const setPartialOntologyState = useSetAtom(writePartialOntologyState);
	const onChangeValue = (keys: KeysType) => updateYAMLValue(keys);
	const { navigate } = useNavigation();
	const [displayName, setDisplayName] = useState(display_name);
	const { isOpen: isAskAIModalOpen, onOpen: onAskAIModalOpen, onClose: onAskAIModalClose } = useModal();

	const parseMeta = useMemo(() => parseValue(meta) || [], [meta]);
	const isWithRawSql = typeof data_source === 'object' && data_source !== null && 'raw_sql' in data_source;

	useEffect(() => {
		if (display_name === undefined) {
			setDisplayName(removeUnderscoresAndCapitalize(name || UNTITLED_METRIC_DISPLAY));
			return;
		}
		setDisplayName(display_name);
	}, [display_name, name]);

	const onNameUpdate = useCallback(
		(value: string) => {
			const valuesToEdit: KeysType = [
				{
					key: 'meta',
					value: [...parseMeta, { key: 'display_name', value }],
				},
			];
			if (!isEditEntity) {
				valuesToEdit.unshift({
					key: 'name',
					value: convertToValidCoreName(value),
				});
			}

			updateYAMLValue(valuesToEdit);
		},
		[isEditEntity, parseMeta, updateYAMLValue]
	);

	const onAcceptAISuggestions = useCallback(
		(selectedFields: AskAIChatFieldSuggestion[]) => {
			const properties: KeysType = [];
			const newMeta: Partial<typeof meta> = {};
			const newDataSource: Partial<typeof data_source> = {};
			for (const field of selectedFields) {
				if (field.propertyName === 'display_name' || field.propertyName === 'description') {
					newMeta[field.propertyName] = field.value;
				} else if (
					field.propertyName === 'schema' ||
					field.propertyName === 'table' ||
					field.propertyName === 'filters'
				) {
					newDataSource[field.propertyName] = field.value;
				} else {
					properties.push({ key: field.propertyName, value: field.value });
				}
			}
			if (Object.keys(newMeta).length > 0) {
				properties.push({
					key: 'meta',
					value: [
						{ key: 'display_name', value: newMeta.display_name ?? meta?.display_name },
						{ key: 'description', value: newMeta.description ?? meta?.description },
					],
				});
			}
			if (Object.keys(newDataSource).length > 0) {
				properties.push({
					key: 'data_source',
					value: [
						{ key: 'schema', value: newDataSource.schema ?? data_source?.schema },
						{ key: 'table', value: newDataSource.table ?? data_source?.table },
						{ key: 'filters', value: newDataSource.filters ?? data_source?.filters },
					],
				});
			}
			updateYAMLValue(properties);
		},
		[data_source, meta, updateYAMLValue]
	);

	const closeModal = useCallback(() => {
		resetToDefault();
		setIsAdvancedMode(isWithRawSql);
		onClose();
	}, [isWithRawSql, onClose, resetToDefault]);

	const handleSaveError = useCallback(
		(errorMessage: string) => {
			if (ontologyState.loading) return;

			invalidateCache();

			setPartialOntologyState({
				errorMessage,
				editorRequestMessageState: 'ERROR',
				saveInProgress: false,
				normalizationInProgress: false,
				validationInProgress: false,
			});
			toast({
				variant: 'error',
				message: 'Failed, please review and try again',
			});
			reportEvent({
				event: 'save-and-run-ontology-error',
				metaData: {
					feature: 'Upsert entity Editor',
					entity: ontologyState?.entityName,
				},
			});
			if (!isEditEntity) navigate({ path: `${OntologyPagePath}/entity/${name}` });
			closeModal();
		},
		[
			invalidateCache,
			isEditEntity,
			name,
			navigate,
			ontologyState,
			reportEvent,
			setPartialOntologyState,
			toast,
			closeModal,
		]
	);

	const isValidEntityName = name && name !== UNTITLED_METRIC_NAME;

	const onSubmit = useCallback(async () => {
		const entityName = isEditEntity ? selectedOntology?.name : name;
		if (isValidEntityName && entityName !== undefined) {
			const entityDefinition = usersYAMLValue.split('\n');
			onUpdateWarningModalClose();
			let savedEntityVersion: string;
			setPartialOntologyState({ saveInProgress: true });
			try {
				const upsertEntityResult = isEditEntity
					? await updateEntityMutation({
							variables: {
								entityName,
								entityDefinition,
								currentEntityVersion: ontologyState.loading ? '' : ontologyState.currentEntityVersion,
							},
					  })
					: await createEntityMutation({ variables: { entityDefinition } });
				if (upsertEntityResult.errors) {
					const error = upsertEntityResult.errors[0];
					handleSaveError(error.message);
					return;
				}
				if (!upsertEntityResult.data) {
					handleSaveError(UNKNOWN_SAVE_AND_RUN_ERROR);
					return;
				}
				savedEntityVersion = getUpsertDefinitionHash(upsertEntityResult);
				if (!upsertEntityResult?.data) return;
			} catch (error) {
				if (error instanceof ApolloError || error instanceof Error) {
					handleSaveError(error.message);
					return;
				}
				handleSaveError(UNKNOWN_SAVE_AND_RUN_ERROR);
				return;
			}
			setPartialOntologyState({ saveInProgress: false, normalizationInProgress: true });
			try {
				await runNormalizationMutation();
			} catch (error) {
				if (error instanceof Error) {
					handleSaveError(error.message);
					return;
				}
				handleSaveError(UNKNOWN_SAVE_AND_RUN_ERROR);
				return;
			}
			setPartialOntologyState({ normalizationInProgress: false });
			if (isEditEntity)
				setSuccessOntologyState({
					entityName: entityName,
					savedYaml: usersYAMLValue,
					currentEntityVersion: savedEntityVersion,
					savedEntityVersion,
				});
			toast({
				variant: 'ok',
				message: 'Successfully saved',
			});
			invalidateCache();
			setPartialOntologyState({
				errorMessage: '',
				editorRequestMessageState: 'NONE',
			});

			if (selectedOntology?.name !== name) navigate({ path: `${OntologyPagePath}/entity/${name}` });
			closeModal();
			onModalClose?.();
		}
	}, [
		closeModal,
		createEntityMutation,
		handleSaveError,
		invalidateCache,
		isEditEntity,
		isValidEntityName,
		name,
		navigate,
		onModalClose,
		onUpdateWarningModalClose,
		ontologyState,
		ontologyState.loading,
		runNormalizationMutation,
		selectedOntology?.name,
		setPartialOntologyState,
		setSuccessOntologyState,
		toast,
		updateEntityMutation,
		usersYAMLValue,
	]);

	const primaryKeysSelected = primary_keys?.filter((el) => !!el)?.length;
	const isSubmitDisabled = !isValidEntityName || !primaryKeysSelected || !hasUnsavedChanges || hasErrors;

	useEffect(() => setIsAdvancedMode(isWithRawSql), [isWithRawSql]);

	const modalHeader = (
		<ModalHeader
			boxShadow={shadows.borderBottom}
			color="gray.1000"
			padding={` ${saveAndRunInProgress ? '22px' : '14px'} 16px`}
		>
			<Flex justifyContent="space-between" alignItems="center">
				{!saveAndRunInProgress && (
					<Button
						variant="outline"
						isIconOnly
						onClick={() => {
							reportEvent({
								event: 'ontology-object-edit-cancel-clicked',
							});
							closeModal();
						}}
						size="small"
						colorScheme="black"
					>
						<CloseLarge16 />
					</Button>
				)}
				<Typography
					width={saveAndRunInProgress ? '100%' : '40%'}
					variant={'DesktopH7Medium'}
					textAlign={saveAndRunInProgress ? 'center' : 'end'}
				>
					{modalTitle}
				</Typography>
				{!saveAndRunInProgress && (
					<Flex paddingY="1px" alignContent={'right'}>
						<TinySwitch
							onClick={wrapWithReport(
								() => {
									if ((hasErrors || isWithRawSql) && isAdvancedMode) {
										onWarningModalOpen();
									} else {
										setIsAdvancedMode(!isAdvancedMode);
									}
								},
								'ontology-object-edit-mode-toggle',
								{
									objectType: 'entity',
									objectName: isEditEntity ? selectedOntology?.name : '',
									newState: !isAdvancedMode ? 'YAML' : 'UI',
								}
							)}
							isEnabled={isAdvancedMode}
							text="Advanced"
						/>
					</Flex>
				)}
			</Flex>
		</ModalHeader>
	);

	const modalBody = (
		<Box maxH={'566px'} overflowY={'scroll'} padding={'24px'}>
			<Box marginBottom={isAdvancedMode ? '24px' : '32px'}>
				<NameField
					testId={TestIDs.ENTITY_TITLE_INPUT}
					isEditable={hasWritePermission}
					label="Entity name"
					onNameUpdate={onNameUpdate}
					propertyDefinitionValueName={selectedOntology?.name}
					value={displayName}
					isWithNameReport
					isEditFlow={isEditEntity}
					propertyType={'entity'}
				/>
			</Box>
			{isAdvancedMode ? (
				<ModalYAMLEditor
					entityName={isEditEntity && selectedOntology ? selectedOntology?.name : ''}
					setHasErrors={setHasErrors}
					hasUnsavedChanges={hasUnsavedChanges}
					onChange={setEditorYAMLValue}
					entityYAMLValue={entityYAMLValue}
				/>
			) : (
				<ModalForm
					onChangeValue={onChangeValue}
					entityState={entityState}
					selectedOntology={selectedOntology}
					isEditEntity={isEditEntity}
				/>
			)}
		</Box>
	);

	const modalFooter = (
		<Box shadow={shadows.borderTop} padding={'16px 24px'}>
			<Flex flexDirection="row" justifyContent={hasWritePermission ? 'space-between' : 'flex-end'} alignItems="center">
				{hasWritePermission ? (
					<Button
						variant="ghost"
						borderColor={'gray.500'}
						colorScheme="black"
						onClick={() => {
							reportEvent({
								event: 'ontology-object-edit-cancel-clicked',
							});
							closeModal();
						}}
					>
						Cancel
					</Button>
				) : (
					<Button
						variant="solid"
						colorScheme="blue"
						onClick={() => {
							reportEvent({
								event: 'ontology-object-edit-cancel-clicked',
							});
							closeModal();
						}}
					>
						Close
					</Button>
				)}
				{hasWritePermission && (
					<Tooltip size="md" label={!isValidEntityName && 'Name cannot be untitled'}>
						<Button
							isLoading={saveAndRunInProgress}
							isDisabled={isSubmitDisabled}
							variant="solid"
							colorScheme={isSubmitDisabled ? 'black' : 'blue'}
							onClick={wrapWithReport(
								() => (isEditEntity ? onUpdateWarningModalOpen() : onSubmit()),
								'ontology-object-apply-changes-clicked',
								{
									entityName: isEditEntity ? selectedOntology?.name : '',
									feature: 'Upsert entity modal',
									objectType: 'entity',
									objectName: isEditEntity ? selectedOntology?.name : '',
									fieldname: 'Entity name',
									input: display_name,
									flow: isEditEntity ? 'edit' : 'create',
									editMode: isAdvancedMode ? 'YAML' : 'UI',
								}
							)}
						>
							{isEditEntity ? 'Update' : 'Create'}
						</Button>
					</Tooltip>
				)}
			</Flex>
		</Box>
	);

	return (
		<>
			<Modal
				closeOnEsc={!saveAndRunInProgress}
				isOpen={isOpen}
				onClose={closeModal}
				closeOnOverlayClick={false}
				maxWidth="460px"
				isCentered
			>
				<>
					{modalHeader}
					{saveAndRunInProgress ? (
						<Flex
							width={'100%'}
							height={'656px'}
							justifyContent={'center'}
							alignItems={'center'}
							flexDirection={'column'}
							data-testid={TestIDs.ENTITY_MODAL_LOADER}
						>
							<Spinner thickness="3px" color="blue.600" size={'60px'} />
						</Flex>
					) : (
						<>
							<AskAIOntologyBuilderChatPopover
								builderType={'entity'}
								entityName={entityState?.name}
								existingYaml={isEditEntity ? entityYAMLValue : undefined}
								isOpen={isAskAIModalOpen}
								onClose={onAskAIModalClose}
								onAcceptSuggestions={onAcceptAISuggestions}
								triggerElement={<AskAIInputAsButton onClick={onAskAIModalOpen} />}
							/>
							{modalBody}
							{modalFooter}
						</>
					)}
				</>
			</Modal>
			<ConfirmationModal
				primaryButtonLabel="Leave"
				submitColorScheme="blue"
				isOpen={isWarningModalOpen}
				onSubmit={wrapWithReport(
					() => {
						if (isEditEntity) {
							initializeEntityValues();
						} else {
							resetToDefault();
						}
						setIsAdvancedMode(false);
						onWarningModalClose();
					},
					`ontology-leave-YAML-with-${isWithRawSql ? 'rawsql' : 'error'}-modal`,
					{
						action: 'confirm',
						objectName: isEditEntity ? selectedOntology?.name : '',
						objectType: 'entity',
						parentEntity: selectedOntology?.name,
					}
				)}
				modalTitle="Leave advanced mode"
				modalText={`Leaving the advanced mode with errors will clear your${isWithRawSql ? ' raw_sql ' : ' '}input.`}
				onClose={wrapWithReport(
					onWarningModalClose,
					`ontology-leave-YAML-with-${isWithRawSql ? 'rawsql' : 'error'}-modal`,

					{
						action: 'cancel',
						objectName: isEditEntity ? selectedOntology?.name : '',
						objectType: 'entity',
						parentEntity: selectedOntology?.name,
					}
				)}
			/>
			<ConfirmationModal
				isLoading={saveAndRunInProgress}
				modalIcon={<TagIcon24 color={colors.blue[600]} />}
				isOpen={isUpdateWarningModalOpen}
				onSubmit={async () => {
					reportEvent({
						event: 'save-and-run-ontology-confirmed',
						metaData: {
							objectType: 'entity',
							parentEntity: selectedOntology?.name,
							editMode: isAdvancedMode ? 'YAML' : 'UI',
						},
					});
					await onSubmit();
				}}
				onClose={() => {
					reportEvent({
						event: 'save-and-run-ontology-canceled',
						metaData: {
							objectType: 'entity',
							parentEntity: selectedOntology?.name,
							editMode: isAdvancedMode ? 'YAML' : 'UI',
						},
					});
					onUpdateWarningModalClose();
				}}
				modalTitle="Save changes."
				modalText="Making changes to this entity will affect other entities, metrics, and dashboards within the platform."
			/>
		</>
	);
}
