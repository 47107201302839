import { useCallback } from 'react';
import { useValidateEntitiesLazyQuery } from 'src/generated/graphql';
const UNKNOWN_VALIDATION_ERROR = 'Unknown Validation Error Occurred';

export function useValidateEntities({
	onValidationStart,
	entityName,
	handleValidationError,
	handleValidationSuccess,
}: {
	onValidationStart: () => void;
	entityName?: string;
	handleValidationError: (error: string) => void;
	handleValidationSuccess: () => void;
}): { validateEntities: () => Promise<void>; isValidationInProgress: boolean } {
	const [validateEntitiesQuery, { loading: isValidationInProgress }] = useValidateEntitiesLazyQuery({
		fetchPolicy: 'network-only',
	});

	const validateEntities = useCallback(async () => {
		if (entityName == undefined) {
			handleValidationError('Entity name is not defined');
			return;
		}
		onValidationStart();

		const validationResult = await validateEntitiesQuery({
			variables: {
				entityNames: [entityName],
			},
		});

		const validationResponses = validationResult.data?.validateEntities;
		if (validationResponses == undefined) {
			handleValidationError(UNKNOWN_VALIDATION_ERROR);
			return;
		}

		const invalidEntitiesResponses = validationResponses.validateEntitiesSemanticsResponses.filter(
			(response) => !response.isValid
		);
		const hasValidationErrors = invalidEntitiesResponses.length > 0;
		if (!hasValidationErrors) {
			handleValidationSuccess();
			return;
		}

		const currentEntityErrorResponse = invalidEntitiesResponses.find((response) => response.entityName == entityName);
		const hasErrorOnCurrentEntity = currentEntityErrorResponse != undefined;
		if (hasErrorOnCurrentEntity) {
			handleValidationError(currentEntityErrorResponse?.errors ?? UNKNOWN_VALIDATION_ERROR);
			return;
		}

		const firstErrorResponseWithErrors = invalidEntitiesResponses.find(
			(invalidResponse) => invalidResponse?.errors != null
		);
		const firstErrorResponse =
			firstErrorResponseWithErrors != undefined ? firstErrorResponseWithErrors : invalidEntitiesResponses[0];
		handleValidationError(firstErrorResponse?.errors ?? UNKNOWN_VALIDATION_ERROR);
	}, [entityName, handleValidationError, handleValidationSuccess, onValidationStart, validateEntitiesQuery]);

	return { validateEntities, isValidationInProgress };
}
