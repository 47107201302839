import { PrimitiveAtom } from 'jotai';
import { Metric, FormulaMetric } from '../../../lib/completions/semanticTypes/metrics.schema';
import { ExplicitDimension, Relationship } from '../../../lib/completions/semanticTypes/normalization.schema';
import { EntityDefinitionState } from '../../../lib/completions/semanticTypes';
import { PeriodUnit } from '@sightfull/period-ranges';
import { CloudSupportedOperators, Filter } from '../../../generated/graphql';
import { ChartType } from '../Chart/types';
import { MetricCompareToUnitType } from '../../../pages/MetricPage/utils/state.types';

export const CREATE_NEW_METRIC_INITIAL_PROMPT_QUERY_PARAM = 'initialPrompt';

export type AskAIChatFieldSuggestion = {
	label: string;
	propertyName: string;
	display?: string;
	value?: any;
	existingValue?: any;
};

export type DiscoveredMetricRelativePeriodRange = {
	startShift: number;
	endShift: number;
	startUnit: PeriodUnit;
	endUnit: PeriodUnit;
	unit: PeriodUnit;
	description: string;
};

export type DiscoveredMetricAbsolutePeriodRange = {
	startDate: string;
	endDate: string;
	unit: PeriodUnit;
	description: string;
};

export type DiscoveredMetricFilter = {
	dimension: string;
	operator: CloudSupportedOperators;
	values: string[];
};

export type DiscoverMetricsSuggested = {
	metric: string;
	period?: DiscoveredMetricRelativePeriodRange;
	absolutePeriod?: DiscoveredMetricAbsolutePeriodRange;
	breakdown?: string;
	filters?: DiscoveredMetricFilter[];
	resultDescription?: string;
	chartType?: string;
	compareToUnit?: string;
};

export type ChatEventStepCalcMetricParams = {
	metric: string;
	period: 'absolute' | 'relative';
	relativePeriod?: DiscoveredMetricRelativePeriodRange;
	absolutePeriod?: DiscoveredMetricAbsolutePeriodRange;
	breakdown?: string;
	filters?: DiscoveredMetricFilter[];
	chartType?: ChartType | null;
	compareToUnit?: MetricCompareToUnitType | null;
};

export type MetricDiscoveryPromptClass = 'results' | 'discovery';
export type DiscoverMetricsResult = {
	reasoning: string;
	classification?: MetricDiscoveryPromptClass;
	metrics?: DiscoverMetricsSuggested[];
	examples?: string[];
};
export type OntologyBuilderEntityResult = { entity: EntityDefinitionState };
export type OntologyBuilderDimensionResult = { dimension: ExplicitDimension };
export type OntologyBuilderRelationshipResult = { relationship: Relationship };
export type OmniResult = { text: string };

export type OnCalcResultsReceived = (args: { metric: string; calcResults: object; sqlQuery?: string }) => void;
export type OnCalcResultsStarted = (args: { metric: DiscoverMetricsSuggested }) => void;

export type AskAIChatVote = 'up' | 'down';
export type AskAIOntologyBuilderSubType = 'entity' | 'dimension' | 'relationship';
export type AskAIChatMessageType =
	| 'omni'
	| 'metric-discovery'
	| 'ontology-builder-entity'
	| 'ontology-builder-dimension'
	| 'ontology-builder-relationship';

export interface AskAIChatMessage {
	type: AskAIChatMessageType;
	userPrompt: string;
	promptId?: string;
	messageKey: string;
	events?: ChatEventWithText[];
	promptContext?: Record<string, any>;
	onAcceptSuggestions?: (selectedFields: AskAIChatFieldSuggestion[]) => void;
	error?: boolean;
	errorText?: string;
	deltaText?: string;
	result?:
		| OmniResult
		| DiscoverMetricsResult
		| OntologyBuilderEntityResult
		| OntologyBuilderDimensionResult
		| OntologyBuilderRelationshipResult;
	completionTime?: number;
	initiated?: boolean;
	vote?: AskAIChatVote;
}

export type AskAIChatCardProps = {
	chatMessageAtom: PrimitiveAtom<AskAIChatMessage>;
	chatIndex: number;
	cardMargin?: string;
	isLast?: boolean;
	isFullChat?: boolean;
};

// Type guards
export function isAskAIMetricDiscoveryChatMessage(
	message: AskAIChatMessage
): message is AskAIChatMessage & { result?: DiscoverMetricsResult } {
	return message.type === 'metric-discovery';
}

export interface AskAIOmniChatMessage extends AskAIChatMessage {
	type: 'omni';
	result?: OmniResult;
}

export function isAskAIOmniChatMessage(message: AskAIChatMessage): message is AskAIOmniChatMessage {
	return message.type === 'omni';
}

export function isAskAIOntologyBuilderEntityChatMessage(
	message: AskAIChatMessage
): message is AskAIChatMessage & { result?: OntologyBuilderEntityResult } {
	return message.type === 'ontology-builder-entity';
}

export function isAskAIOntologyBuilderDimensionChatMessage(
	message: AskAIChatMessage
): message is AskAIChatMessage & { result?: OntologyBuilderDimensionResult } {
	return message.type === 'ontology-builder-dimension';
}

export function isAskAIOntologyBuilderRelationshipChatMessage(
	message: AskAIChatMessage
): message is AskAIChatMessage & { result?: OntologyBuilderRelationshipResult } {
	return message.type === 'ontology-builder-relationship';
}

// Agents
export type AIAgentTriggerType = 'explicit' | 'silent';

export type AIAgentName =
	| 'aggregate-metric-builder'
	| 'formula-metric-builder'
	| 'metric-discovery-v2'
	| 'omni'
	| 'metric-results'
	| 'ontology-builder';

export type CreateAIAgentThreadResponse = {
	agentName: string;
	threadId: string;
};

export type AIAgentOp = {
	create?: { prompt: string };
	edit?: { definition: string; changes: { field: string; value: string }[] };
};

export type AIAgentMetricBuilderOp = AIAgentOp;
export type AIAgentOntologyBuilderEntityOp = AIAgentOp;
export type AIAgentOntologyBuilderDimensionOp = AIAgentOntologyBuilderEntityOp & { entity: string };
export type AIAgentOntologyBuilderRelationshipOp = AIAgentOntologyBuilderEntityOp & { entity: string };
export type AIAgentOntologyBuilderOp =
	| AIAgentOntologyBuilderEntityOp
	| AIAgentOntologyBuilderDimensionOp
	| AIAgentOntologyBuilderRelationshipOp;

export type AIAgentMetricBuilderResponse = {
	status: 'error' | 'complete';
	suggestion?: { metric?: Metric; formula_metric?: FormulaMetric };
	error?: string;
};

export type AIAgentOntologyBuilderResponse = {
	status: 'error' | 'complete';
	suggestion?: OntologyBuilderEntityResult | OntologyBuilderDimensionResult | OntologyBuilderRelationshipResult;
	error?: string;
};

export type AIAgentMetricDiscoveryResponse = {
	result: {
		status: 'error' | 'complete';
		suggestion?: DiscoverMetricsResult;
		error?: string;
	};
};

export type AIAgentDescribeMetricResultsResponse = {
	result: {
		status: 'error' | 'complete';
		text?: string;
		error?: string;
	};
};

export type AIAgentOmniResultsResponse = {
	status: 'error' | 'complete';
	text: string;
	error?: string;
};

export const ChatEventTypes = [
	'start',
	'ping',
	'thinking',
	'tool',
	'step',
	'delta',
	'result',
	'error',
	'end',
	'calculating',
	'analyzing',
] as const;
export type ChatEventType = typeof ChatEventTypes[number];

export const ChatEventResultTypes = ['metric-describe', 'perform-discovery', 'omni'] as const;
export type ChatEventResultType = typeof ChatEventResultTypes[number];

export interface ChatEventResultData {
	type: ChatEventResultType;
	result: unknown;
}
export interface ChatEventResultPerformDiscovery extends ChatEventResultData {
	type: 'perform-discovery';
	result: AIAgentMetricDiscoveryResponse;
}
export interface ChatEventResultMetricDescribe extends ChatEventResultData {
	type: 'metric-describe';
	result: AIAgentDescribeMetricResultsResponse;
}
export interface ChatEventResultOmni extends ChatEventResultData {
	type: 'omni';
	result: AIAgentOmniResultsResponse;
}

export type ChatEventResultDataType =
	| ChatEventResultOmni
	| ChatEventResultPerformDiscovery
	| ChatEventResultMetricDescribe;

export function isChatEventResultType(event: string): event is ChatEventResultType {
	return ChatEventResultTypes.includes(event as ChatEventResultType);
}

export function isChatEventResultPerformDiscovery(
	event: ChatEventResultData
): event is ChatEventResultPerformDiscovery {
	return event.type === 'perform-discovery';
}

export function isChatEventResultMetricDescribe(event: ChatEventResultData): event is ChatEventResultMetricDescribe {
	return event.type === 'metric-describe';
}

export function isChatEventResultOmni(event: ChatEventResultData): event is ChatEventResultOmni {
	return event.type === 'omni';
}

export interface ChatEventData {
	text: string;
	[x: string]: any;
}
export interface ChatEvent {
	event: ChatEventType;
	data: ChatEventData;
}

export interface ChatEventEnd extends ChatEvent {
	event: 'end';
}
export interface ChatEventPing extends ChatEvent {
	event: 'ping';
}

export interface ChatEventThinkingData extends ChatEventData {
	prompt: string;
}
export interface ChatEventThinking extends ChatEvent {
	event: 'thinking';
	data: ChatEventThinkingData;
}

export interface ChatEventDeltaTextData extends ChatEventData {
	text: string;
}

export interface ChatEventDelta extends ChatEvent {
	event: 'delta';
	data: ChatEventDeltaTextData;
}

export interface ChatEventStartData extends ChatEventData {
	promptId: string;
}

export interface ChatEventStart extends ChatEvent {
	event: 'start';
	data: ChatEventStartData;
}

export function isChatEventStart(event: ChatEvent): event is ChatEventStart {
	return event.event === 'start';
}

export function isChatEventDelta(event: ChatEvent): event is ChatEventDelta {
	return event.event === 'delta';
}

export type ChatEventStepType = 'general' | 'calc-metric';
export interface ChatEventStepData extends ChatEventData {
	type: ChatEventStepType;
	payload: unknown;
}

export interface ChatEventStep extends ChatEvent {
	event: 'step';
	data: ChatEventStepData;
}

export interface ChatEventStepCalcMetricParamVariables {
	metricName: string;
	start: string;
	end: string;
	groupBy?: string[] | null;
	filterBy?: Filter[] | null;
	chartType?: ChartType | null;
	compareToUnit?: string;
	userMetricDefinitions?: any | null;
}

export interface ChatEventStepCalcMetricPayload {
	calcParams?: ChatEventStepCalcMetricParams;
	calcParamVariables?: ChatEventStepCalcMetricParamVariables;
	sqlQuery?: { sql: string; dialect: string };
	error?: string;
}
export interface ChatEventStepSuccessfulCalcMetricPayload extends ChatEventStepCalcMetricPayload {
	calcParams: ChatEventStepCalcMetricParams;
	calcParamVariables: ChatEventStepCalcMetricParamVariables;
	sqlQuery: { sql: string; dialect: string };
}

export function isSuccessfulCalcMetricPayload(payload: any): payload is ChatEventStepSuccessfulCalcMetricPayload {
	return (
		!!payload &&
		typeof payload === 'object' &&
		'calcParams' in payload &&
		'calcParamVariables' in payload &&
		'sqlQuery' in payload
	);
}

export interface ChatEventStepDataCalcMetric extends ChatEventStepData {
	type: 'calc-metric';
	payload: ChatEventStepCalcMetricPayload;
}

export interface ChatEventStepCalcMetric extends ChatEventStep {
	event: 'step';
	data: ChatEventStepDataCalcMetric;
}

export interface ChatEventToolData extends ChatEventData {
	name: string;
	args: Record<string, unknown>;
}
export interface ChatEventTool extends ChatEvent {
	event: 'tool';
	data: ChatEventToolData;
}
export interface ChatEventCalculating extends ChatEvent {
	event: 'calculating';
	data: { text: string; metric: DiscoverMetricsSuggested };
}
export interface ChatEventAnalyzing extends ChatEvent {
	event: 'analyzing';
	data: { text: string; results: { calcResults: any; sqlQuery?: string } };
}
export interface ChatEventResult extends ChatEvent {
	event: 'result';
	data: { text: string; type: ChatEventResultType; result: ChatEventResultData };
}
export interface ChatEventError extends ChatEvent {
	event: 'error';
}

export function isChatEvent(event: object): event is ChatEvent {
	return (
		!!event &&
		typeof event === 'object' &&
		'event' in event &&
		typeof event.event === 'string' &&
		ChatEventTypes.includes(event.event as ChatEventType) &&
		'data' in event
	);
}

export function isChatEventStep(event: ChatEvent): event is ChatEventStep {
	return isChatEvent(event) && event.event === 'step' && typeof event.data === 'object' && 'type' in event.data;
}

export function isChatEventStepCalcMetric(event: ChatEvent): event is ChatEventStepCalcMetric {
	return isChatEventStep(event) && event.data.type === 'calc-metric' && 'payload' in event.data;
}

export function isChatEventCalculating(event: ChatEvent): event is ChatEventCalculating {
	return isChatEventWithText(event) && event.event === 'calculating';
}

export function isChatEventAnalyzing(event: ChatEvent): event is ChatEventAnalyzing {
	return isChatEventWithText(event) && event.event === 'analyzing';
}

export type ChatEventWithText = Extract<ChatEvent, { data: { text: string } }>;
export function isChatEventWithText(event: ChatEvent): event is ChatEventWithText {
	return isChatEvent(event) && !!event.data && typeof event.data === 'object' && 'text' in event.data;
}

export interface ChatEventListener {
	onOpen?(): void;
	onClose?(): void;
	onEvent?(event: ChatEvent): void;
	onStart?(event: ChatEventStartData): void;
	onDelta?(event: ChatEventDeltaTextData): void;
	onResult?(event: ChatEventResultData): void;
	onError?(error: string): void;
}
