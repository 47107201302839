import Box from '@components/Box';
import Flex from '@components/Flex';
import { useMemo } from 'react';
import Skeleton from 'src/common/components/Skeleton';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { MetricPagePath } from 'src/pages/MetricPage/pageRoutesPaths';

export type EditableValueContainerProps = {
	prefixComponent?: React.ReactNode;
	hasLeftPadding?: boolean;
	children: React.ReactNode;
};

export function EditableValueContainer({
	prefixComponent,
	children,
	hasLeftPadding = true,
}: EditableValueContainerProps) {
	const { isLoading: isRulesEngineLoading } = useMetricDerivedState();
	// Don't use isMetricPage() function here as it won't update on render
	const isMetric = useMemo(() => window.location.pathname.includes(`/${MetricPagePath}/`), []);
	const isSkeletonVisible = isRulesEngineLoading && isMetric;

	return (
		<Flex color={'gray.1000'} pl={hasLeftPadding ? '26px' : '0'} alignItems={'center'} flexDir={'row'}>
			{prefixComponent}
			<Box flex={1} overflowX={'hidden'}>
				{isSkeletonVisible ? <Skeleton height={'32px'} /> : children}
			</Box>
		</Flex>
	);
}
