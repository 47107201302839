import { useCallback } from 'react';
import { RunNormalizationMutation, useRunNormalizationMutation } from 'src/generated/graphql';
import { FetchResult } from '@apollo/client';

export function useRunNormalization({
	handleNormalizationError,
}: {
	handleNormalizationError: (error: string) => void;
}): { runNormalization: () => Promise<boolean>; normalizationInProgress: boolean } {
	const [runNormalizationMutation, { loading: normalizationInProgress }] = useRunNormalizationMutation();
	const hasNormalizationFailed = (runNormalizationResult: FetchResult<RunNormalizationMutation>) =>
		runNormalizationResult.data?.runCore.status && runNormalizationResult.data.runCore.status !== 'SUCCESS';

	const runNormalization = useCallback(async () => {
		try {
			const runNormalizationResult = await runNormalizationMutation();
			if (runNormalizationResult?.data && hasNormalizationFailed(runNormalizationResult)) {
				handleNormalizationError(runNormalizationResult?.data?.runCore.status);
				return false;
			}
			return true;
		} catch (error) {
			if (error instanceof Error) {
				handleNormalizationError(error.message);
			}
			return false;
		}
	}, [handleNormalizationError, runNormalizationMutation]);

	return { runNormalization, normalizationInProgress };
}
