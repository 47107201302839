import { Atom, Provider } from 'jotai';
import {
	InitialMetricEditorStateProps,
	useInitialMetricEditorState,
} from '@hooks/MetricHandling/initializeMetricEditState';

function MetricEditProviderWithDerivedState({
	children,
	isNewMetric,
	metricKind,
}: {
	children: React.ReactNode;
} & InitialMetricEditorStateProps) {
	useInitialMetricEditorState(isNewMetric ? { isNewMetric, metricKind } : { isNewMetric: false, metricKind: null });
	return <>{children}</>;
}

export function MetricEditProvider({
	children,
	isNewMetric,
	metricKind,
	initialValues,
}: {
	children: React.ReactNode;
	initialValues?: Iterable<readonly [Atom<unknown>, unknown]>;
} & InitialMetricEditorStateProps) {
	const props: InitialMetricEditorStateProps = isNewMetric
		? { isNewMetric: true, metricKind }
		: { isNewMetric: false, metricKind: null };
	return (
		<Provider initialValues={initialValues}>
			<MetricEditProviderWithDerivedState {...props}>{children}</MetricEditProviderWithDerivedState>
		</Provider>
	);
}
