import { ErrorYellow16 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { OntologyDimensionInfo } from '../../utils/normalizeYaml';
import { getIconByType } from '../../utils/utils';
import ItemCard from './ItemCard';
import ThreeDotsButton from './ThreeDotsButton';
import { TestIDs } from 'src/common/types/test-ids';

export interface ListHeaderProps {
	testId?: string;
	item: OntologyDimensionInfo;
	isFullyDefined?: boolean;
	onDelete: VoidFunction;
	isEditable: boolean;
}

export default function DimensionItem({ item, isFullyDefined, onDelete, isEditable }: ListHeaderProps) {
	return (
		<ItemCard
			cardActions={
				<ThreeDotsButton
					testId={TestIDs.DIMENSION_ACTION_DROPDOWN}
					actionTestId={TestIDs.DELETE_DIMENSION_ACTION}
					type="dimensions"
					onDelete={onDelete}
					isEditable={isEditable}
				/>
			}
			icon={getIconByType(item.type)}
			title={item?.meta?.display_name || removeUnderscoresAndCapitalize(item?.name)}
			suffixIcon={!isFullyDefined && <ErrorYellow16 />}
			description={
				<Typography variant={'Paragraph12R'} textOverflow={'ellipsis'} noOfLines={1} color={'gray.800'}>
					{item?.meta?.description || 'no description'}
				</Typography>
			}
		/>
	);
}
