import SightfulSelect from '@components/Select';
import classes from '@pages/MetricPage/components/PeriodPicker/PeriodPicker.module.scss';
import { Flex } from '@chakra-ui/react';
import classNames from 'classnames';
import isFunction from 'lodash/fp/isFunction';
import always from 'lodash/fp/always';
import { useState } from 'react';
import noop from 'lodash/fp/noop';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

const t = function Translate(id: string, ...args: (string | undefined)[]): string {
	const dict: Record<string, string | ((...any: (string | undefined)[]) => string)> = {
		compare_to: 'Compare',
		previous_week: 'Previous week',
		previous_month: 'Previous month',
		previous_quarter: 'Previous quarter',
		previous_year: 'Previous year',
		custom: 'Custom',
		disabled_compare_custom: 'Comparison is not available when viewing a custom absolute period',
		disabled_compare_period_tooltip: (compareToPeriod?: string, currentPeriod?: string) =>
			`${compareToPeriod} is not available when viewing ${currentPeriod}`,
	};
	const dictionaryItem = dict[id];
	return (isFunction(dictionaryItem) ? dictionaryItem : always(dictionaryItem))(...args);
};

export const COMPARE_TO_UNIT_SELECTOR_WEEK = 'week' as const;
export const COMPARE_TO_UNIT_SELECTOR_MONTH = 'month' as const;
export const COMPARE_TO_UNIT_SELECTOR_QUARTER = 'quarter' as const;
export const COMPARE_TO_UNIT_SELECTOR_YEAR = 'year' as const;
export const COMPARE_TO_UNIT_SELECTOR_CUSTOM = 'custom' as const;
type CompareToUnitSelectorValue =
	| typeof COMPARE_TO_UNIT_SELECTOR_WEEK
	| typeof COMPARE_TO_UNIT_SELECTOR_MONTH
	| typeof COMPARE_TO_UNIT_SELECTOR_QUARTER
	| typeof COMPARE_TO_UNIT_SELECTOR_YEAR
	| typeof COMPARE_TO_UNIT_SELECTOR_CUSTOM;

export default function CompareToSelector({
	unit,
	onUnitChange,
	onClick = noop,
	disabledUnits = [],
	currentViewUnit,
	isDisabled = false,
}: {
	unit: undefined | CompareToUnitSelectorValue;
	onClick: () => void;
	onUnitChange: (unit: CompareToUnitSelectorValue | undefined) => void;
	disabledUnits: string[];
	currentViewUnit?: CompareToUnitSelectorValue;
	isDisabled?: boolean;
}) {
	const [isSelectorOpen, setIsSelectorOpen] = useState(false);
	return (
		<Flex
			{...buildIntercomAttributes({
				type: 'button',
				target: 'compare',
			})}
			className={classNames(classes.first, classes.last)}
			alignItems="center"
		>
			<SightfulSelect
				isDisabled={isDisabled}
				tooltip={isDisabled ? t('disabled_compare_custom') : ''}
				onChange={({ value }) => onUnitChange(value)}
				onClick={onClick}
				selectedOption={{
					value: unit,
					label: {
						none: t('compare_to'),
						week: t('previous_week'),
						month: t('previous_month'),
						quarter: t('previous_quarter'),
						year: t('previous_year'),
						custom: t('custom'),
					}[unit ?? 'none'],
				}}
				options={[
					{
						label: t('previous_week'),
						value: COMPARE_TO_UNIT_SELECTOR_WEEK,
						isDisabled: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_WEEK),
						tooltip: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_WEEK)
							? t('disabled_compare_period_tooltip', t('previous_week'), currentViewUnit)
							: null,
					},
					{
						label: t('previous_month'),
						value: COMPARE_TO_UNIT_SELECTOR_MONTH,
						isDisabled: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_MONTH),
						tooltip: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_MONTH)
							? t('disabled_compare_period_tooltip', t('previous_month'), currentViewUnit)
							: null,
					},
					{
						label: t('previous_quarter'),
						value: COMPARE_TO_UNIT_SELECTOR_QUARTER,
						isDisabled: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_QUARTER),
						tooltip: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_QUARTER)
							? t('disabled_compare_period_tooltip', t('previous_quarter'), currentViewUnit)
							: null,
					},
					{
						label: t('previous_year'),
						value: COMPARE_TO_UNIT_SELECTOR_YEAR,
						isDisabled: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_YEAR),
						tooltip: disabledUnits.includes(COMPARE_TO_UNIT_SELECTOR_YEAR)
							? t('disabled_compare_period_tooltip', t('previous_year'), currentViewUnit)
							: null,
					},
				]}
				isGhost={true}
				size="small"
				onMenuOpen={() => setIsSelectorOpen(true)}
				onMenuClose={() => setIsSelectorOpen(false)}
				menuIsOpen={isSelectorOpen}
			></SightfulSelect>
		</Flex>
	);
}
