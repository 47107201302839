import Box from '@components/Box';
import Button from '@components/Button';
import Flex from '@components/Flex';
import { ChevronLeft16 } from '@components/Icons';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import { useOnOverflow } from '@hooks/ui/useOnOverflow';

import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ConfirmationModal } from 'src/common/components/ConfirmationModal';
import { NameField } from 'src/common/components/NameField';
import { SelectOption } from 'src/common/components/Select/types';
import { TinySwitch } from 'src/common/components/TinySwitch/TinySwitch';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useModal } from 'src/common/hooks/ui/useModal';
import useToast from 'src/common/hooks/ui/useToast';
import { TestIDs } from 'src/common/types/test-ids';
import { AutocompleteAlignmentProvider } from 'src/pages/MetricPage/components/MonacoEditor/AutocompleteAlignmentProvider';
import { usePropertyBuilderState } from 'src/pages/OntologyPage/hooks/useDimensionBuilderDerivedState';
import { PropertyUpdate } from 'src/pages/OntologyPage/utils/updateYaml';
import {
	entityHasEmptyValues,
	isOntologyDimensionInfo,
	isOntologyRelationshipInfo,
} from 'src/pages/OntologyPage/utils/utils';
import { useReportEvent } from 'src/services/analytics';
import useNavigationBlock from 'src/services/useNavigationBlock';
import shadows from 'src/style/shadows';
import { OntologyStateAtomDerived, writePartialOntologyState } from '../../../atoms/OntologyState';
import { DimensionYAMLEditor } from './DimensionEditor';
import { DimensionsForm } from './DimensionsForm';
import { RelationshipYAMLEditor } from './RelationshipEditor';
import { RelationshipsForm } from './RelationshipsForm';
import { AskAIOntologyBuilderChatPopover } from 'src/common/components/AskAI/Chat/OntologyBuilder/AskAIOntologyBuilderChatPopover';
import { OnAcceptAIChatPropertyFieldSuggestionsProps } from '../../../utils/types';
import { AskAIInputAsButton } from '../../../../../common/components/AskAI/AskAIInputAsButton';
import { isUntitledMetric } from 'src/common/utils/metricUtils';

export default function EditPropertyPanel({
	name,
	onMoveBack,
	onSubmit,
	isEditable,
	isAdvancedMode,
	setIsAdvancedMode,
	entitiesOptions,
	onAcceptAISuggestions,
}: {
	name: string;
	onMoveBack?: VoidFunction;
	onSubmit: (update: PropertyUpdate) => void;
	isEditable?: boolean;
	isAdvancedMode: boolean;
	setIsAdvancedMode: (val: boolean) => void;
	entitiesOptions: SelectOption[] | void;
	onAcceptAISuggestions: (props: OnAcceptAIChatPropertyFieldSuggestionsProps) => void;
}) {
	const {
		propertyValue,
		hasUnsavedChanges,
		propertyType,
		propertyDefinitionValue,
		propertyName,
		setPropertyValue,
		upsertYAMLProperty,
		upsertYAMLProperties,
		resetInitialValue,
	} = usePropertyBuilderState({ name });
	const ontologyState = useAtomValue(OntologyStateAtomDerived);
	const titleRef = useRef(null);
	const isOverflowingName = useOnOverflow(titleRef, [name], undefined, false);
	const setPartialOntologyState = useSetAtom(writePartialOntologyState);
	const isWithRawSql = propertyValue?.includes('raw_sql') && !propertyValue?.includes('name: raw_sql');
	const isDimensions = propertyType === 'dimensions';

	const toast = useToast();

	const isManualNormalizationEnabled = useFeatureFlag('pulse.sightfull2.ontology.isManualNormalizationEnabled');

	const { isOpen: isWarningModalOpen, onOpen: onWarningModalOpen, onClose: onWarningModalClose } = useModal();
	const { isOpen: isAskAIModalOpen, onOpen: onAskAIModalOpen, onClose: onAskAIModalClose } = useModal();

	const { reportEvent, wrapWithReport } = useReportEvent();

	const { setIsNavigationBlocked } = useNavigationBlock();

	useEffect(
		() => setIsNavigationBlocked({ isBlocked: hasUnsavedChanges }),
		[hasUnsavedChanges, setIsNavigationBlocked]
	);

	useEffect(() => {
		if (isDimensions) {
			setIsAdvancedMode(!!isWithRawSql);
		}
	}, [isWithRawSql, setIsAdvancedMode, isDimensions]);

	const onChange = useCallback((value: string) => setPropertyValue(value), [setPropertyValue]);

	const onNameUpdate = useMemo(
		() => (value: string) =>
			upsertYAMLProperty('meta', {
				display_name: value,
				description: propertyDefinitionValue?.meta?.description,
			}),
		[upsertYAMLProperty, propertyDefinitionValue?.meta?.description]
	);

	const hasEmptyValues = useCallback(
		() => entityHasEmptyValues(propertyType, propertyDefinitionValue),
		[propertyType, propertyDefinitionValue]
	);

	if (ontologyState.loading) return null;
	if (propertyValue == undefined || !propertyType) return <PropertyNotFound name={name} onMoveBack={onMoveBack} />;

	const title = propertyDefinitionValue?.meta?.display_name || propertyDefinitionValue?.name;
	const isNameUntitled = isUntitledMetric({ name });
	const isSubmitDisabled =
		!hasUnsavedChanges ||
		ontologyState.hasYamlErrors ||
		!propertyDefinitionValue?.name ||
		isNameUntitled ||
		!propertyDefinitionValue?.type ||
		hasEmptyValues();

	const TopPanelSection = (
		<Flex justifyContent={'space-between'} boxShadow={shadows.borderBottom}>
			<Flex onClick={onMoveBack} alignItems={'center'} width={'100%'} padding={'12px'}>
				<Button onClick={onMoveBack} isIconOnly variant="outline" size="inline" colorScheme="black">
					<ChevronLeft16 />
				</Button>
				<Tooltip size="md" variant="fluid" label={isOverflowingName && name}>
					<Typography noOfLines={1} color={'gray.1000'} marginLeft={'4px'} variant="DesktopH8Medium">
						<Box maxW={'170px'} ref={titleRef}>
							{title}
						</Box>
					</Typography>
				</Tooltip>
			</Flex>

			<Flex paddingX={'24px'}>
				<TinySwitch
					onClick={wrapWithReport(
						() => {
							if ((ontologyState.hasYamlErrors || isWithRawSql) && isAdvancedMode) {
								onWarningModalOpen();
							} else {
								setIsAdvancedMode(!isAdvancedMode);
							}
						},
						'ontology-object-edit-mode-toggle',
						{
							parentEntity: ontologyState.entityName,
							objectType: 'entity',
							objectName: propertyDefinitionValue?.meta?.display_name || propertyDefinitionValue?.name,
							newState: !isAdvancedMode ? 'YAML' : 'UI',
						}
					)}
					isEnabled={isAdvancedMode}
					text="Advanced"
				/>
			</Flex>
		</Flex>
	);

	const PanelBody = (
		<>
			{isAdvancedMode &&
				(!isDimensions ? (
					<Box height={'100%'} marginTop={'-5px'}>
						<RelationshipYAMLEditor
							entity={ontologyState.entityName}
							value={propertyValue}
							onChange={onChange}
							onErrorsChange={(hasErrors) => {
								setPartialOntologyState({ hasYamlErrors: hasErrors });
							}}
							isEditable={isEditable}
							hasUnsavedChanges={hasUnsavedChanges}
							onFocus={() => {
								reportEvent({
									event: 'ontology-object-yaml-focus-clicked',
									metaData: {
										objectType: propertyType,
										parentEntity: ontologyState.entityName,
										objectName: propertyName,
									},
								});
							}}
						/>
					</Box>
				) : (
					<Box height={'100%'} marginTop={'-5px'}>
						<DimensionYAMLEditor
							isRawSql={propertyValue.includes('raw_sql')}
							entity={ontologyState.entityName}
							value={propertyValue}
							onChange={onChange}
							onErrorsChange={(hasErrors) => {
								setPartialOntologyState({ hasYamlErrors: hasErrors });
							}}
							isEditable={isEditable}
							hasUnsavedChanges={hasUnsavedChanges}
							onFocus={() => {
								reportEvent({
									event: 'ontology-object-yaml-focus-clicked',
									metaData: {
										objectType: propertyType,
										parentEntity: ontologyState.entityName,
										objectName: propertyName,
									},
								});
							}}
						/>
					</Box>
				))}
			{!isAdvancedMode &&
				(!isDimensions
					? isOntologyRelationshipInfo(propertyDefinitionValue) && (
							<RelationshipsForm
								upsertYAMLProperty={upsertYAMLProperty}
								upsertYAMLProperties={upsertYAMLProperties}
								entitiesOptions={entitiesOptions}
								isEditRelationshipFlow
								isEditable={isEditable}
								propertyDefinitionValue={propertyDefinitionValue}
								entityName={ontologyState.entityName}
							/>
					  )
					: isOntologyDimensionInfo(propertyDefinitionValue) && (
							<DimensionsForm
								isEditDimesnionFlow
								isEditable={isEditable}
								onChange={upsertYAMLProperty}
								propertyDefinitionValue={propertyDefinitionValue}
								entityName={ontologyState.entityName}
							/>
					  ))}
		</>
	);

	const shouldShowManualNormalizationButtons = isManualNormalizationEnabled && propertyType === 'dimensions';

	const ApplyButton = ({
		text,
		shouldSkipNormalization,
		activeColor,
		isOutline = false,
	}: {
		text: string;
		shouldSkipNormalization: boolean;
		activeColor?: 'black' | 'blue';
		isOutline?: boolean;
	}) => (
		<Tooltip placement="left" size={'md'} label={isNameUntitled && 'Name cannot be untitled'}>
			<Button
				colorScheme={isSubmitDisabled ? 'gray' : activeColor}
				variant={isOutline ? 'outline' : 'solid'}
				size="small"
				isDisabled={isSubmitDisabled}
				onClick={() => {
					try {
						onSubmit({
							currentPropertyName: name,
							ontologyPropertyType: propertyType,
							propertyDefinition: propertyValue,
							shouldSkipNormalization,
						});
					} catch (e) {
						if (e instanceof Error) {
							toast({ variant: 'error', message: e.message, duration: 5000 });
						}
					}
				}}
			>
				{text}
			</Button>
		</Tooltip>
	);
	const PanelFooter = (
		<Flex
			backgroundColor={'white'}
			bottom={'0'}
			left={'0'}
			position={'sticky'}
			zIndex={1}
			flexDir={'row'}
			justifyContent={'end'}
			width={'100%'}
			padding={'16px 24px'}
			gap="12px"
			borderTop={'1px solid'}
			borderColor={'gray.300'}
		>
			{isEditable && shouldShowManualNormalizationButtons && (
				<>
					<ApplyButton text="Save without running" shouldSkipNormalization={true} activeColor="black" isOutline />
					<ApplyButton text="Save & Run" shouldSkipNormalization={false} activeColor="blue" />
				</>
			)}
			{isEditable && !shouldShowManualNormalizationButtons && (
				<>
					<Button
						colorScheme="black"
						variant="outline"
						size="small"
						onClick={() => {
							reportEvent({
								event: 'ontology-object-edit-cancel-clicked',
								metaData: {
									objectType: propertyType,
								},
							});
							onMoveBack?.();
						}}
					>
						Cancel
					</Button>
					<ApplyButton text="Update" shouldSkipNormalization={false} activeColor="blue" />
				</>
			)}
		</Flex>
	);

	return (
		<Flex overflow={'hidden'} flexDirection="column" height={'100%'}>
			{TopPanelSection}
			<AutocompleteAlignmentProvider
				position={'relative'}
				display={'flex'}
				overflowY={'auto'}
				flex={1}
				flexDirection={'column'}
			>
				<AskAIOntologyBuilderChatPopover
					builderType={propertyType === 'dimensions' ? 'dimension' : 'relationship'}
					entityName={ontologyState.entityName}
					existingYaml={propertyValue}
					isOpen={isAskAIModalOpen}
					onClose={onAskAIModalClose}
					onAcceptSuggestions={(selectedFields) =>
						onAcceptAISuggestions({ propertyDefinitionValue, upsertYAMLProperties, selectedFields })
					}
					triggerElement={<AskAIInputAsButton onClick={onAskAIModalOpen} />}
				/>
				<Flex flex={1} flexDirection={'column'} position={'relative'} padding={'24px'} paddingTop={0}>
					<Box paddingY={'24px'}>
						<NameField
							testId={isDimensions ? TestIDs.DIMENSION_TITLE_INPUT : undefined}
							isEditable={isEditable}
							onNameUpdate={onNameUpdate}
							propertyDefinitionValueName={propertyDefinitionValue?.meta?.display_name || propertyDefinitionValue?.name}
							value={propertyDefinitionValue?.meta?.display_name}
							propertyType={propertyType || 'dimensions'}
							isEditFlow
							isWithNameReport
						/>
					</Box>
					{PanelBody}
				</Flex>
			</AutocompleteAlignmentProvider>
			{PanelFooter}
			<ConfirmationModal
				primaryButtonLabel="Leave"
				submitColorScheme="blue"
				isOpen={isWarningModalOpen}
				onSubmit={wrapWithReport(
					() => {
						resetInitialValue();
						setIsAdvancedMode(false);
						onWarningModalClose();
					},
					`ontology-leave-YAML-with-${isWithRawSql ? 'rawsql' : 'error'}-modal`,
					{
						action: 'confirm',
						objectName: propertyDefinitionValue?.meta?.display_name,
						objectType: propertyType,
						parentEntity: ontologyState.entityName,
					}
				)}
				modalTitle="Leave advanced mode"
				modalText={`Leaving the advanced mode with errors will clear your${isWithRawSql ? ' raw_sql ' : ' '}input.`}
				onClose={wrapWithReport(
					onWarningModalClose,
					`ontology-leave-YAML-with-${isWithRawSql ? 'rawsql' : 'error'}-modal`,
					{
						action: 'cancel',
						objectName: propertyDefinitionValue?.meta?.display_name,
						objectType: propertyType,
						parentEntity: ontologyState.entityName,
					}
				)}
			/>
		</Flex>
	);
}

function PropertyNotFound({ name, onMoveBack }: { name: string; onMoveBack?: VoidFunction }) {
	return (
		<Flex flexDirection="column" height={'100%'}>
			<Flex onClick={onMoveBack} alignItems={'center'} width={'100%'} padding={'12px'} boxShadow={shadows.borderBottom}>
				<Button onClick={onMoveBack} isIconOnly variant="outline" size="inline" colorScheme="black">
					<ChevronLeft16 />
				</Button>
				<Tooltip size="md" variant="fluid" label={name}>
					<Typography noOfLines={1} color={'gray.1000'} marginLeft={'4px'} variant="DesktopH8Medium">
						{name} not found
					</Typography>
				</Tooltip>
			</Flex>
		</Flex>
	);
}
