import Menu from 'src/layout/Menu/Menu';
import TrialBarContainer from 'src/layout/TrialBar/TrialBarContainer';
import routes, { RoutesWithoutNavigationMenu } from 'src/pages/routes';

import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { useFronteggAuth } from '@hooks/useFronteggLogin';
import { PlaygroundBanner } from '@layout/PlaygroundBanner/PlaygroundBanner';
import PlaygroundRedirectPage from '@pages/PlaygroundRedirectPage/PlaygroundRedirectPage';
import { DEFAULT_PLAYGROUND_TENANT_ID } from '@pages/PlaygroundRedirectPage/types';
import { AnalyticsPageContext } from '@services/analytics';
import { Provider } from 'jotai';
import Flex from 'src/common/components/Flex';
import useMentionsFetch from 'src/common/hooks/fetching/useMentionsFetch';
import useEmbedMode from 'src/common/hooks/navigation/useEmbedSetting';
import { useHomepageRedirect } from 'src/common/hooks/useHomepageRedirect';
import { PlaygroundType } from 'src/generated/graphql';
import { EntityProfileScope } from 'src/layout/EntityProfileDrawer/atoms';
import { useEnvironment } from 'src/stores/environment';
import { useInitSemantics } from 'src/stores/semantics';
import classes from './Page.module.scss';

function AppLayout() {
	const [embedSetting] = useEmbedMode();
	useMentionsFetch();
	useInitSemantics();
	useHomepageRedirect();
	const { user: rawUser } = useFronteggAuth();
	const { tenant } = useEnvironment();
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const isSightfullWebsiteRedirectedUser =
		tenant === DEFAULT_PLAYGROUND_TENANT_ID && location.pathname === '/arr-playground';

	if (rawUser?.id == null && isSightfull2 && isSightfullWebsiteRedirectedUser) {
		return <></>;
	}

	if (isSightfullWebsiteRedirectedUser) {
		if (isSightfull2) {
			const userId = rawUser?.id ?? '';
			if (location.pathname === '/arr-playground') {
				return <PlaygroundRedirectPage userId={userId} playgroundType={PlaygroundType.Arr} />;
			}
		}
		return <>Error. Please contact the Sightfull team.</>;
	}

	const isWithNavigationMenu =
		RoutesWithoutNavigationMenu.find((route) => `/${route}` === location.pathname) === undefined &&
		embedSetting !== 'widget';

	return (
		<Flex direction={'column'} width="100vw" maxH="100vh" height="100vh" className={classes.appLayout}>
			<TrialBarContainer />
			<AnalyticsPageContext page={'Playground Banner'}>
				<PlaygroundBanner />
			</AnalyticsPageContext>
			<Provider scope={EntityProfileScope}>
				{isWithNavigationMenu && <Menu />}
				{routes}
			</Provider>
		</Flex>
	);
}

export default AppLayout;
