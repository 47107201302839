import { AbsolutePeriodRange, RelativePeriodRange } from '@sightfull/period-ranges';
import { useEffect, useState } from 'react';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { usePeriodRange } from './usePeriodRange';
import useDimensionsState from '@pages/MetricPage/components/InvestigatePanel/useDimensionsState';
import { MetricPageSearchParams, MetricPageSearchParamsAtom } from '../atoms/MetricPageSearchParams';
import { useAtomValue } from 'jotai';
import {
	DiscoveredMetricAbsolutePeriodRange,
	DiscoveredMetricFilter,
	DiscoveredMetricRelativePeriodRange,
} from '../../../common/components/AskAI/types';
import { useMetricFiltersV2 } from '../components/InvestigatePanel/useMetricFiltersV2';
import { useSemanticDefinitions } from '../../../common/hooks/stores/useSemanticDefinitions';
import { FiltersV2Dict } from '../../../common/utils/MetricSearchParams';
import { dimensionTypeToFilterType } from '../../../common/components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/constants';
import { EnrichedSemanticDefinitions } from '../../../lib/completions/semanticTypes';
import { DimensionType } from 'src/lib/completions/semanticTypes/normalization.schema';
import { useOverrideChartType } from '@pages/MetricPage/hooks/useOverrideChartType';
import { ChartType, SupportedChartTypes } from '@components/Chart/types';
import { useMetricPageSearchParams } from '@pages/MetricPage/hooks/useMetricPageSearchParams';
import { MetricCompareToUnit } from '@pages/MetricPage/utils/state.types';

export type MetricSearchParamsBuilderProps = {
	metric: string;
	period?: DiscoveredMetricRelativePeriodRange;
	absolutePeriod?: DiscoveredMetricAbsolutePeriodRange;
	breakdown?: string;
	filters?: DiscoveredMetricFilter[];
	chartType?: string;
	compareToUnit?: string;
};

function getEntity(semanticDefinitions?: EnrichedSemanticDefinitions, entityName?: string) {
	return semanticDefinitions?.entities.find((entity) => entity.name === entityName);
}

function getValueByType(value: string, type: DimensionType) {
	switch (type) {
		case 'number':
			return Number(value);
		case 'bool':
		case 'boolean':
			return value === 'true';
		default:
			return value;
	}
}

function getLoadedDimensionUsingTraversal({
	metric,
	dimension,
	semanticDefinitions,
}: {
	metric: string;
	dimension: string;
	semanticDefinitions?: EnrichedSemanticDefinitions;
}) {
	const enrichedMetric =
		semanticDefinitions?.metrics.find((m) => m.name === metric) ??
		semanticDefinitions?.formula_metrics.find((m) => m.name === metric);
	let entity = getEntity(semanticDefinitions, enrichedMetric?.entity);
	const parts = dimension.split('.');
	const dimensionName = parts.pop();
	const relationshipParts = parts;
	while (entity && relationshipParts.length > 0) {
		const relationshipPart = relationshipParts.shift();
		entity = getEntity(
			semanticDefinitions,
			entity.relationships?.find((r) => r.name === relationshipPart)?.referenced_entity
		);
	}
	return entity?.dimensions.find((d) => d.name === dimensionName);
}

function convertSuggestedFiltersToFiltersV2({
	metric,
	filters,
	semanticDefinitions,
}: {
	metric: string;
	filters: DiscoveredMetricFilter[];
	semanticDefinitions?: EnrichedSemanticDefinitions;
}): FiltersV2Dict[] {
	const filterV2Objects = filters.map(({ dimension, operator, values }) => {
		const loadedDimnesion = getLoadedDimensionUsingTraversal({ semanticDefinitions, metric, dimension });
		if (!loadedDimnesion) return;
		return {
			key: `$${dimension}`,
			values: values.map((value) => getValueByType(value, loadedDimnesion.type)),
			operator,
			type: dimensionTypeToFilterType[loadedDimnesion.type],
		};
	});
	const filtered = filterV2Objects?.filter((f) => !!f);
	return filtered ?? [];
}

function isMetricCompareToUnitKey(value: string): value is keyof typeof MetricCompareToUnit {
	return value in MetricCompareToUnit;
}

export function useMetricSearchParamsBuilder({
	metric,
	period,
	absolutePeriod,
	breakdown,
	filters,
	chartType,
	compareToUnit,
}: MetricSearchParamsBuilderProps): [boolean, MetricPageSearchParams] {
	const metricPageSearchParams = useAtomValue(MetricPageSearchParamsAtom);
	const { semanticDefinitions } = useSemanticDefinitions();
	const [, setPeriodRange] = usePeriodRange();
	const [, { addDimensionsParamsByType }] = useDimensionsState();
	const [, { setFilterByV2 }] = useMetricFiltersV2();
	const [isDoneBuilding, setIsDoneBuilding] = useState(false);
	const [, setChartType] = useOverrideChartType();
	const { setPartialSearchParams } = useMetricPageSearchParams();

	useEffect(() => {
		if (breakdown && !metricPageSearchParams.searchParams.has('groupBy')) {
			addDimensionsParamsByType('groupBy', [{ key: breakdown, value: '' }]);
		} else if (filters && !metricPageSearchParams.searchParams.has('filterByV2')) {
			const filtersV2 = convertSuggestedFiltersToFiltersV2({ metric, filters, semanticDefinitions });
			setFilterByV2(filtersV2);
		} else if (!metricPageSearchParams.searchParams.has('periodRange')) {
			if (absolutePeriod) {
				const { startDate, endDate, unit } = absolutePeriod;
				const periodRange = AbsolutePeriodRange.fromUnitAndDates(
					unit,
					new Date(startDate),
					new Date(endDate),
					fiscalYearOffset()
				);
				setPeriodRange(periodRange);
			} else if (period) {
				const { startShift, startUnit, endShift, endUnit, unit } = period;
				const periodRange = new RelativePeriodRange(
					{ startShift, startUnit, endShift, endUnit },
					unit,
					fiscalYearOffset()
				);
				setPeriodRange(periodRange);
			}
		} else if (
			chartType &&
			SupportedChartTypes.some((type) => chartType == type) &&
			!metricPageSearchParams.searchParams.has('chartType')
		) {
			setChartType(chartType as ChartType);
		} else if (
			compareToUnit &&
			!metricPageSearchParams.searchParams.has('compareToUnit') &&
			isMetricCompareToUnitKey(compareToUnit)
		) {
			setPartialSearchParams({ compareToUnit });
		} else {
			setIsDoneBuilding(true);
		}
	}, [
		breakdown,
		addDimensionsParamsByType,
		metricPageSearchParams.searchParams,
		setPeriodRange,
		period,
		absolutePeriod,
		setFilterByV2,
		filters,
		metric,
		semanticDefinitions,
		chartType,
		setChartType,
		compareToUnit,
		setPartialSearchParams,
	]);

	return [isDoneBuilding, metricPageSearchParams];
}
