import Flex from '@components/Flex';
import { UNTITLED_METRIC_NAME } from '@hooks/MetricHandling/useMetricEdit';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { useCalculationPageState } from '@pages/ArrModelPage/hooks/useCalculationPageState';
import { IsMetricPageURLBasedAtom, MetricPageSearchParamsAtom } from '@pages/MetricPage/atoms/MetricPageSearchParams';
import { TableRefAtom } from '@pages/MetricPage/atoms/TableRef';
import RulesEngineRunner from '@pages/MetricPage/components/RulesEngineRunner';
import { MetricEditProvider } from 'src/common/providers/MetricEditProvider';
import shadows from 'src/style/shadows';
import { MappingFrame } from '../mapping/MappingFrame';
import { ArrModelSidePanel } from './ArrModelSidePanel';

export default function ArrModelPageGuard() {
	const isArrModelPageEnabled = useFeatureFlag('pulse.sightfull2.arrModel.enable');
	if (!isArrModelPageEnabled) return null;
	return (
		// TODO: This provider will probably move when implementing the mapping page, ignoring it for now..
		<MetricEditProvider
			isNewMetric={true}
			metricKind={'aggregate'}
			initialValues={[
				[MetricPageSearchParamsAtom, { metricName: UNTITLED_METRIC_NAME, searchParams: '' }],
				[IsMetricPageURLBasedAtom, false],
				[TableRefAtom, null],
			]}
		>
			<RulesEngineRunner>
				<ArrModelPage />
			</RulesEngineRunner>
		</MetricEditProvider>
	);
}

function ArrModelPage() {
	const a = useCalculationPageState();
	if (a.isLoading) return <>ABC</>;
	return (
		<Flex padding={'8px'} flexDirection={'column'} height="100vh">
			<Flex alignItems={'flex-start'} gap={'8px'} flex={1} maxH={'100%'}>
				<ArrModelSidePanel handleCollapsePanelClick={() => 0} isCollapsed={false} />
				<Flex
					flex={1}
					height={'100%'}
					background={'white'}
					borderRadius={'12px'}
					overflow={'hidden'}
					boxShadow={shadows.mainCanvas}
				>
					<MappingFrame />
				</Flex>
			</Flex>
		</Flex>
	);
}
