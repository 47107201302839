import { ReactNode } from 'react';
import { MonacoTooltipComponent } from 'src/lib/completions/widgetBuilder/MonacoTooltipComponent';
import { resolveContextFromSemanticsAndName } from 'src/pages/OntologyPage/components/JoinKeys/utils';
import {
	formatDimensionLabel,
	removeLastFilterLabelItem,
} from '../LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/utils';
import { isDimensionContext } from 'src/lib/completions/completionProvider';
import { getContextCompletionFilterTooltip } from 'src/lib/completions/widgetBuilder/contextFilterTooltip';
import { useSemanticDefinitions } from 'src/common/hooks/stores/useSemanticDefinitions';

type TooltipPropsType = {
	dimensionName: string;
	dimensionsEntity: string;
	entityName: string;
	label: string;
};

export function HoverTooltip({ dimensionName, dimensionsEntity, entityName, label }: TooltipPropsType): ReactNode {
	const { semanticDefinitions } = useSemanticDefinitions();
	if (!semanticDefinitions || !label) return;
	const context = resolveContextFromSemanticsAndName(semanticDefinitions, dimensionsEntity, dimensionName);
	const path = removeLastFilterLabelItem(formatDimensionLabel(label));
	const formattedDimensionPath = `${entityName} / ${path}`;
	if (!context || !isDimensionContext(context)) return;

	return (
		<MonacoTooltipComponent
			tooltipMarkdownString={getContextCompletionFilterTooltip(context, dimensionName, formattedDimensionPath)}
		/>
	);
}
