import Flex from '@components/Flex';
import Skeleton from 'src/common/components/Skeleton';
import classes from './RBACSkeleton.module.scss';

const { avatarSkeleton, textSkeleton } = classes;
const LIST_LENGTH = 3;

export function ListSkeleton() {
	return (
		<Flex flexDirection={'column'} width={'100%'}>
			{Array.from({ length: LIST_LENGTH }, (_, i) => (
				<Flex gap={'12px'} width={'100%'} padding={'8px 12px'} key={i}>
					<Skeleton className={avatarSkeleton} />
					<Flex flex={1} gap={'4px'} flexDirection={'column'}>
						<Skeleton width={'25%'} className={textSkeleton} />
						<Skeleton width={'75%'} className={textSkeleton} />
					</Flex>
				</Flex>
			))}
		</Flex>
	);
}
