import { GetUserInformation } from 'src/queries/user';
import { useFeatureFlagsStore } from 'src/stores/featureFlags';
import { useUserStore } from 'src/stores/user';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GetTenantInfoQuery } from 'src/generated/graphql';
import * as Normalize from 'src/normalize';
import { GetTenantInfo } from 'src/queries/core-reader';
import { initUserInfo } from 'src/services/analytics';
import { useTenantConfigStore } from 'src/stores/tenantConfig';
import TenantConfigType, { defaultPlanConfig } from 'src/types/tenantConfig';

export const initializeUserInformation = async (apolloClient: ApolloClient<NormalizedCacheObject>) => {
	const {
		data: { login, tenants },
	} = await apolloClient.query({
		query: GetUserInformation,
		variables: {
			id_token: null,
		},
	});

	const user = Normalize.user(login.user, login.intercomHMAC);
	useUserStore.setState({ user });
	const normalizedFlags = Normalize.flags(login.features, user.id);
	useFeatureFlagsStore.setState(normalizedFlags);

	const isSightfull2 = normalizedFlags['pulse.sightfull2.enable'];

	if (isSightfull2) return;
	const normalizedTenantSettings = Normalize.tenantSettings(tenants);
	useTenantConfigStore.setState(normalizedTenantSettings);

	initUserInfo(user, normalizedTenantSettings);
};

export const initializeTenantInformationCoreReader = async (apolloClient: ApolloClient<NormalizedCacheObject>) => {
	const {
		data: { tenantInfo },
	} = await apolloClient.query<GetTenantInfoQuery>({
		query: GetTenantInfo,
	});

	const normalizedTenantSettings: TenantConfigType = {
		companyName: tenantInfo.name,
		fiscalYearStart: `${tenantInfo.fiscalYearOffset + 1}`,
		decimalDigits: tenantInfo.decimalDigits ?? undefined,
		logoUrl: tenantInfo.logoUrl ?? '',
		graphColors:
			tenantInfo.graphColors
				?.trim()
				?.split(',')
				?.map((c) => `#${c}`) ?? [],
		lastEtlSync: new Date(tenantInfo.lastEtlSynced ?? 0),
		lookbackMonths: tenantInfo.lookbackMonths ?? undefined,
		trialEnd: tenantInfo.trialEnd ?? '',
		schemasVersion: tenantInfo.schemasVersion ?? undefined,
		tenantState: tenantInfo.tenantState ?? undefined,
		plan: tenantInfo.plan ?? defaultPlanConfig,
		isSightfullTenant: tenantInfo.isSightfullTenant,
		aiSettings: tenantInfo.aiSettings
			? {
					omniPage: tenantInfo.aiSettings.omniPage
						? {
								subtitle: tenantInfo.aiSettings.omniPage.subtitle ?? undefined,
								suggestions: tenantInfo.aiSettings.omniPage.suggestions?.map((s) => ({
									prompt: s.prompt ?? undefined,
									display: s.display ?? undefined,
								})),
						  }
						: undefined,
			  }
			: undefined,
	};

	useTenantConfigStore.setState(normalizedTenantSettings);

	initUserInfo(useUserStore.getState().user, normalizedTenantSettings);
};
