import { Box } from '@chakra-ui/react';
import MetricDataOverview from '@components/MetricView/MetricDataOverview';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { BreakdownsPanel } from '../FiltersAndBreakdown/BreakdownsPanel';
import { FiltersPanel } from '../FiltersAndBreakdown/FiltersPanel';
import { FiltersPanel2 } from '../FiltersAndBreakdown/FiltersPanel2';
import { convertModalResultToValidCoreValues } from '../FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { OnModalOpenType } from '../FiltersAndBreakdown/types';
import { ParametersPanel } from '../Parameters/ParametersPanel';
import useDimensionsState from './useDimensionsState';

type Props = {
	isShowingFullSize: boolean;
	onModalOpen: OnModalOpenType;
	isDisabled?: boolean;
	toggleGenAIPopover: VoidFunction;
};

export function InvestigatePanel({ isShowingFullSize, onModalOpen, isDisabled, toggleGenAIPopover }: Props) {
	const [, { addDimensionsParamsByType }] = useDimensionsState();
	const { objectsTypes } = useMetricDerivedState();
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const isFiltersV2 = useFeatureFlag('pulse.sightfull2.filters2.enable');
	const isDataOverviewEnabled = useFeatureFlag('pulse.react.generative.dataOverview.enable');
	const isFiltersOperatorsEnabled = useFeatureFlag('pulse.sightfull2.filters2.operators.enable');

	const onModalClose = (result: any) => {
		addDimensionsParamsByType(
			result.type,
			isSightfull2 ? convertModalResultToValidCoreValues(result, objectsTypes[0]) : result.items
		);
	};

	return (
		<Box marginTop="20px" marginX="20px">
			{isShowingFullSize && isDataOverviewEnabled && <MetricDataOverview toggleGenAIPopover={toggleGenAIPopover} />}
			<ParametersPanel divider={'bottom'} isShowingFullSize={isShowingFullSize} />
			{isSightfull2 && isFiltersV2 && isFiltersOperatorsEnabled ? (
				<FiltersPanel2 isDisabled={isDisabled} isShowingFullSize={isShowingFullSize} />
			) : (
				<FiltersPanel
					isDisabled={isDisabled}
					isShowingFullSize={isShowingFullSize}
					onModalOpen={onModalOpen}
					onModalClose={onModalClose}
				/>
			)}
			<BreakdownsPanel isDisabled={isDisabled} isShowingFullSize={isShowingFullSize} />
		</Box>
	);
}
