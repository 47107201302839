import Box from '@components/Box';
import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import Flex from '@components/Flex';
import Markdown from '@components/Markdown';
import Typography from '@components/Typography';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OkDouble16 } from '../../Icons';
import { AskAIChatFieldSuggestion } from '../types';

type AskAIChatFieldSuggestionsProps = {
	fields: AskAIChatFieldSuggestion[];
	onAcceptSuggestions?: (selected: AskAIChatFieldSuggestion[]) => void;
	isLastChatCard?: boolean;
};

function isFieldValueChanged(field: AskAIChatFieldSuggestion): boolean {
	return JSON.stringify(field.value) !== JSON.stringify(field.existingValue);
}

function shouldDisplayField(field: AskAIChatFieldSuggestion): boolean {
	if (!field.display) return false;
	return isFieldValueChanged(field);
}

function FieldValue({ value, isDisabled }: { value?: string; isDisabled: boolean }) {
	return (
		<Box
			backgroundColor={'gray.200'}
			borderRadius={'8px'}
			marginLeft={'24px'}
			padding={'8px 12px'}
			opacity={isDisabled ? 0.5 : 1}
		>
			<Typography variant={'Paragraph13R'} color={'gray.1000'} wordBreak={'break-word'}>
				<Markdown isAI>{value}</Markdown>
			</Typography>
		</Box>
	);
}

export function AskAIChatFieldSuggestions({
	fields,
	onAcceptSuggestions,
	isLastChatCard,
}: AskAIChatFieldSuggestionsProps) {
	const displayableFields = useMemo(() => {
		return fields.filter(shouldDisplayField);
	}, [fields]);

	const hiddenFields = useMemo(() => {
		return new Set(
			fields.filter((field) => !field.display && isFieldValueChanged(field)).map((field) => field.propertyName)
		);
	}, [fields]);

	const [selectedFields, setSelectedFields] = useState(new Set());

	useEffect(() => {
		const newSelectedFields = isLastChatCard
			? new Set(displayableFields.map((field) => field.propertyName))
			: new Set();
		setSelectedFields(newSelectedFields);
	}, [displayableFields, isLastChatCard]);

	const onCheckChanged = useCallback(
		(isChecked: boolean, value: string) => {
			setSelectedFields((prev) => {
				if (isChecked) prev.add(value);
				else prev.delete(value);
				return new Set(prev);
			});
		},
		[setSelectedFields]
	);

	const onAccept = useCallback(() => {
		const allSelectedFields = selectedFields.union(hiddenFields);
		onAcceptSuggestions?.(fields.filter((field) => allSelectedFields.has(field.propertyName)));
		setSelectedFields(() => new Set());
	}, [fields, hiddenFields, selectedFields, onAcceptSuggestions]);

	if (displayableFields.length === 0) {
		return (
			<Box padding={'8px 16px 16px 16px'}>
				<Typography variant={'Paragraph13R'}>I have no suggestions 🫣 Can you provide more context?</Typography>
			</Box>
		);
	}

	return (
		<Flex direction={'column'} gap={'20px'} padding={'8px 16px 16px 16px'}>
			{displayableFields.map((field, index) =>
				shouldDisplayField(field) ? (
					<Flex direction={'column'} gap={'12px'} key={index}>
						<Checkbox
							isFluid={true}
							variant={'rounded'}
							checkboxColor={'blue.600'}
							size={'xs'}
							value={field.propertyName}
							label={field.label}
							onChange={onCheckChanged}
							isChecked={selectedFields.has(field.propertyName)}
						/>
						<FieldValue value={field.display} isDisabled={!selectedFields.has(field.propertyName)} />
					</Flex>
				) : null
			)}
			<Flex direction={'row-reverse'}>
				<Button
					onClick={onAccept}
					size={'inline'}
					variant={'solid'}
					colorScheme={'primaryBlue'}
					backgroundColor={'blue.600'}
					borderRadius={'8px'}
					padding={'5px 12px'}
					isDisabled={selectedFields.size === 0}
					leftIcon={<OkDouble16 />}
				>
					Apply Selected
				</Button>
			</Flex>
		</Flex>
	);
}
