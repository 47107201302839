import eq from 'lodash/fp/eq';
import some from 'lodash/fp/some';
import { PeriodUnit } from '@sightfull/period-ranges';

const PeriodScale = [
	[''],
	['day'],
	['week', 'fweek'],
	['month'],
	['cquarter', 'quarter', 'fquarter'],
	['fyear'],
	[null],
];

export default function calcEffectiveCompareToUnit(selectedPeriodUnit?: PeriodUnit, compareToPeriodUnit?: PeriodUnit) {
	const selectedPeriodUnitWeight = PeriodScale.findIndex(some(eq(selectedPeriodUnit)));
	const compareToPeriodUnitWeight = PeriodScale.findIndex(some(eq(compareToPeriodUnit)));
	return selectedPeriodUnit !== 'custom'
		? compareToPeriodUnit && selectedPeriodUnitWeight > compareToPeriodUnitWeight
			? selectedPeriodUnit
			: compareToPeriodUnit
		: null;
}
