import Divider from '@components/Divider';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { AffectedMetricsButton } from '@components/AffectedMetricsButton/AffectedMetricsButton';
import pluralize from 'pluralize';

export const AffectedMetricsPanel = ({ affectedMetricsList }: { affectedMetricsList: string[] }) => {
	return (
		<>
			<Divider direction={'horizontal'} color={'gray.300'} marginTop={'20px'} marginBottom={'20px'} />
			<Flex
				justifyContent={'space-between'}
				alignItems={'center'}
				borderRadius={'8px'}
				backgroundColor={'gray.200'}
				mb={'20px'}
				p={'16px'}
			>
				<Typography variant={'DesktopH8Medium'} color={'gray.1000'}>
					Affected {pluralize('metric', affectedMetricsList.length)} ({affectedMetricsList.length})
				</Typography>
				<AffectedMetricsButton
					colorScheme={'gray'}
					size={'inline'}
					variant={'solid'}
					label={'View list'}
					metricNames={affectedMetricsList}
				/>
			</Flex>
		</>
	);
};
