import { useCallback } from 'react';
import {
	AIAgentName,
	AskAIChatCardProps,
	isAskAIMetricDiscoveryChatMessage,
	isChatEventResultMetricDescribe,
} from '../types';
import { useAIAgents } from './useAIAgents';
import { useCachedPerformDiscovery } from './useCachedPerformDiscovery';

const agentState = { running: false };

export function useMetricResultsAIAgent({ chatMessageAtom }: Pick<AskAIChatCardProps, 'chatMessageAtom'>) {
	const { chatMessage, setChatMessage, addChatEvent } = useCachedPerformDiscovery({ chatMessageAtom });

	const { getOrCreateAIAgentThread, callStreamingAIAgent } = useAIAgents();

	const callDescribeMetricResultsAIAgent = useCallback(
		async ({ metric, calcResults }: { metric: string; calcResults: object }) => {
			if (agentState.running) return;
			agentState.running = true;
			const agentName: AIAgentName = `metric-results`;
			try {
				const threadId = await getOrCreateAIAgentThread({ agentName });
				return new Promise<string>((resolve, reject) =>
					callStreamingAIAgent({
						agentName,
						threadId,
						body: { describe: { metric, calcResults, contextPrompt: chatMessage.userPrompt } },
						events: {
							onError(error) {
								reject(error);
							},
							onEvent(event) {
								addChatEvent(event);
							},
							onClose() {
								agentState.running = false;
							},
							onResult(event) {
								if (isChatEventResultMetricDescribe(event)) {
									if (event.result.result.status === 'complete' && event.result.result.text) {
										resolve(event.result.result.text);
										return;
									}
									reject(event.result.result.error ?? 'Result has an unknown error');
								}
								reject('Something went wrong, no result available');
							},
						},
					})
				);
			} catch (error) {
				agentState.running = false;
				throw error;
			}
		},
		[getOrCreateAIAgentThread, callStreamingAIAgent, chatMessage.userPrompt, addChatEvent]
	);

	const getMetricResultsDescription = useCallback(
		async ({ metric, calcResults }: { metric: string; calcResults: object }) => {
			if (!isAskAIMetricDiscoveryChatMessage(chatMessage)) return;
			const suggestedMetric = chatMessage.result?.metrics?.find((m) => m.metric === metric);
			if (!suggestedMetric) return;
			if (!suggestedMetric.resultDescription) {
				const text = await callDescribeMetricResultsAIAgent({ metric, calcResults });
				if (text) {
					suggestedMetric.resultDescription = text;
					setChatMessage((prev) => ({
						...prev,
					}));
				}
			}
			return suggestedMetric.resultDescription;
		},
		[callDescribeMetricResultsAIAgent, chatMessage, setChatMessage]
	);

	return { chatMessage, getMetricResultsDescription, addChatEvent };
}
