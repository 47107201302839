import { useCallback } from 'react';
import { useSemanticDefinitions } from 'src/common/hooks/stores/useSemanticDefinitions';
import {
	useCreateEntityMutation,
	useGetEntityDefinitionLazyQuery,
	useRunNormalizationMutation,
	useSchemasDataQuery,
	useUpdateEntityMutation,
} from 'src/generated/graphql';
import { Relationship, RelationshipType } from 'src/lib/completions/semanticTypes/normalization.schema';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { TSchemaTable, TTableColumn } from '../AddMultipleEntitiesModal';
import YAML from 'yaml';
import { useInvalidateCache } from 'src/services/apollo';
import { useToast } from 'src/common/hooks/ui/useToast';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';

type Props = {
	onClose: VoidFunction;
};

export const useCreateMultipleEntities = ({ onClose }: Props) => {
	const toast = useToast();
	const { data: schemas } = useSchemasDataQuery();
	const { semanticDefinitions } = useSemanticDefinitions();
	const [createEntityMutation] = useCreateEntityMutation();
	const [updateEntityMutation] = useUpdateEntityMutation();
	const [getEntityDefinition] = useGetEntityDefinitionLazyQuery();
	const { invalidateCache } = useInvalidateCache();
	const [runNormalizationMutation] = useRunNormalizationMutation();
	const isAddingSourceDimensionsEnabled = useFeatureFlag(
		'pulse.sightfull2.ontology.addSourceDimensionsInMultipleEntitiesImport.enable'
	);

	const createSourceDimension = useCallback((entityName: string, dimension: TTableColumn) => {
		const MAX_DB_COLUMN_LENGTH = 49;
		const maxDimensionLength = MAX_DB_COLUMN_LENGTH - entityName.length;
		const { name, description, dataType } = dimension;
		const lowercasedName = name.toLowerCase();
		const sourceDimensionPrefix = '$src__';
		const [inferredDimensionName, inferredDimensionDescription] = description?.split(':') || [];
		const prettyName = removeUnderscoresAndCapitalize(inferredDimensionName || name);

		return {
			name: lowercasedName.slice(0, maxDimensionLength),
			type: dataType,
			sources: [{ sql: `${sourceDimensionPrefix}${lowercasedName}` }],
			meta: {
				display_name: prettyName,
				description: inferredDimensionDescription?.trim() || description || '',
			},
		};
	}, []);

	const createRelationships = useCallback(
		(schemaName: string, entityTable: string, restOfChosenEntityTables: string[]): Relationship[] => {
			const tableConstraints =
				schemas?.schemasData.find((schema) => schema.name === schemaName)?.tableConstraints || [];

			if (!tableConstraints) {
				return [];
			}

			const entityTableConstraints = tableConstraints.find(
				(tableConstraint) => tableConstraint.tableName === entityTable
			);

			if (!entityTableConstraints) {
				return [];
			}

			const columnRelations = entityTableConstraints.columns.map((column) => {
				return {
					columnName: column.columnName,
					relationships: column.relationships.filter((relationship) =>
						restOfChosenEntityTables.includes(relationship.tableName)
					),
				};
			});

			return columnRelations.flatMap((columnRelation) => {
				return columnRelation.relationships.map((relationship) => {
					const referencedEntity = relationship.tableName.toLowerCase();
					return {
						name: `${relationship.tableName}_${relationship.columnName}`.toLowerCase(),
						type: relationship.relationshipType as RelationshipType,
						referenced_entity: referencedEntity,
						on: `$src__${columnRelation.columnName.toLocaleLowerCase()} = $${referencedEntity}.src__${relationship.columnName.toLocaleLowerCase()}`,
						meta: {
							description: `A relationship between ${removeUnderscoresAndCapitalize(
								entityTable.toLowerCase()
							)} and ${removeUnderscoresAndCapitalize(referencedEntity)}`,
							display_name: removeUnderscoresAndCapitalize(referencedEntity),
						},
					};
				});
			});
		},
		[schemas]
	);

	const getAlreadyImportedEntities = useCallback(
		(schemaName: string) => {
			return semanticDefinitions?.entities.filter(
				(entity) => 'schema' in entity.data_source && entity.data_source.schema.name === schemaName
			);
		},
		[semanticDefinitions]
	);

	const createEntity = useCallback(
		(entityData: TSchemaTable, selectedSchemaTables: TSchemaTable[]) => {
			const { schema, table } = entityData;
			const entityName = table.name.toLowerCase();
			const sourceDimensions = table.columns.map((column) => createSourceDimension(table.name, column));
			const prettyName = removeUnderscoresAndCapitalize(table.name.toLowerCase());
			const primaryKeys = table.columns.filter((column) => column.isPrimaryKey).map((column) => `SRC__${column.name}`);
			const restOfChosenEntityTables = selectedSchemaTables
				.filter((entity) => entity.schema === schema && entity.table.name !== table.name)
				.map((entity) => entity.table.name);
			const alreadyImportedEntities = getAlreadyImportedEntities(schema);
			const alreadyImportedEntitiesTables: string[] =
				alreadyImportedEntities
					?.map((entity) => ('table' in entity.data_source ? entity.data_source.table : ''))
					.filter((table) => table !== '') || [];
			const relationships = createRelationships(schema, table.name, restOfChosenEntityTables);
			const relationshipsToExistingEntities = createRelationships(schema, table.name, alreadyImportedEntitiesTables);

			const entityYAML = YAML.stringify({
				name: entityName,
				meta: {
					description: table.description || '',
					display_name: prettyName,
				},
				data_source: {
					schema,
					table: table.name,
				},
				primary_keys: primaryKeys,
				dimensions: isAddingSourceDimensionsEnabled ? sourceDimensions : [],
				relationships: [...relationships, ...relationshipsToExistingEntities],
			}).split('\n');

			return {
				schema,
				tableName: table.name,
				entityYAML,
				relationshipsToExistingEntities,
			};
		},
		[createRelationships, getAlreadyImportedEntities]
	);

	const getUpdatedExistingEntities = useCallback(
		async (schemaName: string, entityTableName: string, relationshipsToExistingEntities: Relationship[]) => {
			const updatedEntities = [];

			for (const relationship of relationshipsToExistingEntities) {
				const existingEntity = await getEntityDefinition({ variables: { entityName: relationship.referenced_entity } });

				if (!existingEntity.data?.getEntityDefinition.entityDefinition) {
					continue;
				}

				const existingEntityVersion = existingEntity.data?.getEntityDefinition.entityDefinitionVersion.definitionHash;
				const existingEntityYAML = YAML.parse(existingEntity.data?.getEntityDefinition.entityDefinition.join('\n'));

				if (!('table' in existingEntityYAML.data_source)) {
					continue;
				}

				const inferredRelationships = createRelationships(schemaName, existingEntityYAML.data_source.table, [
					entityTableName,
				]);
				const notExistingRelationships = inferredRelationships.filter(
					(relationship) => !existingEntityYAML.relationships?.some((r: Relationship) => r.name === relationship.name)
				);

				const updatedEntityYAML = YAML.stringify({
					...existingEntityYAML,
					relationships: [...existingEntityYAML.relationships, ...notExistingRelationships],
				});

				updatedEntities.push({
					updatedEntityYAML: updatedEntityYAML.split('\n'),
					version: existingEntityVersion,
					entityName: existingEntityYAML.name,
				});
			}

			return updatedEntities;
		},
		[createRelationships, getEntityDefinition]
	);

	const createEntityYAMLs = useCallback(
		async (selectedSchemaTables: TSchemaTable[]) => {
			const entityYAMLs = selectedSchemaTables.map((entity) => createEntity(entity, selectedSchemaTables));

			// setIsLoading(true);
			try {
				for (const { entityYAML, schema, tableName, relationshipsToExistingEntities } of entityYAMLs) {
					await createEntityMutation({ variables: { entityDefinition: entityYAML } });
					const updatedEntities = await getUpdatedExistingEntities(schema, tableName, relationshipsToExistingEntities);

					for (const { updatedEntityYAML, entityName, version } of updatedEntities) {
						await updateEntityMutation({
							variables: { entityName, entityDefinition: updatedEntityYAML, currentEntityVersion: version },
						});
					}
				}

				await runNormalizationMutation();

				// setIsLoading(false);
				const successMessage = `Successfully created ${selectedSchemaTables.length} ${
					selectedSchemaTables.length > 1 ? 'entities' : 'entity'
				}`;
				toast({
					variant: 'ok',
					message: successMessage,
				});
			} catch (err) {
				const errorMessage = err instanceof Error ? err.message : 'Failed to create entities';
				toast({
					variant: 'error',
					message: errorMessage,
				});
			}

			await invalidateCache();
			onClose();
		},
		[
			createEntity,
			createEntityMutation,
			getUpdatedExistingEntities,
			invalidateCache,
			onClose,
			runNormalizationMutation,
			toast,
			updateEntityMutation,
		]
	);

	return {
		createEntityYAMLs,
	};
};
