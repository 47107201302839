import { PopoverContent, useDisclosure } from '@chakra-ui/react';
import Box from '@components/Box';
import Flex from '@components/Flex';
import Button from '@components/Button';
import Tooltip from '@components/Tooltip';
import ListItem from '@components/ListItem';
import Popover from '@components/Popover';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { useReportEvent } from 'src/services/analytics';
import { AddLarge, AddSmall16, DownloadSolid16 } from 'src/common/components/Icons';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

type Props = {
	onCreateNewOpen: VoidFunction;
	onCreateMultipleOpen: VoidFunction;
	isEditable: boolean;
};

export const CreateNewButton = ({ onCreateNewOpen, onCreateMultipleOpen, isEditable }: Props) => {
	const { reportEvent } = useReportEvent({ feature: 'Sidebar' });
	const isFeatureFlagEnabled = useFeatureFlag('pulse.sightfull2.ontology.multipleEntitiesImport.enable');
	const isMultipleEntitiesInsertEnabled = isFeatureFlagEnabled;
	const isWithDropdown = isMultipleEntitiesInsertEnabled;
	const { isOpen, onClose, onOpen } = useDisclosure();

	const onSelectorOpen = () => {
		reportEvent({ event: 'sidebar-create-new-item-clicked' });
		onOpen();
	};

	const onSelectorClose = () => {
		onClose();
	};

	const PlusButton = (
		<Tooltip size="md" variant="fluid" label={'Create new'} placement={'right'} marginLeft={'6px'}>
			<Box
				{...buildIntercomAttributes({
					area: 'dashboards',
					type: 'button',
					target: 'create-new-button',
				})}
			>
				<Button
					size={'small'}
					variant={'outline'}
					colorScheme={'gray'}
					testId={isWithDropdown ? 'create-new-entity-picker' : 'create-new-entity-button'}
					onClick={isWithDropdown ? onSelectorOpen : onCreateNewOpen}
					isIconOnly
					_hover={{ bgColor: colors.buttonPanelItemColor }}
					mixBlendMode={'multiply'}
					isDisabled={!isEditable}
				>
					<AddLarge color={colors.gray['900']} />
				</Button>
			</Box>
		</Tooltip>
	);

	const popover = (
		<Popover
			triggerElement={PlusButton}
			isOpen={isOpen}
			placement={'bottom-start'}
			onClose={onSelectorClose}
			popoverContentProps={{
				boxShadow: shadows['panelShadow'],
				borderRadius: '8px',
				border: `1px solid ${colors.gray[300]}`,
			}}
		>
			<PopoverContent>
				<Flex alignSelf="center" direction={'column'} py="8px" width="224px">
					<ListItem
						color="gray.1000"
						hoverColor={'gray.200'}
						label="Create entity"
						size="sm"
						onClick={onCreateNewOpen}
						prefixIcon={<AddSmall16 />}
						testId={'create-new-entity-button'}
					/>
					<ListItem
						color="gray.1000"
						hoverColor={'gray.200'}
						label="Import multiple entities"
						size="sm"
						onClick={onCreateMultipleOpen}
						prefixIcon={<DownloadSolid16 />}
					/>
				</Flex>
			</PopoverContent>
		</Popover>
	);

	return <>{isWithDropdown ? popover : PlusButton}</>;
};
