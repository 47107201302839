import { Box, Input, Popover, PopoverAnchor, PopoverContent } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import useMentionsState from 'src/common/hooks/stores/useMentionsState';
import { useModal } from 'src/common/hooks/ui/useModal';
import { Mention } from 'src/types/mention';
import Button from '../../Button';
import { SpecificResource } from '../../Can/types';
import Flex from '../../Flex';
import Typography from '../../Typography';
import { RoleSelect } from '../RoleSelect/RoleSelect';
import { RoleType } from '../constants';
import { ResourceRolesUser, useAssignRoleToUserOnResource } from '../useResourceRoles';
import { AutoCompleteList } from './AutoCompleteList';
import { InviteChipList } from './InviteChipList';
import { checkForMatch } from 'src/common/utils/format';

const MIN_FILTER_CHARACTERS = 1;

export function InviteTextInput({
	myRole,
	resource,
	users,
}: {
	myRole: RoleType;
	resource: SpecificResource;
	users?: ResourceRolesUser[];
}) {
	const [value, setValue] = useState('');
	const [inviteChips, setInviteChips] = useState<Mention[]>([]);
	const [selectedRole, setSelectedRole] = useState<RoleType>('viewer');
	const mentions = useMentionsState();

	const { isOpen, onClose, onOpen } = useModal();

	const filteredMentions = useMemo(() => {
		if (value.length < MIN_FILTER_CHARACTERS) return [];
		return mentions.filter((mention) => {
			return (
				!inviteChips.find((inviteChip) => inviteChip.id === mention.id) &&
				checkForMatch({ subject: mention.name || '', value })
			);
		});
	}, [inviteChips, mentions, value]);

	const [assignRoleToUserOnResource] = useAssignRoleToUserOnResource({ resource });

	const onInvite = useCallback(() => {
		inviteChips.forEach((chip) => {
			if (chip.id) assignRoleToUserOnResource(chip.id, selectedRole);
		});

		setValue('');
		setInviteChips([]);
		setSelectedRole('viewer');
	}, [assignRoleToUserOnResource, inviteChips, selectedRole]);

	return (
		<Flex direction={'row'} gap="12px" paddingX={'16px'} paddingTop={'16px'} paddingBottom={'4px'}>
			<Flex
				direction={'row'}
				padding={'5px'}
				border={'1px solid'}
				borderColor={'gray.300'}
				_hover={{ borderColor: 'gray.500' }}
				_focusWithin={{ borderColor: 'gray.700' }}
				borderRadius={'6px'}
				flex={1}
			>
				<Flex direction={'column'} flex={1}>
					<InviteChipList
						chips={inviteChips}
						onDelete={(user) => {
							setInviteChips(inviteChips.filter((chip) => chip.id !== user.id));
						}}
					/>
					<Popover isOpen={isOpen} autoFocus={false} placement="bottom-start">
						<PopoverAnchor>
							<Box>
								<Typography variant="DesktopH8Regular" color={'gray.1000'} minWidth={'100%'}>
									<Input
										autoFocus
										border={'none'}
										outline={'none'}
										focusBorderColor="transparent"
										onBlur={onClose}
										onFocus={onOpen}
										borderColor="transparent"
										value={value}
										onChange={(e) => {
											setValue(e.target.value);
											if (e.target.value.length > 0) {
												onOpen();
											}
										}}
										placeholder={inviteChips.length == 0 ? 'Invite others by name or email' : ''}
										_placeholder={{ color: 'gray.500', fontSize: '14px', lineHeight: '20px', fontWeight: '400' }}
										padding={'0'}
										paddingLeft={'8px'}
										height={'24px'}
										onKeyDown={(e) => {
											const target = e.target;
											const key = e.key;

											if (key == 'Backspace') {
												if ('selectionStart' in target && 'selectionEnd' in target) {
													if (target.selectionStart === 0 && target.selectionEnd === 0) {
														setInviteChips(inviteChips.slice(0, -1));
													}
												}
											}
										}}
									/>
								</Typography>
							</Box>
						</PopoverAnchor>
						<PopoverContent marginLeft={'-7px'} marginTop={'6px'}>
							<AutoCompleteList
								mentions={filteredMentions}
								usersWithRoles={users ?? []}
								onItemSelected={(mention) => {
									setInviteChips([...inviteChips, mention]);
									setValue('');
								}}
							/>
						</PopoverContent>
					</Popover>
				</Flex>
				{!!inviteChips.length && (
					<Flex height={'fit-content'}>
						<RoleSelect
							onSelect={(role) => {
								if (role) setSelectedRole(role);
							}}
							selectedRole={selectedRole}
							myRole={myRole}
							isBorderedToggle
							isIncludingRemove={false}
						/>
					</Flex>
				)}
			</Flex>
			<Button
				variant={'solid'}
				size={'small'}
				colorScheme={!inviteChips.length ? 'lightGray' : 'blue'}
				isDisabled={!inviteChips.length}
				onClick={onInvite}
			>
				Invite
			</Button>
		</Flex>
	);
}
