import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { convertFiltersToRawDimensionsDict, Filter, FilterV2 } from 'src/pages/MetricPage/utils/state.types';
import { tryParseJson } from 'src/pages/MetricPage/utils/useNormalizedSearchParams';
import { GLOBAL_FILTERS_URL_PARAM } from '../utils/consts';
import { calcCoreValidFilters } from 'src/lib/metricRules/DerivedStateCalculators';
import { useCoreReaderFiltersApi } from 'src/common/hooks/fetching/useCoreReaderFiltersApi';
import { CloudSupportedOperators, SupportedDimensionTypes } from 'src/generated/graphql';
import { isFilterV1 } from 'src/pages/MetricPage/components/InvestigatePanel/useMetricFiltersV2';
import useDebouncedCallback from 'src/common/hooks/useDebouncedCallback';

export function useGlobalFilters(): [
	[FilterV2[], string[], boolean],
	(filters: FilterV2[]) => void,
	(objectTypes: string[]) => void,
	({ isResetingFilterNodeTypes }: { isResetingFilterNodeTypes: boolean }) => void,
	(isLoading: boolean) => void
] {
	const [urlSearchParams, setUrlSearchParams] = useSearchParams();
	const [filterNodeTypes, setFilterNodeTypes] = useState(new Set<string>());
	const [isFiltersLoading, setIsFiltersLoading] = useState(false);
	const [, fetchRelationshipsAndDimensions] = useCoreReaderFiltersApi('cache-first');

	const globalFilters: FilterV2[] = useMemo(() => {
		const rawFilters = urlSearchParams.get(GLOBAL_FILTERS_URL_PARAM);
		if (!rawFilters) return [];
		const filters = tryParseJson(rawFilters);
		if (!Array.isArray(filters)) return [];
		if (filters.some((f) => isFilterV1(f))) return [];
		return filters;
	}, [urlSearchParams]);

	const oldFiltersV1: Filter[] = useMemo(() => {
		const rawFilters = urlSearchParams.get(GLOBAL_FILTERS_URL_PARAM);
		if (!rawFilters) return [];
		const filters = tryParseJson(rawFilters);
		if (!Array.isArray(filters)) return [];

		const parsedFilters = filters.map((f) => ({
			label: f.l,
			key: f.k,
			values: f.v,
			type: 'text' as const,
		}));
		return parsedFilters;
	}, [urlSearchParams]);

	const setGlobalFilters = useCallback(
		(filters: FilterV2[]) => {
			const newSearchParams = new URLSearchParams(urlSearchParams.toString());
			if (filters.length === 0) {
				newSearchParams.delete(GLOBAL_FILTERS_URL_PARAM);
			} else {
				const filtersString = JSON.stringify(filters);
				newSearchParams.set(GLOBAL_FILTERS_URL_PARAM, filtersString);
			}
			setUrlSearchParams(newSearchParams);
			setIsFiltersLoading(false);
		},
		[setUrlSearchParams, urlSearchParams]
	);

	const onAddFilterObjects = useCallback((objectTypes: string[]) => {
		setFilterNodeTypes((filterObjects) => new Set([...filterObjects, ...objectTypes]));
	}, []);

	const resetGlobalFilters = useCallback(
		({ isResetingFilterNodeTypes }: { isResetingFilterNodeTypes: boolean }) => {
			if (isResetingFilterNodeTypes) setFilterNodeTypes(new Set());
			setGlobalFilters([]);
		},
		[setGlobalFilters]
	);

	const objectsTypes = useMemo(() => Array.from(filterNodeTypes), [filterNodeTypes]);

	const isShouldTransformFilters = useMemo(
		() => objectsTypes.length > 0 && oldFiltersV1.length > 0 && !oldFiltersV1.some((filter) => !filter.key),
		[objectsTypes.length, oldFiltersV1]
	);

	const transformOldFilters = useCallback(async () => {
		const globalFilters: FilterV2[] = [];
		await Promise.all(
			objectsTypes.map(async (entity: string) => {
				const { filters } = await calcCoreValidFilters(
					oldFiltersV1.map((el) => ({ ...el, type: SupportedDimensionTypes.String })),
					{ objectsTypes: [entity] },
					fetchRelationshipsAndDimensions,
					{
						filterBy: convertFiltersToRawDimensionsDict(oldFiltersV1),
					}
				);
				if (filters.length === 0) return;
				const newFilters: FilterV2[] = filters.map((filter) => ({
					...filter,
					type: SupportedDimensionTypes.String,
					operator: CloudSupportedOperators.OneOf,
					baseEntity: entity,
				}));
				globalFilters.push(...newFilters);
			})
		);
		setGlobalFilters(globalFilters);
		setIsFiltersLoading(false);
	}, [objectsTypes, setGlobalFilters, fetchRelationshipsAndDimensions, oldFiltersV1]);

	const debounceTransformFilters = useDebouncedCallback(transformOldFilters, 2000); // 2000 delay is for all objectsTypes initialisation

	useEffect(() => {
		if (isShouldTransformFilters) {
			setIsFiltersLoading(true);
			debounceTransformFilters();
		}
	}, [debounceTransformFilters, isShouldTransformFilters]);

	return [
		[globalFilters, objectsTypes, isFiltersLoading],
		setGlobalFilters,
		onAddFilterObjects,
		resetGlobalFilters,
		setIsFiltersLoading,
	];
}
