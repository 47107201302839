import pluralize from 'pluralize';
import Button from '@components/Button';
import Flex from '@components/Flex';
import { AddMedium16, Search16 } from '@components/Icons';
import Typography from '@components/Typography';
import { capitalizedFirstLetter } from 'src/common/utils/format';
import Input from '@components/Input';
import shadows from 'src/style/shadows';
import { TestIDs } from 'src/common/types/test-ids';

export interface ListHeaderProps {
	type: 'dimensions' | 'relationships';
	onAdd?: VoidFunction;
	onSearch?: (search?: string) => void;
	total: number;
	searchValue?: string;
	isEditable?: boolean;
}

export default function ListHeader({ type, onAdd, total, onSearch, searchValue, isEditable }: ListHeaderProps) {
	const isActiveSearch = searchValue !== undefined;

	function ActionsArea({ setSearch }: { setSearch: VoidFunction }) {
		return (
			<Flex paddingY={'3px'} justifyContent={'space-between'} alignItems={'center'}>
				<Typography variant={'DesktopH8Medium'} color={'gray.1000'}>
					{total} {pluralize(capitalizedFirstLetter(type), total)}
				</Typography>
				<Flex>
					<Button isIconOnly variant="outline" colorScheme="gray" size="xs" onClick={setSearch}>
						<Search16 />
					</Button>
					{isEditable && (
						<Button
							testId={TestIDs.CREATE_PROPERTY_ACTION}
							isIconOnly
							variant="outline"
							colorScheme="gray"
							size="xs"
							onClick={onAdd}
						>
							<AddMedium16 />
						</Button>
					)}
				</Flex>
			</Flex>
		);
	}

	const onClose = () => !searchValue && onSearch?.(undefined);

	if (isActiveSearch)
		return (
			<Flex width={'100%'} borderRadius={'8px'} shadow={shadows.focus.medium.gray[200]}>
				<Input
					borderColor="gray.900"
					isErasable={!!searchValue}
					autoFocus
					leftComponent={<Search16 />}
					size="sm"
					placeholder={`Search ${type}`}
					value={searchValue}
					onEnter={onClose}
					onBlur={onClose}
					onChange={(val: string) => onSearch?.(val)}
				/>
			</Flex>
		);
	return <ActionsArea setSearch={() => onSearch?.('')} />;
}
