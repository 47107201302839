import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { FiltersV2Dict } from 'src/common/utils/MetricSearchParams';
import { CloudSupportedOperators, SupportedDimensionTypes } from 'src/generated/graphql';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { useMetricPageSearchParams } from '../../hooks/useMetricPageSearchParams';
import { Filter, FilterV2 } from '../../utils/state.types';

type FilterProps = {
	onFilterSubmit: (items: FilterV2[]) => void;
	setFilterByV2(filters?: FiltersV2Dict[]): void;
	entityName: string;
};

function areFiltersIdentical(filter1: FiltersV2Dict, filter2: FiltersV2Dict) {
	return (
		filter1.key === filter2.key && filter1.operator === filter2.operator && _.isEqual(filter1.values, filter2.values)
	);
}

export function removeIdenticalFilters(filters?: FiltersV2Dict[]) {
	return filters?.filter((filter, index, self) => {
		return index === self.findIndex((f) => areFiltersIdentical(f, filter));
	});
}

export function useMetricFiltersV2(): [FilterV2[], FilterProps] {
	const { filters, objectsTypes } = useMetricDerivedState();
	const entityName = objectsTypes[0];
	const { searchParams, setSearchParams } = useMetricPageSearchParams();

	const setFilterByV2 = useCallback(
		(filters?: FiltersV2Dict[]) => {
			setSearchParams({
				...searchParams,
				filterByV2: filters,
				orderedComponents: undefined,
				sortOrder: undefined,
				tableColumnState: undefined,
			});
		},
		[searchParams, setSearchParams]
	);

	const onFilterSubmit = useCallback(
		(items: FilterV2[]) => {
			const newItemsAsDict = items.map<FiltersV2Dict>((item) => {
				return {
					key: item.key,
					values: item.values,
					type: item.type,
					operator: item.operator,
				};
			});

			const newParams = removeIdenticalFilters([...newItemsAsDict]);
			setFilterByV2(newParams);
		},
		[setFilterByV2]
	);

	const filtersValue = useMemo(() => {
		const filtersV2 = filters.filter(isFilterV2);
		const filtersV1: FilterV2[] = filters.filter(isFilterV1).map((filter) => convertToFilterV2(filter, entityName));
		return [...filtersV2, ...filtersV1];
	}, [filters, entityName]);

	return [filtersValue, { onFilterSubmit, setFilterByV2, entityName }];
}

export function isFilterV2(item: FilterV2 | Filter): item is FilterV2 {
	return 'operator' in item;
}

export function isFilterV1(item: FilterV2 | Filter): item is Filter {
	return !isFilterV2(item);
}

export function convertToFilterV2(item: FilterV2 | Filter, baseEntity: string): FilterV2 {
	if (isFilterV2(item)) {
		return item;
	}
	return {
		key: item.key,
		label: item.label,
		values: item.values,
		type: SupportedDimensionTypes.String,
		operator: CloudSupportedOperators.OneOfSensitive,
		baseEntity,
	};
}
