import Box from '@components/Box';
import Divider from '@components/Divider';
import { SpaceSelector } from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpaceSelector';
import { SpacesListDrawer } from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpacesListDrawer';

export const SpacesNavigationDrawer = () => {
	return (
		<>
			<Box paddingRight={'16px'}>
				<SpaceSelector />
				<Divider marginY="20px" color="natural.200" direction="horizontal" />
			</Box>
			<SpacesListDrawer />
		</>
	);
};
