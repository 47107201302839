import { Box, Grid, GridItem } from '@chakra-ui/react';
import Divider from '@components/Divider';
import { SelectOption } from '@components/Select/types';
import Typography from '@components/Typography';
import debounce from 'lodash/debounce';
import inRange from 'lodash/inRange';
import { useEffect, useRef } from 'react';
import { MetricCard } from 'src/pages/MetricCatalog/MetricCard';
import { metricCatalogBodyId } from 'src/pages/MetricCatalog/MetricCatalog';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import { Category, MetricMetadata } from 'src/types/metric';
import { navigateToMetricFn } from './types';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

export function MetricCatalogCategory({
	metricCategory,
	onMetricClick: onMetricClick,
	headerTopHeight,
	isLastCategory = false,
	activeCategory,
	handleCategoryClick,
	metricIndex,
}: {
	metricCategory: Category;
	onMetricClick?: navigateToMetricFn;
	headerTopHeight: number;
	isLastCategory?: boolean;
	handleCategoryClick?: (category: string) => void;
	activeCategory?: string;
	metricIndex: number;
}) {
	const { name: categoryName, metrics } = metricCategory;
	const ref = useRef<HTMLDivElement>(null);
	const { reportEvent } = useReportEvent();

	useEffect(() => {
		function handleScroll() {
			const selfHeight = ref.current?.getBoundingClientRect().top;
			if (selfHeight == undefined) return;

			const heightDifference = headerTopHeight - selfHeight;
			const shouldSetCategoryActive =
				activeCategory != categoryName && inRange(heightDifference, -180, 20) && !activeCategory;

			if (shouldSetCategoryActive) {
				handleCategoryClick?.(categoryName);
			}
		}

		const handleScrollDebounced = debounce(handleScroll, 20);
		handleScrollDebounced();

		document?.getElementById(metricCatalogBodyId)?.addEventListener('scroll', handleScrollDebounced);
		return () => document?.getElementById(metricCatalogBodyId)?.removeEventListener('scroll', handleScrollDebounced);
	}, [activeCategory, categoryName, headerTopHeight, handleCategoryClick]);

	function metricCardFromMetric(metric: MetricMetadata, index: number) {
		return (
			<MetricCard
				key={`${categoryName}-${metric.name}`}
				metricName={metric.name}
				metricDisplayName={metric.displayName}
				metricDescription={metric.oneliner ?? ''}
				onClick={(selectedFlavor) => {
					reportEvent({
						event: 'metric-navigate-to',
						metaData: { metric: metric.name, categoryName, selectedFlavor },
					});
					onMetricClick?.(metric, selectedFlavor);
				}}
				flavors={createFlavorOptions(metric?.flavors)}
				isMetricCalculated={metric?.isCalculated}
				dataIntercomSequence={index + 1}
			/>
		);
	}

	const flavorDisplayNames: Record<string, string> = {
		'#': 'Count',
	};

	function createFlavorOptions(flavors: string[]): SelectOption[] {
		return flavors.map((flavor) => ({ value: flavor, label: flavorDisplayNames[flavor] }));
	}

	const metricCards = metrics.map((metric: MetricMetadata, index) => metricCardFromMetric(metric, index));
	return (
		<Grid
			pt="16px"
			gridTemplateColumns="repeat(12, [col-start] 1fr)"
			columnGap="16px"
			rowGap="16px"
			{...buildIntercomAttributes({
				area: 'catalog',
				type: 'category',
				target: categoryName,
				sequence: metricIndex + 1,
			})}
		>
			<GridItem colSpan={12}>
				<span id={categoryName} ref={ref}>
					<Box lineHeight="none" pt="24px" mb="-4px">
						<Typography color={'gray.700'} variant="DesktopH8Medium">
							{categoryName}
						</Typography>
					</Box>
				</span>
			</GridItem>
			{metricCards.map((metricCard) => (
				<GridItem display="flex" colSpan={{ base: 6, sm: 6, md: 4, lg: 3, xl: 3 }} key={metricCard.key}>
					{metricCard}
				</GridItem>
			))}

			{!isLastCategory && (
				<GridItem colSpan={12}>
					<Divider color={colors.gray[300]} mt={'32px'} mb="8px" direction="horizontal" />
				</GridItem>
			)}
		</Grid>
	);
}
