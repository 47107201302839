import Box from '@components/Box';
import ModalFooter from '@components/ModalFooter';
import { useEffect, useMemo, useState } from 'react';
import { useCollectionsSubscriptions } from 'src/common/hooks/fetching/useCollectionsSubscriptions';
import { GetCollectionsSubscription } from 'src/generated/graphql';
import { FillDetailsHeader } from 'src/pages/MetricPage/components/ShareSignalModal/FillDetailsHeader';
import { CollectionType, CollectionView } from 'src/types/signal';
import { CollectionUpsertModal } from 'src/layout/Menu/NavigationDrawer/CollectionUpsertModal';
import { useReportEvent } from 'src/services/analytics';
import { FillDetailsForm } from './FillDetailsForm';

export type ModalDetails = {
	title: string;
	collectionType?: CollectionType;
	collectionName: string;
	description?: string;
	publishAs: CollectionView;
};

export function FillDetailsModal({
	modalTitle = 'Publish',
	upsertCollectionModalState,
	metricTitle,
	description,
	onSubmit,
	collectionType,
	rawCollections,
	onClose,
	isLoading,
	isShowingPublishAs,
	submitButtonText = 'Publish',
	shouldShowSelectCollection = false,
}: {
	modalTitle?: string;
	upsertCollectionModalState: {
		isOpenUpsertCollection: boolean;
		onOpenUpsertCollection: VoidFunction;
		onCloseUpsertCollection: VoidFunction;
	};
	shouldShowSelectCollection?: boolean;
	metricTitle: string;
	description?: string;
	onSubmit: (details: ModalDetails, rawCollections?: GetCollectionsSubscription) => void;
	collectionType: CollectionType;
	rawCollections?: GetCollectionsSubscription;
	onClose: () => void;
	isLoading: boolean;
	isShowingPublishAs: boolean;
	submitButtonText?: string;
}) {
	const { reportEvent } = useReportEvent({ destination: collectionType, feature: 'Publish flow' });

	const { isOpenUpsertCollection, onOpenUpsertCollection, onCloseUpsertCollection } = upsertCollectionModalState;

	const [modalData, setModalData] = useState<ModalDetails>({
		collectionType,
		publishAs: 'graph',
		title: metricTitle,
		description: description ?? '',
		collectionName: '',
	});

	const { rawWorkspacesList } = useCollectionsSubscriptions();
	const updatedRawCollections: GetCollectionsSubscription | undefined = useMemo(() => {
		if (rawCollections != null) return rawCollections;
		return rawWorkspacesList;
	}, [rawCollections, rawWorkspacesList]);

	useEffect(() => {
		setModalData((d) => ({ ...d, description: description ?? '', title: metricTitle ?? '' }));
	}, [description, metricTitle]);

	const shouldAllowSubmit = !!modalData.title.trim() && (!shouldShowSelectCollection || !!modalData.collectionName);

	return (
		<Box>
			<FillDetailsHeader title={modalTitle} onClose={onClose} />
			<FillDetailsForm
				isShowingPublishAs={isShowingPublishAs}
				modalData={modalData}
				setModalData={setModalData}
				metricTitle={metricTitle}
				shouldShowSelectCollection={shouldShowSelectCollection}
				updatedRawCollections={updatedRawCollections}
				onOpenUpsertCollection={onOpenUpsertCollection}
			/>
			<Box width={'100%'} shadow={'borderTop'}>
				<ModalFooter
					size={'md'}
					padding={'12px 20px'}
					primaryButtonLabel={submitButtonText}
					onCancel={() => {
						onClose();
						setModalData((d) => ({ ...d, description: description ?? '', title: metricTitle ?? '' }));
					}}
					color={shouldAllowSubmit ? 'blue' : 'gray'}
					onPrimaryClick={() => {
						reportEvent({
							event: 'metric-publish-publish',
						});
						onSubmit(modalData, updatedRawCollections);
					}}
					isPrimaryEnabled={shouldAllowSubmit}
					isPrimaryLoading={isLoading}
				/>
			</Box>
			<CollectionUpsertModal
				flow={'Publish flow'}
				stopRedirect
				hasOverlay={false}
				isOpen={isOpenUpsertCollection}
				onClose={(args?: { name: string; id?: string }) => {
					const name = args?.name;
					if (name) {
						setModalData({ ...modalData, collectionName: name });
						reportEvent({
							event: 'metric-publish-input-provided',
							metaData: { field: `pick a ${collectionType}`, input: name },
						});
					}
					onCloseUpsertCollection();
				}}
				collectionType={collectionType}
			/>
		</Box>
	);
}
