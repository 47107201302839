import Box from '@components/Box';
import Flex from '@components/Flex';
import CSS from 'csstype';
import Typography from '../Typography';
import { TypographyVariant } from '../Typography/types';
import classes from './ToggleButton.module.scss';
import { IconToggleProps, TextToggleProps, ToggleButtonProps, ToggleButtonSize } from './types';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

export function ToggleButton({
	options,
	isDisabled = false,
	selectedIndex,
	onChildClicked,
	transitionDuration = '120ms',
	selectedColor = 'blue',
	shouldFlexContent = false,
	size = 'md',
	testId,
	fullWidth,
	intercomDataAttributes,
}: ToggleButtonProps) {
	const flexOptions = shouldFlexContent
		? {
				justifyContent: 'center',
				flex: '1',
		  }
		: undefined;

	const onClick = (index: number) => {
		if (isDisabled) {
			return;
		}
		onChildClicked(index);
	};

	const getClassName = ({
		isSelected,
		isDisabled,
		option,
		index,
	}: {
		isSelected: boolean;
		isDisabled: boolean;
		option: ToggleButtonProps['options'][number];
		index: number;
	}) => {
		const isFirst = index == 0;
		const isLast = index == options.length - 1;

		const classNames = [classes.cell];
		classNames.push(classes[selectedColor]);
		if (isFirst) classNames.push(classes.first);
		if (isLast) classNames.push(classes.last);
		if (isSelected && !isDisabled) {
			classNames.push(classes.selected);
		}

		if (isDisabled) {
			classNames.push(isSelected ? classes.disabledSelected : classes.disabled);
		}

		if (!isLast && index + 1 == selectedIndex) {
			classNames.push(classes.selectedLeft);
		}

		if (typeof option != 'string') {
			classNames.push(classes.icon);
		}

		return classNames.join(' ');
	};

	return (
		<Box display={fullWidth ? 'block' : 'inline-block'} data-testid={testId}>
			<Flex height={heightBySize[size]} alignItems="center">
				{options.map((option, index) => {
					const isSelected = index == selectedIndex;
					const cursor = !isSelected && !isDisabled ? 'pointer' : undefined;

					const className = getClassName({ isSelected, isDisabled, option, index });

					return typeof option == 'string' ? (
						<TextToggle
							key={`toggle-btn-child-${index}`}
							index={index}
							size={size}
							option={option}
							onClick={() => onClick(index)}
							isSelected={isSelected}
							className={className}
							flexOptions={flexOptions}
							transitionDuration={transitionDuration}
							textPaddingBySize={textPaddingBySize[size]}
							cursor={cursor}
							intercomData={intercomDataAttributes?.[index]}
						/>
					) : (
						<IconToggle
							key={`toggle-btn-child-${index}`}
							index={index}
							size={size}
							option={option}
							onClick={() => onClick(index)}
							isSelected={isSelected}
							className={className}
							flexOptions={flexOptions}
							transitionDuration={transitionDuration}
							textPaddingBySize={textPaddingBySize[size]}
							cursor={cursor}
							intercomData={intercomDataAttributes?.[index]}
						/>
					);
				})}
			</Flex>
		</Box>
	);
}

const IconToggle = ({
	className,
	index,
	onClick,
	flexOptions,
	size = 'md',
	transitionDuration,
	cursor,
	option,
	intercomData,
}: IconToggleProps) => {
	return (
		<Flex
			key={`toggle-btn-child-${index}`}
			padding={nodePaddingBySize[size]}
			className={className}
			_hover={{ cursor }}
			transitionDuration={transitionDuration}
			as={'button'}
			alignItems={'center'}
			onClick={onClick}
			{...buildIntercomAttributes({
				area: intercomData?.area,
				type: intercomData?.type,
				target: intercomData?.target,
			})}
			{...flexOptions}
		>
			<Typography variant={variantBySize[size]} minHeight="100%" _hover={{ cursor }}>
				{option}
			</Typography>
		</Flex>
	);
};

const TextToggle = ({
	className,
	index,
	onClick,
	flexOptions,
	size = 'md',
	transitionDuration,
	cursor,
	option,
	intercomData,
}: TextToggleProps) => {
	return (
		<Flex
			className={className}
			key={`toggle-btn-child-${index}`}
			height="100%"
			alignItems={'center'}
			onClick={onClick}
			as="button"
			overflow="hidden"
			transitionDuration={transitionDuration}
			{...buildIntercomAttributes({
				area: intercomData?.area,
				type: intercomData?.type,
				target: intercomData?.target,
			})}
			{...flexOptions}
			{...textPaddingBySize[size]}
		>
			<Box paddingBottom="2px">
				<Typography variant={variantBySize[size]} minHeight="100%" _hover={{ cursor }}>
					{option}
				</Typography>
			</Box>
		</Flex>
	);
};

const heightBySize: { [key in ToggleButtonSize]: CSS.Property.Height } = {
	xs: '32px',
	sm: '36px',
	md: '42px',
	lg: '54px',
};

const variantBySize: { [key in ToggleButtonSize]: TypographyVariant } = {
	xs: 'DesktopH8Medium',
	sm: 'DesktopH8Medium',
	md: 'DesktopH7Regular',
	lg: 'DesktopH8Medium',
};

const textPaddingBySize: { [key in ToggleButtonSize]: { px: CSS.Property.Padding; py: CSS.Property.Padding } } = {
	xs: {
		px: '12px',
		py: '5px',
	},
	sm: {
		px: '16px',
		py: '7px',
	},
	md: {
		px: '20px',
		py: '10px',
	},
	lg: {
		px: '20px',
		py: '10px',
	},
};

const nodePaddingBySize = {
	xs: '8px',
	sm: '10px',
	md: '13px',
	lg: '18px',
};
