import { Box, ModalHeader } from '@chakra-ui/react';
import Flex from '@components/Flex';
import Input from '@components/Input';
import Typography from '@components/Typography';
import useMutation from '@hooks/fetching/useMutation';
import { CloseLarge16 } from '@icons/index';
import capitalize from 'lodash/capitalize';
import { useCallback, useEffect, useState } from 'react';
import Button from 'src/common/components/Button';
import { FolderSelect } from 'src/common/components/FolderSelect';
import Modal from 'src/common/components/Modal';
import ModalFooter from 'src/common/components/ModalFooter';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { TestIDs } from 'src/common/types/test-ids';
import { DashboardGetSingleQuery, GetAllFoldersQuery, GetCollectionsSubscription } from 'src/generated/graphql';
import DescriptionField from 'src/pages/OntologyPage/components/DescriptionField';
import { InsertCollectionAndFeed, UpdateCollection } from 'src/queries/collections';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { useDashboardFolders } from 'src/common/hooks/useDashboardFolders';
import { DEFAULT_EMOJIPICKER_VALUE } from 'src/common/components/EmojiPicker/EmojiPicker';
import { ListAllFolders } from 'src/queries/folders';
import useUser from 'src/common/hooks/stores/useUser';
import { DashboardGetSingle } from 'src/queries/dashboards';
import _ from 'lodash';
import { FolderData } from 'src/common/components/LeftSidePanelV2/DashboardFolder/types';

type OnCloseArgs = { name: string; id?: string };

type CollectionUpsertModalProps = {
	isOpen: boolean;
	onClose: (params?: OnCloseArgs) => void;
	collectionType: string;
	collection?: GetCollectionsSubscription['workspaces'][0];
	stopRedirect?: boolean;
	folderId?: string;
	hasOverlay?: boolean;
	flow?: string;
};

export type SpaceValue = {
	spaceName: string;
	spaceDescription: string;
};

const duplicateNameError =
	'Uniqueness violation. duplicate key value violates unique constraint "workspaces_tid_name_collection_type_key"';

const MODAL_Z_INDEX = 'var(--chakra-zIndices-popover)';

export function CollectionUpsertModal({
	isOpen,
	onClose,
	collectionType,
	collection,
	stopRedirect,
	folderId,
	hasOverlay = true,
	flow,
}: CollectionUpsertModalProps) {
	const [{ id: my_id }] = useUser();
	const isDashboardFoldersEnable = useFeatureFlag('pulse.sightfull2.dashboard.folders.enable');
	const modalState = collection ? 'edit' : 'create';
	const { reportEvent } = useReportEvent({ itemType: 'dashboard', flow: flow || modalState, feature: 'Sidebar' });
	const [spaceValue, setSpaceValue] = useState<SpaceValue>({
		spaceName: collection?.name || '',
		spaceDescription: collection?.description || '',
	});
	const [folderData, setFolderData] = useState<FolderData>({
		id: folderId || collection?.folder_id,
		emoji: DEFAULT_EMOJIPICKER_VALUE,
		folderName: '',
	});

	const { spaceName, spaceDescription } = spaceValue;

	const { id, emoji, folderName } = folderData;

	const [error, setError] = useState('');
	const isError = !!error;
	const submitMessage = collection ? 'Save' : 'Create';
	const collectionLabel = capitalize(collectionType); // todo: this is too specific for workpspace and dashboards
	const [collectionMutate, { error: mutationError, loading: isLoading }] = useMutation(
		collection ? UpdateCollection : InsertCollectionAndFeed
	);
	const { createDashboardFolder, isCreateFolderLoading } = useDashboardFolders();

	const isFolderFieldVisible = isDashboardFoldersEnable && collectionType === 'dashboard';
	const isNotLoading = !isLoading && !isCreateFolderLoading;
	const isFolderValid = isFolderFieldVisible ? !!id || (!!emoji && !!folderName?.trim()) : true;
	const isPrimaryEnabled = isNotLoading && !!spaceName.trim() && isFolderValid;

	const setInitialSpaceValue = useCallback(() => {
		setSpaceValue((prevValue) => ({
			...prevValue,
			spaceName: collection?.name || '',
			spaceDescription: collection?.description || '',
		}));
		setFolderData((folderData) => ({ ...folderData, id: folderId || collection?.folder_id }));
	}, [collection?.description, collection?.name, collection?.folder_id, folderId]);

	useEffect(() => setInitialSpaceValue(), [setInitialSpaceValue]);

	const onModalClose = (params?: OnCloseArgs) => {
		onClose(params);
		setInitialSpaceValue();
		setError('');
	};

	const isFormValid = () => {
		const isSpaceNameValid = !spaceName;

		if (isSpaceNameValid) {
			setError(`Please choose a name for your ${collectionLabel}`);
		}

		return !isSpaceNameValid;
	};

	const asyncErrorHandler = () => {
		if (!mutationError?.message) return;

		switch (mutationError.message) {
			case duplicateNameError:
				setError(`A ${collectionLabel} with this name already exists, please choose a different name`);
				break;
			default:
				// TODO: add a 'critical' level log
				setError('Error occurred');
		}
	};

	useEffect(asyncErrorHandler, [mutationError, collectionLabel]);

	const { navigate } = useNavigation();

	async function submit() {
		const isValid = isFormValid();

		if (!isValid) return;

		reportEvent({ event: 'sidebar-create-new-item-create', metaData: { itemType: 'dashboard' } });

		if (id || !isDashboardFoldersEnable || collectionType === 'workspace') {
			await onSubmitRequests();
		} else {
			await createDashboardFolder(folderData?.folderName || '', folderData.emoji || '', false).then((res) =>
				onSubmitRequests(res?.insert_folders_one?.id)
			);
		}
	}
	const onSubmitRequests = async (newFolderId?: string) => {
		const folderId = newFolderId || id;
		if (!collection) {
			const variables = {
				name: spaceName,
				description: spaceDescription,
				collection_type: collectionType,
				folder_id: folderId,
			};
			await collectionMutate({
				variables,
				optimisticResponse: {
					insert_workspaces_one: {
						id: `temp-${Date.now()}`,
						...variables,
					},
				},
				update: async (cache, { data }) => {
					const newDashboard = {
						id: data?.insert_workspaces_one.id,
						...variables,
						order: Date.now() * 1000,
						layout: {},
						feed: { feed_signals: [] },
					};

					if (!newDashboard || !data?.insert_workspaces_one.id) return;

					const existingFolders = cache.readQuery<GetAllFoldersQuery>({
						query: ListAllFolders,
						variables: { my_id },
					});

					if (!existingFolders) return;

					const updatedFolders = existingFolders.folders.map((folder) =>
						folder.id === folderId
							? { ...folder, dashboards: _.sortBy([...folder.dashboards, newDashboard], 'order') }
							: folder
					);
					cache.writeQuery<GetAllFoldersQuery>({
						query: ListAllFolders,
						variables: { my_id },
						data: { folders: [...updatedFolders] },
					});
				},
			}).then((res) => {
				const collectionId = res?.data?.insert_workspaces_one?.id;
				if (!stopRedirect) navigate({ path: `/${collectionType}/${collectionId}` });
				onModalClose({ name: spaceName, id: collectionId });
			});
		} else {
			const variables = {
				id: collection.id,
				name: spaceName,
				order: collection.order,
				description: spaceDescription,
				folder_id: folderId,
			};
			await collectionMutate({
				variables,
				optimisticResponse: {
					update_workspaces_by_pk: {
						__typename: 'workspaces',
						...variables,
					},
				},
				update: (cache, { data }) => {
					const updatedCollection = data?.update_workspaces_by_pk;
					if (!updatedCollection) return;
					const existingFolders = cache.readQuery<GetAllFoldersQuery>({
						query: ListAllFolders,
						variables: { my_id },
					});
					if (!existingFolders) return;
					const updatedFolders = existingFolders.folders.map((folder) => {
						if (folder.id === folderId) {
							const updatedDashboards = folder.dashboards.map((dashboard) => {
								if (dashboard.id === collection.id) {
									return {
										...dashboard,
										name: updatedCollection.name || dashboard.name,
										description: updatedCollection.description ?? dashboard.description,
									};
								}
								return dashboard;
							});
							return { ...folder, dashboards: updatedDashboards };
						}
						return folder;
					});
					cache.writeQuery<GetAllFoldersQuery>({
						query: ListAllFolders,
						variables: { my_id },
						data: { folders: updatedFolders },
					});
					const dashboardFromCache = cache.readQuery<DashboardGetSingleQuery>({
						query: DashboardGetSingle,
						variables: {
							id: collection.id,
							my_id,
						},
					});

					if (!dashboardFromCache) return;
					cache.writeQuery({
						query: DashboardGetSingle,
						data: {
							dashboards: [
								{
									...dashboardFromCache.dashboards[0],
									name: updatedCollection.name,
									description: updatedCollection.description,
								},
							],
						},
					});
				},
			}).then(() => {
				onModalClose({ name: spaceName });
			});
		}
	};

	const FolderField = (
		<>
			<FolderSelect flow={modalState} isInitSelect folderData={folderData} setFolderData={setFolderData} />
		</>
	);

	return (
		<Modal
			maxWidth={'460px'}
			zIndex={MODAL_Z_INDEX}
			isOpen={isOpen}
			onClose={onModalClose}
			closeOnOverlayClick={false}
			isCentered
			hasOverlay={hasOverlay}
		>
			<ModalHeader p="16px" boxShadow={shadows.borderBottom}>
				<Flex justifyContent="space-between" alignItems="center">
					<Button isIconOnly variant="outline" onClick={() => onModalClose()} size="inline" colorScheme="black">
						<CloseLarge16 color={colors.gray[900]} />
					</Button>
					<Box margin={'0 auto'}>
						<Typography color={'gray.1000'} marginLeft={'-40px'} variant="DesktopH7Medium">
							{_.capitalize(modalState)} {collectionType?.toLowerCase()}
						</Typography>
					</Box>
				</Flex>
			</ModalHeader>
			<Flex flexDirection={'column'} padding="24px" gap={'24px'}>
				<Flex flexDirection={'column'} gap={'12px'}>
					<Typography variant={'DesktopH10Regular'} color={'gray.1000'}>
						{`${collectionLabel} name`}
					</Typography>
					<Input
						autoFocus
						size="md"
						isInvalid={isError}
						fontSize={'14px'}
						placeholder={'Add name'}
						errorMessage={error}
						onBlur={() =>
							reportEvent({
								event: 'sidebar-dashboard-modal-input-provided',
								metaData: { field: 'name', input: spaceName },
							})
						}
						onChange={(spaceName: string) => setSpaceValue((prevValue) => ({ ...prevValue, spaceName }))}
						value={spaceName}
						data-testid={TestIDs.NEW_COLLECTION_NAME_INPUT(collectionType)}
					/>
				</Flex>
				<Flex flexDirection={'column'} gap={'12px'}>
					<DescriptionField
						placeholder={`Describe this ${collectionType?.toLowerCase()}`}
						value={spaceDescription || ''}
						onBlur={() =>
							reportEvent({
								event: 'sidebar-dashboard-modal-input-provided',
								metaData: { field: 'description', input: spaceDescription },
							})
						}
						onChange={(spaceDescription: string) => {
							setSpaceValue((prevValue) => ({ ...prevValue, spaceDescription }));
						}}
					/>
				</Flex>
				{isFolderFieldVisible && FolderField}
			</Flex>
			<Box boxShadow={shadows.borderTop}>
				<ModalFooter
					size={'lg'}
					primaryButtonLabel={submitMessage}
					isPrimaryLoading={isLoading}
					isPrimaryEnabled={isPrimaryEnabled}
					onPrimaryClick={submit}
					onCancel={() => onModalClose()}
					color={isPrimaryEnabled ? 'blue' : 'gray'}
					cancelButtonColor={'gray'}
					testId={TestIDs.NEW_COLLECTION_MODAL_FOOTER(collectionType)}
				/>
			</Box>
		</Modal>
	);
}
