import { useCallback } from 'react';
import { useSchemasDataQuery } from 'src/generated/graphql';

export const useSchemaData = () => {
	const { data: schemas } = useSchemasDataQuery();

	const getEntitySourceTableColumns = useCallback(
		(entityName: string) => {
			const columns = schemas?.schemasData
				?.find((schema) =>
					schema.tables?.find((table) => {
						return table.name.toLowerCase() === entityName.toLowerCase();
					})
				)
				?.tables.find((table) => table.name.toLowerCase() === entityName.toLowerCase())?.columns;

			const columnsWithKeys = columns?.map((column) => ({ ...column, key: `$src__${column.name.toLowerCase()}` }));

			return columnsWithKeys;
		},
		[schemas?.schemasData]
	);

	return {
		getEntitySourceTableColumns,
	};
};
