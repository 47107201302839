import Flex from '@components/Flex';
import Box from '@components/Box';
import Avatar from '@components/Avatar/Avatar';
import Typography from '@components/Typography';
import Tooltip from '@components/Tooltip';

import { useMemo, useRef } from 'react';
import useUser from '@hooks/stores/useUser';
import { useOnOverflow } from '@hooks/ui/useOnOverflow';
import { DeleteTrash16, Memo16 } from '@components/Icons';
import { AskAIChatMessageType } from '../types';
import Button from '../../Button';
import { Spacer } from '@chakra-ui/react';
import { useAIPowerMode } from '../hooks/useAIPowerMode';

export type AskAIChatCardLayoutProps = {
	type: AskAIChatMessageType;
	children: JSX.Element[] | JSX.Element;
	userPrompt: string;
	onDelete?: () => void;
	footer?: JSX.Element;
	borderColor: string;
	backgroundColor?: string;
	cardMargin?: string;
};

function CardAvatar() {
	const [{ picture }] = useUser();

	if (picture) {
		return <Avatar width={'24px'} height={'24px'} imageUrl={picture} shape="round" />;
	}
	return <Memo16 />;
}

export function ChatCardHeader({
	userPrompt,
	onDelete,
}: {
	userPrompt: string;
	onDelete?: () => void;
	type: AskAIChatMessageType;
}) {
	const descriptionRef = useRef(null);
	const isOverflowingDescription = useOnOverflow(descriptionRef, [userPrompt], undefined, false);
	const { isPowerMode } = useAIPowerMode();

	const canDelete = useMemo(() => {
		return onDelete !== undefined && isPowerMode();
	}, [onDelete, isPowerMode]);

	return (
		<Flex gap="8px" direction={'row'} alignItems="center" padding={'16px 16px 0 16px'}>
			<CardAvatar />
			<Tooltip
				size="md"
				variant="fluid"
				label={isOverflowingDescription && userPrompt}
				placement={'top'}
				margin={'0px 0px 8px 0px'}
			>
				<Typography ref={descriptionRef} color={'gray.800'} variant="DesktopH10Regular" noOfLines={2}>
					{userPrompt}
				</Typography>
			</Tooltip>
			<Spacer />
			{canDelete ? (
				<Button
					variant={'outline'}
					colorScheme={'lightGray'}
					onClick={onDelete}
					size={'xs'}
					color={'gray.800'}
					isIconOnly={true}
				>
					<DeleteTrash16 />
				</Button>
			) : undefined}
		</Flex>
	);
}

export function AskAIChatCardLayout({
	children,
	type,
	userPrompt,
	onDelete,
	footer,
	borderColor,
	backgroundColor,
	cardMargin = '12px',
}: AskAIChatCardLayoutProps) {
	return (
		<Box margin={cardMargin}>
			<Box border={'1px solid'} borderColor={borderColor} backgroundColor={backgroundColor} borderRadius={'8px'}>
				<Flex direction={'column'} gap={'12px'}>
					<ChatCardHeader userPrompt={userPrompt ?? ''} type={type} onDelete={onDelete} />
					{children}
				</Flex>
			</Box>
			{footer && <Box marginTop={'10px'}>{footer}</Box>}
		</Box>
	);
}
