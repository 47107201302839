import Box from 'src/common/components/Box';
import Checkbox from 'src/common/components/Checkbox';
import Flex from 'src/common/components/Flex';
import { EditableValueContainer } from '../../Editors/EditableValueContainer';
import { FILTER_NULL_OPTION_NAME } from '../constants';
import { ValueEditorProps } from './ValueEditorByFilter';

export function FilterBooleanEditor({ values, onApply, prefixComponent, isLoadingDimensionValues }: ValueEditorProps) {
	return (
		<InternalFilterBooleanEditor
			isLoading={isLoadingDimensionValues}
			prefixComponent={prefixComponent}
			isTrueSelected={values.includes(true)}
			isFalseSelected={values.includes(false)}
			isNullSelected={values.includes(null)}
			onApply={(isChecked: boolean, value: boolean | null) => {
				if (isChecked) {
					onApply([...values, value]);
				} else {
					onApply(values.filter((v) => v !== value));
				}
			}}
		/>
	);
}

type BooleanEditorProps = {
	isTrueSelected: boolean;
	isFalseSelected: boolean;
	isNullSelected: boolean;
	onApply: (isChecked: boolean, value: boolean | null) => void;
	prefixComponent?: React.ReactNode;
	isLoading?: boolean;
};

function BooleanCheckbox({
	label,
	isChecked,
	onChecked,
	isLoading,
}: {
	label: string;
	isChecked: boolean;
	onChecked: (isChecked: boolean) => void;
	isLoading?: boolean;
}) {
	return (
		<Box padding={'8px'}>
			<Checkbox
				isDisabled={isLoading}
				isFluid={true}
				variant={'rounded'}
				checkboxColor={'blue.600'}
				size={'xs'}
				isChecked={isChecked}
				label={label}
				onChange={onChecked}
			/>
		</Box>
	);
}

function InternalFilterBooleanEditor({
	isTrueSelected,
	isFalseSelected,
	isNullSelected,
	onApply,
	prefixComponent,
	isLoading,
}: BooleanEditorProps) {
	return (
		<EditableValueContainer prefixComponent={prefixComponent}>
			<Flex height="32px">
				<BooleanCheckbox
					isLoading={isLoading}
					isChecked={isTrueSelected}
					label={'True'}
					onChecked={(isChecked) => onApply(isChecked, true)}
				/>
				<BooleanCheckbox
					isLoading={isLoading}
					isChecked={isFalseSelected}
					label={'False'}
					onChecked={(isChecked) => onApply(isChecked, false)}
				/>
				<BooleanCheckbox
					isLoading={isLoading}
					isChecked={isNullSelected}
					label={FILTER_NULL_OPTION_NAME}
					onChecked={(isChecked) => onApply(isChecked, null)}
				/>
			</Flex>
		</EditableValueContainer>
	);
}
