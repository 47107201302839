import { useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import { useUpdateEntityMutation } from 'src/generated/graphql';
import { useSemanticDefinitions } from '@hooks/stores/useSemanticDefinitions';
import { useRunNormalization } from '@pages/OntologyPage/hooks/useRunNormalization';
import { useValidateEntities } from '@pages/OntologyPage/hooks/useValidateEntities';
import { useInvalidateCache } from '@services/apollo';
const SAVE_FAILED_ERROR_MESSAGE = 'Save Failed, please review and try again';

export function useUpdateEntity({
	onUpdateStart,
	entityName,
	currentEntityVersion,
	handleSaveError,
	handleSaveSuccess,
	onNormalizationStart,
	handleNormalizationError,
	handleNormalizationSuccess,
	onValidationStart,
	handleValidationError,
	handleValidationSuccess,
	onNormalizationSkip,
}: {
	onUpdateStart: () => void;
	entityName: string;
	currentEntityVersion: string;
	handleSaveError: (error: string) => void;
	handleSaveSuccess: (definitionLines: string[], savedDefinitionHash: string) => void;
	onNormalizationStart: () => void;
	handleNormalizationError: (error: string) => void;
	handleNormalizationSuccess: (args: { additionalColumns?: string[] }) => void;
	onValidationStart: () => void;
	handleValidationError: (error: string) => void;
	handleValidationSuccess: () => void;
	onNormalizationSkip: () => void;
}) {
	const [updateEntityMutation, { loading: updateEntityInProgress }] = useUpdateEntityMutation();
	const { reloadSemantics: reloadPulseSemantics } = useSemanticDefinitions();
	const { runNormalization, normalizationInProgress } = useRunNormalization({ handleNormalizationError });
	const { validateEntities, isValidationInProgress } = useValidateEntities({
		onValidationStart,
		entityName,
		handleValidationError,
		handleValidationSuccess,
	});
	const { invalidateCache } = useInvalidateCache();

	const updateEntity = useCallback(
		async (shouldSkipNormalization: boolean, entityDefinitionLines: string[], additionalTableColumns?: string[]) => {
			onUpdateStart();

			let isRenamed: boolean;
			let savedDefinitionHash: string;
			try {
				const upsertEntityResult = await updateEntityMutation({
					variables: {
						entityName,
						entityDefinition: entityDefinitionLines,
						currentEntityVersion,
					},
				});
				isRenamed = upsertEntityResult.data?.updateEntity?.updateSemanticAction == 'RENAME';
				if (upsertEntityResult.errors) {
					const error = upsertEntityResult?.errors[0];
					handleSaveError(error?.message ?? SAVE_FAILED_ERROR_MESSAGE);
					return;
				}
				if (!upsertEntityResult.data) {
					handleSaveError(SAVE_FAILED_ERROR_MESSAGE);
					return;
				}

				savedDefinitionHash = upsertEntityResult.data.updateEntity.semanticDefinitionVersion.definitionHash;
			} catch (e) {
				if (e instanceof ApolloError) {
					handleSaveError(e.message);
					return;
				}
				handleSaveError(SAVE_FAILED_ERROR_MESSAGE);
				return;
			}

			handleSaveSuccess(entityDefinitionLines, savedDefinitionHash);

			void reloadPulseSemantics();

			if (!shouldSkipNormalization) {
				onNormalizationStart();
				const normalizationSuccess = await runNormalization();
				if (!normalizationSuccess) return;
				invalidateCache();
			} else {
				onNormalizationSkip();
			}
			handleNormalizationSuccess({ additionalColumns: additionalTableColumns });

			if (!shouldSkipNormalization) {
				await validateEntities();
			}

			// TODO: The isRenamed redirect mechanism MUST be changed
			if (isRenamed) {
				throw new Error('Entity name has been changed, please refresh the page');
			}
		},
		[
			onUpdateStart,
			handleSaveSuccess,
			reloadPulseSemantics,
			handleNormalizationSuccess,
			updateEntityMutation,
			entityName,
			currentEntityVersion,
			handleSaveError,
			onNormalizationStart,
			runNormalization,
			invalidateCache,
			onNormalizationSkip,
			validateEntities,
		]
	);

	return { updateEntity, updateEntityInProgress, normalizationInProgress, isValidationInProgress };
}
