import { useEffect, useMemo, useRef, useState } from 'react';
import Button from '../Button';
import { FilterOutline16 } from '../Icons';
import { useDisclosure } from '@chakra-ui/react';
import Popover from 'src/common/components/Popover';
import Flex from '@components/Flex';
import Box from '@components/Box';
import shadows from 'src/style/shadows';
import FiltersAndBreakdownsModal from 'src/pages/MetricPage/components/FiltersAndBreakdown/FiltersAndBreakdownsModal';
import useFiltersAndBreakdown from 'src/pages/MetricPage/components/FiltersAndBreakdown/useFiltersAndBreakdown';
import { FilterV2 } from 'src/pages/MetricPage/utils/state.types';
import { useFilterV2Logic } from './useFilterV2Logic';
import { useCoreNodeScheme } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { useReportEvent } from 'src/services/analytics';
import { FILTER_MODEL_TYPE } from 'src/pages/MetricPage/components/FiltersAndBreakdown/consts';
import { FilterDropdownPanel } from 'src/common/components/FilterDropdownButton/FilterDropdownPanel';
import { FiltersAndBreakdownResponseType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import colors from 'src/style/colors';
import { isOntologyPage } from 'src/pages/utils/locations';

type FilterDropdownButtonProps = {
	isLoading?: boolean;
	entityName?: string;
	onFilterSubmit: (filters: FilterV2[]) => void;
	initialFilters: FilterV2[];
	eventMetaData: object;
	availableEntities?: string[];
};

export function FilterDropdownButton({
	isLoading = false,
	entityName,
	availableEntities = [],
	onFilterSubmit,
	initialFilters,
	eventMetaData,
}: FilterDropdownButtonProps) {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const { reportEvent } = useReportEvent();
	const [modalState, modalActions] = useFiltersAndBreakdown();
	const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
	const [activeFilterIndex, setActiveFilterIndex] = useState<number | undefined>();
	const { filtersArray, addFilters, editFilter, removeFilter } = useFilterV2Logic({
		initialFilters,
		onFilterSubmit,
	});

	const openModalEventName = isOntologyPage() ? 'entity-filters-clicked' : 'dashboard-filters-clicked';
	const isButtonActive = useMemo(() => filtersArray?.some((filter) => filter.values.length > 0), [filtersArray]);

	const isInsideDropdown = (target: HTMLElement) =>
		target.closest('.chakra-popover__popper') ||
		target.closest('.select__single-value') ||
		target.closest('.select__value-container');

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const target = event.target as HTMLElement;
			if (isInsideDropdown(target)) return;
			if (wrapperRef.current && !wrapperRef.current.contains(target)) onClose();
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [onClose]);

	const onModalOpen = (filterIndex?: number) => {
		reportEvent({
			event: openModalEventName,
			metaData: { ...eventMetaData, entity: entityName || availableEntities },
		});
		onClose();
		setActiveFilterIndex(filterIndex);
		modalActions.onOpen(FILTER_MODEL_TYPE, modalActions.onClose);
	};

	const onModalClose = () => {
		modalActions.onClose();
		if (filtersArray.length) onOpen();
	};

	const onAddItems = (result: FiltersAndBreakdownResponseType) => {
		addFilters(result, activeFilterIndex);
		// setTimeout is needed to allow the FiltersAndBreakdown modal to close before opening another modal.
		setTimeout(onOpen, 200);
	};

	const objectsTypes = entityName ? [entityName] : availableEntities;

	const coreNodeScheme = useCoreNodeScheme({
		objectsTypes: objectsTypes || '',
		schemeType: 'global',
		fetchPolicy: 'no-cache',
		isIncludingTypePrefix: !entityName,
		readyToFetch: !isLoading && objectsTypes.length > 0,
	});

	const buttonBackgroundColor = filtersArray.length
		? isButtonActive
			? colors.blue[100]
			: colors.gray[200]
		: 'transparent';

	return (
		<Flex ref={wrapperRef} position={'relative'}>
			<Popover
				isWithPortal
				popoverContentProps={{
					boxShadow: shadows['z5'],
					borderRadius: '8px',
					overflow: 'visible',
				}}
				isOpen={isOpen}
				placement="bottom-end"
				triggerElement={() => (
					<Button
						variant={'outline'}
						size={'inline'}
						leftIcon={<FilterOutline16 />}
						background={buttonBackgroundColor}
						isDisabled={isLoading}
						onClick={() => (!filtersArray.length && !isOpen ? onModalOpen() : onToggle())}
						colorScheme={isButtonActive ? 'primaryBlue' : 'black'}
					>
						{filtersArray.length > 0 ? `Filter (${filtersArray.length})` : 'Add filter'}
					</Button>
				)}
			>
				<Box padding={'16px'} width={'332px'}>
					<FilterDropdownPanel
						eventMetaData={eventMetaData}
						isLoading={isLoading}
						editFilter={editFilter}
						removeFilter={removeFilter}
						onCloseDropdown={onClose}
						onModalOpen={onModalOpen}
						filters={filtersArray}
					/>
				</Box>
			</Popover>
			<FiltersAndBreakdownsModal
				hasBaseNode={!!entityName}
				type={modalState.type}
				isOpen={modalState.isOpen}
				onClose={onModalClose}
				onAddItems={onAddItems}
				nodeScheme={coreNodeScheme}
				initialFilter={modalState.initialFilter}
			/>
		</Flex>
	);
}
