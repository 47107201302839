import { BuiltInPeriodRangePresets, PeriodRange } from '@sightfull/period-ranges';
import { useMemo } from 'react';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';

export function usePeriodRangeMetadata(periodRange: PeriodRange) {
	return useMemo(() => {
		if (!periodRange.isRelativeSupported) {
			return periodRange.prettyString;
		}
		return (
			BuiltInPeriodRangePresets.byRelativePeriodRange(periodRange.asRelativeRange, fiscalYearOffset())?.description ||
			periodRange.asRelativeRange.prettyString
		);
	}, [periodRange]);
}
