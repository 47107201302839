import Box from '@components/Box';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { $isListNode, ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode, HeadingNode } from '@lexical/rich-text';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
	$getSelection,
	$isRangeSelection,
	$isRootNode,
	$setSelection,
	CAN_REDO_COMMAND,
	CAN_UNDO_COMMAND,
	CLICK_COMMAND,
	FORMAT_TEXT_COMMAND,
	SELECTION_CHANGE_COMMAND,
	TextFormatType,
} from 'lexical';
import { CSSProperties, forwardRef, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Divider from 'src/common/components/Divider';
import { REMOVE_TEXT_SELECTION } from '../../commands';
import FloatingLinkEditor from '../../components/FloatingLinkEditor';
import TextTypeSelector from '../../components/TextTypeSelector';
import ToolbarButton from '../../components/ToolbarButton';
import { blockTypeToBlockName, LowPriority } from '../../consts';
import { Bold, Italic, Mention, Underline } from '../../icons';
import { BlockType } from '../../types';
import { getSelectedNode } from '../../utils';
import { ADD_MENTION_NODE } from '../mention/Mention';

import { Link16 } from 'src/common/components/Icons';
import classes from '../../styles/TextEditor.module.scss';
import classnames from 'classnames';
import Flex from '@components/Flex';
import colors from 'src/style/colors';
import { isMacOperatingSystem } from 'src/common/utils/utils';

export type FloatingMenuCoords = { x: number; y: number } | undefined;

type ToolbarProps = {
	isDisabled?: boolean;
	editor?: ReturnType<typeof useLexicalComposerContext>[0];
	coords?: FloatingMenuCoords;
	isFloating?: boolean;
};

export const ToolbarPlugin = forwardRef<HTMLDivElement, ToolbarProps>(function ToolbarPlugin(props, ref) {
	const { isDisabled = false, coords, editor: propsEditor, isFloating } = props;
	const [componentEditor] = useLexicalComposerContext();
	const editor = propsEditor || componentEditor;
	const [, setCanUndo] = useState(false);
	const [, setCanRedo] = useState(false);
	const [blockType, setBlockType] = useState<BlockType>('paragraph');
	const [isLink, setIsLink] = useState(false);
	const [isBold, setIsBold] = useState(false);
	const [isItalic, setIsItalic] = useState(false);
	const [isUnderline, setIsUnderline] = useState(false);

	const updateToolbar = useCallback(() => {
		const selection = $getSelection();
		if ($isRangeSelection(selection)) {
			const anchorNode = selection.anchor.getNode();
			const element = $isRootNode(anchorNode) ? anchorNode : anchorNode.getTopLevelElementOrThrow();
			const elementKey = element.getKey();
			const elementDOM = editor.getElementByKey(elementKey);
			if (elementDOM !== null) {
				if ($isListNode(element)) {
					const parentList = $getNearestNodeOfType(anchorNode, ListNode);
					const type: BlockType = parentList ? parentList.getTag() : element.getTag();
					setBlockType(type);
				} else {
					const parentHeading = $getNearestNodeOfType(anchorNode, HeadingNode) ?? anchorNode;
					const type: BlockType = $isHeadingNode(parentHeading)
						? parentHeading.getTag()
						: (parentHeading.getType() as BlockType);
					setBlockType(type);
				}
			}

			// Update text format
			setIsBold(selection.hasFormat('bold'));
			setIsItalic(selection.hasFormat('italic'));
			setIsUnderline(selection.hasFormat('underline'));

			// Update links
			const node = getSelectedNode(selection);
			const parent = node.getParent();
			const isLink = $isLinkNode(parent) || $isLinkNode(node);

			setIsLink(isLink);
		}
	}, [editor]);

	const focusLinkNode = useCallback(
		(el: EventTarget | null) => {
			const selection = $getSelection();

			if ($isRangeSelection(selection)) {
				if (!isLink) {
					return;
				}

				const range = document.createRange();
				range.selectNodeContents(el as Node);

				const sel = window.getSelection();
				sel?.removeAllRanges();
				sel?.addRange(range);
			}
		},
		[isLink]
	);

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateToolbar();
				});
			}),
			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				() => {
					updateToolbar();
					return false;
				},
				LowPriority
			),
			editor.registerCommand(
				REMOVE_TEXT_SELECTION,
				() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						if (selection.getTextContent()) {
							$setSelection(null);
						}
					}

					return true;
				},
				LowPriority
			),

			editor.registerCommand(
				CLICK_COMMAND,
				(e: MouseEvent) => {
					focusLinkNode(e.target);
					return false;
				},
				LowPriority
			),
			editor.registerCommand(
				CAN_UNDO_COMMAND,
				(canUndo) => {
					setCanUndo(canUndo);
					return false;
				},
				LowPriority
			),
			editor.registerCommand(
				CAN_REDO_COMMAND,
				(canRedo) => {
					setCanRedo(canRedo);
					return false;
				},
				LowPriority
			)
		);
	}, [editor, focusLinkNode, updateToolbar]);

	const insertLink = useCallback(() => {
		if (isDisabled) return;
		editor.dispatchCommand(TOGGLE_LINK_COMMAND, !isLink ? '' : null);
	}, [editor, isDisabled, isLink]);

	const afterLinkInsertion = () => {
		setIsLink(false);
	};

	const insertMention = useCallback(() => {
		if (isDisabled) return;
		editor.dispatchCommand(ADD_MENTION_NODE, '');
	}, [editor, isDisabled]);

	const formatText = (formatType: TextFormatType) => {
		if (isDisabled) return;
		editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType);
	};

	const floatingToolbarPositionStyles: CSSProperties = {
		position: 'absolute',
		top: coords?.y,
		left: coords?.x,
		zIndex: 3,
		width: 'fit-content',
	};
	const dividerColor = isFloating ? colors.gray[1000] : colors.gray[300];
	const isTextType = blockType === 'text';
	const blockName = blockTypeToBlockName[blockType];

	return (
		<Box
			className={classnames(classes.toolbarContainer, { [classes.isFloating]: isFloating })}
			opacity={isDisabled ? '0.3' : '1'}
			ref={ref}
			style={isFloating ? floatingToolbarPositionStyles : {}}
		>
			<TextTypeSelector blockType={blockType} editor={editor} isDisabled={isDisabled} isFloatingToolbar={isFloating} />
			<Divider direction="vertical" ml="8px" mr="8px" color={dividerColor} />

			<Flex gap={'4px'}>
				<ToolbarButton
					isActive={isBold && isTextType}
					onClick={() => formatText('bold')}
					tooltipLabel={
						isTextType ? `Bold ${isMacOperatingSystem ? '⌘' : 'Ctrl'}+B` : `Bold cannot span with ${blockName}`
					}
					isFloatingToolbar={isFloating}
					isDisabled={!isTextType}
				>
					<Bold />
				</ToolbarButton>

				<ToolbarButton
					isActive={isItalic}
					onClick={() => formatText('italic')}
					tooltipLabel={`Italic ${isMacOperatingSystem ? '⌘' : 'Ctrl'}+I`}
					isFloatingToolbar={isFloating}
				>
					<Italic />
				</ToolbarButton>

				<ToolbarButton
					isActive={isUnderline}
					onClick={() => formatText('underline')}
					tooltipLabel={`Underline ${isMacOperatingSystem ? '⌘' : 'Ctrl'}+U`}
					isFloatingToolbar={isFloating}
				>
					<Underline />
				</ToolbarButton>
			</Flex>

			<Divider direction="vertical" ml="8px" mr="8px" color={dividerColor} />

			<Flex gap={'4px'}>
				<ToolbarButton onClick={insertMention} tooltipLabel={'Mention @'} isFloatingToolbar={isFloating}>
					<Mention />
				</ToolbarButton>

				<ToolbarButton
					isActive={isLink}
					onClick={insertLink}
					tooltipLabel={`Link ${isMacOperatingSystem ? '⌘' : 'Ctrl'}+L`}
					isFloatingToolbar={isFloating}
				>
					<Link16 />
				</ToolbarButton>
				{isLink && createPortal(<FloatingLinkEditor editor={editor} onEdit={afterLinkInsertion} />, document.body)}
			</Flex>
		</Box>
	);
});

export default ToolbarPlugin;
