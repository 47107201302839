import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Divider from 'src/common/components/Divider';
import Typography from 'src/common/components/Typography';
import { useSharedMonacoTextFieldProviders } from 'src/lib/completions/hooks/useMonacoTextFieldProviders';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { GRANULAR_METRIC_NAME } from '../consts';
import { ArrModelOutputState } from '../types';
import { MappingTextInput } from './components/MappingTextInput';
interface MappingBuilderPanelProps {
	onFocusedInputBoxIndexChange: (index: number) => void;
	setGranularEntityArrAmountDefinitions: (arrAmountDefinitions: string[]) => void;
	setGranularEntityRecognitionEndDefinitions: (recognitionEndDefinitions: string[]) => void;
	setGranularEntityRecognitionStartDefinitions: (recognitionStartDefinitions: string[]) => void;
	isLoading: boolean;
	arrModelOutputState: ArrModelOutputState;
}

export function MappingBuilderPanel({
	onFocusedInputBoxIndexChange,
	setGranularEntityArrAmountDefinitions,
	setGranularEntityRecognitionEndDefinitions,
	setGranularEntityRecognitionStartDefinitions,
	arrModelOutputState,
	isLoading,
}: MappingBuilderPanelProps) {
	useSharedMonacoTextFieldProviders({
		entity: arrModelOutputState.granularEntityName ?? '',
		metric: GRANULAR_METRIC_NAME,
	});
	const [arrAmountDefinitions, setArrAmountDefinitions] = useState<string[]>(
		arrModelOutputState.granularEntityArrAmountDefinitions
	);
	const [arrRecognitionStartDefinitions, setArrRecognitionStartDefinitions] = useState<string[]>(
		arrModelOutputState.granularEntityRecognitionStartDefinitions
	);
	const [arrRecognitionEndDefinitions, setArrRecognitionEndDefinitions] = useState<string[]>(
		arrModelOutputState.granularEntityRecognitionEndDefinitions
	);
	const [filters, setFilters] = useState<string[]>(arrModelOutputState.granularMetricFilters);

	useEffect(() => {
		setArrAmountDefinitions(arrModelOutputState.granularEntityArrAmountDefinitions);
		setArrRecognitionStartDefinitions(arrModelOutputState.granularEntityRecognitionStartDefinitions);
		setArrRecognitionEndDefinitions(arrModelOutputState.granularEntityRecognitionEndDefinitions);
		setFilters(arrModelOutputState.granularMetricFilters);
	}, [arrModelOutputState]);

	return (
		<Flex direction="column" boxShadow={shadows.borderRight} overflowY="auto" height={'100%'}>
			<Flex>
				<Typography
					variant="DesktopH6Medium"
					color={colors.gray[1000]}
					marginTop={'24px'}
					marginBottom={'16px'}
					marginX="24px"
				>
					Mapping
				</Typography>
			</Flex>

			<Flex direction="column" gap="32px" padding="24px">
				<MappingTextInput
					heading="ARR amount"
					subtitle="Identify the field for the ARR amount, or define how to calculate it."
					placeholder="Add value"
					values={arrAmountDefinitions}
					onChange={(values) => setArrAmountDefinitions(values)}
					id="arr-amount"
					onFocus={() => onFocusedInputBoxIndexChange(0)}
					onBlur={() => {
						onFocusedInputBoxIndexChange(-1);
						setGranularEntityArrAmountDefinitions(arrAmountDefinitions.filter((value) => value !== ''));
					}}
					isLoading={isLoading}
					contextSettings={{
						entity: arrModelOutputState.granularEntityName || '',
						includeTypes: ['dimension_numeric', 'relationship'],
						metric: '',
					}}
				/>

				<MappingTextInput
					heading="ARR recognition start"
					subtitle="Indicate when to start recognizing ARR."
					placeholder="Add value"
					values={arrRecognitionStartDefinitions}
					onChange={(values) => setArrRecognitionStartDefinitions(values)}
					id="arr-recognition-start"
					onFocus={() => onFocusedInputBoxIndexChange(1)}
					onBlur={() => {
						onFocusedInputBoxIndexChange(-1);
						setGranularEntityRecognitionStartDefinitions(
							arrRecognitionStartDefinitions.filter((value) => value !== '')
						);
					}}
					isLoading={isLoading}
					contextSettings={{
						entity: arrModelOutputState.granularEntityName || '',
						includeTypes: ['dimension_date', 'relationship'],
						metric: '',
					}}
				/>

				<MappingTextInput
					heading="ARR recognition end"
					subtitle='Indicate when to stop recognizing ARR. This could be a field (e.g., "End Date") or a calculation.'
					placeholder="Add value"
					values={arrRecognitionEndDefinitions}
					onChange={(values) => setArrRecognitionEndDefinitions(values)}
					id="arr-recognition-end"
					onFocus={() => onFocusedInputBoxIndexChange(2)}
					onBlur={() => {
						onFocusedInputBoxIndexChange(-1);
						setGranularEntityRecognitionEndDefinitions(arrRecognitionEndDefinitions.filter((value) => value !== ''));
					}}
					isLoading={isLoading}
					contextSettings={{
						entity: arrModelOutputState.granularEntityName || '',
						includeTypes: ['dimension_date', 'relationship'],
						metric: '',
					}}
				/>
				<Divider direction="horizontal" />

				<MappingTextInput
					heading="Filter"
					subtitle="Define the rows that should be included in the ARR."
					placeholder="Add value "
					values={filters}
					onChange={(values) => setFilters(values)}
					id="filter"
					isOptional
					isLoading={isLoading}
					contextSettings={{
						entity: arrModelOutputState.granularEntityName || '',
						metric: GRANULAR_METRIC_NAME,
					}}
				/>
			</Flex>
		</Flex>
	);
}
