import Flex from '@components/Flex';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { ListSkeleton } from './SkeletonComponents';
import { ResourceRolesUser } from '../ResourceAccessModal/useResourceRoles';
import { UsersCredentials } from './UsersCredentials';
import Scrollable from '../Scrollable';
import { useMemo } from 'react';

type UsersLisProps = {
	isLoading?: boolean;
	owners: ResourceRolesUser[];
	metricName: string;
};

export function UsersList({ isLoading, owners, metricName }: UsersLisProps) {
	const List = useMemo(
		() =>
			owners.map((user, i) => (
				<Flex
					key={user.userId + i}
					paddingTop={i === 0 ? '8px' : '0px'}
					paddingBottom={i === owners.length - 1 ? '8px' : '0px'}
				>
					<UsersCredentials metricName={metricName} user={user} />
				</Flex>
			)),
		[metricName, owners]
	);

	return (
		<Flex
			flexDirection={'column'}
			width={'100%'}
			boxShadow={shadows.cardShadow}
			borderRadius={'8px'}
			border={`1px solid ${colors.gray[300]}`}
			maxH={owners.length > 3 ? '205px' : '196px'}
		>
			<Scrollable>{isLoading ? <ListSkeleton /> : List}</Scrollable>
		</Flex>
	);
}
