import { ModalHeader } from '@chakra-ui/react';
import Flex from '@components/Flex';
import Center from '@components/Center';
import Typography from '@components/Typography';
import Button from 'src/common/components/Button';
import { CloseLarge16 } from 'src/common/components/Icons';
import shadows from 'src/style/shadows';

interface ModalHeaderProps {
	onClose: () => void;
	title: string;
}

export function FillDetailsHeader({ onClose, title }: ModalHeaderProps) {
	return (
		<ModalHeader boxShadow={shadows.borderBottom} color="gray.1000" padding={'12px'}>
			<Flex position={'relative'}>
				<Button variant="outline" isIconOnly onClick={onClose} size="inline" color={'gray.900'} colorScheme="black">
					<CloseLarge16 />
				</Button>

				<Center zIndex={-1} position={'absolute'} height={'100%'} width={'100%'}>
					<Typography width={'40%'} variant={'DesktopH7Medium'} textAlign={'center'}>
						{title}
					</Typography>
				</Center>
			</Flex>
		</ModalHeader>
	);
}
