import Flex from '@components/Flex';
import { AffectedMetricItem } from '@components/AffectedMetricsButton/types';
import Typography from '@components/Typography';
import { useReportEvent } from '@services/analytics';
import { MetricPagePath } from '@pages/MetricPage/pageRoutesPaths';
import useNavigation from '@services/useNavigation';
import Link from '../Link';
import Box from '@components/Box';
import { useRef } from 'react';
import { useOnOverflow } from '@hooks/ui/useOnOverflow';
import Tooltip from '@components/Tooltip';

export const AffectedMetric = ({ name, displayName, entity, source }: AffectedMetricItem) => {
	const { reportEvent } = useReportEvent();
	const { getHref } = useNavigation();
	const displayNameRef = useRef(null);
	const isOverflowingDisplayName = useOnOverflow(displayNameRef, [displayName], undefined, false);

	return (
		<Link
			href={getHref({ path: `${MetricPagePath}/${name}` })}
			_hover={{ textDecoration: 'none' }}
			isExternal
			onClick={() => {
				reportEvent({
					event: 'affected-metric-item-clicked',
					metaData: { metric: name },
				});
			}}
		>
			<Flex
				padding={'12px'}
				borderRadius={'4px'}
				gap={'4px'}
				flexDirection={'column'}
				_hover={{ bg: 'blue.100', cursor: 'pointer' }}
			>
				<Tooltip size="md" variant="fluid" label={isOverflowingDisplayName && displayName}>
					<Typography
						color={'gray.1000'}
						variant="DesktopH8Medium"
						noOfLines={1}
						wordBreak={'break-all'}
						textOverflow="ellipsis"
					>
						<Box ref={displayNameRef}>{displayName}</Box>
					</Typography>
				</Tooltip>

				<Flex gap={'4px'} color={'gray.700'}>
					<Typography variant="DesktopH10Regular">{entity}</Typography>
					<Box lineHeight={'16px'}>·</Box>
					<Typography variant="DesktopH10Regular">{source}</Typography>
				</Flex>
			</Flex>
		</Link>
	);
};
