import { useMetricFilter } from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/useMetricFilter';
import { useSemanticDefinitions } from '@hooks/stores/useSemanticDefinitions';
import { resolveContextFromSemanticsAndName } from '@pages/OntologyPage/components/JoinKeys/utils';
import { Breakdown } from 'src/generated/graphql';
import { MonacoTooltipComponent } from 'src/lib/completions/widgetBuilder/MonacoTooltipComponent';
import { getContextCompletionTooltip } from 'src/lib/completions/widgetBuilder/contextTooltip';
import { BreakdownOutline16 } from '../../Icons';
import { DrilldownCard } from './DrillDownCard';

export function BreakdownDrilldownCard({
	breakdown,
	onRemove,
	onClick,
	onModalOpen,
}: {
	breakdown: Breakdown;
	onRemove: VoidFunction;
	onClick?: VoidFunction;
	onModalOpen: VoidFunction;
}) {
	const { entityToFetch, dimensionToFetch } = useMetricFilter({
		filter: {
			key: breakdown.key,
			values: [],
			label: breakdown.label,
			type: 'text',
		},
	});
	const { semanticDefinitions } = useSemanticDefinitions();

	return (
		<DrilldownCard
			onModalOpen={onModalOpen}
			icon={<BreakdownOutline16 />}
			tooltip={breakdown.label}
			onRemove={onRemove}
			removeTooltip={'Remove breakdown'}
			title={breakdown.label}
			onClick={onClick}
			titleHoverTooltipBuilder={() => {
				if (!dimensionToFetch || !semanticDefinitions || !entityToFetch) return;
				const context = resolveContextFromSemanticsAndName(semanticDefinitions, entityToFetch, dimensionToFetch);
				if (!context) return;
				return (
					<MonacoTooltipComponent tooltipMarkdownString={getContextCompletionTooltip(context, dimensionToFetch)} />
				);
			}}
		/>
	);
}
