import { AffectedMetricItem } from '@components/AffectedMetricsButton/types';
import { EnrichedFormulaMetric, EnrichedMetric, EnrichedSemanticDefinitions } from 'src/lib/completions/semanticTypes';
import { removeUnderscoresAndCapitalize } from 'src/normalize';

export const getAffectedMetricDataItems = (
	metricNames: string[],
	semanticDefinitions?: EnrichedSemanticDefinitions
): AffectedMetricItem[] => {
	return metricNames.map((metricName) => {
		const isMatchingMetric = (metricName: string) => (metric: EnrichedMetric | EnrichedFormulaMetric) =>
			metric.name === metricName;
		const metric =
			semanticDefinitions?.metrics.find(isMatchingMetric(metricName)) ||
			semanticDefinitions?.formula_metrics.find(isMatchingMetric(metricName));
		const entity = semanticDefinitions?.entities.find((e) => e.name === metric?.entity);

		return {
			name: metricName,
			displayName: metric?.meta?.display_name || removeUnderscoresAndCapitalize(metricName),
			entity: entity?.meta?.display_name || 'Entity',
			source:
				(entity?.data_source && 'schema' in entity.data_source && entity.data_source.schema?.meta?.source) || 'Source',
		};
	});
};
