import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { upsertYAMLKey } from 'src/models/YamlUtils/yamlUtils';
import { PropertyDerivedStateAtom, updatePropertyValue, updateSelectedProperty } from '../atoms/PropertyState';
import { OntologyPropertyType } from '../utils/types';
import { CreateNewDimensionPropertyName, CreateNewRelationshipPropertyName } from './useOntologySearchParams';

export const newDimensionYAML = [
	'name: untitled',
	'type: ""',
	'sources: ',
	'  - sql: ""',
	'meta:',
	'  display_name: Untitled',
	'  description: ""',
];

export const createDimensionYAMLDefinition = ({
	name,
	type,
	sources,
	meta,
}: {
	name: string;
	type: string;
	sources: string[];
	meta: Record<string, string>;
}) => {
	const sourcesDefinition = sources.map((source) => `  - sql: ${source}`);
	const metaDefinition = Object.entries(meta).map(([key, value]) => `  ${key}: "${value}"`);
	return [`name: ${name}`, `type: ${type}`, 'sources: ', ...sourcesDefinition, 'meta:', ...metaDefinition];
};
export const newRelationshipYAML = [
	'name: untitled',
	'referenced_entity: ""',
	'type: ""',
	'on: ""',
	'meta:',
	' display_name: Untitled',
	' description: ""',
];

export type KeysType = { key: Parameters<typeof upsertYAMLKey>[1]; value?: Parameters<typeof upsertYAMLKey>[2] }[];

export function usePropertyBuilderState({ name }: { name: string }) {
	const propertyState = useAtomValue(PropertyDerivedStateAtom);
	const updateSelectedPropertyAction = useSetAtom(updateSelectedProperty);
	const updatePropertyValueAction = useSetAtom(updatePropertyValue);

	const resetInitialValue = useCallback(
		() => updateSelectedPropertyAction({ name }),
		[name, updateSelectedPropertyAction]
	);

	useEffect(() => resetInitialValue(), [name, propertyState?.propertyName, resetInitialValue]);

	const setPropertyValue = useCallback(
		(value: string) => {
			updatePropertyValueAction({ value });
		},
		[updatePropertyValueAction]
	);

	const upsertYAMLProperties = useCallback(
		(keys: KeysType) => {
			const value = propertyState?.propertyValue;
			if (!value) return;
			const userValue = keys.reduce(
				(editorValue, keyVal) => upsertYAMLKey(editorValue, keyVal.key, keyVal.value),
				value
			);
			updatePropertyValueAction({ value: userValue });
		},
		[propertyState?.propertyValue, updatePropertyValueAction]
	);

	const upsertYAMLProperty = useCallback(
		(
			key: Parameters<typeof upsertYAMLProperties>[0][0]['key'],
			value?: Parameters<typeof upsertYAMLProperties>[0][0]['value']
		) => {
			upsertYAMLProperties([{ key, value }]);
		},
		[upsertYAMLProperties]
	);

	return { ...propertyState, setPropertyValue, upsertYAMLProperties, upsertYAMLProperty, resetInitialValue };
}

export function useCreatePropertyBuilderState({ propertyType }: { propertyType: OntologyPropertyType }) {
	const propertyState = useAtomValue(PropertyDerivedStateAtom);

	const updateSelectedPropertyAction = useSetAtom(updateSelectedProperty);
	const updatePropertyValueAction = useSetAtom(updatePropertyValue);

	const initialValue = useMemo(() => {
		return propertyType == 'dimensions' ? newDimensionYAML.join('\n') : newRelationshipYAML.join('\n');
	}, [propertyType]);

	const resetInitialValue = useCallback(
		() => updatePropertyValueAction({ value: initialValue }),
		[initialValue, updatePropertyValueAction]
	);

	useEffect(() => {
		const newName = propertyType == 'dimensions' ? CreateNewDimensionPropertyName : CreateNewRelationshipPropertyName;
		if (propertyState?.propertyName !== newName) updateSelectedPropertyAction({ name: newName });
		resetInitialValue();
	}, [
		propertyType,
		propertyState?.propertyName,
		updateSelectedPropertyAction,
		updatePropertyValueAction,
		resetInitialValue,
	]);

	const setPropertyValue = useCallback(
		(value: string) => {
			updatePropertyValueAction({ value });
		},
		[updatePropertyValueAction]
	);

	const upsertYAMLProperties = useCallback(
		(keys: KeysType) => {
			const value = propertyState?.propertyValue;
			if (!value) return;
			const userValue = keys.reduce(
				(editorValue, keyVal) => upsertYAMLKey(editorValue, keyVal.key, keyVal.value),
				value
			);
			updatePropertyValueAction({ value: userValue });
		},
		[propertyState?.propertyValue, updatePropertyValueAction]
	);

	const upsertYAMLProperty = useCallback(
		(
			key: Parameters<typeof upsertYAMLProperties>[0][0]['key'],
			value?: Parameters<typeof upsertYAMLProperties>[0][0]['value']
		) => {
			upsertYAMLProperties([{ key, value }]);
		},
		[upsertYAMLProperties]
	);

	return { ...propertyState, setPropertyValue, upsertYAMLProperties, upsertYAMLProperty, resetInitialValue };
}
