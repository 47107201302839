import { PopoverContent } from '@chakra-ui/react';
import Flex from '@components/Flex';
import Popover from '@components/Popover';
import { ExploreMenu } from '@components/TopNavigation/ExploreMenu';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { useModal } from '@hooks/ui/useModal';
import { Cube14, Dashboards14, Globe14, Plus16 } from '@icons/index';
import { DashboardPath } from '@pages/DashboardPage';
import { HeaderNavigationButton } from '@pages/MetricCatalog/HeaderNavigationButton';
import { METRIC_LIBRARY_URL, openInSourcePlatform } from '@pages/MetricCatalog/utils';
import { OntologyPagePath } from '@pages/OntologyPage/OntologyBuilderPage';
import { useReportEvent } from '@services/analytics';
import useNavigation from '@services/useNavigation';
import { TestIDs } from 'src/common/types/test-ids';
import shadows from 'src/style/shadows';

export function MetricCatalogHeaderV2Buttons() {
	const { isOpen, onClose, onOpen } = useModal();
	const { navigate } = useNavigation();
	const isMetricPageEnabled = useFeatureFlag('pulse.sightfull2.createNewMetric.enable');
	const { wrapWithReport } = useReportEvent();

	return (
		<Flex justifyContent={'center'} alignItems={'center'} gap={'4px'} alignSelf={'stretch'}>
			<HeaderNavigationButton
				Icon={Dashboards14}
				title={'Dashboards'}
				onClick={() => navigate({ path: DashboardPath })}
				dataIntercomArea={'homepage'}
				dataIntercomType={'button'}
				dataIntercomTarget={'dashboards'}
			/>
			{isMetricPageEnabled && (
				<Popover
					triggerElement={
						<HeaderNavigationButton
							Icon={Plus16}
							title={'Create new metric'}
							onClick={wrapWithReport(onOpen, 'create-new-metric-clicked')}
							dataIntercomArea={'homepage'}
							dataIntercomType={'button'}
							dataIntercomTarget={'create-new-metric'}
							isActive={isOpen}
							testId={TestIDs.CREATE_NEW_METRIC_BUTTON_IN_BIG_HEADER}
						/>
					}
					isOpen={isOpen}
					placement={'bottom-start'}
					onClose={onClose}
					popoverContentProps={{
						boxShadow: shadows['a-22'],
					}}
					closeOnBlur={true}
				>
					<PopoverContent>
						<ExploreMenu isCreateNewMetric onClose={onClose} />
					</PopoverContent>
				</Popover>
			)}

			<HeaderNavigationButton
				Icon={Globe14}
				title={'Start with template'}
				onClick={wrapWithReport(openInSourcePlatform, 'open-website-metrics-library', {
					link: METRIC_LIBRARY_URL,
				})}
				dataIntercomArea={'homepage'}
				dataIntercomType={'button'}
				dataIntercomTarget={'templates'}
			/>
			<HeaderNavigationButton
				Icon={Cube14}
				title={'Data model'}
				onClick={() => navigate({ path: OntologyPagePath })}
				dataIntercomArea={'homepage'}
				dataIntercomType={'button'}
				dataIntercomTarget={'ontology'}
			/>
		</Flex>
	);
}
