import Flex from '@components/Flex';
import { TestIDs } from 'src/common/types/test-ids';
import { METRIC_COPIES } from '@pages/CreateNewMetricPage/copy';
import { ExploreAggregateMetric, ExploreFormulaMetric } from '@icons/index';
import Typography from '@components/Typography';
import Box from '@components/Box';
import Divider from '@components/Divider';
import { useReportEvent } from '@services/analytics';
import useNavigation from '@services/useNavigation';
import { MetricType } from 'src/common/types/common';
import { ReactNode } from 'react';
import { Label } from '@components/Labels';
import { useLocation } from 'react-router-dom';
import { CreateNewMetricPagePath, ExploreNewMetricPagePath } from '@pages/CreateNewMetricPage/CreateNewMetricPage';
import { CREATE_NEW_METRIC_INITIAL_PROMPT_QUERY_PARAM } from '../AskAI/types';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

export type MenuItemProps = {
	icon: ReactNode;
	title: string;
	description: string;
	type?: MetricType;
	isComingSoon?: boolean;
	testid?: string;
};

export const ExploreMenu = ({
	onClose,
	onMetricTypeClick,
	isCreateNewMetric = false,
	autoRunPrompt,
	isNewTab = false,
}: {
	onClose: VoidFunction;
	onMetricTypeClick?: (type: MetricType) => void;
	isCreateNewMetric?: boolean;
	autoRunPrompt?: string;
	isNewTab?: boolean;
}) => {
	const { reportEvent } = useReportEvent();
	const { navigate } = useNavigation();
	const location = useLocation();

	const getLink = (type?: MetricType) => {
		return `${isCreateNewMetric ? CreateNewMetricPagePath : ExploreNewMetricPagePath}/${type}`;
	};

	const onClick = (type?: MetricType) => {
		if (!type) return;

		reportEvent({
			event: 'create-new-metric-type-modal',
			metaData: {
				action: 'Create',
				feature: isCreateNewMetric ? 'Metric Catalog' : 'Explore',
				page: 'Menu',
				metricType: type,
			},
		});
		onMetricTypeClick?.(type);
		navigate({
			path: getLink(type),
			isNewTab,
			additionalSearchParams: autoRunPrompt
				? new URLSearchParams({
						[CREATE_NEW_METRIC_INITIAL_PROMPT_QUERY_PARAM]: autoRunPrompt,
				  })
				: undefined,
		});
		onClose();
	};

	const MenuItem = ({ icon, title, description, type, isComingSoon = false, testid }: MenuItemProps) => {
		const isCurrentPage = location.pathname.includes(getLink(type));

		return (
			<Flex
				alignItems={'flex-start'}
				padding={'13px 16px'}
				gap={'12px'}
				borderRadius={'4px'}
				backgroundColor={isCurrentPage ? 'gray.200' : 'transparent'}
				_hover={{
					backgroundColor: isComingSoon ? 'transparent' : 'blue.100',
					cursor: isComingSoon ? 'default' : 'pointer',
				}}
				data-testid={testid}
				onClick={() => onClick(type)}
				{...buildIntercomAttributes({
					area: 'explore-menu',
					type: 'button',
					target: type,
				})}
			>
				<Box>{icon}</Box>
				<Flex flexDirection={'column'} gap={'8px'}>
					<Flex flexDirection={'column'} gap={'2px'}>
						<Typography variant="DesktopH8Medium" color={isComingSoon ? 'gray.600' : 'gray.1000'}>
							{title}
						</Typography>
						<Typography variant="Paragraph12R" color={isComingSoon ? 'gray.600' : 'gray.700'}>
							{description}
						</Typography>
					</Flex>
					{isComingSoon && (
						<Label size={'small'} style={'light'} variant={'filled'} colorScheme={'primary'}>
							Coming soon
						</Label>
					)}
				</Flex>
			</Flex>
		);
	};

	return (
		<Flex
			alignSelf="center"
			direction={'column'}
			p="8px"
			width="300px"
			borderRadius="8px"
			{...buildIntercomAttributes({
				area: 'topbar',
				type: 'menu',
				target: 'Explore',
			})}
		>
			<MenuItem
				testid={TestIDs.AGGREGATE_METRIC_DROPDOWN_ITEM}
				icon={<ExploreAggregateMetric width={'40px'} height={'40px'} />}
				{...METRIC_COPIES[0]}
			/>
			<Box paddingX={'16px'}>
				<Divider color="gray.300" direction="horizontal" marginY="8px" />
			</Box>
			<MenuItem
				testid={TestIDs.FORMULA_METRIC_DROPDOWN_ITEM}
				icon={<ExploreFormulaMetric width={'40px'} height={'40px'} />}
				{...METRIC_COPIES[1]}
			/>
		</Flex>
	);
};
