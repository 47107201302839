import { useCallback } from 'react';
import { useInvalidateCache } from '@services/apollo';
import { useDeleteEntityMutation } from 'src/generated/graphql';
import { useRunNormalization } from '@pages/OntologyPage/hooks/useRunNormalization';
import { useSemanticDefinitions } from '@hooks/stores/useSemanticDefinitions';
const DELETE_FAILED_ERROR_MESSAGE = 'Delete Failed, please review and try again';

export function useDeleteEntity({
	entityName,
	onDeleteStart,
	onNormalizationStart,
	onNormalizationSuccess,
	onSaveSuccess,
	handleSaveError,
	handleNormalizationError,
}: {
	entityName: string;
	onDeleteStart: VoidFunction;
	onNormalizationStart: VoidFunction;
	onNormalizationSuccess: VoidFunction;
	onSaveSuccess: VoidFunction;
	handleSaveError: (error: string) => void;
	handleNormalizationError: (error: string) => void;
}) {
	const { invalidateCache } = useInvalidateCache();
	const [deleteEntityMutation, { loading: deleteEntityInProgress }] = useDeleteEntityMutation();
	const { runNormalization, normalizationInProgress } = useRunNormalization({ handleNormalizationError });
	const { reloadSemantics: reloadPulseSemantics } = useSemanticDefinitions();

	const deleteEntity = useCallback(
		async (
			onErrorCallback?: VoidFunction,
			onSuccessCallback?: VoidFunction,
			outerOnNormalizationSuccess?: VoidFunction
		) => {
			onDeleteStart();
			try {
				const { data: deleteData } = await deleteEntityMutation({
					variables: { entityName },
				});
				if (!deleteData) {
					handleSaveError(DELETE_FAILED_ERROR_MESSAGE);
					onErrorCallback?.();
					return;
				}
			} catch {
				handleSaveError(DELETE_FAILED_ERROR_MESSAGE);
				onErrorCallback?.();
			}
			onSuccessCallback?.();
			onSaveSuccess();

			await reloadPulseSemantics();

			onNormalizationStart();
			await runNormalization().then(() => {
				invalidateCache();
				onNormalizationSuccess();
				outerOnNormalizationSuccess?.();
			});
		},
		[
			onDeleteStart,
			onSaveSuccess,
			reloadPulseSemantics,
			onNormalizationStart,
			runNormalization,
			deleteEntityMutation,
			entityName,
			handleSaveError,
			invalidateCache,
			onNormalizationSuccess,
		]
	);

	return { deleteEntity, deleteEntityInProgress, normalizationInProgress };
}
