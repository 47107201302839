import Box from '@components/Box';
import Divider from '@components/Divider';
import { FilterOutline16 } from '@components/Icons';
import { AddDrillDownCard } from '@components/LeftExpandCollapsePanel/Drilldown/AddDrillDownCard';
import Typography from '@components/Typography';
import { useReportEvent } from '@services/analytics';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Tooltip from 'src/common/components/Tooltip';
import * as CONSTS from 'src/pages/MetricPage/components/FiltersAndBreakdown/consts';
import classes from '../InvestigatePanel/InvestigatePanel.module.scss';
import { useMetricFiltersV2 } from '../InvestigatePanel/useMetricFiltersV2';
import { Title } from '../PanelTitle/Title';
import { FiltersAndBreakdownResponseType } from './types';
import { isMetricPage } from '@pages/utils/locations';
import { FilterDrillDownCard } from 'src/common/components/FilterDropdownButton/FilterDrillDownCard';
import { isFilterComplete } from 'src/common/components/FilterDropdownButton/useFilter';
import { useFilterV2Logic } from 'src/common/components/FilterDropdownButton/useFilterV2Logic';
import { useMetricDerivedState } from '@pages/MetricPage/hooks/useMetricDerivedState';
import FiltersAndBreakdownsModal from './FiltersAndBreakdownsModal';
import useFiltersAndBreakdown from './useFiltersAndBreakdown';
import { useCoreNodeScheme } from './NodeScheme/useCoreNodeScheme';

export function FiltersPanel2({ isShowingFullSize, isDisabled }: { isShowingFullSize: boolean; isDisabled?: boolean }) {
	const [hasIncompleteFilters, setHasIncompleteFilters] = useState(false);
	const { wrapWithReport, reportEvent } = useReportEvent();
	const [initialFilters, { onFilterSubmit, entityName }] = useMetricFiltersV2();
	const { isLoading } = useMetricDerivedState();
	const [activeFilterIndex, setActiveFilterIndex] = useState<number | undefined>();
	const { filtersArray, addFilters, editFilter, removeFilter, removeAllFilters } = useFilterV2Logic({
		initialFilters,
		onFilterSubmit,
	});

	const [modalState, modalActions] = useFiltersAndBreakdown();

	useEffect(() => {
		setHasIncompleteFilters(!filtersArray.every(isFilterComplete));
	}, [filtersArray]);

	const eventMetaData = useMemo(
		() => ({
			feature: 'filters',
			page: isMetricPage() ? 'Metric Page' : 'Signal Page',
			application: 'metric investigation',
		}),
		[]
	);

	const openFilterModal = useCallback(
		(filterIndex?: number) => {
			setActiveFilterIndex(filterIndex);
			modalActions.onOpen(CONSTS.FILTER_MODEL_TYPE, modalActions.onClose);
		},
		[modalActions, setActiveFilterIndex]
	);

	const onAddItems = (result: FiltersAndBreakdownResponseType) => {
		setTimeout(() => addFilters(result, activeFilterIndex), 200);
		// setTimeout is needed to allow the FiltersAndBreakdown modal to close before opening another modal.
	};

	const coreNodeScheme = useCoreNodeScheme({
		objectsTypes: [entityName],
		schemeType: 'global',
		fetchPolicy: 'no-cache',
		isIncludingTypePrefix: !entityName,
		readyToFetch: !isLoading,
	});

	const drillDownCards = useMemo(() => {
		if (isDisabled) return null;
		return filtersArray.map((filter, filterIndex) => (
			<FilterDrillDownCard
				onModalOpen={() => openFilterModal(filterIndex)}
				filterIndex={filterIndex}
				editFilter={editFilter}
				entityName={entityName}
				key={filter.key + filter.values.join() + filter.operator}
				filter={filter}
				onRemove={wrapWithReport(() => removeFilter(filterIndex), 'metric-filters-delete-clicked')}
				isEditorAutoOpened={filter.values.length === 0}
				eventMetaData={eventMetaData}
				onFilterCompletenessChange={(isComplete) => {
					setHasIncompleteFilters(!isComplete);
				}}
			/>
		));
	}, [filtersArray, isDisabled, editFilter, entityName, removeFilter, eventMetaData, wrapWithReport, openFilterModal]);

	return (
		<>
			<Box whiteSpace="nowrap" height={isShowingFullSize ? 'min-content' : '0'} opacity={isShowingFullSize ? '1' : '0'}>
				<Title
					onAction={() => {
						reportEvent({ event: 'filters-remove-all-filters', metaData: eventMetaData });
						removeAllFilters();
					}}
					text={'Filters'}
					showAction={!!filtersArray?.length}
				/>
				{drillDownCards}
				<Tooltip label={hasIncompleteFilters ? 'Select a value to add another filter.' : undefined} size={'sm'}>
					<Box width={'100%'}>
						<AddDrillDownCard
							isDisabled={isDisabled || hasIncompleteFilters || isLoading}
							type={CONSTS.FILTER_MODEL_TYPE}
							onClick={wrapWithReport(() => openFilterModal(), 'metric-filters-clicked')}
							dataIntercomTarget={'Add filter'}
						/>
					</Box>
				</Tooltip>
			</Box>
			{!isShowingFullSize && (
				<Box
					display="flex"
					width="32px"
					height="32px"
					paddingTop={'15px'}
					position="relative"
					justifyContent="center"
					alignItems="center"
				>
					<FilterOutline16 />
					{!!filtersArray.length && (
						<Box className={classes.badgeNumber}>
							<Typography variant="Paragraph12M">{filtersArray.length}</Typography>
						</Box>
					)}
				</Box>
			)}
			<Box paddingY={isShowingFullSize ? '24px' : '8px'}>
				<Divider direction={'horizontal'} />
			</Box>
			<FiltersAndBreakdownsModal
				hasBaseNode={!!entityName}
				type={CONSTS.FILTER_MODEL_TYPE}
				isOpen={modalState.isOpen}
				onClose={modalActions.onClose}
				onAddItems={onAddItems}
				nodeScheme={coreNodeScheme}
				initialFilter={modalState.initialFilter}
			/>
		</>
	);
}
