import { useMemo } from 'react';
import { CalculationPageState } from '@pages/ArrModelPage/types';
import { useArrModelOutputState } from '@pages/ArrModelPage/hooks/useArrModelOutputState';
import { useMetricEdit } from '@hooks/MetricHandling/useMetricEdit';
import { MetricOperation } from 'src/lib/completions/semanticTypes/metrics.schema';
import { GRANULAR_METRIC_NAME } from '@pages/ArrModelPage/consts';

export function useCalculationPageState(): CalculationPageState & {
	setGranularMetricOperation: (granularMetricOperation: MetricOperation) => void;
	setGranularMetricMeasure: (granularMetricMeasure: string) => void;
	setGranularMetricXAxis: (granularMetricXAxis: string) => void;
	onSave: () => void;
	onPreview: () => void;
} {
	const {
		arrModelOutputState,
		savedModelState,
		setGranularMetricOperation,
		setGranularMetricMeasure,
		setGranularMetricXAxis,
	} = useArrModelOutputState();
	const { onSave, isUpsertMetricLoading, isLoading, onPreview } = useMetricEdit({ metricName: GRANULAR_METRIC_NAME });

	const hasUnsavedChanges = useMemo((): boolean => {
		return (
			arrModelOutputState.granularMetricOperation !== savedModelState.granularMetricOperation ||
			arrModelOutputState.granularMetricMeasure !== savedModelState.granularMetricMeasure ||
			arrModelOutputState.granularMetricXAxis !== savedModelState.granularMetricXAxis
		);
	}, [arrModelOutputState, savedModelState]);

	const hasAllRequiredValues = useMemo(() => {
		return (
			arrModelOutputState.granularMetricOperation != undefined &&
			arrModelOutputState.granularMetricMeasure != undefined &&
			arrModelOutputState.granularMetricXAxis != undefined
		);
	}, [
		arrModelOutputState.granularMetricMeasure,
		arrModelOutputState.granularMetricOperation,
		arrModelOutputState.granularMetricXAxis,
	]);
	return {
		canSave: hasUnsavedChanges && !isUpsertMetricLoading && hasAllRequiredValues,
		isSaving: isUpsertMetricLoading,
		hasUnsavedChanges,
		setGranularMetricOperation,
		setGranularMetricMeasure,
		setGranularMetricXAxis,
		isLoading,
		onSave,
		onPreview,
	};
}
