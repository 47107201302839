import Box from '@components/Box';
import { ReactNode } from 'react';
import useEmbedMode from 'src/common/hooks/navigation/useEmbedSetting';
import { AnalyticsPageContext, PageType } from 'src/services/analytics';
import classes from './Page.module.scss';
import classnames from 'classnames';

// TODO: Use layout store.
function Page({ children, page }: { children: ReactNode; page: PageType }) {
	const [embed] = useEmbedMode();
	const isWidget = embed === 'widget';
	const isPageWithNewLeftBar =
		page === 'Dashboard' || page === 'Workspace' || page === 'Ontology Page' || page === 'AI Agent';

	return (
		<AnalyticsPageContext page={page}>
			<Box
				id={'pageContainer'}
				className={classnames({
					[classes.pageContainer]: !isPageWithNewLeftBar,
					[classes.pageContainerFull]: !isWidget,
					[classes.pageContainerFullWithNewLeftBar]: isPageWithNewLeftBar,
				})}
			>
				{children}
			</Box>
		</AnalyticsPageContext>
	);
}

export default Page;
