import { gql } from '@apollo/client';
import { disableFragmentWarnings } from 'graphql-tag';
disableFragmentWarnings();

export const LikesFields = gql`
	fragment LikesFields on signals {
		id
		likes_aggregate {
			aggregate {
				count
			}
		}
		my_likes: likes_aggregate(where: { author_id: { _eq: $my_id } }) {
			aggregate {
				count
			}
		}
	}
`;

export const CommentFields = gql`
	fragment CommentFields on comments {
		message
		created_at
		edited_at
		author {
			first_name
			last_name
			email
			picture
		}
		id
		author_id
	}
`;

export const CommentsList = gql`
	${CommentFields}
	fragment CommentsList on signals {
		id
		comments(order_by: { created_at: asc }) {
			...CommentFields
		}
	}
`;

export const SignalFields = gql`
	${CommentsList}
	${LikesFields}
	fragment SignalFields on signals {
		attachment
		created_at
		edited_at
		title
		message
		id
		widget_type
		author {
			email
			first_name
			last_name
			picture
		}
		insight_type
		author_id
		...CommentsList
		...LikesFields
		feed_signals {
			feed {
				workspace {
					collection_type
					id
				}
			}
			resolved
		}
	}
`;

export const FeedSignalObject = gql`
	${SignalFields}
	fragment FeedSignalObject on feed_signals {
		signal_id
		feed_id
		signal {
			...SignalFields
		}
		order
		resolved
		reason
		updated_at
	}
`;

export const FeedSignalsList = gql`
	${FeedSignalObject}
	fragment FeedSignalsList on feeds {
		id
		feed_signals(where: { resolved: { _eq: false } }, order_by: { order: asc }, limit: $limit, offset: $offset) {
			...FeedSignalObject
		}
	}
`;

export const SignalsGetByFeed = gql`
	subscription SignalsGetByFeed($my_id: uuid = "", $feed_id: uuid = "", $limit: Int, $offset: Int) {
		feeds_by_pk(id: $feed_id) {
			...FeedSignalsList
			feed_signals_aggregate(where: { resolved: { _eq: false } }) {
				aggregate {
					count
				}
			}
		}
	}
`;
export const SignalGet = gql`
	${SignalFields}
	subscription SignalGet($my_id: uuid = "", $signal_id: uuid = "") {
		signals_by_pk(id: $signal_id) {
			...SignalFields
		}
	}
`;
export const SignalInsert = gql`
	${SignalFields}
	mutation SignalInsert($message: String = "", $attachment: jsonb = "", $my_id: uuid = "") {
		insert_signals_one(object: { attachment: $attachment, message: $message }) {
			id
			...SignalFields
		}
	}
`;
export const SignalPin = gql`
	${FeedSignalsList}
	mutation SignalPin($feed_id: uuid = "", $signal_id: uuid = "", $my_id: uuid = "", $limit: Int, $offset: Int) {
		insert_feed_signals_one(
			object: { feed_id: $feed_id, signal_id: $signal_id }
			on_conflict: { constraint: feed_signals_pkey }
		) {
			feed {
				...FeedSignalsList
			}
		}
	}
`;
export const DeleteSignal = gql`
	${FeedSignalsList}
	mutation DeleteSignal($signal_id: uuid!, $feed_id: uuid!, $my_id: uuid!, $limit: Int, $offset: Int) {
		delete_feed_signals_by_pk(feed_id: $feed_id, signal_id: $signal_id) {
			feed {
				...FeedSignalsList
			}
		}
	}
`;
export const SignalUpsert = gql`
	${FeedSignalsList}
	${SignalFields}
	mutation SignalUpsert(
		$feed_insert_objects: [feed_signals_insert_input!]!
		$feed_delete_ids: [uuid!]!
		$signal_id: uuid = ""
		$attachment: jsonb
		$title: String
		$message: String
		$my_id: uuid
		$limit: Int
		$offset: Int
		$widget_type: String
	) {
		insert_signals_one(
			object: { id: $signal_id, attachment: $attachment, message: $message, title: $title, widget_type: $widget_type }
			on_conflict: { constraint: signals_pkey, update_columns: [message, attachment, title] }
		) {
			id
			...SignalFields
		}

		# We delete everything and then add what's needed
		delete_feed_signals(where: { signal_id: { _eq: $signal_id }, feed_id: { _in: $feed_delete_ids } }) {
			returning {
				feed {
					...FeedSignalsList
				}
			}
		}

		insert_feed_signals(objects: $feed_insert_objects, on_conflict: { constraint: feed_signals_pkey }) {
			returning {
				feed {
					...FeedSignalsList
				}
			}
		}
	}
`;

export const SignalsInsert = gql`
	${FeedSignalsList}
	${SignalFields}
	mutation SignalsInsert(
		$feed_insert_objects: [feed_signals_insert_input!]!
		$signals: [signals_insert_input!] = {}
		$my_id: uuid
		$limit: Int
		$offset: Int
	) {
		insert_signals(
			objects: $signals
			on_conflict: { constraint: signals_pkey, update_columns: [message, attachment, title] }
		) {
			affected_rows
		}

		insert_feed_signals(objects: $feed_insert_objects, on_conflict: { constraint: feed_signals_pkey }) {
			returning {
				feed {
					...FeedSignalsList
				}
			}
		}
	}
`;

export const SignalResolve = gql`
	mutation SignalResolve($feed_id: uuid = "", $signal_id: uuid = "") {
		update_feed_signals_by_pk(pk_columns: { feed_id: $feed_id, signal_id: $signal_id }, _set: { resolved: true }) {
			feed {
				id
			}
		}
	}
`;
export const GetWorkspacesSignalFeeds = gql`
	query GetWorkspacesSignalFeeds($signal_id: uuid = "") {
		workspaces(where: { feed: { feed_signals: { signal_id: { _eq: $signal_id }, resolved: { _eq: false } } } }) {
			feed {
				id
			}
		}
	}
`;

/// LIKES
export const LikeDelete = gql`
	mutation LikeDelete($my_id: uuid = "", $signal_id: uuid = "") {
		delete_signal_likes(where: { author_id: { _eq: $my_id }, signal_id: { _eq: $signal_id } }) {
			affected_rows
			returning {
				signal_id
				author_id
				signal {
					...LikesFields
				}
			}
		}
	}
`;
export const LikeInsert = gql`
	mutation LikeInsert($my_id: uuid = "", $signal_id: uuid = "") {
		insert_signal_likes_one(object: { signal_id: $signal_id }) {
			signal_id
			author_id
			signal {
				...LikesFields
			}
		}
	}
`;

/// COMMENTS
export const CommentInsert = gql`
	${CommentsList}
	mutation CommentInsert($signal_id: uuid = "", $message: String = "", $attachment: jsonb = "") {
		insert_comments_one(object: { signal_id: $signal_id, message: $message, attachment: $attachment }) {
			signal {
				...CommentsList
			}
		}
	}
`;
export const CommentDelete = gql`
	${CommentsList}
	mutation CommentDelete($comment_id: uuid = "") {
		update_comments_by_pk(pk_columns: { id: $comment_id }, _set: { is_deleted: true }) {
			signal {
				...CommentsList
			}
		}
	}
`;
export const CommentEdit = gql`
	${CommentsList}
	mutation CommentEdit($message: String = "", $comment_id: uuid = "") {
		update_comments_by_pk(pk_columns: { id: $comment_id }, _set: { message: $message }) {
			...CommentFields
		}
	}
`;
export const FeedSignalsCount = gql`
	subscription FeedSignalsCount($feed_id: uuid = "") {
		feed_signals_aggregate(where: { feed_id: { _eq: $feed_id }, resolved: { _eq: false } }) {
			aggregate {
				count
			}
		}
	}
`;
