import Flex from '@components/Flex';
import Typography from '@components/Typography';
import useOutsideClick from '@hooks/interaction/useOutsideClick';
import useUser from '@hooks/stores/useUser';
import {
	ConfigurationSolid16,
	GlobeOutline20,
	HelpCircleOutline20,
	HygieneOutline20,
	Logout,
	Newsletter16,
	SettingsSolid16,
	TenantsSolid16,
} from '@icons/index';
import { ReactNode, useRef } from 'react';
import NavigationAvatarMenuItem from './NavigationAvatarMenuItem';

import Divider from '@components/Divider';
import TenantPicker from '@components/TenantPicker';
import { AdminPortal } from '@frontegg/react';
import { useModal } from '@hooks/ui/useModal';
import { AccessController } from 'src/common/components/AccessController';
import AvatarContainer from 'src/common/components/Avatar/AvatarContainer';
import Popover from 'src/common/components/Popover';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { TestIDs } from 'src/common/types/test-ids';
import { HELP_CENTER_PAGE_LINK } from 'src/common/utils/consts';
import { ConfigurationPath } from 'src/pages/ConfigurationPage';
import { DataHygienePath } from 'src/pages/DataHygiene';
import { SubscriptionPagePath } from 'src/pages/SubscriptionPage/SubscriptionPage';
import { useReportEvent } from 'src/services/analytics';
import { toggleIntercom } from 'src/services/intercom';
import useNavigation from 'src/services/useNavigation';
import { useEnvironment } from 'src/stores/environment';
import { useTenantConfigStore } from 'src/stores/tenantConfig';
import colors from 'src/style/colors';
import { Permissions } from 'src/types/environment';
import classes from './NavigationAvatarMenu.module.scss';

// TODO: Change to Chakra UI popover when a design system component will be required

function NavigationAvatarMenu({
	children,
	isTopNavigationMenuOpen,
	setIsTopNavigationMenuOpen,
}: {
	children: ReactNode;
	isTopNavigationMenuOpen: boolean;
	setIsTopNavigationMenuOpen: (value: boolean) => void;
}) {
	const { reportEvent } = useReportEvent();
	const { navigate } = useNavigation();
	const menuRef = useRef<HTMLDivElement>(null);
	useOutsideClick(menuRef, () => setIsTopNavigationMenuOpen(false));
	const [user, { logout }] = useUser();
	const { companyName } = useTenantConfigStore();
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const { isOpen: isTenantPickerOpen, onOpen: onTenantPickerOpen, onClose: onTenantPickerClose } = useModal();
	const isSelfServiceConfigEnabled = useFeatureFlag('pulse.react.selfServiceConfig') && !isSightfull2;
	const isDataHygieneEnabled = useFeatureFlag('pulse.dataHygiene.enabled') && !isSightfull2;
	const isAccountSettingsEnabled = useFeatureFlag('pulse.settings.enable');

	const isSubscriptionsEnabled = !isSightfull2;

	const { isMultiTenant } = useEnvironment();
	const iconColor = colors.gray[1000];

	const openFrontegg = () => {
		reportEvent({
			event: 'avatar-settings-clicked',
		});
		AdminPortal.show();
	};

	return (
		<Flex
			data-testid={TestIDs.AVATAR_MENU}
			cursor="pointer"
			alignItems="center"
			borderRadius="26px"
			maxH="40px"
			pos="relative"
			ref={menuRef}
		>
			<Popover
				popoverContentProps={{
					marginTop: '7px',
					boxShadow: 'avatarMenuShadow',
					width: '300px',
					paddingBottom: '8px',
				}}
				placement="bottom-end"
				triggerElement={children}
				isOpen={isTopNavigationMenuOpen}
			>
				<div className={classes.topOption}>
					<AvatarContainer width="40px" height="40px" shape="round" />
					<Flex flexDirection="column" gap="2px">
						<Typography variant="DesktopH8Medium" color="gray.1000">
							{user.firstName} {user.lastName}
						</Typography>
						<Typography variant="DesktopH8Regular" color="gray.600">
							{companyName}
						</Typography>
					</Flex>
				</div>

				{isDataHygieneEnabled && (
					<NavigationAvatarMenuItem
						text="Data Hygiene"
						leftIcon={<HygieneOutline20 color={iconColor} />}
						onClick={() => {
							navigate({ path: DataHygienePath });
						}}
					/>
				)}
				{isSelfServiceConfigEnabled && (
					<AccessController permission={Permissions.readConfiguration}>
						<NavigationAvatarMenuItem
							text="Configuration"
							leftIcon={<ConfigurationSolid16 color={iconColor} />}
							onClick={() => {
								navigate({ path: ConfigurationPath });
							}}
						/>
					</AccessController>
				)}

				{isAccountSettingsEnabled && (
					<div>
						<div className={classes.dividerWrapper}>
							<Divider direction="horizontal" />
						</div>
						<NavigationAvatarMenuItem
							text="Account settings"
							leftIcon={<SettingsSolid16 color={iconColor} />}
							onClick={openFrontegg}
						/>
					</div>
				)}

				{isSubscriptionsEnabled && (
					<NavigationAvatarMenuItem
						text="Manage subscriptions"
						leftIcon={<Newsletter16 color={iconColor} />}
						onClick={() => {
							navigate({ path: SubscriptionPagePath });
						}}
					/>
				)}

				<div className={classes.dividerWrapper}>
					<Divider direction="horizontal" />
				</div>

				<NavigationAvatarMenuItem
					text="Get support"
					leftIcon={<HelpCircleOutline20 color={iconColor} />}
					onClick={toggleIntercom}
				/>
				<NavigationAvatarMenuItem
					text="Help center"
					leftIcon={<GlobeOutline20 color={iconColor} />}
					onClick={() => {
						window.open(HELP_CENTER_PAGE_LINK);
					}}
				/>

				<div className={classes.dividerWrapper}>
					<Divider direction="horizontal" />
				</div>

				{isMultiTenant && (
					<NavigationAvatarMenuItem
						text="Switch tenant"
						leftIcon={<TenantsSolid16 color={iconColor} />}
						onClick={() => {
							setIsTopNavigationMenuOpen(false);
							onTenantPickerOpen();
						}}
					/>
				)}

				<NavigationAvatarMenuItem
					testId={TestIDs.LOGOUT_BUTTON}
					text="Logout"
					leftIcon={<Logout color={iconColor} />}
					onClick={logout}
				/>
			</Popover>
			<TenantPicker isOpen={isTenantPickerOpen} onClose={onTenantPickerClose} />
		</Flex>
	);
}

export default NavigationAvatarMenu;
