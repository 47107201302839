import { ChartType, ID_PREFIX_SERIES_TIME_SPAN_SECONDARY } from 'src/common/components/Chart/types';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import uniq from 'lodash/fp/uniq';
import stubTrue from 'lodash/fp/stubTrue';

export function calcCoreAvailableChartTypes(
	availableChartTypes: ChartType[],
	{ chartOptions }: Pick<MetricDerivedState, 'chartOptions'>,
	urlChartType?: MetricSearchParams['chartType']
): Pick<MetricDerivedState, 'chartType' | 'isTableView'> {
	const chartTypeFromVizRules =
		chartOptions.series.find((s) => s.custom.seriesType == 'component' && s.chartType)?.chartType ??
		chartOptions.series.at(0)?.chartType;

	if (!chartTypeFromVizRules) {
		console.warn('no chart type found, using column');
	}

	const chartTypeFromEngine = chartTypeFromVizRules ?? 'column';

	const isTableView = urlChartType == 'table';

	const isOverrideChartTypeAllowed = urlChartType && !isTableView && availableChartTypes.includes(urlChartType);

	const selectedChartType = urlChartType && isOverrideChartTypeAllowed ? urlChartType : chartTypeFromEngine;

	const chartTypesAll = stubTrue;
	const chartTypesPopSupported = (chartType: ChartType) => ['line', 'column'].includes(chartType);
	const isPop = chartOptions.series.some(({ id }) => id?.startsWith(ID_PREFIX_SERIES_TIME_SPAN_SECONDARY));

	const activeChartTypes = uniq(availableChartTypes).filter(isPop ? chartTypesPopSupported : chartTypesAll);

	const activeSelectedChartType = activeChartTypes.includes(selectedChartType) ? selectedChartType : 'column';

	return {
		chartType: { selectedValue: activeSelectedChartType, optionalValues: activeChartTypes },
		isTableView,
	};
}
