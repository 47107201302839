import { Box, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import Modal from 'src/common/components/Modal';
import { useModal } from 'src/common/hooks/ui/useModal';
import useToast from 'src/common/hooks/ui/useToast';
import { useShareSignalApi } from 'src/common/hooks/useShareSignalApi';
import { FillDetailsModal, ModalDetails } from 'src/pages/MetricPage/components/ShareSignalModal/FillDetailsModal';
import { CollectionType, Signal } from 'src/types/signal';

export function EditSignalModal({
	signal,
	metricDisplayName,
	collectionType,
	isModalOpen,
	onModalClose = () => void 0,
}: {
	signal: Signal;
	metricDisplayName: string;
	collectionType: CollectionType;
	isModalOpen: boolean;
	onModalClose: () => void;
}) {
	const { isOpen, onClose: onHookClose } = useDisclosure();
	const {
		isOpen: isOpenUpsertCollection,
		onOpen: onOpenUpsertCollection,
		onClose: onCloseUpsertCollection,
	} = useModal();
	const [shareSignal, { mutationError, isLoading }] = useShareSignalApi(signal);
	const showToast = useToast();

	useEffect(() => {
		if (mutationError) showToast({ variant: 'error', message: `Error sharing signal: ${mutationError.message}` });
	}, [mutationError, showToast]);

	const onClose = () => {
		onHookClose();
		onModalClose?.();
	};
	function onSubmit({ description, title }: ModalDetails) {
		shareSignal({
			attachmentContentURL: signal.attachment ?? '',
			message: description,
			collectionToPin: [],
			collectionToUnPin: [],
			title: title,
			signalId: signal.id,
		}).then(onClose);
	}

	return (
		<Box>
			<Modal
				maxHeight={isOpenUpsertCollection ? '0' : 'auto'}
				isOpen={isOpen || isModalOpen}
				onClose={onClose}
				isCentered
				maxWidth={'420px'}
			>
				<FillDetailsModal
					modalTitle={'Rename signal'}
					upsertCollectionModalState={{
						isOpenUpsertCollection,
						onOpenUpsertCollection,
						onCloseUpsertCollection,
					}}
					metricTitle={signal.title || metricDisplayName}
					description={signal.message}
					onSubmit={onSubmit}
					collectionType={collectionType}
					isLoading={isLoading}
					onClose={onClose}
					isShowingPublishAs={false}
					submitButtonText="Save"
				/>
			</Modal>
		</Box>
	);
}
