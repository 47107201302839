import { Flex, Skeleton } from '@chakra-ui/react';
import BuilderTextInput from 'src/common/components/BuilderTextInput';
import Typography from 'src/common/components/Typography';
import { SuggestionsContextSettings } from 'src/lib/completions/hooks/useMonacoTextFieldProviders';
import { LabelWithIcon } from 'src/pages/MetricPage/components/CalculatePanel/Builder/components/LabelWithIcon';
import colors from 'src/style/colors';

interface MappingTextInputProps {
	heading: string;
	subtitle: string;
	placeholder: string;
	values?: string[];
	onChange?: (values: string[]) => void;
	id: string;
	isOptional?: boolean;
	onFocus?: () => void;
	onBlur?: () => void;
	isLoading?: boolean;
	contextSettings: SuggestionsContextSettings;
}

export function MappingTextInput({
	heading,
	subtitle,
	placeholder,
	values = [],
	onChange,
	id,
	isOptional = false,
	onFocus,
	onBlur,
	isLoading = false,
	contextSettings,
}: MappingTextInputProps) {
	if (values.length === 0) {
		values = [''];
	}
	const isLastValueEmpty = values[values.length - 1] === '';

	return (
		<Flex direction="column" gap="12px">
			<Flex direction="column" gap="4px">
				<Flex gap="4px" alignItems="center">
					<Typography variant="DesktopH8Medium" color={colors.gray[1000]}>
						{heading}
					</Typography>
					{isOptional && (
						<Typography variant="DesktopH10Regular" color={colors.gray[800]}>
							(Optional)
						</Typography>
					)}
				</Flex>
				<Typography variant="Paragraph12R" color={colors.gray[800]}>
					{subtitle}
				</Typography>
			</Flex>

			<Flex direction="column" gap="8px">
				{!isLoading ? (
					<>
						{values.map((value, index) => (
							<BuilderTextInput
								key={`${id}-${index}`}
								placeholder={placeholder}
								validation={'None'}
								validationText={''}
								onChange={(newValue) => {
									const newValues = [...values];
									newValues[index] = newValue;
									onChange?.(newValues);
								}}
								contextSettings={isLoading ? undefined : contextSettings}
								id={`${id}-${index}`}
								text={value}
								fieldName={`${id}-${index}`}
								hasPrefixIcon={false}
								onFocus={() => {
									setTimeout(() => {
										onFocus?.();
									}, 50);
								}}
								onBlur={() => {
									onBlur?.();
								}}
							/>
						))}

						{!isLastValueEmpty && (
							<LabelWithIcon
								text={'Fallback'}
								onClick={() => {
									onChange?.([...values, '']);
								}}
								isFieldsDisabled={false}
							/>
						)}
					</>
				) : (
					<Flex width={'100%'} height={'44px'} backgroundColor={colors.gray[200]} borderRadius={'8px'}>
						<Skeleton width="100%" height="100%" borderRadius="12px" startColor="gray.100" endColor="gray.200" />
					</Flex>
				)}
			</Flex>
		</Flex>
	);
}
