import { AskAIChatCardsList } from '../AskAIChatCardList';
import { useCallback } from 'react';
import { useAskAIChatMessages } from '../../hooks/useAskAIChatMessages';
import { DrawerFooter, DrawerHeader } from '../../../Drawer';
import { AIChatHeader } from '@pages/AskAI/components/AIChatHeader';
import { AIChatItem } from '@pages/AskAI/components/AIChatItem';

type AskAIMetricDiscoveryChatPageProps = {
	onBack: () => void;
	onClose: () => void;
};

export function AskAIMetricDiscoveryChatPage({ onBack, onClose }: AskAIMetricDiscoveryChatPageProps) {
	const { addChatMessage } = useAskAIChatMessages();

	const onSubmit = useCallback(
		(userPrompt: string) => {
			addChatMessage({ userPrompt, type: 'metric-discovery' });
		},
		[addChatMessage]
	);

	return (
		<>
			<DrawerHeader padding={0}>
				<AIChatHeader onClose={onClose} onBack={onBack} />
			</DrawerHeader>

			<AskAIChatCardsList />

			<DrawerFooter padding={0}>
				<AIChatItem onSubmit={onSubmit} />
			</DrawerFooter>
		</>
	);
}
