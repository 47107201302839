import * as yaml from 'js-yaml';
import Box from '@components/Box';
import Button from '@components/Button';
import Flex from '@components/Flex';
import { ArrowUpRight, BarChart16, BarChartEmoji16, Code16, Copy16, Hammer16, Sparkles16 } from '@components/Icons';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import { Provider } from 'jotai';
import React, { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import {
	IsMetricPageURLBasedAtom,
	MetricPageSearchParamsAtom,
} from 'src/pages/MetricPage/atoms/MetricPageSearchParams';
import Link from '../../../Link';
import Spacer from '../../../Spacer';
import { useAskAIChatMessages } from '../../hooks/useAskAIChatMessages';
import { useCachedPerformDiscovery } from '../../hooks/useCachedPerformDiscovery';
import { useOpenInNewTab } from '../../hooks/useOpenInNewTab';
import { useReportAIEvent } from '../../hooks/useReportAIEvent';
import {
	AskAIChatCardProps,
	AskAIChatMessage,
	OnCalcResultsReceived,
	OnCalcResultsStarted,
	DiscoverMetricsSuggested,
	MetricDiscoveryPromptClass,
	ChatEventWithText,
	isChatEventAnalyzing,
	isChatEventCalculating,
} from '../../types';
import { AskAIMetricChartPreview } from './AskAIChatMetricPreview';
import { ActionChip, ActionChipsGroup } from '../AskAIActionChips';
import { AskAICreateNewMetricPopover } from '../../AskAICreateNewMetricPopover';
import { useModal } from '@hooks/ui/useModal';
import {
	useSemanticDefinitions,
	useSemanticsGetMetricDisplayName,
} from '../../../../hooks/stores/useSemanticDefinitions';
import { AskAIChatEvents, AskAIChatEventsProps } from '../AskAIChatEvents';
import { Code } from '@chakra-ui/react';
import { useOnCopy } from '../../../../hooks/interaction/useOnCopy';
import { useReportEvent } from '../../../../../services/analytics';

function AdditionalActionClickable({
	index,
	icon,
	onClick,
	onHover,
	children,
	testId,
}: {
	index: number;
	onClick: () => void;
	onHover?: (hover: boolean) => void;
	icon?: JSX.Element;
	children: React.ReactNode;
	testId: string;
}) {
	const withBorder = useMemo(() => index > 0, [index]);

	const onLinkClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
		(event) => {
			event.preventDefault();
			onClick();
		},
		[onClick]
	);

	const onMouseOver = useCallback(() => {
		onHover?.(true);
	}, [onHover]);

	const onMouseOut = useCallback(() => {
		onHover?.(false);
	}, [onHover]);

	return (
		<Link
			_hover={{ textDecoration: 'none' }}
			_active={{ textDecoration: 'none' }}
			data-testid={testId}
			width={'100%'}
			alignItems={'center'}
			onClick={onLinkClick}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseOut}
		>
			{withBorder && <Box margin={'0 44px'} borderTop={'1px'} borderColor={'gray.300'} />}
			<Flex
				_hover={{ backgroundColor: 'gray.200' }}
				gap={'12px'}
				alignItems={'center'}
				padding={'0 44px 0 16px'}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
			>
				<Flex alignSelf={'start'} marginTop={'16px'}>
					{icon}
				</Flex>
				<Flex padding={'12px 0'} gap={'12px'} width={'100%'} alignItems={'center'}>
					{children}
				</Flex>
			</Flex>
		</Link>
	);
}

function AdditionalMetricAction({
	index,
	metrics,
	testId,
	userPrompt,
}: {
	userPrompt: string;
	index: number;
	metrics: DiscoverMetricsSuggested[];
	testId: string;
}) {
	const { semanticDefinitions } = useSemanticDefinitions();
	const getMetricDisplayName = useSemanticsGetMetricDisplayName();
	const { reportAskAIEvent } = useReportAIEvent();
	const { addChatMessage } = useAskAIChatMessages();
	const metric = metrics[index];
	const { onOpenInNewTab } = useOpenInNewTab({ metric, userPrompt });
	const metricDisplay = getMetricDisplayName(metric.metric);
	const breakdownDisplayName = useMemo(() => {
		if (!metric.breakdown) return;
		const entityName = semanticDefinitions?.metrics.find((metricDef) => metricDef.name === metric.metric)?.entity;
		if (!entityName) return;
		const entity = semanticDefinitions.entities.find((entity) => entity.name === entityName);
		const dimension = entity?.dimensions.find((dimension) => dimension.name === metric.breakdown);
		return dimension?.meta?.display_name ?? metric.breakdown;
	}, [metric.breakdown, metric.metric, semanticDefinitions]);

	const [showActions, setShowActions] = useState(false);

	const onHover = useCallback((hover: boolean) => {
		setShowActions(hover);
	}, []);

	const previewMetricInCard = useCallback(() => {
		reportAskAIEvent({
			event: 'ask-ai-discovery-suggestion-click',
			metaData: {
				metric,
			},
		});
		addChatMessage({
			type: 'metric-discovery',
			userPrompt: `${userPrompt} using metric ${metric.metric}`,
		});
	}, [addChatMessage, userPrompt, metric, reportAskAIEvent]);

	return (
		<AdditionalActionClickable
			index={index}
			onClick={previewMetricInCard}
			onHover={onHover}
			testId={testId}
			icon={<BarChartEmoji16 />}
		>
			<Flex width={'100%'} alignItems={'center'}>
				{metricDisplay}
				{breakdownDisplayName && ` by ${breakdownDisplayName}`}
				<Spacer />
				{showActions && (
					<Box maxHeight={'24px'}>
						<Tooltip
							label={'Open in metric page'}
							size={'md'}
							variant={'fluid'}
							background={'black'}
							placement={'top'}
							marginBottom="8px"
						>
							<Button
								onClick={onOpenInNewTab}
								variant={'outline'}
								colorScheme={'gray'}
								size={'xxs'}
								isIconOnly={true}
								textColor={'gray.900'}
								blendMode={'multiply'}
								borderRadius={'4px'}
							>
								<ArrowUpRight />
							</Button>
						</Tooltip>
					</Box>
				)}
			</Flex>
		</AdditionalActionClickable>
	);
}

function AdditionalActionAskExample({ index, example, testId }: { index: number; example: string; testId: string }) {
	const { reportAskAIEvent } = useReportAIEvent();
	const { addChatMessage } = useAskAIChatMessages();

	const askExample = useCallback(() => {
		reportAskAIEvent({
			event: 'ask-ai-discovery-example-click',
			metaData: {
				example,
			},
		});
		addChatMessage({ userPrompt: example, type: 'metric-discovery' });
	}, [addChatMessage, example, reportAskAIEvent]);

	return (
		<AdditionalActionClickable index={index} onClick={askExample} testId={testId} icon={<Sparkles16 />}>
			<Flex alignItems={'center'}>{example}</Flex>
		</AdditionalActionClickable>
	);
}

function AdditionalActionsHeader({ children }: { children: React.ReactNode }) {
	return (
		<Box padding={'12px 16px 8px 16px'}>
			<Typography variant="Paragraph14M" color={'gray.1000'}>
				{children}
			</Typography>
		</Box>
	);
}

function AdditionalActionsContent({ children }: { children: React.ReactNode }) {
	return <Flex direction={'column'}>{children}</Flex>;
}

function AdditionalMetricActions({
	metrics,
	chatMessage,
	chatIndex,
}: {
	chatIndex: number;
	chatMessage: AskAIChatMessage;
	metrics: DiscoverMetricsSuggested[];
}) {
	return metrics.map((metric, index) => (
		<MetricPreviewGuard metricName={metric.metric} key={index}>
			<AdditionalMetricAction
				index={index}
				metrics={metrics}
				userPrompt={chatMessage.userPrompt}
				testId={`ask-ai-chat-discovery-${chatIndex}-additional-metric-action-${index}`}
			/>
		</MetricPreviewGuard>
	));
}

function AdditionalExampleActions({ examples, chatIndex }: { chatIndex: number; examples: string[] }) {
	return examples.map((example, index) => (
		<AdditionalActionAskExample
			key={index}
			index={index}
			example={example}
			testId={`ask-ai-chat-discovery-${chatIndex}-additional-example-action-${index}`}
		/>
	));
}

function AdditionalActions({
	additionalMetrics,
	isRunning,
	examples,
	createNewMetricPrompt,
	chatMessage,
	chatIndex,
}: {
	chatIndex: number;
	chatMessage: AskAIChatMessage;
	isRunning: boolean;
	additionalMetrics: DiscoverMetricsSuggested[];
	createNewMetricPrompt?: string;
	examples: string[];
	classification?: MetricDiscoveryPromptClass;
}) {
	const hasMetrics = additionalMetrics.length > 0;
	const pluralMetrics = additionalMetrics.length > 1;
	const hasExamples = examples.length > 0;
	if (isRunning || (!hasMetrics && !hasExamples && !createNewMetricPrompt)) {
		return <Box />;
	}

	return (
		<Flex
			paddingBottom={'8px'}
			direction={'column'}
			backgroundColor={'gray.100'}
			borderTop={'1px solid'}
			borderColor={'gray.300'}
			borderRadius={'0 0 8px 8px'}
		>
			{hasMetrics && (
				<>
					<AdditionalActionsHeader>
						{`Here ${pluralMetrics ? 'are' : 'is'} ${additionalMetrics.length} more relevant metric${
							pluralMetrics ? 's' : ''
						}:`}
					</AdditionalActionsHeader>
					<AdditionalActionsContent>
						<AdditionalMetricActions metrics={additionalMetrics} chatMessage={chatMessage} chatIndex={chatIndex} />
					</AdditionalActionsContent>
				</>
			)}
			{hasExamples && (
				<>
					<AdditionalActionsHeader>{`Here are a few examples of questions you can ask:`}</AdditionalActionsHeader>
					<AdditionalActionsContent>
						<AdditionalExampleActions examples={examples} chatIndex={chatIndex} />
					</AdditionalActionsContent>
				</>
			)}
		</Flex>
	);
}

function CreateNewMetricActionChip({ prompt }: { prompt: string }) {
	const { isOpen, onOpen, onClose } = useModal();

	return (
		<AskAICreateNewMetricPopover
			prompt={prompt}
			key={'create-new-metric'}
			createInNewTab={true}
			isSelectMetricTypeOpen={isOpen}
			onSelectMetricTypeClose={onClose}
		>
			<ActionChip onClick={onOpen} icon={<Hammer16 />} testId={'create-new-metric'} metadata={{ prompt }}>
				Build a new metric
			</ActionChip>
		</AskAICreateNewMetricPopover>
	);
}

function ChatActionChips({
	metrics = [],
	chatMessage,
	isRunning,
}: {
	metrics?: DiscoverMetricsSuggested[];
	chatMessage: AskAIChatMessage;
	isRunning: boolean;
}) {
	const createNewMetricPrompt = metrics.length === 0 ? chatMessage.userPrompt : undefined;
	if (isRunning || !createNewMetricPrompt) return null;
	return (
		<ActionChipsGroup>
			<CreateNewMetricActionChip prompt={createNewMetricPrompt} />
		</ActionChipsGroup>
	);
}

export function AskAIMetricDiscoveryChatSuggestions({
	metrics = [],
	examples = [],
	classification = 'discovery',
	onCalcResultsReceived,
	onCalcResultsStarted,
	chatMessageAtom,
	chatIndex,
	chatEvents,
	isRunning,
}: AskAIChatCardProps &
	AskAIChatEventsProps & {
		classification?: MetricDiscoveryPromptClass;
		metrics?: DiscoverMetricsSuggested[];
		examples?: string[];
		onCalcResultsReceived: OnCalcResultsReceived;
		onCalcResultsStarted: OnCalcResultsStarted;
	}) {
	const { chatMessage } = useCachedPerformDiscovery({ chatMessageAtom });

	const allMetrics = [...metrics];
	const previewMetric = allMetrics.shift();
	const additionalMetrics = allMetrics;
	const shouldShowPreview = classification === 'results' ? !!previewMetric?.resultDescription : true;

	return (
		<Flex gap={'16px'} direction={'column'}>
			{previewMetric && (
				<MetricPreviewGuard metricName={previewMetric.metric}>
					<MetricResultsPanel
						previewMetric={previewMetric}
						onCalcResultsReceived={onCalcResultsReceived}
						onCalcResultsStarted={onCalcResultsStarted}
						chatMessage={chatMessage}
						chatIndex={chatIndex}
						chatEvents={chatEvents}
						shouldShowPreview={shouldShowPreview}
					/>
				</MetricPreviewGuard>
			)}
			<ChatActionChips metrics={metrics} chatMessage={chatMessage} isRunning={isRunning} />
			<AskAIChatEvents chatEvents={chatEvents} isRunning={isRunning} />
			<AdditionalActions
				isRunning={isRunning}
				additionalMetrics={additionalMetrics}
				classification={classification}
				examples={examples}
				createNewMetricPrompt={!metrics || metrics.length === 0 ? chatMessage.userPrompt : undefined}
				chatMessage={chatMessage}
				chatIndex={chatIndex}
			/>
		</Flex>
	);
}

type ViewPanelType = 'chart' | 'semantic' | 'sql';

function ViewPanelSelectButton({
	isActive,
	viewName,
	onClick,
	icon,
	children,
}: {
	onClick: (viweName: ViewPanelType) => void;
	isActive: boolean;
	viewName: ViewPanelType;
	icon: React.ReactElement;
	children: React.ReactNode;
}) {
	const { wrapWithReport } = useReportEvent({ viewName, isActive });
	return (
		<Button
			variant={'outline'}
			colorScheme={'lightGray'}
			size={'xs'}
			onClick={wrapWithReport(() => onClick(viewName), `ask-ai-discovery-view-panel-select-${viewName}-button`)}
			backgroundColor={isActive ? 'gray.200' : undefined}
			leftIcon={icon}
		>
			{children}
		</Button>
	);
}

function CopyToClipboardButton({ text, viewName }: { text?: string; viewName: string }) {
	const { onCopyText } = useOnCopy();
	const { wrapWithReport } = useReportEvent();

	const onCopyClicked = useCallback(
		(text: string) => {
			void onCopyText({ contentToCopy: text, message: 'Copied to clipboard', variant: 'ok' });
		},
		[onCopyText]
	);

	return (
		<Box position={'absolute'} right={'8px'} top={'8px'}>
			<Tooltip label={'Copy to clipboard'} size={'md'} variant={'fluid'}>
				<Button
					variant={'outline'}
					colorScheme={'gray'}
					size={'xs'}
					onClick={wrapWithReport(() => text && onCopyClicked(text), 'ask-ai-discovery-copy-to-clipboard', {
						viewName,
					})}
					isIconOnly={true}
				>
					<Copy16 />
				</Button>
			</Tooltip>
		</Box>
	);
}

function MetricResultsPanel({
	previewMetric,
	onCalcResultsStarted,
	onCalcResultsReceived,
	chatMessage,
	chatIndex,
	chatEvents,
	shouldShowPreview,
}: {
	chatIndex: number;
	chatMessage: AskAIChatMessage;
	chatEvents?: ChatEventWithText[];
	shouldShowPreview: boolean;
	previewMetric: DiscoverMetricsSuggested;
	onCalcResultsReceived: OnCalcResultsReceived;
	onCalcResultsStarted: OnCalcResultsStarted;
}) {
	const [selectedView, setSelectedView] = useState<ViewPanelType>('chart');
	const { onOpenInNewTab } = useOpenInNewTab({ metric: previewMetric, userPrompt: chatMessage.userPrompt });

	const semanticQuery = useMemo(
		() => yaml.dump(chatEvents?.find((event) => isChatEventCalculating(event))?.data.metric),
		[chatEvents]
	);

	const sqlText = useMemo(
		() => chatEvents?.find((event) => isChatEventAnalyzing(event))?.data.results?.sqlQuery,
		[chatEvents]
	);

	if (!previewMetric) return;

	return (
		<Flex direction={'column'} gap={'12px'} padding={'0 16px'} display={shouldShowPreview ? undefined : 'none'}>
			<Flex justifyContent={'space-between'}>
				<Flex gap={'8px'} alignItems={'center'}>
					<ViewPanelSelectButton
						isActive={selectedView === 'chart'}
						viewName={'chart'}
						onClick={setSelectedView}
						icon={<BarChart16 />}
					>
						Preview
					</ViewPanelSelectButton>
					<ViewPanelSelectButton
						isActive={selectedView === 'semantic'}
						viewName={'semantic'}
						onClick={setSelectedView}
						icon={<Code16 />}
					>
						Semantic Query
					</ViewPanelSelectButton>
					<ViewPanelSelectButton
						isActive={selectedView === 'sql'}
						viewName={'sql'}
						onClick={setSelectedView}
						icon={<Code16 />}
					>
						SQL
					</ViewPanelSelectButton>
				</Flex>
				<Tooltip label={'Open in metric page'} size={'md'} variant={'fluid'}>
					<Button variant={'outline'} colorScheme={'gray'} size={'xs'} onClick={onOpenInNewTab} isIconOnly={true}>
						<ArrowUpRight />
					</Button>
				</Tooltip>
			</Flex>
			<Box maxHeight={'260px'}>
				<Box display={selectedView === 'chart' ? 'unset' : 'none'}>
					<AskAIMetricChartPreview
						metric={previewMetric}
						showPreview={shouldShowPreview}
						onCalcResultsReceived={onCalcResultsReceived}
						onCalcResultsStarted={onCalcResultsStarted}
						onClick={onOpenInNewTab}
						testId={`ask-ai-chat-discovery-${chatIndex}-metric-preview`}
					/>
				</Box>
				<Box display={selectedView === 'semantic' ? 'unset' : 'none'}>
					<Code
						backgroundColor={'unset'}
						whiteSpace={'pre-wrap'}
						fontSize={'12px'}
						lineHeight={'16px'}
						width={'100%'}
						height={'260px'}
						padding={'8px 12px'}
						border={'1px solid'}
						borderRadius={'8px'}
						borderColor={'gray.300'}
						overflow={'auto'}
						position={'relative'}
					>
						<CopyToClipboardButton text={semanticQuery} viewName={selectedView} />
						{semanticQuery}
					</Code>
				</Box>
				<Box display={selectedView === 'sql' ? 'unset' : 'none'}>
					<Code
						backgroundColor={'unset'}
						whiteSpace={'pre-wrap'}
						fontSize={'12px'}
						lineHeight={'16px'}
						height={'260px'}
						width={'100%'}
						padding={'8px 12px'}
						border={'1px solid'}
						borderRadius={'8px'}
						borderColor={'gray.300'}
						overflow={'auto'}
						position={'relative'}
					>
						<CopyToClipboardButton text={sqlText} viewName={selectedView} />
						{sqlText}
					</Code>
				</Box>
			</Box>
		</Flex>
	);
}

function MetricPreviewGuard({ metricName, children }: { metricName: string; children: React.ReactNode }) {
	return (
		<Provider
			initialValues={[
				[
					MetricPageSearchParamsAtom,
					{
						metricName: metricName,
						searchParams: new URLSearchParams(),
					},
				],
				[IsMetricPageURLBasedAtom, false],
			]}
		>
			{children}
		</Provider>
	);
}
