import { MetricType } from 'src/common/types/common';

export const CREATE_METRIC_PAGE = ['scratch', 'template'];

export const METRIC_COPIES: {
	title: string;
	description: string;
	subDescription?: string;
	type: MetricType;
	isComingSoon?: boolean;
}[] = [
	{
		title: 'Aggregate metric',
		description: 'For calculating a new value based on your data.',
		subDescription: 'Best for Bookings, New Leads, and Total ARR metrics.',
		type: 'aggregate',
	},
	{
		title: 'Formula metric',
		description: 'Use existing metrics as building blocks to calculate rates, sums, etc.',
		subDescription: 'Best for Funnel Conversion Rate, Pipeline Waterfall and Cost per Lead metrics.',
		type: 'formula',
	},
];
export const DEFAULT_METRIC_TYPE: MetricType = 'aggregate';

export const NEW_METRIC_HELP_CENTER_LINK = 'https://docs.sightfull.com/metrics/metric_types/';
