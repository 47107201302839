import { Input } from '@chakra-ui/react';
import Box from '@components/Box';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { SelectLabel } from 'src/pages/MetricPage/components/CalculatePanel/Builder/components';

import colors from 'src/style/colors';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import shadows from 'src/style/shadows';
import { useReportEvent } from 'src/services/analytics';
import { useAtomValue } from 'jotai';
import { OntologyStateAtomDerived } from 'src/pages/OntologyPage/atoms/OntologyState';
import { OntologyPropertyType } from 'src/pages/OntologyPage/utils/types';
import { UNTITLED_METRIC_DISPLAY } from '@hooks/MetricHandling/useMetricEdit';

export function NameField({
	inputRef,
	isEditable = true,
	onNameUpdate,
	propertyDefinitionValueName,
	value,
	propertyType,
	isEditFlow = false,
	isWithNameReport = false,
	label = 'Name',
	isAutoFocused = false,
	isAutoSelected = false,
	isInvalid = false,
	isWithDefaultText = true,
	onBlur,
	placeholder = 'Add name',
	testId,
}: {
	inputRef?: MutableRefObject<HTMLInputElement | null>;
	isEditable?: boolean;
	onNameUpdate: (val: string) => void;
	propertyDefinitionValueName?: string;
	value?: string;
	propertyType?: OntologyPropertyType | 'entity' | 'metric';
	isEditFlow?: boolean;
	isWithNameReport?: boolean;
	label?: string;
	isAutoFocused?: boolean;
	isAutoSelected?: boolean;
	isInvalid?: boolean;
	onBlur?: VoidFunction;
	placeholder?: string;
	isWithDefaultText?: boolean;
	testId?: string;
}) {
	const nameRef = useRef<HTMLInputElement | null>(null);
	const { reportEvent } = useReportEvent();
	const ontologyState = useAtomValue(OntologyStateAtomDerived);
	const [displayName, setDisplayName] = useState(
		value || removeUnderscoresAndCapitalize(propertyDefinitionValueName || UNTITLED_METRIC_DISPLAY)
	);

	useEffect(() => {
		if (value === '') setDisplayName(UNTITLED_METRIC_DISPLAY);
		if (value === undefined) {
			setDisplayName(removeUnderscoresAndCapitalize(propertyDefinitionValueName || UNTITLED_METRIC_DISPLAY));
			return;
		}
		setDisplayName(value);
	}, [propertyDefinitionValueName, value]);

	useEffect(() => {
		if (isAutoSelected) {
			const elementRef = inputRef || nameRef;
			elementRef?.current?.select();
		}
	}, [isAutoSelected, inputRef]);

	const onNameChange = (nameValue: string) => {
		onNameUpdate(nameValue);
		setDisplayName(nameValue);
	};

	return (
		<Box>
			<SelectLabel marginBottom={'12px'} color={colors.gray[1100]} text={label} />
			<Input
				data-testid={testId}
				ref={inputRef || nameRef}
				autoFocus={isAutoFocused}
				_disabled={{ opacity: 1 }}
				isDisabled={!isEditable}
				fontSize="14px"
				onChange={(e) => onNameChange(e.target.value)}
				onBlur={() => {
					if (isWithNameReport)
						reportEvent({
							event: 'ontology-object-edit-UI-input-provided',
							metaData: {
								parentEntity: ontologyState.loading ? '' : ontologyState.entityName,
								objectType: propertyType,
								objectName: propertyDefinitionValueName,
								fieldname: `name`,
								input: value,
								flow: isEditFlow ? 'edit' : 'create',
							},
						});

					if (!displayName?.trim() && isWithDefaultText) onNameChange(UNTITLED_METRIC_DISPLAY);

					onBlur?.();
				}}
				placeholder={placeholder}
				color={isInvalid ? colors.red[800] : colors.gray[1000]}
				size="md"
				value={displayName}
				_focusVisible={{ borderColor: colors.gray[900], boxShadow: shadows.focus.medium.gray[100] }}
				border={'1px solid'}
				borderColor={isInvalid ? colors.red[800] : colors.gray[400]}
				backgroundColor={isInvalid ? colors.red[100] : !isEditable ? colors.gray[100] : colors.white}
				_hover={{ borderColor: isInvalid ? colors.red[800] : colors.gray[500] }}
				_focus={{ borderColor: isInvalid ? colors.red[800] : colors.gray[600] }}
				borderRadius={'8px'}
				_placeholder={{ color: isInvalid ? colors.red[300] : colors.gray[500] }}
			/>
		</Box>
	);
}
