import { AskAIModalItem } from '@components/AskAI/AskAIModalItem';
import Box from '@components/Box';
import { AskAIInput } from '@components/AskAI/AskAIInput';

export function AIChatItem({ onSubmit }: { onSubmit: (userPrompt: string) => void }) {
	return (
		<AskAIModalItem backgroundColor={'gray.100'} borderTop={true}>
			<Box padding={'8px'} flex={1}>
				<AskAIInput
					placeholder={'Ask any business question'}
					hasBorder={true}
					onSubmit={onSubmit}
					borderRadius={'32px'}
					submitButtonBorderRadius={'26px'}
					clearInputOnSubmit={true}
					size={'sm'}
					reportEvent={'ask-ai-chat-query'}
					reportFeature={'Ask AI'}
				/>
			</Box>
		</AskAIModalItem>
	);
}
