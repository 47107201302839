import Flex from '@components/Flex';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import { buildIntercomAttributes } from 'src/common/utils/domElements';

export function StringValueDisplay({
	value,
	hint,
	tooltip,
	isEditing,
	isEnabled,
	onStartEditing,
	isIntercomDetectable = false,
}: {
	value?: string;
	hint: string;
	tooltip?: string;
	isEditing: boolean;
	isEnabled: boolean;
	onStartEditing: () => void;
	isIntercomDetectable?: boolean;
}) {
	// TODO: Can a value be empty? (=='')
	const isChoosingValue = value == undefined || value == '';
	return (
		<Tooltip size={'sm'} label={tooltip} variant="fluid" placement={'right'} as={'div'} shouldWrapChildren={false}>
			<Flex
				alignItems={'center'}
				color={isChoosingValue ? 'gray.700' : 'gray.1000'}
				padding={'8px'}
				borderRadius={'6px'}
				width={'100%'}
				backgroundColor={isEditing ? 'gray.200' : 'transparent'}
				_hover={{
					backgroundColor: !isEnabled ? 'transparent' : 'blue.100',
				}}
				cursor={isEnabled ? 'pointer' : 'default'}
				onClick={isEnabled ? onStartEditing : undefined}
				{...(isIntercomDetectable &&
					buildIntercomAttributes({
						type: 'button',
						target: isChoosingValue ? 'investigation-dropdown-empty-value' : 'investigation-dropdown-filled-value',
					}))}
			>
				<Typography
					variant={'DesktopH10Regular'}
					textOverflow={'ellipsis'}
					whiteSpace={'nowrap'}
					overflow={'hidden'}
					wordBreak={'break-all'}
					as={'div'}
					width={'100%'}
				>
					{isChoosingValue ? hint : value}
				</Typography>
			</Flex>
		</Tooltip>
	);
}
