import { ReactNode } from 'react';
import { useModal } from 'src/common/hooks/ui/useModal';
import colors from 'src/style/colors';
import Box from '../../Box';
import Flex from '../../Flex';
import { ChevronDown16, ChevronUp16, Lock16, Office } from '../../Icons';
import { Label } from '../../Labels';
import Popover from '../../Popover';
import Typography from '../../Typography';
import { RoleType } from '../constants';

type GeneralAccessSelectOption = {
	title: string;
	description: string;
	icon: ReactNode;
	role: RoleType | null;
	isComingSoon: boolean;
};

export function GeneralAccessSelect({
	organizationName,
	generalAccessRole,
	onChangeGeneralAccessRole,
	isEditable,
}: {
	organizationName: string;
	generalAccessRole: RoleType | null;
	onChangeGeneralAccessRole: (role: RoleType | null) => void;
	isEditable: boolean;
}) {
	const options: GeneralAccessSelectOption[] = [
		{
			title: organizationName,
			description: 'Anyone in your organization can access.',
			icon: <Office />,
			role: 'viewer',
			isComingSoon: false,
		},
		{
			title: 'Only people invited',
			description: 'Only people invited can access.',
			icon: <Lock16 />,
			role: null,
			isComingSoon: true,
		},
	];

	const selectedOption = options.find((option) => option.role === generalAccessRole) || options[0];

	const { isOpen, onToggle, onClose } = useModal();

	return (
		<Popover
			closeOnBlur={true}
			isOpen={isOpen}
			variant={'outline'}
			onClose={onClose}
			placement="bottom"
			popoverContentProps={{
				borderRadius: '8px',
				boxShadow: 'var(--chakra-shadows-lg)',
			}}
			triggerElement={
				<Flex
					justifyContent={'space-between'}
					alignItems={'center'}
					padding={'11px 12px 11px 16px'}
					gap={'16px'}
					borderRadius={'8px'}
					border={`1px solid ${isEditable && isOpen ? colors.gray[700] : colors.gray[300]}`}
					backgroundColor={isEditable ? 'white' : 'gray.100'}
					cursor={isEditable ? 'pointer' : 'default'}
					onClick={isEditable ? onToggle : undefined}
					width={'418px'}
					_hover={{ borderColor: !isEditable ? colors.gray[300] : isOpen ? colors.gray[700] : colors.gray[500] }}
				>
					<Flex alignItems={'center'} gap={'12px'}>
						<Box color={'gray.1000'}>{selectedOption.icon}</Box>
						<Flex flexDirection={'column'} gap={'4px'}>
							<Typography variant={'DesktopH8Regular'} color={'gray.1000'}>
								{selectedOption.title}
							</Typography>
							<Typography variant={'DesktopH10Regular'} color={'gray.700'}>
								{selectedOption.description}
							</Typography>
						</Flex>
					</Flex>
					<Box color={isEditable ? 'gray.900' : 'gray.600'}>{isOpen ? <ChevronUp16 /> : <ChevronDown16 />}</Box>
				</Flex>
			}
		>
			<Flex direction={'column'} paddingY="8px">
				{options.map((option) => (
					<GeneralAccessSelectListItem
						key={option.title}
						title={option.title}
						description={option.description}
						icon={option.icon}
						isComingSoon={option.isComingSoon}
						isSelected={option.role === selectedOption.role}
						onClick={() => {
							onClose();
							if (option.role !== selectedOption.role) {
								onChangeGeneralAccessRole(option.role);
							}
						}}
					/>
				))}
			</Flex>
		</Popover>
	);
}

function GeneralAccessSelectListItem({
	title,
	description,
	icon,
	isSelected,
	isComingSoon,
	onClick,
}: {
	title: string;
	description: string;
	icon: ReactNode;
	isSelected: boolean;
	isComingSoon: boolean;
	onClick?: () => void;
}) {
	return (
		<Flex
			direction={'row'}
			padding={'10px 16px'}
			gap={'12px'}
			alignItems={'start'}
			_hover={{ backgroundColor: !isComingSoon ? 'blue.100' : undefined }}
			backgroundColor={isSelected ? 'gray.200' : 'white'}
			cursor={!isComingSoon ? 'pointer' : 'default'}
			onClick={!isComingSoon ? onClick : undefined}
			width={'416px'}
			color={!isComingSoon ? 'gray.1000' : 'gray.700'}
		>
			<Box width={'16px'} height={'16px'}>
				{icon}
			</Box>
			<Flex gap={'4px'} direction={'column'} flex={1}>
				<Typography variant="DesktopH8Regular">{title}</Typography>
				<Typography variant="DesktopH10Regular" color={'gray.700'}>
					{description}
				</Typography>
			</Flex>
			{isComingSoon && (
				<Label variant="filled" colorScheme="primary" style="light" size="small">
					Coming soon
				</Label>
			)}
		</Flex>
	);
}
